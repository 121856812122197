<template>
  <div class="" style="height:100%; width:100%;">
    <highcharts :constructorType="'stockChart'" class="hc" :options="chartOptions" :ref="chartId"
      style="height:100%; width:100%;"></highcharts>
  </div>
</template>

<script>
import { mapActions /*mapMutations*/ } from "vuex";

export default {
  name: "TechnicalAnalysis",
  components: {},
  data() {
    return {
      chartId: 'Chart-' + Math.floor(Math.random() * 999999).toString(),
      chartOptions: {
        credits: {
          enabled: false,
          text: "OptionEdge.in",
          href: "https://optionedge.in",
        },
        chart: {
          borderWidth: 0,
          zoomType: 'y',
          panning: {
            enabled: true,
            type: 'y'
          },
          panKey: 'shift'
        },
        plotOptions: {
          candlestick: {
            color: '#ef5350',
            upColor: '#26a69a',
            upLineColor: '#26a69a',
            lineColor: '#ef5350',
          },
          line: {
            color: 'darkgray'
          },
          spline: {
            color: 'darkgray'
          },
          series: {
            animation: false
          }
        },
        rangeSelector: {
          enabled: false,
        },
        navigator: {
          enabled: false
        },
        stockTools: {
          gui: {
            enabled: false,
          },
        },
        title: {
          useHTML: true,
          text: "Please click on Refresh Chart button to update with latest data",
          floating: true,
          style: {
            wordWrap: 'break-word',
            'text-align': 'center'
          }
        },
        scrollbar: {
          enabled: false
        },
        legend: {
          enabled: true,
        },
        xAxis: {
          crosshair: {
            snap: false,
          },
          scrollbar: {
            enabled: false
          },
        },
        yAxis: [
          {
            id: 'y-axis',
            height: "90%",
            crosshair: {
              interpolate: true,
              snap: false,
              label: {
                enabled: true,
                format: "{value:.2f}", // One decimal
              },
            },
          },
          {
            top: "80%",
            height: "20%",
            offset: 0,
          },
        ],
        series: [
          {
            type: "candlestick",
            showInLegend: false,
            id: "chartData",
            name: "Position",
            data: [],
            enableMouseTracking: true,
            tooltip: {
              valueDecimals: 2,
            },
          },
          {
            type: "column",
            id: "volume",
            name: "Volume",
            data: [],
            yAxis: 1,
            visible: true,
            showInLegend: false,
          },
          {
            type: "vwap",
            linkedTo: "chartData",
            showInLegend: false,
            enableMouseTracking: true,
            tooltip: {
              valueDecimals: 2,
            },
            color: "red",
            lineWidth: 1,
            params: {
              period: 400,
            },
            dataGrouping: {
              enabled: false,
            },
          },
          // {
          //   type: 'pivotpoints',
          //   linkedTo: 'chartData',
          //   zIndex: 90000,
          //   lineWidth: 2,
          //   lineColor: 'blue',
          //   visible: true,
          //   params: {
          //       algorithm: 'fibonacci',
          //       period: 21
          //   },
          //   name: 'Fibonacci PP (14)',
          //   dataLabels: {
          //       overflow: 'none',
          //       crop: false,
          //       y: 4,
          //       style: {
          //           fontSize: 9
          //       }
          //   }
          // },
          // {
          //   type: 'vbp',
          //   linkedTo: 'chartData',
          //   showInLegend: true
          // },
          // {
          //   type: 'bb',
          //   linkedTo: 'chartData'
          // },
          // {
          //   type: 'ikh',
          //   linkedTo: 'chartData',
          //   tenkanLine: {
          //       styles: {
          //           lineColor: 'lightblue',
          //       }
          //   },
          //   kijunLine: {
          //       styles: {
          //           lineColor: 'darkred'
          //       }
          //   },
          //   chikouLine: {
          //       styles: {
          //           lineColor: 'lightgreen'
          //       }
          //   },
          //   senkouSpanA: {
          //       styles: {
          //           lineColor: 'green'
          //       }
          //   },
          //   senkouSpanB: {
          //       styles: {
          //           lineColor: 'red'
          //       }
          //   },
          //   senkouSpan: {
          //       color: 'rgba(0, 255, 0, 0.3)',
          //       styles: {
          //           fill: 'rgba(0, 0, 255, 0.1)'
          //       }
          //   }
          // }          
          // {
          //   type: 'ema',
          //   linkedTo: 'chartData',
          //   visible: true,
          //   enableMouseTracking: false,
          //   showInLegend: false,
          //   color: 'blue',
          //   lineWidth: 1,
          //   params: {
          //       period: 31
          //   },
          //   dataGrouping: {
          //     enabled: false,
          //   },            
          // },
          {
            id: 'supertrend',
            type: 'supertrend',
            linkedTo: 'chartData',
            visible: true,
            lineWidth: 2,
            marker: {
              enabled: true
            },
            risingTrendColor: '#16C535',
            fallingTrendColor: 'orange',
            changeTrendLine: {
              styles: {
                lineWidth: 0.5,
                lineColor: '#000',
                dashStyle: 'Dash'
              }
            }
          },
        ],
      },
    };
  },
  props: ['ohlc', 'min', 'max', 'volume', 'chartTitle', 'showHideStopTarget', 'showHideExecuted', 'stop', 'target', 'executed', 'isLoading', 'isTrailing', 'chartType', 'showVolume', 'showNavigator', 'showSupertrend'],
  watch: {
    ohlc: function () {
      this.updateChartSeries();
    },
    showHideStopTarget: function () {
      this.updateStopTargetFlagAndPlotLines();
    },
    showHideExecuted: function () {
      this.updateStopTargetFlagAndPlotLines();
    },
    stop: function () {
      this.updateStopFlagAndPlotLines();
    },
    target: function () {
      this.updateTargetFlagAndPlotLines();
    },
    chartType: function () {
      this.updateTargetFlagAndPlotLines();
    },
    chartTitle() {
      this.updateChartSeries();
    },
    isLoading(value) {
      // if (value)
      //   this.showLoading()
      // else
      //   this.hideLoading();
      console.log(value)
    }
  },
  methods: {
    ...mapActions([
      "sendMessage",
      "subscribeToLiveFeed",
      "sendClientNotification",
      "showMessageAlert",
    ]),
    updateStopTargetFlagAndPlotLines() {
      let yAxis = this.$refs[this.chartId].chart.get('y-axis');

      if (this.showHideExecuted) {
        yAxis.removePlotLine('executed-line');

        yAxis.addPlotLine({
          id: 'executed-line',
          color: 'blue',
          dashStyle: 'shortdash',
          label: {
            align: 'left',
            text: 'executed: ' + parseFloat(this.executed.toFixed(2))
          },
          width: 2,
          value: parseFloat(this.executed)
        })
      }


      if (this.showHideStopTarget) {
        // Add plot lines & flag for stop target   

        this.updateStopFlagAndPlotLines();
        this.updateTargetFlagAndPlotLines();

      } else {
        yAxis.removePlotLine('stop-line');
        yAxis.removePlotLine('target-line');
        yAxis.removePlotBand('stop-plot-band');
        yAxis.removePlotBand('target-plot-band');
      }
    },
    updateStopFlagAndPlotLines() {
      if (!this.showHideStopTarget) return

      let yAxis = this.$refs[this.chartId].chart.get('y-axis');
      yAxis.removePlotLine('stop-line');
      yAxis.addPlotLine({
        id: 'stop-line',
        color: 'orange',
        dashStyle: 'shortdash',
        label: {
          align: 'left',
          text: 'stop: ' + parseFloat(this.stop).toFixed(2)
        },
        width: 2,
        value: parseFloat(this.stop)
      })

      yAxis.removePlotBand('stop-plot-band');
      yAxis.addPlotBand({
        id: 'stop-plot-band',
        from: this.executed,
        to: this.stop,
        color: 'rgba(230, 64, 34, 0.1)',
        label: {
          text: 'Stop Range'
        }
      })
    },
    updateTargetFlagAndPlotLines() {
      if (!this.showHideStopTarget) return

      let yAxis = this.$refs[this.chartId].chart.get('y-axis');
      yAxis.removePlotLine('target-line');
      yAxis.addPlotLine({
        id: 'target-line',
        color: 'darkgreen',
        dashStyle: 'shortdash',
        label: {
          align: 'left',
          text: 'target: ' + parseFloat(this.target).toFixed(2)
        },
        width: 2,
        value: parseFloat(this.target)
      })

      yAxis.removePlotBand('target-plot-band');
      yAxis.addPlotBand({
        id: 'target-plot-band',
        from: this.isTrailing ? this.stop : this.executed,
        to: this.target,
        color: 'rgba(36, 200, 34, 0.1)',
        label: {
          text: 'Target Range'
        }
      })
    },
    showLoading() {
      this.$refs[this.chartId].chart.showLoading();
    },
    hideLoading() {
      this.$refs[this.chartId].chart.hideLoading();
    },
    updateChartSeries() {

      this.chartOptions.series[0].type = this.chartType

      if (this.showSupertrend == true) {
        // this.$refs[this.chartId].chart.series[3].show()

        this.chartOptions.series[3].visible = true
        // this.$refs[this.chartId].chart.series[3].setVisible(true)

        // let supertrend = this.$refs[this.chartId].chart.get("supertrend")
        // supertrend.visible = true
        // supertrend.show()
      }
      else {
        //this.$refs[this.chartId].chart.series[3].hide()

        this.chartOptions.series[3].visible = false
        // this.$refs[this.chartId].chart.series[3].setVisible(false)
        // let supertrend = this.$refs[this.chartId].chart.get("supertrend")
        // supertrend.visible = false
        // supertrend.hide()        
      }

      let yAxis = this.$refs[this.chartId].chart.get('y-axis');
      yAxis.setExtremes(this.min, this.max);

      // this.chartOptions.series[0].data = this.ohlc.map(
      //   ({ x, open, high, low, close }) => [x, open, high, low, close]
      // );

      this.chartOptions.series[0].data = []
      var seriesData = []

      if (this.ohlc) {
        seriesData = this.ohlc.map(
          ({ x, open, high, low, close }) => [x, open, high, low, close]
        );
      }

      for (let i = 0; i < seriesData.length; i++) {
        this.chartOptions.series[0].data.push(seriesData[i])
      }

      this.chartOptions.navigator.enabled = this.showNavigator

      this.chartOptions.series[1].visible = this.showVolume
      // this.chartOptions.series[3].visible = this.showVolume

      this.chartOptions.series[1].data = []

      if (this.volume) {
        for (let i = 0; i < this.volume.length; i++) {
          this.chartOptions.series[1].data.push({ x: this.volume[i].x, y: this.volume[i].y, color: this.volume[i].color });
        }
      }

      //this.chartOptions.series[1].data = this.volume.map(({ x, y}) => [x, y]);

      this.chartOptions.title.text = this.chartTitle;

      this.$refs[this.chartId].chart.hideLoading();

      this.updateStopTargetFlagAndPlotLines();
    },
  },
  mounted() {
    // setTimeout(() => {
    //   this.$refs[this.chartId].chart.setSize(null);
    // }, 1000);

    this.updateChartSeries();
    this.updateStopTargetFlagAndPlotLines();
    this.updateStopFlagAndPlotLines();
    this.updateTargetFlagAndPlotLines();
  }
};
</script>

<style scoped></style>
