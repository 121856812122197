<template>
  <div>
    <h2>Holidays</h2>
  </div>
</template>

<script>
// import _ from 'lodash'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Holidays',
  components: {
  },
  computed: {
    ...mapState({
      showHideBasketEditor: state => state.showHideBasketEditor,
    }),
  },
  methods: {
    ...mapActions([
    ]),
  }
}
</script>

<style scoped>
</style>