let crosshairs = {
  show: true,
  width: 2,
  position: 'back',
  opacity: 0.9,
  stroke: {
    color: 'orange',
    width: 2,
    dashArray: 0,
  },
  fill: {
    type: 'solid',
    color: '#B1B9C4',
    gradient: {
      colorFrom: '#D8E3F0',
      colorTo: '#BED1E6',
      stops: [0, 100],
      opacityFrom: 0.4,
      opacityTo: 0.5,
    },
  },
  dropShadow: {
    enabled: false,
    top: 1,
    left: 1,
    blur: 1,
    opacity: 0.4,
  },
}

function editorPayoff(id, xseries, underlyingAtNow, strikeIncrementStep) {
  console.log(underlyingAtNow) 

  let localUnderlyingAtNow = Math.ceil(underlyingAtNow / strikeIncrementStep) * strikeIncrementStep;

  return {
    chart: {
      id: id,
    },
    plotOptions: {
      line: {
        colors: {
          ranges: [
            {
              from: -9999999, // Any value less than this will be red
              to: 0,
              color: "#e85347" // Red color
            },
            {
              from: 0,
              to: 999999, // Any value greater than this will be green
              color: "#1ee0ac" // Green color
            }
          ]
        },
      }
    },
    xaxis: {
      categories: xseries,
      crosshairs: crosshairs,
      tickAmount: 10,
      labels: {
        show: true,
        rotate: 0,
        rotateAlways: false,
        hideOverlappingLabels: true,
        showDuplicates: false,
        trim: false,
        datetimeUTC: true,
        datetimeFormatter: {
          year: 'yyyy',
          month: "MMM 'yy",
          day: 'dd MMM',
          hour: 'HH:mm',
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value.toFixed(2)
        }
      },
      hideOverlappingLabels: true,
      tooltip: {
        enabled: true
      },
      crosshairs: crosshairs,
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          borderColor: 'red',
          borderWidth: 3,
          strokeDashArray: 0,
          label: {
            borderColor: '#00E396',
            style: {
              color: '#fff',
              background: '#00E396'
            }
          }
        }
      ],
      xaxis: [
        {
          x: localUnderlyingAtNow,
          borderColor: "red",
          strokeDashArray: 0,
          borderWidth: 3,
          label: {
            borderColor: "white",
            style: {
              color: "black",
              background: "white"
            },
            orientation: 'horizontal',
            text: localUnderlyingAtNow + ''
          }
        }
      ]
    },
    grid: {
      show: true,
      borderColor: 'lightgray',
      strokeDashArray: 0,
      position: 'back',
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0) + " Rs";
          }
          return y;
        }
      }
    },
  }
}

function executedPayoff(id, xseries, underlyingAtNow, strikeIncrementStep) {
  console.log(underlyingAtNow)

  let localUnderlyingAtNow = Math.ceil(underlyingAtNow / strikeIncrementStep) * strikeIncrementStep;

  return {
    chart: {
      id: id,
    },
    xaxis: {
      categories: xseries,
      crosshairs: crosshairs,
      tickAmount: 10,
      labels: {
        show: true,
        rotate: 0,
        rotateAlways: false,
        hideOverlappingLabels: true,
        showDuplicates: false,
        trim: false,
        datetimeUTC: true,
        datetimeFormatter: {
          year: 'yyyy',
          month: "MMM 'yy",
          day: 'dd MMM',
          hour: 'HH:mm',
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value.toFixed(2)
        }
      },
      tooltip: {
        enabled: true
      },
      crosshairs: crosshairs,
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          borderColor: 'red',
          borderWidth: 3,
          strokeDashArray: 0,
          label: {
            borderColor: '#00E396',
            style: {
              color: '#fff',
              background: '#00E396'
            }
          }
        }
      ],
      xaxis: [
        {
          x: localUnderlyingAtNow,
          borderColor: "red",
          strokeDashArray: 0,
          borderWidth: 3,
          label: {
            borderColor: "white",
            style: {
              color: "black",
              background: "white"
            },
            orientation: 'horizontal',
            text: localUnderlyingAtNow + ''
          }
        }
      ]
    },
    grid: {
      show: true,
      borderColor: 'lightgray',
      strokeDashArray: 0,
      position: 'back',
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0) + " Rs";
          }
          return y;
        }
      }
    },
  }
}


export default {
  editorPayoff: editorPayoff,
  executedPayoff: executedPayoff,
};