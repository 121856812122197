import Api from '@/services/Api'
import moment from 'moment/moment'

export default {
  generateRandomStrategyName(prefix, suffix, length) {
    if (!length)
      length = 7

    var s = ''
    var randomchar = function () {
      var n = Math.floor(Math.random() * 62)
      if (n < 10) return n //1-10
      if (n < 36) return String.fromCharCode(n + 55) //A-Z
      return String.fromCharCode(n + 61) //a-z
    }
    while (s.length < length) s += randomchar()
    return (prefix ? prefix : '') + s + (suffix ? suffix : '')
  },
  getBrokers() {
    return [
      {
        id: -1, name: 'Choose...'
      },
      {
        id: 1, name: 'AliceBlue'
      },
      {
        id: 8, name: 'SAS'
      },
      {
        id: 9, name: 'Dhan'
      }
    ]
  },
  getStrikeDiff(instrument) {
    if (instrument == 'nifty')
      return 50
    else if (instrument == 'banknifty')
      return 100
    else if (instrument == 'finnifty')
      return 50
    else if (instrument == 'midcpnifty')
      return 25
    else
      return 50
  },
  async getRecentAvailableFeedDate() {
    let result = Date.now()
    try {
      let response = await Api().get(`/recent-feed-date`);
      result = response.data.date;
    } catch (error) {
      console.log(error)
    }
    return result
  },
  async getAtm(instrument) {
    let result = -1
    try {
      let response = await Api().get(`/atm/${instrument}`);
      result = response.data.atm;
    } catch (error) {
      console.log(error)
    }
    return result
  },
  async getAllBrokers() {
    let result = []
    try {
      let response = await Api().get(`/brokers/all`);
      result = response.data.brokers;
    } catch (error) {
      console.log(error)
    }
    return result
  },
  async saveBrokerConfig(brokerConfig) {
    let result = []
    try {
      let response = await Api().post(`/brokers`, brokerConfig);
      result = response;
    } catch (error) {
      console.log(error)
    }
    return result
  },
  async deleteBrokerConfig(id) {
    let result = []
    try {
      let response = await Api().delete(`/brokers/${id}`);
      result = response;
    } catch (error) {
      console.log(error)
    }
    return result
  },

  async startBrokerSession(id) {
    let result = []
    try {
      let response = await Api().post(`/broker-session/start/${id}`);
      result = response;
    } catch (error) {
      console.log(error)
    }
    return result
  },

  async stopBrokerSession(id) {
    let result = []
    try {
      let response = await Api().post(`/broker-session/stop/${id}`);
      result = response;
    } catch (error) {
      console.log(error)
    }
    return result
  },

  async startBrokerTicker(id) {
    let result = []
    try {
      let response = await Api().post(`/broker-ticker/start/${id}`);
      result = response;
    } catch (error) {
      console.log(error)
    }
    return result
  },

  async getActiveFeedSourceId(type) {
    let result = []
    try {
      let response = await Api().get(`/get-feed-source/${type}`);
      result = response.data.id;
    } catch (error) {
      console.log(error)
    }
    return result
  },

  async changeFeedSource(type, id) {
    let result = []
    try {
      let response = await Api().get(`/change-feed-source/${type}/${id}`);
      result = response;
    } catch (error) {
      console.log(error)
    }
    return result
  },

  async stopBrokerTicker(id) {
    let result = []
    try {
      let response = await Api().post(`/broker-ticker/stop/${id}`);
      result = response;
    } catch (error) {
      console.log(error)
    }
    return result
  },

  async getStrikeInterval(instrument) {
    let result = -1
    try {
      let response = await Api().get(`/strike-interval/${instrument}`);
      result = response.data.interval;
    } catch (error) {
      console.log(error)
    }
    return result
  },
  async getExpiry(instrument, expiry_type) {
    let result = '';
    try {
      let response = await Api().get(`/expiry/${instrument}/${expiry_type}`);
      result = response.data.expiry
    } catch (error) {
      console.log(error)
    }

    return result;
  },
  async getExpiries(forDate, instrument, type) {
    let result = '';
    if (!type)
      type = "all";

    try {
      let response = await Api().get(`/expiries/${forDate}/${instrument}/${type}`);
      result = response.data.expiries
    } catch (error) {
      console.log(error)
    }

    return result;
  },
  async getExecutionMode() {
    let result = '';
    try {
      let response = await Api().get(`/execution-mode`);
      result = response.data.executionMode
    } catch (error) {
      console.log(error)
    }

    return result;
  },
  async getInstrumentInfos() {
    let result;

    try {
      let response = await Api().get(`/instrument-infos`);
      result = response.data.instrumentInfos
    } catch (error) {
      console.log(error)
    }

    return result;
  },
  async getSymbolOptions(instrument, expiry, strike, option_type) {
    let momentDate = moment(expiry)
    let symbol = `${instrument}${momentDate.format('DDMMMYY')}${option_type.substring(0, 1)}${strike}`.toLocaleUpperCase()
    return symbol
  },
  async getSymbolFutures(instrument, expiry) {
    let momentDate = moment(expiry)
    let symbol = `${instrument}${momentDate.format('YYMMM')}FUT`;
    return symbol.toLocaleUpperCase();
  },
  getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  },
  downloadCSV(content, fileName, mimeType) {
    var a = document.createElement('a');
    mimeType = mimeType || 'application/octet-stream';

    if (navigator.msSaveBlob) { // IE10
      navigator.msSaveBlob(new Blob([content], {
        type: mimeType
      }), fileName);
    } else if (URL && 'download' in a) {
      a.href = URL.createObjectURL(new Blob([content], {
        type: mimeType
      }));
      a.setAttribute('download', fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      location.href = 'data:application/octet-stream,' + encodeURIComponent(content);
    }
  }
}