<template>
  <div class="fix-at-bottom"
    style="z-index:9;padding-top: 2px; padding-right:5px;padding-left:5px; border: 1px dotted gray;">
    <div>
      <!-- <hr style="height: 3px;border: none;color:gray;background-color: gray;"/> -->
      <table style="margin: 0 auto;width:100%;">
        <tr>
          <td>
            <label class="d-block" style="width: 100px;"> <b>{{ activeOptionChain.toUpperCase() }}</b></label>
          </td>

          <td>
            <input class="form-control form-control-sm" style="width:80px; display:inline"
              v-model="optionSimulatorParams.strikeUpDownMaxRangeValue" @change="strikeUpDownMaxRangeValueChanged"
              :step="optionSimulatorParams.strikeMaxRangeStep" type="number" title="Strike Max Up/Down Range" />
          </td>

          <!-- <td>
            <a @click="getTimestamps()" title="Get Intervals"
            class="nav-link" href="javascript:void(0);">
              <span class="material-icons" >
              send
              </span>
            </a>
          </td> -->

          <td>
            <button @click="goBackToStart()" class="btn btn-outline-secondary" title="Day Start"><i class="fa-solid fa-backward-step"></i></button>
          </td>

          <td>
            <button @click="moveBackward(oneDayInMinutes)" class="btn btn-outline-secondary" title="1 Day">1D
              &#8592;</button>
          </td>

          <td>
            <button @click="moveBackward(60)" class="btn btn-outline-secondary" title="1 Hour">1H &#8592;</button>
          </td>

          <td>
            <button @click="moveBackward(30)" class="btn btn-outline-secondary" title="30 Minutes">30M &#8592;</button>
          </td>

          <td>
            <button @click="moveBackward(15)" class="btn btn-outline-secondary" title="15 Minutes">15M &#8592;</button>
          </td>

          <td>
            <button @click="moveBackward(5)" class="btn btn-outline-secondary" title="5 Minutes">5M &#8592;</button>
          </td>

          <td>
            <button @click="moveBackward(3)" class="btn btn-outline-secondary" title="3 Minutes">3M &#8592;</button>
          </td>

          <td>
            <button @click="moveBackward(1)" class="btn btn-outline-secondary" title="1 Minute">1M &#8592;</button>
          </td>

          <td>
            <!-- <datepicker v-model="optionSimulatorParams.onDate" @selected="datePickerSelected"
              :disabled-dates="optionSimulatorParams.datePickerDisabledDates" format="dd-MMM-yyyy"
              input-class="form-control" style="width:110px;"></datepicker> -->

              <VueDatePicker 
                v-model="optionSimulatorParams.onDate" 
                text-input 
                format="dd-MMM-yyyy"
                style="width:150px;"
                :action-row="{ showSelect: false, showCancel: false, showNow: true }"
                @date-update="datePickerSelected"
                :disabled-dates="optionSimulatorParams.datePickerDisabledDates.dates"
                :disabled-week-days="optionSimulatorParams.datePickerDisabledDates.days"
                :enable-time-picker="false"></VueDatePicker>
          </td>

          <td>
            <b>{{ formattedTimestamp }}</b>
          </td>

          <td>
            <button @click="moveForward(1)" class="btn btn-outline-secondary" title="1 Minute">1M &#8594;</button>
          </td>

          <td>
            <button @click="moveForward(3)" class="btn btn-outline-secondary" title="3 Minutes">3M &#8594;</button>
          </td>

          <td>
            <button @click="moveForward(5)" class="btn btn-outline-secondary" title="5 Minutes">5Min &#8594;</button>
          </td>

          <td>
            <button @click="moveForward(15)" class="btn btn-outline-secondary" title="15 Minutes">15M &#8594;</button>
          </td>

          <td>
            <button @click="moveForward(30)" class="btn btn-outline-secondary" title="30 Minutes">30M &#8594;</button>
          </td>

          <td>
            <button @click="moveForward(60)" class="btn btn-outline-secondary" title="1 Hour">1H &#8594;</button>
          </td>

          <td>
            <button @click="moveForward(oneDayInMinutes)" class="btn btn-outline-secondary" title="1 Day">1D
              &#8594;</button>
          </td>

          <td>
            <button @click="goBackToEnd()" class="btn btn-outline-secondary" title="Day End"><i class="fa-solid fa-forward-step"></i></button>
          </td>

        </tr>
      </table>

      <!-- <hr style="height: 3px;border: none;color:gray;background-color: gray;"/> -->
    </div>
  </div>
</template>

<script>
// import _ from "lodash";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import moment from 'moment'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'OptionsSimulatorEx',
  components: {
    VueDatePicker
  },
  data() {
    return {
      strategyName: '',
      showPayOff: true,
      timestamps: [],
      expiries: [],
      timestamp: 1,
      startDate: '',
      payoffDate: '',
      formattedTimestamp: '',
      oneDayInMinutes: 1440,
      previousTimeStampForInstrumentInfos: '00-00-0000',
      marketStartTime: Date.now(),
      marketEndTime: Date.now()

    }
  },
  computed: {
    ...mapState({
      activeOptionChain: state => state.activeOptionChain,
      selectedOptionChainExpiry: state => state.selectedOptionChainExpiry,
      requestOptionChainForSimulatorTrigger: state => state.requestOptionChainForSimulatorTrigger,
      optionSimulatorParams: state => state.optionSimulatorParams,
      holidays: state => state.holidays,
      showHideOptionsSimulator: state => state.showHideOptionsSimulator,
      recentAvailableFeedDate: state => state.recentAvailableFeedDate
    }),
  },
  watch: {
    requestOptionChainForSimulatorTrigger: function () {
      console.log('requestOptionChainForSimulatorTrigger triggered')
      this.getOptionChain();
    },
  },
  methods: {
    ...mapActions([
      'requestOptionsSimulatorOptionChain',
      'getExpiriesForSimulator',
      'showMessageAlert',
      'requestPositionsTechnicalAnalysisDataTrigger',
      'getOptionChainExpiries',
      'getInstrumentInfos'
    ]),
    timestampFormat() {
      this.formattedTimestamp = moment(this.optionSimulatorParams.selectedTimeInUnix).format('HH:mm')
    },
    goBackToStart() {
      var date = moment(this.optionSimulatorParams.onDate).set({ hour: 9, minute: 15 })
      this.optionSimulatorParams.selectedTimeInUnix = parseInt(parseInt(date.valueOf()).toFixed(0))

      this.timestampSelected();
    },
    goBackToEnd() {
      var date = moment(this.optionSimulatorParams.onDate).set({ hour: 15, minute: 29 })
      this.optionSimulatorParams.selectedTimeInUnix = parseInt(parseInt(date.valueOf()).toFixed(0))

      this.timestampSelected(true);
    },    
    datePickerSelected(date) {
      this.optionSimulatorParams.onDate = date
      this.timestampSelected()
    },
    timestampSelected(shouldNotSetStart) {

      var date = moment(this.optionSimulatorParams.onDate)

      if (!shouldNotSetStart ) {
        date = moment(this.optionSimulatorParams.onDate).set({ hour: 9, minute: 15 })
        this.optionSimulatorParams.selectedTimeInUnix = parseInt(parseInt(date.valueOf()).toFixed(0))
        this.marketStartTime = this.optionSimulatorParams.selectedTimeInUnix
      }

      var marketEndTime = moment(this.optionSimulatorParams.onDate).set({ hour: 15, minute: 29 });
      this.marketEndTime = parseInt(parseInt(marketEndTime.valueOf()).toFixed(0))

      this.getExpiriesForSimulator(date.format('DD-MMM-YYYY'))
      this.timestampFormat();
      this.getOptionChainExpiries();
      this.getOptionChain();
    },
    strikeUpDownMaxRangeValueChanged() {
      if (this.optionSimulatorParams.selectedSymbol == "NIFTY 50")
        this.optionSimulatorParams.strikeUpDownMaxRangeNiftyUpdated = true
      else if (this.optionSimulatorParams.selectedSymbol == "NIFTY BANK")
        this.optionSimulatorParams.strikeUpDownMaxRangeNiftyBankUpdated = true
        else if (this.optionSimulatorParams.selectedSymbol == "SENSEX")
        this.optionSimulatorParams.strikeUpDownMaxRangeSensexUpdated = true      
      else if (this.optionSimulatorParams.selectedSymbol == "MIDCPNIFTY")
        this.optionSimulatorParams.strikeUpDownMaxRangeMidcapNiftyUpdated = true
      else if (this.optionSimulatorParams.selectedSymbol == "FINNIFTY")
        this.optionSimulatorParams.strikeUpDownMaxRangeFinNiftyUpdated = true

      this.getOptionChain()
    },
    // getTimestamps () {

    // },
    moveBackward: function (duration) {
      let previousTimeStamp = this.optionSimulatorParams.selectedTimeInUnix - duration * 60 * 1000

      if (duration === this.oneDayInMinutes) {
        let previousTimeStampMoment = moment(previousTimeStamp)
        if (previousTimeStampMoment.day() === 0 || previousTimeStampMoment.day() === 6 || previousTimeStampMoment.day() === 5) {
          let dayDecrement = 1;

          if (previousTimeStampMoment.day() === 0) {
            // set to monday
            dayDecrement = 2;
          } else if (previousTimeStampMoment.day() === 6) {
            // set to monday
            dayDecrement = 3;
          } else if (previousTimeStampMoment.day() === 5) {
            // set to monday
            dayDecrement = 4;
          }

          previousTimeStamp = previousTimeStamp - this.oneDayInMinutes * 60 * 1000 * dayDecrement

        }
        this.optionSimulatorParams.onDate = previousTimeStamp
        this.timestampSelected()
      }


      if (previousTimeStamp < this.marketStartTime) {
        // this.showMessageAlert('Start of the day reaached')
        return
      }

      this.optionSimulatorParams.selectedTimeInUnix = previousTimeStamp

      this.timestampFormat()
      this.getOptionChain()
      this.requestPositionsTechnicalAnalysisDataTrigger();
    },
    moveForward: function (duration) {

      console.log('Moving forward')
      let nextTimeStamp = this.optionSimulatorParams.selectedTimeInUnix + duration * 60 * 1000

      if (duration === this.oneDayInMinutes) {
        console.log('duration === this.oneDayInMinutes true')
        let nextTimeStampMoment = moment(nextTimeStamp)
        if (nextTimeStampMoment.day() == 0 || nextTimeStampMoment.day() == 6) {
          let dayIncrement = 1;

          if (nextTimeStampMoment.day() === 5) {
            // set to monday
            dayIncrement = 3;
          } else if (nextTimeStampMoment.day() === 6) {
            // set to monday
            dayIncrement = 2;
          }

          nextTimeStamp = nextTimeStamp + this.oneDayInMinutes * 60 * 1000 * dayIncrement
        }
        this.optionSimulatorParams.onDate = nextTimeStamp
        this.timestampSelected()
      }

      if (nextTimeStamp >= this.marketEndTime) {
        // this.showMessageAlert('End of the day reaached')
        return
      }

      this.optionSimulatorParams.selectedTimeInUnix = nextTimeStamp

      this.timestampFormat()

      console.log('Get optionchain called')
      this.getOptionChain()
      this.requestPositionsTechnicalAnalysisDataTrigger();
    },
    getOptionChain() {
      this.requestOptionsSimulatorOptionChain({
        simulatorType: 'ex', // ex - new, ops - opst
        onTimeStamp: moment(this.optionSimulatorParams.selectedTimeInUnix).format('DD-MMM-YYYY HH:mm'),
        forExpiry: this.selectedOptionChainExpiry,
        instrument: this.activeOptionChain,
        strikeInterval: this.optionSimulatorParams.strikeIntervalValue,
        strikeUpDownMaxRange: this.optionSimulatorParams.strikeUpDownMaxRangeValue
      });      

      setTimeout(() => {
        let formattedTS = moment(this.optionSimulatorParams.selectedTimeInUnix).format('DD-MMM-YYYY')
        if (this.previousTimeStampForInstrumentInfos != formattedTS) { 
          this.getInstrumentInfos();
          this.previousTimeStampForInstrumentInfos = formattedTS
        }
      }, 1000);      
    }
  },
  mounted() {
    setTimeout(() => {
      this.datePickerSelected(this.recentAvailableFeedDate)
    }, 3000);
  },
  activated() {
  }
}
</script>

<style scoped>
td {
  padding: 0 5px;
}
</style>
