<template>
  <div class="container">
    <div class="home">
        <!-- <div v-if="!$auth0.loading">
          <button v-if="!$auth0.isAuthenticated" @click="login">Log in</button>
          <button v-if="$auth0.isAuthenticated" @click="logout">Log out</button>
        </div> -->
        Home

        <input type="button" value="Send Test Message" @click="sendTestMessage" />
      </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: "home",
  components: {
  },
  methods: {
    ...mapActions([
      'sendTestMessage',
    ]),     
    // Log the user in
    login() {
      // this.$auth0.loginWithRedirect();
    },
    // Log the user out
    logout() {
      // this.$auth0.logout({
      //   logoutParams: {
      //     returnTo: 'http://localhost:8080',
      //   }
      // });
    }
  },
  mounted () {
    if (this.$route.query.error_description)
      alert(this.$route.query.error_description)
  }
};
</script>
