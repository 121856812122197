import {createStore, createLogger} from 'vuex'

import state from './state'
import mutations from './mutations'
import actions from './actions'

let debug = process.env.NODE_ENV !== 'production'

debug = false

const store = createStore({
  state: state,
  mutations: mutations,
  actions: actions,
  strict: debug,
  plugins: debug ? [createLogger()] : []
})

export default store

