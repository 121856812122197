<template>
  <div>
    <div class="border p-2 d-flex justify-content-center">
      <form class="row row-cols-lg-auto  align-items-center">
        <div class="col-12">
          <label class="visually-hidden" for="selectInstrument">Index</label>
          <select v-model="selectedInstrument" @change="optionChainInstrumentChanged" id="selectInstrument"
            class="form-select form-select-sm" >
            <option v-for="instrument in instrumentList" v-bind:value="instrument" v-bind:key="instrument"
              :selected="instrument == selectedInstrument">
              {{ instrument.toLocaleUpperCase() }}
            </option>
          </select>
        </div>
        <div class="col-12">
          <label class="visually-hidden" for="inlineFormSelectPref">Expiries</label>
          <select v-model="selectedExpiryDate" @change="optionChainExpiryChanged" id="inlineFormSelectPref"
            class="form-select form-select-sm" >
            <option v-for="expiry in optionChainExpiries" v-bind:value="expiry" v-bind:key="expiry"
              :selected="expiry === selectedExpiryDate">
              {{ expiry }}
            </option>
          </select>
        </div>
        <div class="ps-0 pe-0 ms-0 me-0">
          <span title="Lot Size">[{{ getLotSize() }}]</span>
        </div>
        <div class="col-12 ps-2 pe-0 ms-0 me-0">
          <button class="btn btn-outline-info border-0" v-on:click="requestOptionChainInternal" title="Refresh Option Chain" role="button">
            <i class="fa-solid fa-rotate"></i>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState, mapActions } from 'vuex'

export default {
  name: 'OptionChainIndexSpotPanel',
  components: {
  },
  data() {
    return {
      instrumentList: ['nifty', 'banknifty', 'finnifty', 'midcpnifty', "sensex"],
      expiries: []
    }
  },
  computed: {
    ...mapState({
      accountPnL: state => state.accountPnL,
      activeOptionChain: state => state.activeOptionChain,
      instrumentInfos: state => state.instrumentInfos,
      optionChainExpiries: state => state.optionChainExpiries,
      selectedOptionChainExpiry: state => state.selectedOptionChainExpiry,
      orderBasketsEditing: state => state.orderBasketsEditing,
      activeExecutedBasket: state => state.activeExecutedBasket,
      showHideChartsInOptionChain: state => state.showHideChartsInOptionChain,
      executedBasketsChartOptions: state => state.executedBasketsChartOptions,
      optionSimulatorParams: state => state.optionSimulatorParams,
      executionMode: state => state.executionMode,
      orderBaskets: state => state.orderBaskets,
      niftySpot: state => state.instruments['NIFTY 50'],
      bankNiftySpot: state => state.instruments['NIFTY BANK'],
      sensexSpot: state => state.instruments['SENSEX'],
      finNiftySpot: state => state.instruments['FINNIFTY'],
      midcapNiftySpot: state => state.instruments['MIDCPNIFTY'],
      indiaVIXSpot: state => state.instruments['INDIA VIX'],
      botMessages: state => state.botMessages,
      niftySpotChange: state => {
        var spot = state.instruments['NIFTY 50']
        var change = spot ? (spot.Ltp - spot.Close) : 0
        return change
      },
      niftySpotChangePercent: state => {
        var spot = state.instruments['NIFTY 50']
        var percent = spot ? (((spot.Ltp - spot.Close) / spot.Ltp) * 100) : 0
        return percent
      },
      bankNiftySpotChange: state => {
        var spot = state.instruments['NIFTY BANK']
        var change = spot ? (spot.Ltp - spot.Close) : 0
        return change
      },
      sensexSpotChange: state => {
        var spot = state.instruments['SENSEX']
        var change = spot ? (spot.Ltp - spot.Close) : 0
        return change
      },      
      bankNiftySpotChangePercent: state => {
        var spot = state.instruments['NIFTY BANK']
        var percent = spot ? (((spot.Ltp - spot.Close) / spot.Ltp) * 100) : 0
        return percent
      },
      finNiftySpotChange: state => {
        var spot = state.instruments['FINNIFTY']
        var change = spot ? (spot.Ltp - spot.Close) : 0
        return change
      },
      finNiftySpotChangePercent: state => {
        var spot = state.instruments['FINNIFTY']
        var percent = spot ? (((spot.Ltp - spot.Close) / spot.Ltp) * 100) : 0
        return percent
      },
      midcapNiftySpotChange: state => {
        var spot = state.instruments['MIDCPNIFTY']
        var change = spot ? (spot.Ltp - spot.Close) : 0
        return change
      },
      midcapNiftySpotChangePercent: state => {
        var spot = state.instruments['MIDCPNIFTY']
        var percent = spot ? (((spot.Ltp - spot.Close) / spot.Ltp) * 100) : 0
        return percent
      },
      indiaVIXSpotChange: state => {
        var spot = state.instruments['INDIA VIX']
        var change = spot ? (spot.Ltp - spot.Close) : 0
        return change
      },
      indiaVIXSpotChangePercent: state => {
        var spot = state.instruments['INDIA VIX']
        var percent = spot ? (((spot.Ltp - spot.Close) / spot.Ltp) * 100) : 0
        return percent
      },
      // expiries: state => state.expiries,
      bankNiftyExpiries: state => state.bankNiftyExpiries,
      finNiftyExpiries: state => state.finNiftyExpiries,
      midcapNiftyExpiries: state => state.midcapNiftyExpiries
    }),
    selectedExpiryDate: {
      get() {
        return this.$store.state.selectedOptionChainExpiry
      },
      set(value) {
        this.$store.commit('updateSelectedExpiry', value)
      }
    },
    selectedInstrument: {
      get() {
        return this.$store.state.activeOptionChain
      },
      set(value) {
        this.$store.commit('updateActiveOptionChain', value)
      }
    },
  },
  methods: {
    ...mapActions([
      'setActiveOptionChain',
      'clearAllBaskets',
      'sendClientNotification',
      'showHideFuturesPanelAction',
      'showHideOptionsChainPanelAction',
      'optionChainInstrumentChanged',
      'optionChainExpiryChanged'
    ]),
    getLotSize() {
      if (this.isObjectEmpty(this.instrumentInfos)) return ''
      else {
        let key = `${this.activeOptionChain}-${this.selectedExpiryDate}`.toUpperCase()
        return this.instrumentInfos[key] ? this.instrumentInfos[key].lotSize : ''   
      }
    },
    isObjectEmpty(obj) {
      return _.isEmpty(obj);
    },
    optionChainExpiryChanged() {
      this.setActiveOptionChain({ instrument: this.activeOptionChain, expiry: this.selectedOptionChainExpiry })
    },
    requestOptionChainInternal() {

      if (this.selectedOptionChainExpiry && this.selectedOptionChainExpiry.length === 0) {
        alert('Please select expiry.')
        return;
      }

      console.log('Setting attive option chain ' + this.selectedOptionChainExpiry)
      this.setActiveOptionChain({ instrument: this.activeOptionChain, expiry: this.selectedOptionChainExpiry })
    },
  },
  mounted() {
    // Get expiries

  }
}
</script>

<style scoped></style>
