<template>
  <div>

    <OptionChainIndexSpotPanel></OptionChainIndexSpotPanel>

    <div class="vl-parent">
      <slot></slot>
      <table class="table  table-sm text-center">
        <thead class="thead">

          <tr v-for="expiry in monthlies" v-bind:key="expiry">
            <th colspan="2">{{ expiry }}</th>
            <th colspan="2">
              {{ instruments['NIFTY' + getMonthlyFuturesBaseSymbol(expiry)] ? instruments['NIFTY' +
                getMonthlyFuturesBaseSymbol(expiry)].Ltp.toFixed(2) : 0 }}
            </th>
            <th colspan="1">
              <a href="javascript:void(0);"
                @click="addToBasketInternal(expiry, '', 'NIFTY' + getMonthlyFuturesBaseSymbol(expiry), 0, 'buy', 'f')">Buy</a>
              |
              <a href="javascript:void(0);"
                @click="addToBasketInternal(expiry, '', 'NIFTY' + getMonthlyFuturesBaseSymbol(expiry), 0, 'sell', 'f')">Sell</a>
            </th>
          </tr>


          <tr class="">
            <th colspan="2"><span class="">Calls</span></th>
            <th><span class="">
                {{ activeOptionChain.toUpperCase() }}
                {{ optionChain && optionChain.length > 0 ? optionChain[0].expiry : '' }}
              </span>
            </th>
            <th colspan="2"><span class="">Puts</span></th>
          </tr>

          <tr>
            <!-- <th>Change</th> -->
            <th>Action</th>
            <!-- <th>Change</th> -->
            <th>LTP</th>
            <th>Strike</th>
            <th>LTP</th>
            <!-- <th>Change</th> -->
            <th>Action</th>
            <!-- <th>Change</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in optionChain" v-bind:key="item.SymbolCall">

            <!--
        <td>
          {{instruments[item.SymbolCall] ? (instruments[item.SymbolCall].Close - instruments[item.SymbolCall].Ltp).toFixed(2) : 0.00}}
        </td>
-->
            <td v-bind:class="getStrikePriceWarning(item.Strike)" @mouseenter="sendBidAskSpreadRequest(item.SymbolCall)">
              <span data-html="true" :title="bidAskSpread.loading ? 'Loading...' : getBidAskSpreadMessage()">
                <a href="javascript:void(0);"
                  v-on:click.exact.prevent="addToBasketInternal(item.Expiry, 'ce', item.SymbolCall, item.Strike, 'buy', 'o')"
                  v-on:click.ctrl.prevent="addToBasketInternal(item.Expiry, 'ce', item.SymbolCall, item.Strike, 'buy', 'o', true)">Buy</a>
                |
                <a href="javascript:void(0);"
                  v-on:click.exact.prevent="addToBasketInternal(item.Expiry, 'ce', item.SymbolCall, item.Strike, 'sell', 'o')"
                  v-on:click.ctrl.prevent="addToBasketInternal(item.Expiry, 'ce', item.SymbolCall, item.Strike, 'sell', 'o', true)">Sell</a>
              </span>
            </td>

            <!-- <td>
          {{instruments[item.SymbolCall] ? (instruments[item.SymbolCall].Ltp - instruments[item.SymbolCall].Close).toFixed(2) : 0.00}}
        </td> -->

            <td v-bind:class="getInTheMoneyBackgroundClass(item.Strike, 'ce')">
              {{ instruments[item.SymbolCall] ? instruments[item.SymbolCall].Ltp.toFixed(2) : 0.00 }}
            </td>

            <td v-bind:class="getATMBackgroundClass(item.Strike)">{{ item.Strike }}</td>

            <td v-bind:class="getInTheMoneyBackgroundClass(item.Strike, 'pe')">
              {{ instruments[item.SymbolPut] ? instruments[item.SymbolPut].Ltp.toFixed(2) : 0.00 }}
            </td>

            <!-- <td >
          {{instruments[item.SymbolPut] ? (instruments[item.SymbolPut].Ltp - instruments[item.SymbolPut].Close).toFixed(2) : 0.00}}
        </td>         -->


            <td v-bind:class="getStrikePriceWarning(item.Strike)" @mouseenter="sendBidAskSpreadRequest(item.SymbolPut)">
              <span data-html="true" :title="bidAskSpread.loading ? 'Loading...' : getBidAskSpreadMessage()">
                <a href="javascript:void(0);"
                  v-on:click.exact.prevent="addToBasketInternal(item.Expiry, 'pe', item.SymbolPut, item.Strike, 'sell', 'o')"
                  v-on:click.ctrl.prevent="addToBasketInternal(item.Expiry, 'pe', item.SymbolPut, item.Strike, 'sell', 'o', true)">Sell</a>
                |
                <a href="javascript:void(0);"
                  v-on:click.exact.prevent="addToBasketInternal(item.Expiry, 'pe', item.SymbolPut, item.Strike, 'buy', 'o')"
                  v-on:click.ctrl.prevent="addToBasketInternal(item.Expiry, 'pe', item.SymbolPut, item.Strike, 'buy', 'o', true)">Buy</a>
              </span>
            </td>
            <!--        
        <td>{{instruments[item.SymbolPut] ? (instruments[item.SymbolPut].Close - instruments[item.SymbolPut].Ltp).toFixed(2) : 0.00}}</td>
-->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapActions } from 'vuex'
import OptionChainIndexSpotPanel from '@/components/OptionChainIndexSpotPanel'

export default {
  name: 'OptionChainNifty',
  components: {
    OptionChainIndexSpotPanel
  },
  data() {
    return {
      niftyFuturesExpiries: [],  // expiry, symbol
      bankniftyFuturesExpiries: []
    }
  },
  computed: {
    ...mapState({
      showHideFuturesPanel: state => state.showHideFuturesPanel,
      showHideOptionsChainPanel: state => state.showHideOptionsChainPanel,
      instruments: state => state.instruments,
      optionChain: state => state.optionChain.OptionChain,
      activeOptionChain: state => state.activeOptionChain,
      niftySpot: state => state.instruments['NIFTY 50'],
      bankNiftySpot: state => state.instruments['NIFTY BANK'],
      indiaVIXSpot: state => state.instruments['INDIA VIX'],
      monthlies: state => state.monthlies,
      bidAskSpread: state => state.bidAskSpread,
      niftySpotChange: state => {
        var spot = state.instruments['NIFTY 50']
        var change = spot ? (spot.Ltp - spot.Close) : 0
        return change
      },
      niftySpotChangePercent: state => {
        var spot = state.instruments['NIFTY 50']
        var percent = spot ? (((spot.Ltp - spot.Close) / spot.Ltp) * 100) : 0
        return percent
      },
      ATM: state => {
        var spot = state.instruments['NIFTY 50'] ? state.instruments['NIFTY 50'].Ltp : 0
        let strikeDiff = 50
        return spot % strikeDiff >= (strikeDiff / 2) ? Math.ceil(spot / strikeDiff) * strikeDiff : Math.floor(spot / strikeDiff) * strikeDiff
      },
      bankNiftySpotChange: state => {
        var spot = state.instruments['NIFTY BANK']
        var change = spot ? (spot.Ltp - spot.Close) : 0
        return change
      },
      bankNiftySpotChangePercent: state => {
        var spot = state.instruments['NIFTY BANK']
        var percent = spot ? (((spot.Ltp - spot.Close) / spot.Ltp) * 100) : 0
        return percent
      },
      indiaVIXSpotChange: state => {
        var spot = state.instruments['INDIA VIX']
        var change = spot ? (spot.Ltp - spot.Close) : 0
        return change
      },
      indiaVIXSpotChangePercent: state => {
        var spot = state.instruments['INDIA VIX']
        var percent = spot ? (((spot.Ltp - spot.Close) / spot.Ltp) * 100) : 0
        return percent
      }
    }),
  },
  methods: {
    ...mapActions([
      'requestOptionChain',
      'addToBasket',
      'sendBidAskSpreadRequest',
      'executeNewOrderBasketTrigger'
    ]),

    // Instrument Types: o -> Options, f -> Futures, s -> Stocks
    addToBasketInternal(expiry, optionType, symbol, strike, transactionType, instrumentType, shouldExecute) {

      // default instrumenType is 'o' -> Options
      if (!instrumentType)
        instrumentType = 'o'

      let order = {
        optionType,
        symbol,
        strike,
        transactionType,
        instrument: 'nifty',
        expiry: expiry,
        instrumentType: instrumentType
      }

      if (shouldExecute)
        this.executeNewOrderBasketTrigger(order)
      else
        this.addToBasket(order)

    },
    getBidAskSpreadMessage() {
      let goodOrWarningMessage = Math.abs(this.bidAskSpread.spread) > 1.5 ? 'WARNING -' : 'Good -'

      let message = `${goodOrWarningMessage} ${this.bidAskSpread.symbol}
Bid: ${this.bidAskSpread.bidPrice}, Ask: ${this.bidAskSpread.askPrice}
Spread: ${this.bidAskSpread.spread}
      `

      return message
    },
    getMonthlyFuturesBaseSymbol(expiry) {
      let splitted = expiry.split('-')
      let baseSymbol = `${splitted[2].substring(2, 4)}${splitted[1]}FUT`

      return baseSymbol
    },
    getInTheMoneyBackgroundClass(strike, optionType) {
      var niftySpotLtp = this.niftySpot ? this.niftySpot.Ltp : 0
      if (optionType == "ce") {
        if (strike <= niftySpotLtp || this.ATM === strike)
          return "atm-row"
      } else
        if (strike >= niftySpotLtp || this.ATM === strike)
          return "atm-row"
    },
    getATMBackgroundClass(strike) {
      if (this.ATM === strike)
        return "atm-strike-row"
      else
        return "non-atm-strike-row"
    },
    getStrikePriceWarning(strike) {
      if (parseInt(strike) % 100 === 0)
        return 'optionChainStrikePriceWarning'
      else
        return ''
    }

  },
  created() {
    // this.requestOptionChain('nifty')
    let currentMonth = moment()
    let nextMonth = currentMonth.clone().add(1, 'M')

    let currentMonthExpiryDate = `${currentMonth.format('MMM-YY').toUpperCase()}`
    let currentMonthSymbolBaseExpiry = `${currentMonth.format('YY')}${currentMonth.format('MMM').toUpperCase()}FUT`

    let nextMonthExpiryDate = `${nextMonth.format('MMM-YY').toUpperCase()}`
    let nextMonthSymbolBaseExpiry = `${nextMonth.format('YY')}${nextMonth.format('MMM').toUpperCase()}FUT`

    this.niftyFuturesExpiries.push({
      expiry: currentMonthExpiryDate,
      symbol: `NIFTY${currentMonthSymbolBaseExpiry}`
    })

    this.niftyFuturesExpiries.push({
      expiry: nextMonthExpiryDate,
      symbol: `NIFTY${nextMonthSymbolBaseExpiry}`
    })

    this.bankniftyFuturesExpiries.push({
      expiry: currentMonthExpiryDate,
      symbol: `BANKNIFTY${currentMonthSymbolBaseExpiry}`
    })

    this.bankniftyFuturesExpiries.push({
      expiry: nextMonthExpiryDate,
      symbol: `BANKNIFTY${nextMonthSymbolBaseExpiry}`
    })
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
