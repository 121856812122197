<template>
  <div class="container">
    <!-- <div v-if="!$auth0.loading">
      <button v-if="$auth0.isAuthenticated" @click="logout">Log out</button>
    </div>
    <br />
    <br /> -->
    <button @click="sendSubscribedSymbolsLtpRefreshRequest" type="button" class="btn btn-outline-info">Refresh Subscribed
      Symbols Ltp</button>
    <br />
    <p>Option Type</p>
    <input v-model="optionTypeInput" class="form-control" placeholder="option type">

    <p>Strike</p>
    <input v-model="strikeInput" type="number" class="form-control" placeholder="strike">

    <br />
    <p><button @click="getNFWeeklySymbol" type="button" class="btn btn-outline-info">Get NF Weekly Symbol</button></p>

    <p><button @click="sendGetRequiredMarginRequest" type="button" class="btn btn-primary">Request Margin</button></p>

    <p>Symbol: {{ symbol }}</p>
    <br />
    <hr />
    <p>Notification Message</p>
    <input v-model="notificationMessage" class="form-control" placeholder="strike">

    <p><button @click="sendClientNotificationInternal" type="button" class="btn btn-primary">Send Notification</button>
    </p>

    <p>Show Broker Terminal - {{ showBrokerTerminal }}</p>
    <input type="checkbox" v-model="showBrokerTerminal" class="form-control" />

    <p><button @click="sendTestMessage" type="button" class="btn btn-primary">Send Test Message</button></p>

    <p><button @click="apiCheck" type="button" class="btn btn-primary">Call Api</button></p>

  </div>
</template>

<script>
import SymbolService from '@/services/SymbolService'
import { mapActions } from 'vuex'
import Utils from "@/services/Utils";

export default {
  name: 'Landing',
  components: {
  },
  data() {
    return {
      symbol: 'Not Set',
      optionTypeInput: 'ce',
      strikeInput: 9600,
      notificationMessage: '',
    }
  },
  methods: {
    ...mapActions([
      'sendSubscribedSymbolsLtpRefreshRequest',
      'sendGetRequiredMarginRequest',
      'sendClientNotification',
      'sendMessage'
    ]),
    async getNFWeeklySymbol() {
      var response = await SymbolService.getNFWeeklySymbol({ optionType: this.optionTypeInput, strike: this.strikeInput })
      this.symbol = response.data
    },
    sendClientNotificationInternal() {
      this.sendClientNotification(this.notificationMessage)
    },
    sendTestMessage() {
      let testMessage = {
        MessageType: "test_message_client_request",
      };

      this.sendMessage(JSON.stringify(testMessage));
    },
    async apiCheck() {
      // let url = `http://localhost:6002/api/check`
      // var result =  await Api().get(url);
      // alert(JSON.stringify(result.data))
      alert(await Utils.getInstrumentInfos());
    },
    logout() {
    }
  }
}
</script>
