<template>
  <div>
    <h4>{{ title && title.length > 0 ? title : 'Setup Brokers' }}</h4>

    <div v-if="brokers.length > 0">
      <div class="table-responsive ">
        <table class="table table-hover table-borderless text-center">
          <thead>
            <tr class="fs-6">
              <!-- <th>Id</th> -->
              <th>Name</th>
              <th v-if="!compact">Broker</th>
              <th v-if="!compact">Live Feeds</th>
              <th v-if="!compact">Candle History</th>
              <!-- <th>Auto Login</th> -->
              <th>Trading</th>
              <th>Feeds</th>
              <th  v-if="!compact" style="width:200px">Set default broker for trades</th>
              <th v-if="executionMode == 'LIVE'">Action</th>
            </tr>
          </thead>
          <tbody class="table-group-divider fs-6">
            <tr v-for="broker in brokers" :key="broker.id">
              <!-- <td>{{ broker.id }}</td> -->
              <td>{{ broker.name }}</td>
              <td v-if="!compact">{{ broker.brokerTypeDescription }}</td>
              <td v-if="!compact">
                <i v-if="broker.isLiveFeedsSupported" class="fa-solid fa-check fa-xl" style="color: #075ced;"></i>
                <i v-else class="fa-solid fa-xmark fa-xl" style="color: red;"></i>
              </td>
              <td v-if="!compact">
                <i v-if="broker.isCandleHistorySupported" class="fa-solid fa-check fa-xl" style="color: #075ced;"></i>
                <i v-else class="fa-solid fa-xmark fa-xl" style="color: red;"></i>
              </td>
              <!-- <td>
                <i v-if="broker.isAutoLoginSupported" class="fa-solid fa-check fa-xl" style="color: #075ced;"></i>
                <i v-else class="fa-solid fa-xmark fa-xl" style="color: red;"></i>
              </td> -->
              <td>
                <button :disabled="broker.loading || broker.tickerLoading || executionMode == 'SIMULATION'"
                  v-if="broker.isTradingSessionActive == true" @click="stopBrokerSession(broker.id)"
                  class="btn btn-outline-primary me-2" title="Stop trading session" style="color: brown" href="#"
                  role="button">
                  <i v-if="!broker.sessionLoading" class="fa-solid fa-stop"></i>
                  <span v-if="broker.sessionLoading" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                </button>
                <button :disabled="broker.tickerLoading || executionMode == 'SIMULATION'" v-else
                  @click="startBrokerSession(broker.id)" class="btn btn-outline-primary me-2"
                  title="Start trading session" style="color: green" href="#" role="button">
                  <i v-if="!broker.sessionLoading" class="fa-solid fa-play"></i>
                  <span v-if="broker.sessionLoading" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                </button>
              </td>
              <td>
                <button :disabled="broker.tickerLoading || executionMode == 'SIMULATION'"
                  v-if="broker.isLiveFeedsActive == true" @click="stopBrokerTicker(broker.id)"
                  class="btn btn-outline-primary me-2" title="Stop live feeds" style="color: brown" href="#"
                  role="button">
                  <i v-if="!broker.tickerLoading" class="fa-solid fa-stop"></i>
                  <span v-if="broker.tickerLoading" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                </button>
                <button v-else :disabled="broker.tickerLoading || executionMode == 'SIMULATION'"
                  @click="startBrokerTicker(broker.id)" class="btn btn-outline-primary me-2"
                  title="Start live feeds. (Trading session needs to be started first)" style="color: green" href="#"
                  role="button">
                  <i v-if="!broker.tickerLoading" class="fa-solid fa-play"></i>
                  <span v-if="broker.tickerLoading" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                </button>
              </td>
              <td v-if="!compact">
                <div class="form-check d-inline-block ">
                  <input class="form-check-input " type="checkbox" value="" />
                </div>
              </td>
              <td v-if="executionMode == 'LIVE'">
                <button :disabled="broker.isTradingSessionActive == true" @click="deleteBroker(broker.id)"
                  class="btn btn-outline-primary me-2" title="Delete Broker" style="color: firebrick" href="#"
                  role="button">
                  <i class="fa-solid fa-trash"></i>
                </button>

                <button  @click="editBroker(broker.id)"
                  class="btn btn-outline-primary " title="Edit Broker" href="#" role="button">
                  <i class="fa-solid fa-pen-to-square"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="activeFeeds.length > 0">
      <h4>Feed Source</h4>
      <hr />
      <div class="mb-3">
        <table>
          <tr>
            <td>
              <label for="inputEmail3" class="form-label me-5">Primary</label>
            </td>
            <td>
              <select :disabled="primaryFeedSourceUpdating" v-model="primaryFeed" class="form-select ">
                <option v-for="broker in activeFeeds" v-bind:value="broker.id" v-bind:key="broker.id">
                  {{ broker.name }} [{{ broker.brokerTypeDescription }}]
                </option>
              </select>
            </td>
            <td>
              <span v-if="primaryFeedSourceUpdating" class="ms-3 spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            </td>
          </tr>
        </table>

        <!-- <label v-if="activeFeeds.length > 1" for="inputEmail3" class="col-sm-1 col-form-label">Standby</label>
        <div v-if="activeFeeds.length > 1" class="col-sm-3">
          <select v-model="standbyFeed"
            class="form-select">
            <option v-for="broker in activeFeeds" v-bind:value="broker.id" v-bind:key="broker.id">
              {{ broker.name }} [{{ broker.brokerTypeDescription }}]
            </option>
          </select>
        </div> -->
      </div>
    </div>

    <div v-if="executionMode == 'LIVE'">
      <hr />
      <h4>Supported brokers</h4>

      <div class="text-center flex-grow-1">
        <input type="hidden" ref="openEditorHiddenInput" data-bs-toggle="offcanvas" href="#addUpdateBrokerOffcanvas" />

        <div class="d-flex flex-wrap">
          <div class="me-5 mt-5">
            <button style="width:100px" data-bs-toggle="offcanvas" @click.stop="addBroker('Zerodha')"
              href="#addUpdateBrokerOffcanvas" type="button" class="btn btn-outline-secondary"><i
                class="fa-solid fa-plus me-2"></i><br />Zerodha</button>
          </div>
          <div class="me-5 mt-5">
            <button style="width:100px" type="button" @click.stop="addBroker('AliceBlue')" data-bs-toggle="offcanvas"
              href="#addUpdateBrokerOffcanvas" class="btn btn-outline-secondary"><i
                class="fa-solid fa-plus me-2"></i><br />Alice Blue</button>
          </div>
          <div class="me-5 mt-5">
            <button style="width:100px" type="button" @click.stop="addBroker('SASOnline')" data-bs-toggle="offcanvas"
              href="#addUpdateBrokerOffcanvas" class="btn btn-outline-secondary"><i
                class="fa-solid fa-plus me-2"></i><br />SAS
              Online</button>
          </div>
          <div class="me-5 mt-5">
            <button style="width:100px" type="button" @click.stop="addBroker('Dhan')" data-bs-toggle="offcanvas"
              href="#addUpdateBrokerOffcanvas" class="btn btn-outline-secondary"><i
                class="fa-solid fa-plus me-2"></i><br />Dhan</button>
          </div>
          <div class="me-5 mt-5">
            <button style="width:100px" type="button" @click.stop="addBroker('FlatTrade')" data-bs-toggle="offcanvas"
              href="#addUpdateBrokerOffcanvas" class="btn btn-outline-secondary"><i
                class="fa-solid fa-plus me-2"></i><br />FlatTrade</button>
          </div>          
        </div>

      </div>

    </div>

    <div class="offcanvas offcanvas-end" data-bs-keyboard="false" data-keyboard="false" data-bs-backdrop="static"
      tabindex="-1" id="addUpdateBrokerOffcanvas" aria-labelledby="addUpdateBrokerOffcanvas">

      <div class="offcanvas-header">
        <h4 class="offcanvas-title" id="offcanvasExampleLabel">{{ mode }} {{ brokerEditing }}</h4>
      </div>

      <div class="offcanvas-body pt-0 mt-0 mb-0 pb-0">

        <ul class="nav nav-fill nav-tabs" role="tablist">
          <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tabBrokerConfigBasicTab1" data-bs-toggle="tab" href="#tabBrokerConfigBasic"
              role="tab" aria-controls="tabBrokerConfigBasic" aria-selected="true"> Basic Configuration</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="tabBrokerConfigAdditionalTab2" data-bs-toggle="tab" href="#tabBrokerConfigAdditional"
              role="tab" aria-controls="tabBrokerConfigAdditional" aria-selected="false"> Additional Configuration </a>
          </li>
        </ul>
        <div class="tab-content pt-5" id="tab-content">
          <div class="tab-pane active" id="tabBrokerConfigBasic" role="tabpanel" aria-labelledby="fill-tab-0">
            <div class="mb-3">
              <label for="formGroupExampleInput" class="form-label">Give this configuration a name:</label>
              <input :disabled="selectedBroker.id && selectedBroker.id.length > 0" v-model="selectedBroker.name"
                type="text" class="form-control" id="formGroupExampleInput" placeholder="Name">
            </div>
            <div class="mb-3">
              <label for="formGroupExampleInput" class="form-label">Username:</label>
              <input v-model="selectedBroker.username" type="text" class="form-control" id="formGroupExampleInput"
                placeholder="Username">
            </div>
            <div class="mb-3">
              <label for="formGroupExampleInput2" class="form-label">Password</label>
              <input v-model="selectedBroker.password" type="text" class="form-control" id="formGroupExampleInput2"
                placeholder="Password">
            </div>
            <div class="mb-3">
              <label for="formGroupExampleInput2" class="form-label">App Id</label>
              <input v-model="selectedBroker.appId" type="text" class="form-control" id="formGroupExampleInput2"
                placeholder="App Id">
            </div>
            <div class="mb-3">
              <label for="formGroupExampleInput2" class="form-label">App Secret/Key</label>
              <input v-model="selectedBroker.appSecret" type="text" class="form-control" id="formGroupExampleInput2"
                placeholder="App Secret">
            </div>

            <div class="mb-3 ms-4">
              <div class="form-check form-switch mt-2" style="transform: scale(1.1);">
                <input v-model="selectedBroker.isAutoLoginSupported" class="form-check-input" type="checkbox"
                  role="switch" id="switchFeaturesAutoLogin" checked />
                <label style="cursor: pointer;" class="form-check-label" for="switchFeaturesAutoLogin">Auto
                  Login</label>
              </div>
            </div>

            <div class="mb-3 ">
              <label for="formGroupExampleInput2" class="form-label">Features Supported</label>
              <div>
                <div v-if="isLiveFeedsSupported(brokerEditing)"
                  class="d-inline-block border border-1 border-primary p-1 me-3">Live Feeds</div>
                <div v-if="isCandleHistorySupported(brokerEditing)"
                  class="d-inline-block border border-1 border-primary p-1">Candle History</div>
              </div>
            </div>

            <div class="mb-3">
              <a role="button" class="btn btn-link ms-0 ps-0" @click.stop="switchToAdditionalDetailsTab()"
                href="javascript:void(0)">Additional configuration for {{ brokerEditing }}</a>
            </div>

          </div>
          <div class="tab-pane" id="tabBrokerConfigAdditional" role="tabpanel"
            aria-labelledby="tabBrokerConfigAdditional">

            <div class="mb-3" v-if="brokerEditing == 'SASOnline' || brokerEditing == 'FlatTrade'">
              <label for="formGroupExampleInput" class="form-label">TOTP:</label>
              <input v-model="selectedBroker.totp" type="text" class="form-control" id="formGroupExampleInput"
                placeholder="TOTP">
            </div>

            <div class="mb-3" v-if="brokerEditing == 'AliceBlue'">
              <label for="formGroupExampleInput" class="form-label">Year of Birth:</label>
              <input v-model="selectedBroker.yearOfBirth" type="text" class="form-control" id="formGroupExampleInput"
                placeholder="Year of Birth">
            </div>

            <div class="mb-3" v-if="brokerEditing == 'AliceBlue'">
              <label for="formGroupExampleInput" class="form-label">MPIN:</label>
              <input v-model="selectedBroker.mpin" type="text" class="form-control" id="formGroupExampleInput"
                placeholder="MPIN">
            </div>

            <div class="mb-3">
              <a role="button" class="btn btn-link ms-0 ps-0" @click.stop="switchToBasicDetailsTab()"
                href="javascript:void(0)">Basic configuration for {{ brokerEditing }}</a>
            </div>

          </div>
        </div>

      </div>
      <div class="offcanvas-footer mb-2 ms-5 me-5">
        <hr />
        <div class="text-end">
          <button type="submit" class="btn btn-subtle me-2" data-bs-dismiss="offcanvas"
            ref="cancelBrokerDrawerButton">Cancel</button>
          <button :disabled="selectedBroker.isTradingSessionActive == true" type="submit" class="btn btn-primary" @click="saveBroker()">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState, mapActions, mapMutations } from 'vuex'
import Utils from "@/services/Utils";
import { createDiscreteApi } from "naive-ui";

const { dialog, message } = createDiscreteApi(["notification", "dialog", "message"]);
// const { notification, dialog, message } = createDiscreteApi(["notification", "dialog", "message"]);

export default {
  name: 'Brokers',
  props: {
    compact: Boolean,
    title: String
  },
  components: {
  },
  data() {
    return {
      standbyFeed: null,
      mode: 'Add',
      brokerEditing: '',
      selectedBroker: {},
      primaryFeedSourceUpdating: false
    }
  },
  computed: {
    ...mapState({
      showHideBasketEditor: state => state.showHideBasketEditor,
      brokers: state => state.brokers,
      primaryFeedSourceId: state => state.primaryFeedSourceId,
      activeOptionChain: state => state.activeOptionChain,
      executionMode: state => state.executionMode,
      selectedOptionChainExpiry: state => state.selectedOptionChainExpiry,
    }),
    primaryFeed: {
      get() {
        return this.primaryFeedSourceId;
      },
      set(value) {
        console.log('New val', value)
        this.primaryFeedSourceChanged(value);
      }
    },
    activeFeeds() {
      var activeFeeds = this.brokers.filter(x => x.isLiveFeedsActive)
      return activeFeeds;
    }
  },
  methods: {
    ...mapActions([
      'setActiveOptionChain',
      'setBrokers',
      'setFeedSourceId'
    ]),
    ...mapMutations({
      OptionChainRequested: 'OptionChainRequested'
    }),
    addBroker(brokerType) {
      this.mode = "Add"
      this.brokerEditing = brokerType
      this.createBrokerModel(brokerType)
    },
    switchToAdditionalDetailsTab() {
      document.getElementById('tabBrokerConfigAdditionalTab2').click()
    },
    switchToBasicDetailsTab() {
      document.getElementById('tabBrokerConfigBasicTab1').click()
    },
    isLiveFeedsSupported(brokerType) {
      return brokerType == 'AliceBlue' || brokerType == 'SASOnline' || brokerType == 'Zerodha'
    },
    isCandleHistorySupported(brokerType) {
      return brokerType == 'AliceBlue' || brokerType == 'SASOnline' || brokerType == 'Zerodha'
    },
    isEmpty(obj) {
      return _.isEmpty(obj)
    },
    async primaryFeedSourceChanged(sourceId) {
      this.primaryFeedSourceUpdating = true
      this.OptionChainRequested()
      let response = await Utils.changeFeedSource('primary', sourceId)
      if (response.status == "error") {
        message.error(
          `error: ${response.message}`,
          {
            keepAliveOnHover: true
          }
        )
        this.primaryFeedSourceUpdating = false
        return;
      }

      this.primaryFeedSourceUpdating = false

      this.setActiveOptionChain({ instrument: this.activeOptionChain, expiry: this.selectedOptionChainExpiry })

      let broker = this.brokers.find(x => x.id == sourceId)
      message.info(
        `Primary feed source set to ${broker.name}-${broker.brokerTypeDescription}`,
        {
          keepAliveOnHover: true
        }
      )

      this.setFeedSourceId({
        type: 'PRIMARY',
        id: sourceId
      })
    },
    async startBrokerSession(brokerId) {
      let broker = this.brokers.find(x => x.id == brokerId)

      broker.sessionLoading = true;

      let response = await Utils.startBrokerSession(brokerId)
      if (response.status == "error") {
        message.error(
          `error: ${response.message}`,
          {
            keepAliveOnHover: true
          }
        )
        broker.sessionLoading = false;
        return;
      }
      let brokers = response.data.brokers
      this.setBrokers(brokers)

      broker = this.brokers.find(x => x.id == brokerId)

      broker.sessionLoading = false;
      message.info(
        `Trading session for ${broker.name}-${broker.brokerTypeDescription} started successfully.`,
        {
          keepAliveOnHover: true
        }
      )
    },
    async stopBrokerSession(brokerId) {

      let broker = this.brokers.find(x => x.id == brokerId)

      let response = await Utils.stopBrokerSession(brokerId)
      if (response.status == "error") {
        message.error(
          `error: ${response.message}`,
          {
            keepAliveOnHover: true
          }
        )
        return;
      }
      let brokers = response.data.brokers
      this.setBrokers(brokers)

      this.stopBrokerTicker(brokerId)

      message.info(
        `Trading session for broker ${broker.name}-${broker.brokerTypeDescription} stopped successfully.`,
        {
          keepAliveOnHover: true
        }
      )

    },
    async startBrokerTicker(brokerId) {
      let broker = this.brokers.find(x => x.id == brokerId)
      broker.tickerLoading = true;

      let response = await Utils.startBrokerTicker(brokerId)
      if (response.status == "error") {
        message.error(
          `error: ${response.message}`,
          {
            keepAliveOnHover: true
          }
        )
        broker.tickerLoading = false;
        return;
      }
      let brokers = response.data.brokers
      this.setBrokers(brokers)

      broker = this.brokers.find(x => x.id == brokerId)
      broker.tickerLoading = false;

      message.info(
        `Live feeds for ${broker.name}-${broker.brokerTypeDescription} started successfully.`,
        {
          keepAliveOnHover: true
        }
      )
    },
    async stopBrokerTicker(brokerId) {

      let broker = this.brokers.find(x => x.id == brokerId)
      broker.tickerLoading = true;

      let response = await Utils.stopBrokerTicker(brokerId)
      if (response.status == "error") {
        message.error(
          `error: ${response.message}`,
          {
            keepAliveOnHover: true
          }
        )
        broker.tickerLoading = false;
        return;
      }
      broker.tickerLoading = false;
      let brokers = response.data.brokers
      this.setBrokers(brokers)
      if (this.activeFeeds.length == 0)
        this.setFeedSourceId({
          type: 'PRIMARY',
          id: ''
        })

      message.info(
        `Feeds for broker ${broker.name}-${broker.brokerTypeDescription} stopped successfully.`,
        {
          keepAliveOnHover: true
        }
      )
    },
    deleteBroker(brokerId) {

      let broker = this.brokers.find(x => x.id == brokerId)
      if (!broker) return

      dialog.warning({
        title: `Delete broker [${broker.name}]?`,
        content: 'Are you sure?',
        positiveText: 'Delete Broker',
        negativeText: 'No',
        blockScroll: false,
        onPositiveClick: async () => {
          let response = await Utils.deleteBrokerConfig(brokerId)
          if (response.status == "error") {
            message.error(
              `error: ${response.message}`,
              {
                keepAliveOnHover: true
              }
            )
            return;
          }
          this.brokers = response.data.brokers
        },
        onNegativeClick: () => {
          //message.error('Not Sure')
        }
      })
    },
    editBroker(brokerId) {
      let broker = this.brokers.find(x => x.id == brokerId)
      if (broker)
        this.selectedBroker = JSON.parse(JSON.stringify(broker))

      this.mode = "Edit"
      this.brokerEditing = this.selectedBroker.brokerTypeDescription

      this.$refs.openEditorHiddenInput.click()
      document.getElementById('tabBrokerConfigBasicTab1').click()
    },
    async saveBroker() {
      if (this.isEmpty(this.selectedBroker)) {

        message.error(
          'Please provide configuration values.',
          {
            keepAliveOnHover: true
          }
        )

        return
      }

      // send this for saving
      // get the response, if success, reffresh the brokers list
      // on error display error and retrn.. 
      let response = await Utils.saveBrokerConfig(this.selectedBroker)

      if (response.status == "error") {
        message.error(
          `error: ${response.message}`,
          {
            keepAliveOnHover: true
          }
        )
        return;
      }

      if (response.data.brokers) {
        let brokers = response.data.brokers
        this.setBrokers(brokers)
      }

      this.$refs.cancelBrokerDrawerButton.click()
    },
    createBrokerModel(brokerType) {

      if (!this.isEmpty(this.selectedBroker) && this.selectedBroker.id > 0) return

      document.getElementById('tabBrokerConfigBasicTab1').click()

      this.selectedBroker = {
        id: null,
        name: '',
        description: '',
        brokerType: 1,
        isLiveFeedsSupported: true,
        isCandleHistorySupported: true,
        isMarginCalculationSupported: false,
        isAutoLoginSupported: true,
        username: '',
        password: '',
        appId: '',
        appSecret: '',
        totp: '',

        yearOfBirth: '',
        mpin: '',

        isTradingSessionActive: false,
        isLiveFeedsActive: false,

        loading: false
      }

      if (brokerType == 'Zerodha') {
        this.selectedBroker.brokerType = 4
      } else if (brokerType == 'AliceBlue') {
        this.selectedBroker.brokerType = 1
      } else if (brokerType == 'SASOnline') {
        this.selectedBroker.brokerType = 8
      } else if (brokerType == 'FlatTrade') {
        this.selectedBroker.brokerType = 10
      } else if (brokerType == 'Dhan') {
        this.selectedBroker.brokerType = 9
      }
    }
  },
}
</script>

<style scoped></style>