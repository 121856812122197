import axios from 'axios'
import store from '../store/'
import { useToast } from 'vue-toastification'

const toast = useToast()

export default() => {
  console.log('Web api base url: ' + store.state.config.webApiBaseUrl)
  var instance = axios.create({
        baseURL: store.state.config.webApiBaseUrl,
        withCredentials: false,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        transformResponse: [function (data) {
            // Do whatever you want to transform the data
            var transFormedData = JSON.parse( data)
            if (transFormedData.status == "fail")
                throw transFormedData.message
            return transFormedData;
          }],               
    })

    instance.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response.data;
      }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        toast.error(error)
        throw error
      });   
      
    return instance;
}