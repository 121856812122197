import BlackScholes from '@/services/BlackScholes'
import Greeks from '@/services/Greeks'
import moment from 'moment'
import { BrokerageMap } from '../constants'
import store from '@/store/index'

const daysInYear = 365

function getFuturesSymbolCurrent(instrument, expiry) {

    // call APIs
    // If beyond the last thursday,
    // select next month
    // let expiryMoment = moment()
    let expiryMoment = moment(expiry)

    let symbol = `${instrument}${expiryMoment.format('YY')}`

    symbol = `${symbol}${expiryMoment.format('MMM')}FUT`
    //symbol = `${symbol}FEBFUT`
    return symbol.toUpperCase()
}

function getSymbol(params) {
    let expiryMoment = moment(params.expiry)
    if (params.isFutures) {
        let symbol = `${params.instrument}${expiryMoment.format('YY')}${expiryMoment.format('MMM')}FUT`
        return symbol.toUpperCase()
    }

    let symbol = `${params.instrument}${expiryMoment.format('DD')}${expiryMoment.format('MMM')}${expiryMoment.format('YY')}${params.optionType[0]}${params.strike}`
    return symbol.toLocaleUpperCase();    
}

function getBlackScholesPrice(basket, order, today, ltp, strikeAtExpiry, underlyingPrice, riskFreeInterest) {

    underlyingPrice = order.underlyingPriceAtOpen
    let expiryMoment = moment(order.expiry)

    let dte = 1

    if (basket.isAdjusting) {
        if (basket.executedBasketPayOffPrice === 'executed') {
            if (order.executedOn)
                dte = expiryMoment.diff(order.executedOn, "days")
            else
                dte = expiryMoment.diff(today, "days")
            // console.log('IsAdjusting. executedBasketPayOffPrice is executed. DTE when eecuted: ' + dte)
        } else {
            dte = expiryMoment.diff(today, "days")
            // console.log('IsAdjusting. executedBasketPayOffPrice is latest. latest DTE: ' + dte)
        }
    }
    else if (basket.executedBasketPayOffPrice === 'executed') {
        if (order.executedOn)
            dte = expiryMoment.diff(order.executedOn, "days")
        else
            dte = expiryMoment.diff(today, "days")
        // console.log('Not adjusting. executedBasketPayOffPrice is executed. DTE when eecuted: ' + dte)
    } else {
        dte = expiryMoment.diff(today, "days")
        // console.log('Not IsAdjusting. executedBasketPayOffPrice is latest. latest DTE: ' + dte)
    }

    if (dte === 0)
        dte = 1

    let callOrPut = order.optionType === "ce" ? "call" : "put"

    // is this the front month
    // var daysFromTodayToFrontMonthsExpiry = get the number days from today to front month
    // front month dte should be set to 0.00001 (ie at expiry)
    // far months should be counted from front months expiry
    // farMnthsDTE = farMonthsDTE - daysFromTodayToFrontMonthsExpiry

    let frontMonthLeg = basket.orders.reduce((r, o) => o.expiry < r.expiry ? o : r)
    let frontMonthExpiryMoment = moment(frontMonthLeg.expiry)

    // Today's IV
    let iv = BlackScholes.getImpliedVolatility(ltp, underlyingPrice, order.strike, dte / daysInYear, riskFreeInterest, callOrPut)
    order.iv = parseFloat(iv.toFixed(2))

    // Reset DTE's at expiry
    if (expiryMoment.isSame(frontMonthExpiryMoment)) {
        dte = 0.00000000000000000001
    }
    else {
        let frontMonthsDTE = frontMonthExpiryMoment.diff(today, "days");
        dte = (dte - frontMonthsDTE)
    }

    // get the price on expiry
    let price = BlackScholes.blackScholes(strikeAtExpiry, order.strike, dte / daysInYear, iv, riskFreeInterest, callOrPut)

    // Pricing calculations are off by 16% ie they are higher by 16%
    // Needs to revision the pricing
    // for now just deduct 10% from the final pricing

    // price = price - (price * 0.20)

    return price * order.lotSize * order.quantity
}

function getFinalBlackScholesPriceOfABasket(basket) {
    let finalPrice = 0
    let orders = basket.orders.filter(x => x.isSquaredOff == false)
    let sortedOrders = orders.sort((a, b) => new Date(a.expiry).getTime() - new Date(b.expiry).getTime());

    let sumOFrontMonthsPrice = 0
    let sumOfBackMonthsPrice = 0

    for (let i = 0; i < sortedOrders.length; i++) {
        if (moment(sortedOrders[i].expiry).isSame(sortedOrders[0].expiry)) {
            if (sortedOrders[i].transactionType === 'sell')
                sumOFrontMonthsPrice += sortedOrders[i].bsPrice
            else
                sumOfBackMonthsPrice += sortedOrders[i].bsPrice
        }
        else {
            if (sortedOrders[i].transactionType === 'buy')
                sumOfBackMonthsPrice += sortedOrders[i].bsPrice
            else
                sumOFrontMonthsPrice += sortedOrders[i].bsPrice
        }
    }

    if (!basket.setupCost)
        basket.setupCost = 0

    finalPrice = sumOfBackMonthsPrice - sumOFrontMonthsPrice + basket.setupCost

    if (finalPrice > 0)
        finalPrice = finalPrice - (finalPrice * 0.20)

    if (basket.payoffShouldIncludeRealizedPnL)
        finalPrice += basket.realizedPL


    // now take into account p/l for already squared off postions
    /*
    let realizedPnl = 0.0
    var squaredOffOrders = basket.orders.filter(x => x.isSquaredOff)
    squaredOffOrders.forEach(order => {
        let legPl = 0.0

        if (order.transactionType === 'buy') {
            legPl = order.squaredOffPrice - order.executedPrice
        } else {
            legPl = order.executedPrice - order.squaredOffPrice
        }

        legPl = legPl * order.lotSize * order.quantity

        realizedPnl += legPl
    })

    finalPrice += realizedPnl

    return finalPrice
    */

    return finalPrice;
}

function getMarketLot(instrument) {

    instrument = instrument.toUpperCase();

    let key = `${instrument}-${store.state.selectedOptionChainExpiry}`.toUpperCase()
    let lotSize = 0

    const isEmpty = typeof store.state.instrumentInfos === 'undefined' || store.state.instrumentInfos === null;
    const instrumentInfos = store.state.instrumentInfos[key] 
    if (!isEmpty && instrumentInfos)
        lotSize = instrumentInfos.lotSize
    else 
        if (instrument === 'NIFTY')
            lotSize = 25
        else if (instrument === 'BANKNIFTY')
            lotSize = 15
        else if (instrument == 'FINNIFTY')
            lotSize = 25
        else if (instrument == 'MIDCPNIFTY')
            lotSize = 75    
        else if (instrument == 'SENSEX')
            lotSize = 10

    return lotSize
}

function getGreeks(ltp, underlyingPrice, strike, expiry, riskFreeInterest, optionType, transactionType, instrumentType) {
    let greeks = {
        iv: 0,
        delta: 0,
        theta: 0,
        vega: 0,
        gamma: 0
    }

    let expiryMoment = moment(expiry)

    let dte = expiryMoment.diff(moment.now(), "days")
    if (dte === 0)
        dte = 1

    if (instrumentType === 'o') {
        let callOrPut = optionType === "ce" ? "call" : "put"

        greeks.iv = parseFloat(BlackScholes.getImpliedVolatility(ltp, underlyingPrice, strike, dte / daysInYear, riskFreeInterest, callOrPut).toFixed(2))

        greeks.delta = parseFloat(Greeks.getDelta(underlyingPrice, strike, dte / 365, greeks.iv, riskFreeInterest, callOrPut).toFixed(2))
        greeks.theta = parseFloat(Greeks.getTheta(underlyingPrice, strike, dte / 365, greeks.iv, riskFreeInterest, callOrPut).toFixed(2))
        greeks.vega = parseFloat(Greeks.getVega(underlyingPrice, strike, dte / 365, greeks.iv, riskFreeInterest).toFixed(2))

        // rounding
        greeks.gamma = Math.round((greeks.gamma * 100) * 100) / 100
        greeks.gamma = parseFloat(Greeks.getGamma(underlyingPrice, strike, dte / 365, greeks.iv, riskFreeInterest).toFixed(2))
    }
    else {
        greeks.iv = 0
        greeks.delta = transactionType === 'buy' ? 1 : -1
        greeks.theta = 0
        greeks.vega = 0
        greeks.gamma = 0
    }

    if (instrumentType === 'o') {
        if (transactionType === 'sell') {
            if (optionType === "pe")
                greeks.delta = Math.abs(greeks.delta)
            else
                greeks.delta = -Math.abs(greeks.delta)

            greeks.theta = Math.abs(greeks.theta)
            greeks.vega = -Math.abs(greeks.vega)
            greeks.gamma = -Math.abs(greeks.gamma)
        }

        greeks.iv = parseFloat(greeks.iv.toFixed(2))
        greeks.delta = parseFloat(greeks.delta.toFixed(2))
        greeks.theta = parseFloat(greeks.theta.toFixed(2))
        greeks.vega = parseFloat(greeks.vega.toFixed(2))
        greeks.gamma = parseFloat(greeks.gamma.toFixed(2))
    }

    return greeks
}

function getEmptyGreeks() {
    return {
        iv: 0,
        delta: 0,
        theta: 0,
        vega: 0,
        gamma: 0
    }
}

function calculateTaxesOptions(price, quantity, transactionType, broker, instrumentType) {
    // let taxes = 0

    // We need to calculate for both the transactions
    // let reverseTransaction = transactionType === 'sell' ? 'buy' : 'sell'

    let taxes = calculateTaxesOptionsInternal(price, quantity, transactionType, broker, instrumentType)
    // taxes = taxes + calculateTaxesOptionsInternal(price, quantity, reverseTransaction, broker)

    return taxes
}

function calculateTaxesOptionsInternal(price, quantity, transactionType, broker, instrumentType) {
    // STT / CTT                0.05 % on Sell side (on premium)
    // Transaction Charges      0.053 %  (On premium)
    // GST                      18 %  on (brokerage + Transaction chares)
    // SEBI Charges             Rs 5 / crore
    // Stamp Charges            0.003 % or Rs 300 / crore on buy side
    let totalValue = price * quantity

    let stt = 0

    if (transactionType === 'sell') {
        if (instrumentType === 'o')
            stt = totalValue * (0.05 / 100)
        else
            stt = totalValue * (0.01 / 100)
    }

    let transactionCharges = 0

    if (instrumentType === 'o')
        transactionCharges = totalValue * (0.05 / 100)
    else
        transactionCharges = totalValue * (0.0019 / 100)

    let sebiCharges = 2
    let stampCharges = 0

    if (transactionType === 'buy')
        if (instrumentType === 'o')
            stampCharges = totalValue * (0.003 / 100)
        else
            stampCharges = totalValue * (0.002 / 100)

    let brokerage = 0
    if (broker)
        brokerage = parseFloat(BrokerageMap[broker])

    let gst = (brokerage + transactionCharges) * (18 / 100)

    return stt + transactionCharges + sebiCharges + stampCharges + brokerage + gst
}

export default {
    getSymbol: getSymbol,
    getFuturesSymbolCurrent: getFuturesSymbolCurrent,
    getGreeks: getGreeks,
    getEmptyGreeks: getEmptyGreeks,
    getMarketLot: getMarketLot,
    getBlackScholesPrice: getBlackScholesPrice,
    getFinalBlackScholesPriceOfABasket: getFinalBlackScholesPriceOfABasket,
    calculateTaxesOptions: calculateTaxesOptions
}