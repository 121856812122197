const state = () => ({
  socket: {
    isConnected: false,
    message: '',
    reconnectError: false,
    heartBeatInterval: 50000,
    // Heartbeat timer
    heartBeatTimer: 0    
  },
  $socket: {},
  config: {},
  brokers: [],
  primaryFeedSourceId: '',
  standbyFeedSourceId: '',
  defaultBrokerId: '',

  marketStatus: 'closed',
  executionMode: 'SIMULATION', // simulation / live

  trackingId: Math.floor(Math.random() * 999999).toString(),

  globalSettings: {
    globalSL: -3500,
    globalTarget: -3500,
    checkGlobalExit: false
  },

  instrumentInfos: {},
  instruments: {},
  optionChain: {},

  niftyAtmStrike: 0,
  bankNiftyAtmStrike: 0,
  sensexAtmStrike: 0,
  finNiftyAtmStrike: 0,
  midcapNiftyAtmStrike: 0,

  isLoadingOptionChain: false,
  showHideOptionChain: true,

  showHideRiskManagementProfile: false,
  showHideAlgoSchedulerSettings: false,

  showHideFuturesPanel: false,
  showHideOptionsChainPanel: true,

  showHideChartsInOptionChain: false,

  requestPositionsTechnicalAnalysisDataTriggered: -1,

  showHideOITopGraphs: true,

  showHideOptionsSimulator: false,
  requestOptionChainForSimulatorTrigger: 0,
  optionSimulatorParams: {
    
    strikeIntervalNiftyBank: 100,
    strikeIntervalSensex: 100,
    strikeIntervalFinNifty: 50,
    strikeIntervalMidcapNifty: 25,
    strikeIntervalNifty: 50,
    strikeIntervalValue: 100,

    strikeUpDownMaxRangeNiftyBank: 2000,
    strikeUpDownMaxRangeNiftyBankUpdated: false,
    strikeUpDownMaxRangeSensex: 2000,
    strikeUpDownMaxRangeSensexUpdated: false,    
    strikeUpDownMaxRangeFinNifty: 800,
    strikeUpDownMaxRangeFinNiftyUpdated: false, 
    strikeUpDownMaxRangeMidcapNifty: 1000,
    strikeUpDownMaxRangeMidcapNiftyUpdated: false,    
    strikeUpDownMaxRangeNifty: 800,
    strikeUpDownMaxRangeNiftyUpdated: false,
    strikeUpDownMaxRangeValue: 2000,

    strikeMaxRangeStepNiftyBank: 500,
    strikeMaxRangeStepFinNifty: 200,  
    strikeMaxRangeStepMidcapNifty: 200,   
    strikeMaxRangeStepNifty: 200,

    strikeMaxRangeStep: 500,

    selectedSymbol: 'NIFTY BANK',
    onDate: Date.now(),
    selectedTimeInUnix: 0,
    recentAvailableFeedDate: Date.now(),
    datePickerDisabledDates: {
      days: [6, 0], // Disable Saturday's and Sunday's 
      dates: [],
    },    

  },

  orderBaskets: [],
  activeExecutedBasket: {},
  isLoadingExecutedOrderBasketsFromServer: false,
  isExecutedOrderBasketsRefreshedFromServer: false,

  activeBasketEditing: {},

  activeOptionChain: 'nifty',
  selectedOptionChainExpiry: '',
  optionChainExpiries: [],
  selectedExpiryNifty: '',
  selectedExpiryBankNifty: '',
  selectedExpiryFinNifty: '',
  selectedExpiryMidcapNifty: '',

  feedArrived: '',
  executeNewOrderFromOptionChainTrigger: '',
  newOrderToBeExecuted: {},

  bidAskSpread: {
    loading: false,
    symbol: '',
    bidPrice: 0.0,
    askPrice: 0.0,
    spread: 99999,
  },

  accountPnL: 0,
  realizedPnL: 0,

  orderBasketsEditing: [],

  showHideOIChangeAnalysis: false,
  showHideExecutedOrderBaskets: true,
  showHideBasketEditor: true,
  brokerInfoPanelDisplayStatus: false,

  basketEditorMaxProfitLossBreakEvens: [],
  basketEditorChartOptions: {
    options: {},
    series: []
  },

  executedBasketPayoffBaskets: [],
  executedBasketsMaxProfitLossBreakEvens: [],
  executedBasketsChartOptions: {
    options: {},
    series: []
  },

  oiAnalysisData: [],
  oiAnalysisDataArrived: new Date(),

  oiAnalysisStrikeWiseData: [],
  oiAnalysisStrikeWiseDataArrived: new Date(),  

  
  oiAnalysisStrikeWiseProfileData: [],
  oiAnalysisStrikeWiseProfileDataArrived: new Date(),  

  holidays: [],
  expiries: [],
  monthlies: [],
  finNiftyExpiries: [],
  finNiftyMonthlies: [],  
  midcapNiftyExpiries: [],
  midcapNiftyMonthlies: [], 
  bankNiftyExpiries: [],
  bankNiftyMonthlies: [],   
  sensexExpiries: [],
  sensexMonthlies: [],    
  oiChangeHistoryDates: [],
  oiChangeHistoryTimesForADate: [],
  
  riskFreeInterest: 0.06,

  strikeUpDownRangeNifty: 1000,
  strikeUpDownRangeBankNifty: 2500,
  strikeUpDownRangeSensex: 1500,  
  strikeUpDownRangeFinNifty: 1000,
  strikeUpDownRangeMidcapNifty: 1000,

  isBrokerConnected: false,

  isBasketEditorInAdjustmentMode: false,

  marginBalance: {}, // key will be broker id, balance object of c# side will be set & updated

  botMessages: {
    squareOffStrategy: '',
    squareOffStrategyList: 0,
    squareOffStrategyConfirmed: ''
  },

  // This field not needd anymore. Moved to as part of the basket itself
  // positionTechnicalAnalysisData: {},  // this field will have ohlc & volume fields - 
  // sample:  ohlc: [{"x":1543482000000,"open":181.7,"high":182.14,"low":180.2,"close":180.3,"color":"#f05f5f","lineColor":"#f05f5f"}]
  //          volume: [{"x":1543482000000,"y":30806,"color":"#f05f5f"}]

  vwapCrossOverTestData: {}, // for testing
  multiChartViewBaskets: [], // This contains the baskets to be displayed in the multichart view for combined premium

  basketGroups: [], // this will hold the basket in a group, {id, name, groupPnL}, default groupId: 1, can not be deleted, every basket will have property groupId 
  activeBasketGroup: {},
  activeBasketGroupId: 10,

  riskManagementProfiles: [],
  algoSchedulerSettings: [],

  multistrikeChartScannerData_CallsAndPuts_BNF: [],
  multistrikeChartScannerData_Straddles_BNF: [],
  multistrikeChartScannerData_TripleStraddles_BNF: {},

  multistrikeChartScannerData_CallsAndPuts_NF: [],
  multistrikeChartScannerData_Straddles_NF: [],
  multistrikeChartScannerData_TripleStraddles_NF: {},

  multistrikeChartScannerData_ComboStraddles: [],

  // riskManagementProfiles: [{
  //   id: 1,
  //   name: '',
  //   stopTargetType: '',
  //   stop: 0,
  //   target: 0,
  //   trail: 0,
  //   isPerLegSL: false,
  //   perLegSLType: 1,
  //   perLegSLValue: 0,
  //   useDeltaBalanceAdjustment: false,
  //   useReEntryAdjustment: false,
  //   futuresBasedSLTarget: false,
  //   futuresSLCondition: '<=',
  //   futuresSLValue: 0,
  //   futuresTargetCondition: 0,
  //   futuresTargetValue: 0,
  // }]  
})

export default state