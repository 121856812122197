import { createRouter, createWebHashHistory } from 'vue-router'
import Landing from '../views/Landing.vue'
import Home from '../views/Home.vue'
// import OIChangeAnalysisView from '../views/OIChangeAnalysisView.vue'
import AdvanceMultiChart from '../views/AdvanceMultiChart.vue'
// import MultiChartScanner from '../views/MultiChartScanner.vue'
import Dashboard from '../views/Dashboard.vue'
import Settings from '../views/Settings.vue'
import NotFoundPage from '../views/NotFoundPage.vue'
import Brokers from '@/components/BrokerManagement/Brokers'
import Holidays from '@/components/Holidays'
import GeneralSettings from '@/components/GeneralSettings'
// import { authGuard } from "@auth0/auth0-vue";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/landing',
    name: 'landing',
    component: Landing,
    // beforeEnter: authGuard
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    // beforeEnter: authGuard
    children: [
      {
        path: 'brokers',
        component: Brokers
      },
      {
        path: 'holidays',
        component: Holidays
      },
      {
        path: 'general-settings',
        component: GeneralSettings
      }            
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    // beforeEnter: authGuard,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "StrategyBuilder" */ '../views/Dashboard.vue')
    component: Dashboard
  },
  // {
  //   path: '/oi-change-analysis',
  //   name: 'OIChangeAnalysisRouter',
  //   beforeEnter: authGuard,
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: OIChangeAnalysisView
  // },
  {
    path: '/advance-multi-chart',
    name: 'AdvanceMultiChart',
    // beforeEnter: authGuard,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AdvanceMultiChart
  },
  // {
  //   path: '/multichart-scanner',
  //   name: 'MultiChartScanner',
  //   beforeEnter: authGuard,
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: MultiChartScanner
  // },
  {
    path: "/:catchAll(.*)",
    name: "Not Found",
    component: NotFoundPage,
  },  

]

const router = createRouter({
  routes: routes,
  history: createWebHashHistory(),
  base: process.env.BASE_URL
})

export default router
