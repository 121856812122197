import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'

// configureCompat({
//   MODE: 3,
//   FEATURE_ID_A: true,
//   FEATURE_ID_B: true
// })

import 'fastbootstrap/dist/css/fastbootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'fastbootstrap'


// Remove in production
// Vue.config.devtools = true
// Vue.config.performance = true

const app = createApp(App)

import VueSimpleAlert from "vue3-simple-alert"
import 'sweetalert2/dist/sweetalert2.min.css';

app.use(VueSimpleAlert, { reverseButtons: true })
app.config.globalProperties.$simpleAlert = VueSimpleAlert;

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
app.use(Toast, {
  timeout: 4000,
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
  position: 'top-right',
  hideProgressBar: false,
  showCloseButtonOnHover: true
});

app.use(store)
app.use(router)

import naive from 'naive-ui'
app.use(naive)

import VueApexCharts from 'vue3-apexcharts'
app.component('apexchart', VueApexCharts)

import Notifications from '@kyvg/vue3-notification';
app.use(Notifications)

import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more'
import Stock from "highcharts/modules/stock"
import HighchartsVue from "highcharts-vue"
import HStockTools from "highcharts/modules/stock-tools"
import Indicators from "highcharts/indicators/indicators"
// import vwap  from "highcharts/indicators/vwap"

import HIndicatorsAll from "highcharts/indicators/indicators-all";
import HDragPanes from "highcharts/modules/drag-panes";
import HAnnotationsAdvanced from "highcharts/modules/annotations-advanced";
import HPriceIndicator from "highcharts/modules/price-indicator";
import HFullScreen from "highcharts/modules/full-screen";

Highcharts.setOptions({
  time: {
    timezone: "Asia/Kolkata",
    useUTC: false
  }
})

Stock(Highcharts)
HighchartsMore(Highcharts)
HStockTools(Highcharts)
Indicators(Highcharts)
//vwap(Highcharts);

HIndicatorsAll(Highcharts)
HDragPanes(Highcharts)
HAnnotationsAdvanced(Highcharts)
HPriceIndicator(Highcharts)
HFullScreen(Highcharts)


app.use(HighchartsVue)

import vSelect from 'vue-select'

app.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';

import Tooltip from 'vue-directive-tooltip'
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css'

app.use(Tooltip)

// Vue.directive('upper', (el, binding) => {
//   let processedValue = el.value.toUpperCase();
//   el.value = processedValue;
//   binding.value = el.value;
// });

import { parseDomain, fromUrl } from "parse-domain";
import VueNativeSock from 'vue-native-websocket-vue3'
import Utils from './services/Utils'


// import config from './data/config.json'
let configUrl = "/data/config.json";

fetch(configUrl).then(response => {
  if (response.ok) {
    return response.json();
  }
  throw new Error('Unable to fetch config.json');
}).then(config => {
  console.log('Config loaded..' + JSON.stringify(config))

  store.state.config = config

  let webSocketServerUrl = config.webSocketServerUrl

  app.use(VueNativeSock, webSocketServerUrl, {
    store: store,
    format: 'json',
    reconnection: true, // (Boolean) whether to reconnect automatically (false)
    reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
    reconnectionDelay: 3000, // (Number) how long to initially wait before attempting a new (1000)
  })
}).catch(reason => {
  let config = {}
  config.webSocketServerUrl = "ws://localhost:7000";
  config.webApiBaseUrl = "http://localhost:6002";

  let webSocketServerUrl = config.webSocketServerUrl; // Default for local development or docker
  console.log(`Loading of config.json failed. Reason: ${reason}. Using default url: ${webSocketServerUrl}`)

  store.state.config = config

  const result = parseDomain(
    fromUrl(window.location.href)
  );

  if (!(result.hostname === 'localhost' || result.hostname === '127.0.0.1'))
    webSocketServerUrl = `wss://wss.${result.domain}.${result.topLevelDomains[0]}`

  app.use(VueNativeSock, webSocketServerUrl, {
    store: store,
    format: 'json',
    reconnection: true, // (Boolean) whether to reconnect automatically (false)
    reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
    reconnectionDelay: 3000, // (Number) how long to initially wait before attempting a new (1000)
  })
})
.finally(async () => {
  store.state.executionMode = await Utils.getExecutionMode()
  let brokers = await Utils.getAllBrokers()
  store.state.brokers = brokers
  store.state.primaryFeedSourceId = await Utils.getActiveFeedSourceId('PRIMARY')

  if (store.state.executionMode == 'SIMULATION') {
    store.state.recentAvailableFeedDate = await Utils.getRecentAvailableFeedDate()
  }
});

app.mount('#app')

export default app
