<template>
  <div style="padding: 5px">
    <div class="mt-1 me-2 mb-2 card">
      <div
        class="btn-group btn-group-narrow btn-group-sm"
        role="group"
        aria-label="Basic example"
      >
        <button
          @click="executeReadymadeStrategy('short-straddle')"
          type="button"
          class="btn btn-outline-primary"
        >
          Short Straddle
        </button>
        <button
          @click="executeReadymadeStrategy('long-straddle')"
          type="button"
          class="btn btn-outline-primary"
        >
          Long Straddle
        </button>
        <button
          @click="resetQuickExecutionPanels('straddle+')"
          type="button"
          class="btn btn-outline-primary"
          :class="quickExecutionPanelType == 'straddle+' ? 'active' : ''"
        >
          Straddle Plus
        </button>
        <button
          @click="executeReadymadeStrategy('strangle')"
          type="button"
          class="btn btn-outline-primary"
        >
          Strangle
        </button>

        <button
          @click="executeReadymadeStrategy('iron-fly')"
          type="button"
          class="btn btn-outline-primary"
        >
          Iron Fly
        </button>
        <button
          @click="executeReadymadeStrategy('iron-condor')"
          type="button"
          class="btn btn-outline-primary"
        >
          Iron Condor
        </button>

        <button
          @click="executeReadymadeStrategy('long-futures')"
          type="button"
          class="btn btn-outline-warning"
        >
          Long Futures
        </button>
        <button
          @click="executeReadymadeStrategy('short-futures')"
          type="button"
          class="btn btn-outline-warning"
        >
          Short Futures
        </button>
        <button
          @click="resetQuickExecutionPanels('expiry-day')"
          :class="quickExecutionPanelType == 'expiry-day' ? 'active' : ''"
          type="button"
          class="btn btn-outline-success"
        >
          Expiry Day
        </button>
      </div>

      <div v-if="quickExecutionPanelTypeVisible" class="card-body p-0">
        <div class="container mt-2 mb-5">
          <div class="row">
            <div class="col-3">
              <label class="form-label">Transaction</label>
              <div
                class="btn-group btn-group-sm form-control"
                style="padding: 0"
              >
                <input
                  type="radio"
                  v-model="quickExecutionTransactionType"
                  value="buy"
                  class="btn-check"
                  name="oe-straddle"
                  id="oe-straddle-buy"
                  autocomplete="off"
                />
                <label class="btn btn-outline-info" for="oe-straddle-buy"
                  >BUY</label
                >
                <input
                  type="radio"
                  v-model="quickExecutionTransactionType"
                  value="sell"
                  class="btn-check"
                  name="oe-straddle"
                  id="oe-straddle-sell"
                  autocomplete="off"
                />
                <label class="btn btn-outline-info" for="oe-straddle-sell"
                  >SELL</label
                >
              </div>
            </div>
            <!-- <div>
              <label title="Quantity [Lot Size x Number of Lots]" class="form-label">{{ activeOptionChain.toUpperCase()
              }}</label>
            </div> -->
            <div class="col-3">
              <label class="form-label">Product</label>
              <select
                :style="{
                  cursor: executionMode != 'LIVE' ? 'not-allowed' : 'auto',
                }"
                :title="
                  executionMode == 'LIVE'
                    ? 'Product'
                    : 'Not applicable in simulation'
                "
                :disabled="executionMode != 'LIVE'"
                v-model="quickExecutionProduct"
                id="quickExecutionProduct"
                class="form-select form-select-sm"
              >
                <option
                  v-for="product in products"
                  v-bind:value="product.value"
                  v-bind:key="product.value"
                >
                  {{ product.name }}
                </option>
              </select>
            </div>
            <div class="col-3">
              <label class="form-label">Basket Group</label>
              <v-select
                v-bind:clearable="false"
                v-on:update:modelValue="executionBasketIdChanged"
                title="Basket Group"
                :options="basketGroups"
                :modelValue="basketGroupId"
                :reduce="
                  (x) => {
                    console.log('reduce');
                    return x.id;
                  }
                "
                label="name"
              />
            </div>
            <div class="col-3">
              <label class="form-label">Broker</label>
              <select
                v-model="selectedBrokerId"
                id="oe-quick-execution-broker"
                v-if="executionMode == 'LIVE'"
                class="form-select form-select-sm"
                @change="updateSelectedBrokerId"
              >
                <option
                  v-for="broker in activeBrokers"
                  v-bind:value="broker.id"
                  v-bind:key="broker.id"
                >
                  {{ broker.name }}&nbsp;({{ broker.brokerTypeDescription }})
                </option>
              </select>
              <!-- <select title="Broker" :disabled="executionMode == 'SIMULATION'" v-model="selectedBrokerId" id="broker"
                  class="form-select" @change="updateSelectedBrokerId">
                  <option v-for="broker in activeBrokers" v-bind:value="broker.id" v-bind:key="broker.id" title="Broker">
                    {{ broker.name }}&nbsp;({{ broker.brokerTypeDescription }})
                  </option>
                </select>              
              <label v-else style="cursor:not-allowed" title="Not applicable in simulation"
                class="form-control form-control-sm">Paper</label> -->
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label class="form-label">Instruments</label>
              <v-select
                multiple
                v-model="quickExecutionSelectedInstruments"
                :options="quickExecutionAvailableInstruments"
              />
            </div>
            <div class="col-3">
              <label class="form-label">Lots</label>

              <div class="input-group mb-3">
                <input
                  class="form-control form-control-sm"
                  v-model="quickExecutionLots"
                  type="number"
                  min="1"
                  placeholder="Lots"
                  @focusout="quickExecutionLotsFocusOut($event)"
                  onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                />

                <span
                  title="Quantity [Lot Size x Number of Lots]"
                  class="input-group-text"
                  >[{{ getLotSize() * quickExecutionLots }}]</span
                >
              </div>
            </div>

            <div class="col-3">
              <label class="form-label">Expiry</label>
              <!-- <v-select v-bind:clearable="false" v-model="quickExecutionBasketGroupId" title="Basket Group"
                :options="basketGroups" :reduce="basketGroups => basketGroups.id" label="name" /> -->
              <select
                v-model="quickExecutionExpiryType"
                id="oe-quick-execution-expiry"
                class="form-select form-select-sm"
              >
                <option
                  v-for="expiry in expiryTypes"
                  v-bind:value="expiry"
                  v-bind:key="expiry"
                >
                  {{ expiry }}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <button
                @click.prevent="quickExecutionPanelExecute(false)"
                class="btn btn-primary btn-sm me-3"
              >
                Add to Strategy Builder
              </button>
                <button
                  @click.prevent="quickExecutionPanelExecute(true)"
                  class="btn btn-primary btn-sm"
                >
                Execute
                <label v-if="quickExecutionPanelType=='straddle+'">Straddle+</label>
                <label v-if="quickExecutionPanelType=='expiry-day'">Expiry Day</label>

                </button>
              <div class="d-inline-block ms-4" v-if="executionMode == 'LIVE'">
                <div class="form-check mb-2">
                  <input
                    v-model="isTesting"
                    id="OEpaperTradeMode"
                    title="Execute in Test Mode"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="ms-2 form-check-label" for="OEpaperTradeMode">
                    Paper Trade
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ul class="nav nav-tabs">
      <li
        class="nav-item"
        v-for="orderBasketItem in orderBasketsEditing"
        v-bind:key="orderBasketItem.id"
      >
        <a
          @click="selectBasket(orderBasketItem.id)"
          class="nav-link"
          :class="{ active: orderBasketItem.id === activeBasketEditing.id }"
          href="javascript:void(0);"
          >{{ orderBasketItem.id }}</a
        >
      </li>
      <li class="nav-item">
        <a
          @click="createNewBasketForEditingInternal()"
          title="Create new basket"
          class="nav-link"
          href="javascript:void(0);"
        >
          <span class="material-icons"> library_add </span>
        </a>
      </li>

      <li class="nav-item" v-if="!isEmpty(orderBasketsEditing)">
        <a
          href="javascript:void(0)"
          class="nav-link"
          @click="exitFromAdjustmentsOrClearBasketInternal()"
        >
          <span class="material-icons" title="Reset basket editor">
            delete_sweep
          </span>
        </a>
      </li>

      <li class="nav-item" v-if="!isEmpty(orderBasketsEditing)">
        <a
          href="javascript:void(0)"
          class="nav-link"
          @click="removeBasketFromEditorInteral(activeBasketEditing.id)"
        >
          <span class="material-icons" title="Remove basket">
            delete_forever
          </span>
        </a>
      </li>
    </ul>

    <!-- 
    <ul>
      <li class="nav-item">
        <label class="space-10"></label>
        <div class="dropdown">
          <button type="button" class="btn btn-info dropdown-toggle btn-sm" data-bs-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false" title="Click => Add to editor, Ctrl+Click => Execute Immediately">
            Strategies
          </button>
          <div class="dropdown-menu">
            <a v-on:click.exact.prevent="executeReadymadeStrategy('short-straddle')"
              v-on:click.ctrl.prevent="executeReadymadeStrategy('short-straddle', true)" class="dropdown-item"
              href="#">Short Straddle (ATM)</a>
            <a v-on:click.exact.prevent="executeReadymadeStrategy('long-straddle')"
              v-on:click.ctrl.prevent="executeReadymadeStrategy('long-straddle', true)" class="dropdown-item"
              href="#">Long Straddle (ATM)</a>

            <div class="dropdown-divider"></div>

            <a v-on:click.exact.prevent="executeReadymadeStrategy('basket-group-straddle-all')"
              v-on:click.ctrl.prevent="executeReadymadeStrategy('basket-group-straddle-all', true)" class="dropdown-item"
              href="#">Basket Group Straddles All</a>

            <a v-on:click.exact.prevent="executeReadymadeStrategy('basket-group-sl-straddle')"
              v-on:click.ctrl.prevent="executeReadymadeStrategy('basket-group-sl-straddle', true)" class="dropdown-item"
              href="#">Basket Group SL - NF &amp; BNF Combo Straddles</a>

            <a v-on:click.exact.prevent="executeReadymadeStrategy('basket-group-sl-calender')"
              v-on:click.ctrl.prevent="executeReadymadeStrategy('basket-group-sl-calender', true)" class="dropdown-item"
              href="#">Basket Group SL - NF &amp; BNF Calendar Spread</a>

            <a v-on:click.exact.prevent="executeReadymadeStrategy('basket-group-sl-calender-straddle-spread-ratio')"
              v-on:click.ctrl.prevent="executeReadymadeStrategy('basket-group-sl-calender-straddle-spread-ratio', true)"
              class="dropdown-item" href="#">Basket &amp; Group SL - Calendar Straddle Spread Ratio</a>

            <div class="dropdown-divider"></div>

            <a v-on:click.exact.prevent="executeReadymadeStrategy('tripple-straddle')"
              v-on:click.ctrl.prevent="executeReadymadeStrategy('tripple-straddle', true, 'no-skip-strike')"
              v-on:click.shift.prevent="executeReadymadeStrategy('tripple-straddle', true, 'skip-strike')"
              class="dropdown-item" href="#"
              title="Click => Add to Editor, Ctrl + Click => Execute (No Skip Strike), Shift + Click => Execute (Skip Strike)">
              Tripple Straddle (ATM-1, ATM, ATM+1)</a>
            <div class="dropdown-divider"></div>
            <a @click="executeReadymadeStrategy('strangle')" class="dropdown-item" href="#">Strangle (ATM-5, ATM+5)</a>
            <a @click="executeReadymadeStrategy('iron-fly')" class="dropdown-item" href="#">Iron Fly (ATM Straddle,
              Hedges: ATM-3, ATM+3)</a>
            <a @click="executeReadymadeStrategy('iron-condor')" class="dropdown-item" href="#">Iron Condor (ATM-5,
              ATM+5,
              Hedges: ATM-8, ATM+8)</a>
          </div>
        </div>
        <label class="space-10"></label>
      </li>

      <li class="nav-item">

        <div class="dropdown ">
          <button type="button" class="btn btn-info dropdown-toggle btn-sm" data-bs-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false" title="Click => Add to editor, Ctrl+Click => Execute Immediately">
            Scalp Options
          </button>
          <div class="dropdown-menu">
            <a v-on:click.exact.prevent="executeReadymadeStrategy('long-pe-atm')"
              v-on:click.ctrl.prevent="executeReadymadeStrategy('long-pe-atm', true)" class="dropdown-item" href="#">Long
              PE (ATM)</a>
            <a v-on:click.exact.prevent="executeReadymadeStrategy('long-pe-atm+1')"
              v-on:click.ctrl.prevent="executeReadymadeStrategy('long-pe-atm+1', true)" class="dropdown-item"
              href="#">Long PE (ATM+1)</a>
            <a v-on:click.exact.prevent="executeReadymadeStrategy('long-pe-atm+2')"
              v-on:click.ctrl.prevent="executeReadymadeStrategy('long-pe-atm+2', true)" class="dropdown-item"
              href="#">Long PE (ATM+2)</a>
            <div class="dropdown-divider"></div>
            <a v-on:click.exact.prevent="executeReadymadeStrategy('long-ce-atm')"
              v-on:click.ctrl.prevent="executeReadymadeStrategy('long-ce-atm', true)" class="dropdown-item" href="#">Long
              CE (ATM)</a>
            <a v-on:click.exact.prevent="executeReadymadeStrategy('long-ce-atm-1')"
              v-on:click.ctrl.prevent="executeReadymadeStrategy('long-ce-atm-1', true)" class="dropdown-item"
              href="#">Long CE (ATM-1)</a>
            <a v-on:click.exact.prevent="executeReadymadeStrategy('long-ce-atm-2')"
              v-on:click.ctrl.prevent="executeReadymadeStrategy('long-ce-atm-2', true)" class="dropdown-item"
              href="#">Long CE (ATM-2)</a>
          </div>
        </div>
        <label class="space-10"></label>
      </li>

      <li class="nav-item">
        <div class="dropdown">
          <button type="button" class="btn btn-info dropdown-toggle btn-sm" data-bs-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false" title="Click => Add to editor, Ctrl+Click => Execute Immediately">
            Scalp Futures
          </button>
          <div class="dropdown-menu">
            <a v-on:click.exact.prevent="executeReadymadeStrategy('long-futures')"
              v-on:click.ctrl.prevent="executeReadymadeStrategy('long-futures', true)" class="dropdown-item" href="#">Long
              Futures</a>
            <a v-on:click.exact.prevent="executeReadymadeStrategy('short-futures')"
              v-on:click.ctrl.prevent="executeReadymadeStrategy('short-futures', true)" class="dropdown-item"
              href="#">Short Futures</a>
          </div>
        </div>
      </li>
    </ul> -->

    <div class="card">
      <div class="p-0">
        <div v-show="isLegsAdded">
          <span
            :set="
              (positionGreeksAtExecution = calculatePositionGreeks('execution'))
            "
          ></span>
          <span
            :set="(positionGreeksAtNow = calculatePositionGreeks('now'))"
          ></span>

          <!-- <draggable :list="activeBasketEditing.orders" group="buildOrderBasket" @start="drag=true" @end="drag=false"> -->
          <table class="table text-center">
            <thead class="thead">
              <tr>
                <th>
                  <input
                    v-model="shouldIncludeAllInPayoff"
                    class="form-check-input"
                    @change="IncludeAllInPayoff()"
                    type="checkbox"
                    value=""
                  />
                </th>
                <th>Instrument</th>
                <th>Expiry</th>
                <th>Strike</th>
                <th>Option Type</th>
                <th>
                  <span>Transaction</span>
                  <button
                    @click="reverseTransactions()"
                    type="button"
                    class="mt-0 pt-0 mb-0 pt-0 ms-1 btn btn-default btn-sm rounded-circle"
                    title="Reverse transactions (buy => sell, sell => buy)"
                  >
                    <i class="fa-solid fa-repeat"></i>
                  </button>
                  <!-- <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> -->
                </th>
                <th>Price</th>
                <th v-if="activeBasketEditing.isAdjusting">Executed Price</th>
                <th
                  v-if="activeBasketEditing.isAdjusting"
                  title="Implied Volaility at Execution"
                >
                  IV-E
                </th>
                <th
                  v-if="activeBasketEditing.isAdjusting"
                  title="Theta at Execution"
                >
                  T-E
                </th>
                <th
                  v-if="activeBasketEditing.isAdjusting"
                  title="Vega at Execution"
                >
                  V-E
                </th>
                <th
                  v-if="activeBasketEditing.isAdjusting"
                  title="Delta at Execution"
                >
                  D-E
                </th>
                <th
                  v-if="activeBasketEditing.isAdjusting"
                  title="Gamma at Execution"
                >
                  G-E
                </th>
                <!-- <th title="Implied Volaility at Now">IV-N</th>
              <th title="Theta at Now. Change in P/L after one day. Negative theta eg Rs -500 means if nothing changes you loose 500 Rs every day. Positive theta eg Rs 500 means you gain 500 Rs everyday if nothing changes.">T-N</th>
              <th title="Vega at Now. Change in P/L when IV increases 1 %. Eg if position if positive vega eg 500.00, you loose 500 when IV goes up by 1%. When vega is negative eg -500.00, you gain 500 rs when vega go down by 1 %">V-N</th>
              <th title="Delta at Now. Change in P/L with 1 point change in underlying eg delta of Rs 10 means, gain of 10 Rs if underlying move up by 1 point. If delta is negative eg Rs -10, mean loss of 10 rs if  underlying move down by 10 points.">D-N</th>
              <th title="Gamma at Now. Gamma is the change in delta with change in underlying price. It denotes the rate of change to delta with respect with underlying price">G-N</th>               -->
                <!-- <th>Symbol</th> -->
                <th>Lots</th>
                <th v-if="!activeBasketEditing.isAdjusting">Action</th>
              </tr>
            </thead>
            <!--<draggable v-model="activeBasketEditing.orders" tag="tbody" ghost-class="ghost"> -->
            <tr
              v-for="(item, symbol) in activeBasketEditing.orders"
              v-bind:key="symbol"
            >
              <td>
                <input
                  v-model="item.includeInPayoff"
                  class="form-check-input"
                  type="checkbox"
                  @change="addOrderToPayoff()"
                />
              </td>
              <td>{{ activeBasketEditing.instrument.toUpperCase() }}</td>
              <td>
                <span
                  v-if="
                    activeBasketEditing.isAdjusting ||
                    item.instrumentType === 'f'
                  "
                >
                  <span>{{ formatExpiry(item.expiry) }}</span>
                </span>
                
                <select
                  v-else
                  v-model="item.expiryEdited"
                  class="form-select form-select-sm"
                  style="height: 32px"
                  @change="updateOrderAfterEdit(item.symbol)"
                >
                  <template v-if="activeBasketEditing.instrument == 'finnifty'">
                    <option
                      v-for="expiry in finNiftyExpiries"
                      :selected="isSelectedExpiry(item.expiry, expiry)"
                      v-bind:value="expiry"
                      v-bind:key="expiry"
                    >
                      {{ expiry }}
                    </option>
                  </template>
                  <template
                    v-if="activeBasketEditing.instrument == 'midcpnifty'"
                  >
                    <option
                      v-for="expiry in midcapNiftyExpiries"
                      :selected="isSelectedExpiry(item.expiry, expiry)"
                      v-bind:value="expiry"
                      v-bind:key="expiry"
                    >
                      {{ expiry }}
                    </option>
                  </template>
                  <template
                    v-else-if="activeBasketEditing.instrument == 'banknifty'"
                  >
                    <option
                      v-for="expiry in bankNiftyExpiries"
                      :selected="isSelectedExpiry(item.expiry, expiry)"
                      v-bind:value="expiry"
                      v-bind:key="expiry"
                    >
                      {{ expiry }}
                    </option>
                  </template>
                  
                  <template
                    v-else-if="activeBasketEditing.instrument == 'sensex'"
                  >
                    <option
                      v-for="expiry in sensexExpiries"
                      :selected="isSelectedExpiry(item.expiry, expiry)"
                      v-bind:value="expiry"
                      v-bind:key="expiry"
                    >
                      {{ expiry }}
                    </option>
                  </template>                  
                  <template v-else>
                    <option
                      v-for="expiry in expiries"
                      :selected="isSelectedExpiry(item.expiry, expiry)"
                      v-bind:value="expiry"
                      v-bind:key="expiry"
                    >
                      {{ expiry }}
                    </option>
                  </template>
                </select>
              </td>
              <td>
                <span v-if="item.instrumentType != 'f'">
                  <a
                    class="btn btn-light border-0 btn-sm me-3"
                    @click="adjustLegStrike(item, 'down')"
                    v-if="!activeBasketEditing.isAdjusting"
                    href="javascript:void(0)"
                    title="Decrement strike"
                    role="button"
                  >
                    <i class="fa-sharp fa-solid fa-arrow-up fa-xl"></i>
                  </a>

                  <span class="fw-bold fs-6" :title="item.symbol">{{
                    item.strike
                  }}</span>

                  <a
                    class="btn btn-light border-0 btn-sm ms-3"
                    @click="adjustLegStrike(item, 'up')"
                    v-if="!activeBasketEditing.isAdjusting"
                    href="javascript:void(0)"
                    title="Increment strike"
                    role="button"
                  >
                    <i class="fa-sharp fa-solid fa-arrow-down fa-xl"></i>
                  </a>
                </span>
              </td>
              <td>{{ item.optionType.toUpperCase() }}</td>

              <td>
                <span
                  class="p-1 badge fs-6"
                  :class="
                    item.transactionType == 'buy'
                      ? 'text-bg-success'
                      : 'text-bg-danger'
                  "
                >
                  {{ item.transactionType.toUpperCase() }}
                </span>
              </td>
              <td @mouseenter="sendBidAskSpreadRequest(item.symbol)">
                <span
                  class="fw-bold"
                  :title="
                    bidAskSpread.loading
                      ? 'Loading...'
                      : getBidAskSpreadMessage()
                  "
                >
                  {{
                    instruments[item.symbol]
                      ? instruments[item.symbol].Ltp.toFixed(2)
                      : 0
                  }}
                </span>
              </td>
              <td v-if="activeBasketEditing.isAdjusting">
                {{ item.executedPrice }}
              </td>
              <td v-if="activeBasketEditing.isAdjusting">
                <span
                  v-if="
                    item.instrumentType === 'o' &&
                    activeBasketEditing.isAdjusting === false
                  "
                >
                  {{
                    item.greeksAtExecution.iv
                      ? (item.greeksAtExecution.iv * 100).toFixed(2)
                      : 0
                  }}
                </span>
                <span v-else>0</span>
              </td>
              <td v-if="activeBasketEditing.isAdjusting">
                {{
                  item.greeksAtExecution.theta
                    ? item.greeksAtExecution.theta.toFixed(4)
                    : 0
                }}
              </td>
              <td v-if="activeBasketEditing.isAdjusting">
                {{
                  item.greeksAtExecution.vega
                    ? item.greeksAtExecution.vega.toFixed(4)
                    : 0
                }}
              </td>
              <td
                v-if="activeBasketEditing.isAdjusting"
                :title="item.greeksAtExecution.delta.toFixed(4)"
              >
                {{
                  item.greeksAtExecution.delta
                    ? item.greeksAtExecution.delta.toFixed(4)
                    : 0
                }}
              </td>
              <td v-if="activeBasketEditing.isAdjusting">
                {{
                  item.greeksAtExecution.gamma
                    ? item.greeksAtExecution.gamma.toFixed(4)
                    : 0
                }}
              </td>

              <!-- <td>
                <span v-if="item.instrumentType === 'o'  && activeBasketEditing.isAdjusting === false" >
                  {{item.greeksAtNow.iv ? (item.greeksAtNow.iv * 100).toFixed(2) : 0}}
                </span>
                <span v-else>0</span>                
              </td>
              <td>{{item.greeksAtNow.theta ? item.greeksAtNow.theta.toFixed(4) : 0}}</td>
              <td>{{item.greeksAtNow.vega ? item.greeksAtNow.vega.toFixed(4) : 0}}</td>
              <td :title="item.greeksAtNow.delta.toFixed(4)">{{item.greeksAtNow.delta ? item.greeksAtNow.delta.toFixed(4) : 0}}</td>
              <td>{{item.greeksAtNow.gamma ? item.greeksAtNow.gamma.toFixed(4) : 0}}</td> -->
              <!-- <td >{{item.symbol}}</td> -->
              <td>
                <span v-if="activeBasketEditing.isAdjusting">{{
                  item.quantity
                }}</span>
                <input
                  v-else
                  @input="quantityUpdated"
                  v-model="item.quantity"
                  style="width: 100px; display: inline"
                  min="1"
                  type="number"
                  class="form-control"
                  placeholder="lots"
                  onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                />
                <label title="Quantity"
                  >[{{ item.quantity * item.lotSize }}]</label
                >
              </td>
              <td v-if="!activeBasketEditing.isAdjusting">
                <a
                  title="Remove position"
                  href="javascript:void(0)"
                  @click="removeFromBasket(item.symbol)"
                  ><i class="fa-solid fa-trash"></i
                ></a>
              </td>
            </tr>

            <tr>
              <td colspan="3">
                <div class="fw-bold">
                  <span>Margin required: </span>
                  <span class="fw-bold red"
                    >&#8377; -{{
                      Number(
                        activeBasketEditing.marginRequired
                          ? activeBasketEditing.marginRequired.toFixed(2)
                          : 0
                      ).toLocaleString()
                    }}</span
                  >
                  <a
                    class="btn link"
                    title="Refresh margin"
                    style="padding-top: 5px"
                    @click="getRequiredMargin"
                    href="javascript:void(0)"
                  >
                    <i class="fa-solid fa-rotate fa-lg"></i>
                  </a>
                </div>
              </td>

              <td>
                <span>
                  <a
                    class="btn btn-light border-0 btn-sm me-3"
                    @click="adjustPositionStrike('down')"
                    v-if="!activeBasketEditing.isAdjusting"
                    href="javascript:void(0)"
                    title="Decrement all strikes"
                    role="button"
                  >
                    <i class="fa-sharp fa-solid fa-arrow-up fa-xl"></i>
                  </a>

                  <!-- <a @click="adjustPositionStrike('down')" v-if="!activeBasketEditing.isAdjusting" href="javascript:void(0)"
                class="strike-incdcr-arrows" title="Decrement all strikes">&#9650;</a> -->

                  <a
                    class="btn btn-light border-0 btn-sm"
                    @click="adjustPositionStrike('up')"
                    v-if="!activeBasketEditing.isAdjusting"
                    href="javascript:void(0)"
                    title="Increment all strikes"
                    role="button"
                  >
                    <i class="fa-sharp fa-solid fa-arrow-down fa-xl"></i>
                  </a>

                  <!-- <a @click="adjustPositionStrike('up')" v-if="!activeBasketEditing.isAdjusting" href="javascript:void(0)"
              class="strike-incdcr-arrows" title="Increment all strikes">&#9660;</a> -->
                </span>
              </td>
              <td colspan="3">
                <div class="fw-bold">
                  Setup cost:
                  <span
                    v-bind:class="{
                      red: activeBasketEditing.setupCost < 0,
                      green: activeBasketEditing.setupCost > 0,
                    }"
                  >
                    &#8377;
                    {{
                      Number(
                        activeBasketEditing.setupCost
                          ? activeBasketEditing.setupCost.toFixed(2)
                          : 0
                      ).toLocaleString()
                    }}
                  </span>
                  ,
                  <span class="red" title="Brokerage and Taxes">
                    &#8377;
                    {{
                      activeBasketEditing.brokerageAndTaxes
                        ? activeBasketEditing.brokerageAndTaxes.toFixed(2)
                        : 0
                    }}
                  </span>
                </div>
              </td>

              <td
                v-if="activeBasketEditing.isAdjusting"
                :colspan="activeBasketEditing.isAdjusting ? 6 : 0"
              ></td>
              <!-- executed price -->
              <!-- <td colspan="5">
              <a class='btn btn-outline-secondary'  
                 @click="refreshGreeksInternal('editing')" href="javascript:void(0)">
                Refresh Greeks
                <span class="material-icons md-18" >
                refresh
                </span>
              </a>            
            </td> -->
              <td>
                <div>
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      v-model="showPayOff"
                      @click="showPayOff = !showPayOff"
                      type="checkbox"
                      role="switch"
                      id="showHidePayoff"
                    />
                    <label class="form-check-label" for="showHidePayoff"
                      >Show/hide payoff</label
                    >
                  </div>
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      v-model="activeBasketEditing.includeInPayoff"
                      @change="addBasketToPayoff()"
                      type="checkbox"
                      role="switch"
                      id="addToPayoff"
                    />
                    <label class="form-check-label" for="addToPayoff"
                      >Add to payoff</label
                    >
                  </div>
                </div>
              </td>
              <td v-if="!activeBasketEditing.isAdjusting">
                <a
                  title="Clear basket"
                  href="javascript:void(0)"
                  @click="clearBasket()"
                  ><i class="fa-solid fa-trash"></i
                ></a>
              </td>
            </tr>
          </table>
          <!-- </draggable> -->
        </div>
        <div
          v-if="
            !(
              isObjectEmpty(activeBasketEditing) ||
              activeBasketEditing.orders.length == 0
            )
          "
          style="margin-left: 10px; margin-right: 10px"
        >
          <p class="bold" v-if="activeBasketEditing.isAdjusting">
            Underlying when executed:
            {{
              activeBasketEditing.underlyingPriceAtOpen
                ? activeBasketEditing.underlyingPriceAtOpen
                : 0
            }}
            <span
              v-bind:class="{
                red: getUnderlyingChange('points') < 0,
                green: getUnderlyingChange('points') > 0,
              }"
              >({{ getUnderlyingChange("points").toFixed(2) }},
              {{ getUnderlyingChange("percent").toFixed(2) }}%)</span
            >
            <span class="space-30"></span>
            <span
              >Realized P/L:
              {{ activeBasketEditing.realizedPL.toFixed(2) }}</span
            >
          </p>
        </div>
        <div
          v-show="!isLegsAdded && !isObjectEmpty(this.activeBasketEditing)"
          class="align-self-center mt-5 mb-5"
        >
          <h5>
            Add positions from option chain to start building & executing your
            basket.
          </h5>
          <a
            class="btn btn-primary mt-2"
            v-show="orderBasketsEditing.length > 0"
            title="Remove empty basket"
            @click="removeBasketFromEditor(activeBasketEditing.id)"
            href="javascript:void(0)"
          >
            Remove Empty Basket
          </a>
        </div>

        <div
          v-show="
            showPayOff &&
            !isObjectEmpty(activeBasketEditing) &&
            activeBasketEditing.orders.length > 0 &&
            orderBasketsEditing.length > 0
          "
          class="p-0 m-0"
        >
          <div class="">
            <!-- <div class="text-right">
              <a title="Refresh payoff with latest price" href="javascript:void(0)"
                v-on:click="updateBasketEditorChart(activeBasketEditing.instrument)" id='updateBasketEditorChart'>
                <span class="material-icons">
                  refresh
                </span>
              </a>
            </div> -->
            <apexchart
              height="450px"
              type="line"
              :options="basketEditorChartOptions.options"
              ref="payoffChart"
              :series="basketEditorChartOptions.series"
            ></apexchart>
            <div>
              <table class="table table-sm text-center bold" style="margin: 0">
                <tr>
                  <th>Basket</th>
                  <th>Max Profit</th>
                  <th>Max Loss</th>
                  <th>Breakeven(s)</th>
                </tr>
                <tr
                  v-for="(item, id) in basketEditorMaxProfitLossBreakEvens"
                  v-bind:key="id"
                >
                  <td>{{ item.id }}</td>
                  <td
                    v-bind:class="{
                      red: item.maxProfit < 0,
                      green: item.maxProfit >= -1,
                    }"
                  >
                    {{
                      item.maxProfit == -1
                        ? "Unlimited"
                        : item.maxProfit.toFixed(2)
                    }}
                  </td>
                  <td
                    v-bind:class="{
                      red: item.maxLoss <= 1,
                      green: item.maxLoss > 1,
                    }"
                  >
                    {{
                      item.maxLoss == 1 ? "Unlimited" : item.maxLoss.toFixed(2)
                    }}
                  </td>
                  <td>
                    {{
                      item.breakEvens.length == 0
                        ? "No Loss"
                        : item.breakEvens.join("-")
                    }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <hr v-if="isLegsAdded && !activeBasketEditing.isAdjusting" />
        <div
          v-if="isLegsAdded && !activeBasketEditing.isAdjusting"
          class="d-flex justify-content-center mt-4"
        >
          <div class="text-center">
            <div class="row">
              <div class="col-auto">
                <div class="input-group">
                  <input
                    class="form-control"
                    v-model="strategyName"
                    id="strategyName"
                    placeholder="Basket name"
                    title="Basket name"
                  />
                  <div class="input-group-append">
                    <div class="input-group-text">
                      <a
                        href="javascript:void(0)"
                        @click="generateRandomStrategyName"
                        title="Generate random basket name"
                        style="height: 21px"
                      >
                        <span class="material-icons"> loop </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto" v-if="executionMode == 'LIVE'">
                <select
                  title="Product"
                  v-model="selectedProduct"
                  id="product"
                  class="form-select"
                  @change="clearErrors"
                >
                  <option
                    v-for="product in products"
                    v-bind:value="product.value"
                    v-bind:key="product.value"
                    title="Product (Intraday/Delivery)"
                  >
                    {{ product.name }}
                  </option>
                </select>
              </div>
              <div class="col-auto" v-if="executionMode == 'LIVE'">
                <select
                  title="Broker"
                  :disabled="executionMode == 'SIMULATION'"
                  v-model="selectedBrokerId"
                  id="broker"
                  class="form-select"
                  @change="updateSelectedBrokerId"
                >
                  <option
                    v-for="broker in activeBrokers"
                    v-bind:value="broker.id"
                    v-bind:key="broker.id"
                    title="Broker"
                  >
                    {{ broker.name }}&nbsp;({{ broker.brokerTypeDescription }})
                  </option>
                </select>
              </div>

              <div class="col-auto">
                <v-select
                  style="width: 300px"
                  v-bind:clearable="false"
                  v-on:update:modelValue="executionBasketIdChanged"
                  title="Basket Group"
                  :options="basketGroups"
                  :modelValue="basketGroupId"
                  :reduce="(x) => x.id"
                  label="name"
                />
              </div>

              <div class="col-auto" v-if="executionMode == 'LIVE'">
                <div class="form-check mb-2">
                  <input
                    v-model="isTesting"
                    id="OEpaperTradeMode"
                    title="Execute in Test Mode"
                    :disabled="executionMode == 'SIMULATION'"
                    class="form-check-input"
                    @change="IncludeAllInPayoff()"
                    type="checkbox"
                  />
                  <label class="ms-2 form-check-label" for="OEpaperTradeMode">
                    Paper Trade
                  </label>
                </div>
              </div>
            </div>
            <div class="mt-2">
              <div class="col-auto">
                <input
                  type="button"
                  style="width: 300px"
                  v-on:click.prevent="executeBasketOrders"
                  id="executeBasketOrders"
                  title="Execute the Basket"
                  class="btn btn-primary"
                  value="Execute"
                />
              </div>
            </div>
            <ul style="margin-top: 10px">
              <li
                style="color: red"
                v-for="error in executionErrors"
                v-bind:key="error.symbol"
              >
                <span v-html="error.message"></span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      tabindex="-1"
      id="executeStrategyParametersModal"
      aria-labelledby="executeStrategyParametersModal"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div style="padding: 20px; margin: 10px">
            <form>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="name">Name</label>
                  <input
                    class="form-control"
                    id="name"
                    readonly
                    placeholder="Name"
                    v-model="strategyParamsModel.strategyName"
                    @focus="$event.target.select()"
                  />
                </div>
                <div class="form-group col-md-4">
                  <label for="broker">Broker</label>
                  <select
                    title="broker"
                    v-model="strategyParamsModel.brokerId"
                    class="form-select"
                  >
                    <option
                      value="-1"
                      :selected="strategyParamsModel.broker == -1"
                    >
                      Choose...
                    </option>
                    <option
                      value="1"
                      :selected="strategyParamsModel.broker == 1"
                    >
                      AliceBlue
                    </option>
                    <option
                      value="8"
                      :selected="strategyParamsModel.broker == 8"
                    >
                      SASOnline
                    </option>
                    <option
                      value="9"
                      :selected="strategyParamsModel.broker == 9"
                    >
                      Dhan
                    </option>
                  </select>
                </div>

                <div class="form-group col-md-4">
                  <label>Execution Type</label>
                  <select
                    title="Execution Type"
                    v-model="strategyParamsModel.executionType"
                    class="form-select"
                  >
                    <option
                      value="live"
                      :selected="strategyParamsModel.executionType == 'live'"
                    >
                      Live
                    </option>
                    <option
                      value="paper"
                      :selected="strategyParamsModel.executionType == 'paper'"
                    >
                      Paper
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-4">
                  <label>Quantity</label>
                  <input
                    class="form-control"
                    v-model="strategyParamsModel.quantity"
                    type="number"
                    placeholder="Multiplier"
                    @focus="$event.target.select()"
                  />
                </div>

                <div class="form-group col-md-4">
                  <label>Expiry</label>
                  <select
                    title="Expiry"
                    v-model="strategyParamsModel.expiry"
                    class="form-select"
                  >
                    <option
                      value="current-weekly"
                      :selected="strategyParamsModel.expiry == 'current-weekly'"
                    >
                      Current Weekly
                    </option>
                    <option
                      value="next-weekly"
                      :selected="strategyParamsModel.expiry == 'next-weekly'"
                    >
                      Next Weekly
                    </option>
                    <option
                      value="current-monthly"
                      :selected="
                        strategyParamsModel.expiry == 'current-monthly'
                      "
                    >
                      Current Monthly
                    </option>
                    <option
                      value="next-monthly"
                      :selected="strategyParamsModel.expiry == 'next-monthly'"
                    >
                      Next Monthly
                    </option>
                  </select>
                </div>

                <div class="form-group col-md-4">
                  <label>Product</label>
                  <select
                    title="Product"
                    v-model="strategyParamsModel.product"
                    class="form-select"
                  >
                    <option
                      value="i"
                      :selected="strategyParamsModel.product == 'i'"
                    >
                      Intraday
                    </option>
                    <option
                      value="d"
                      :selected="strategyParamsModel.product == 'd'"
                    >
                      Delivery
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-4">
                  <label>Instrument</label>
                  <v-select
                    multiple
                    v-model="strategyParamsModel.selectedInstruments"
                    :options="strategyParamsModel.availableInstruments"
                  />

                  <!-- <select title="Instrument" v-model="strategyParamsModel.instrument" class="form-control">
                <option value="all" :selected="strategyParamsModel.instrument == 'all'">
                  All
                </option>
                <option value="nifty" :selected="strategyParamsModel.instrument == 'nifty'">
                  Nifty
                </option>
                <option value="banknifty" :selected="strategyParamsModel.instrument == 'banknifty'">
                  Bank Nifty
                </option>
                <option value="finnifty" :selected="strategyParamsModel.instrument == 'finnifty'">
                  Fin Nifty
                </option>
                <option value="midcpnifty" :selected="strategyParamsModel.instrument == 'midcpnifty'">
                  Midcap Nifty
                </option>                
              </select> -->
                </div>

                <div class="form-group col-md-4">
                  <label>Transaction Type</label>
                  <select
                    title="Transaction Type"
                    v-model="strategyParamsModel.transactionType"
                    class="form-select"
                  >
                    <option
                      value="buy"
                      :selected="strategyParamsModel.transactionType == 'buy'"
                    >
                      Buy
                    </option>
                    <option
                      value="sell"
                      :selected="strategyParamsModel.transactionType == 'sell'"
                    >
                      Sell
                    </option>
                  </select>
                </div>

                <div class="form-group col-md-4">
                  <label>Basket Group</label>
                  <v-select
                    style="width: 370px"
                    v-bind:clearable="false"
                    v-model="basketGroupId"
                    title="Basket Group"
                    :options="basketGroups"
                    :reduce="(basketGroups) => basketGroups.id"
                    label="name"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-subtle"
              data-bs-dismiss="modal"
            >
              Close
            </button>

            <button
              class="btn btn-primary float-right"
              data-bs-dismiss="modal"
              v-on:click.exact.prevent="
                closeExecuteStrategyParamsModal('ok', false)
              "
              v-on:click.ctrl.prevent="
                closeExecuteStrategyParamsModal('ok', true)
              "
            >
              Execute
            </button>

            <button
              v-on:click.prevent="closeExecuteStrategyParamsModal('cancel')"
              data-bs-dismiss="modal"
              class="btn btn-secondary float-right"
              style="margin-right: 10px"
            >
              Cancel
            </button>
            <!-- <button type="button" class="btn btn-primary">Confirm</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState, mapActions, mapMutations } from "vuex";
import { TransactionType } from "../constants";
// import draggable from 'vuedraggable'
import SymbolService from "@/services/SymbolService";
import Utils from "@/services/Utils";
import moment from "moment";
import { useToast } from "vue-toastification";

// https://github.com/Maronato/vue-toastification
const toast = useToast();

export default {
  name: "OrderBasketEditor",
  components: {
    // draggable
  },
  data() {
    return {
      payoffUpdateTimerId: -1,
      quickExecutionPanelTypeVisible: false,
      quickExecutionPanelExecuteButtonTitle: "Execute",
      quickExecutionPanelExecuteStrategyType: "straddle-plus",
      //      quickExecutionBasketGroupId: 1,
      quickExecutionPanelStraddle: false,
      quickExecutionPanelType: "",
      quickExecutionProduct: "i",
      quickExecutionBroker: -1,
      quickExecutionSelectedCallStrike: "ATM",
      quickExecutionSelectedPutStrike: "ATM",
      quickExecutionLots: 1,
      quickExecutionExpiryType: "CURRENT-WEEKLY",
      quickExecutionAvailableStrikes: [
        "ITM 1",
        "ATM",
        "OTM 1",
        "OTM 2",
        "OTM 3",
        "OTM 4",
        "OTM 5",
        "OTM 6",
        "OTM 7",
        "OTM 8",
        "OTM 9",
        "OTM 10",
      ],
      expiryTypes: [
        "CURRENT-WEEKLY",
        "NEXT-WEEKLY",
        "CURRENT-MONTHLY",
        "NEXT-MONTHLY",
      ],
      quickExecutionTransactionType: "buy",
      quickExecutionSelectedInstruments: [
        "NIFTY",
        "BANKNIFTY",
        "FINNIFTY",
        "MIDCPNIFTY",
        "SENSEX"
      ],
      quickExecutionAvailableInstruments: [
        "NIFTY",
        "BANKNIFTY",
        "FINNIFTY",
        "MIDCPNIFTY",
        "SENSEX"
      ],
      strategyName: "",
      basketGroupId: 1,
      selectedBrokerId: "",
      showPayOff: true,
      products: [
        {
          value: "i",
          name: "Intraday (MIS)",
        },
        {
          value: "d",
          name: "Overnight (NRML)",
        },
      ],
      selectedProduct: "d",
      charOptions: {},
      showStrategies: false,
      isTesting: false,
      executionErrors: [], // strategyName: '', message: '',  symbol: ''
      shouldIncludeAllInPayoff: true,
      adjustmentOn: true, // true - executed, false: latest
      strategyParamsModel: {
        strategyName: "", // for display
        strategyCode: "", // for server side identification
        executionType: "paper", // kive / paper
        quantity: 1,
        instrument: "all", // nifty, banknifty, finnifty, midcpnifty, all
        selectedInstruments: ["nifty", "banknifty", "finnifty", "midcpnifty", "sensex"],
        availableInstruments: ["nifty", "banknifty", "finnifty", "midcpnifty", "sensex"],
        transactionType: "buy", // buy, sell
        broker: "", // available brokers
        product: "i", // intraday / delivery
        expiry: "current-weekly", // weekly, next-weekly, monthly, next-monthly
      },
    };
  },
  computed: {
    ...mapState({
      showHideExecutedOrderBaskets: (state) =>
        state.showHideExecutedOrderBaskets,
      instruments: (state) => state.instruments,
      brokers: (state) => state.brokers,
      instrumentInfos: (state) => state.instrumentInfos,
      bidAskSpread: (state) => state.bidAskSpread,
      niftySpot: (state) => state.instruments["NIFTY 50"],
      orderBasketsEditing: (state) => state.orderBasketsEditing,
      basketGroups: (state) => state.basketGroups,
      activeBasketEditing: (state) => state.activeBasketEditing,
      niftyAtmStrike: (state) => state.niftyAtmStrike,
      showHideBasketEditor: (state) => state.showHideBasketEditor,
      orderBaskets: (state) => state.orderBaskets,
      basketEditorChartOptions: (state) => state.basketEditorChartOptions,
      basketEditorMaxProfitLossBreakEvens: (state) =>
        state.basketEditorMaxProfitLossBreakEvens,
      isBasketEditorInAdjustmentMode: (state) =>
        state.isBasketEditorInAdjustmentMode,
      expiries: (state) => state.expiries,
      finNiftyExpiries: (state) => state.finNiftyExpiries,
      midcapNiftyExpiries: (state) => state.midcapNiftyExpiries,
      bankNiftyExpiries: (state) => state.bankNiftyExpiries,
      sensexExpiries: (state) => state.sensexExpiries,
      showHideOptionsSimulator: (state) => state.showHideOptionsSimulator,
      optionSimulatorParams: (state) => state.optionSimulatorParams,
      selectedOptionChainExpiry: (state) => state.selectedOptionChainExpiry,
      activeOptionChain: (state) => state.activeOptionChain,
      monthlies: (state) => state.monthlies,
      executionMode: (state) => state.executionMode,
      primaryFeedSourceId: (state) => state.primaryFeedSourceId,
      defaultBrokerId: (state) => state.defaultBrokerId,
    }),
    basketGroupIds() {
      return this.basketGroups.map((x) => x.id);
    },
    activeBrokers() {
      var activeBrokers = this.brokers.filter((x) => x.isTradingSessionActive);
      return activeBrokers;
    },
    isLegsAdded() {
      return (
        this.activeBasketEditing.orders &&
        this.activeBasketEditing.orders.length > 0
      );
    },
    futuresSymbol() {
      // is this nifty or bank nifty
      // get current month
      return SymbolService.getFuturesSymbolCurrent(
        this.activeBasketEditing.instrument,
        this.monthlies[0]
      );
    },
  },
  watch: {
    "activeBasketEditing.executedBasketPayOffPrice"(value) {
      this.adjustmentOn = value === "executed" ? true : false;
    },
  },
  methods: {
    ...mapActions([
      "sendMessage",
      "setActiveBasketEditing",
      "createNewBasketForEditing",
      "removeBasketFromEditor",
      "clearOrdersFromTheBasket",
      "updateBasketEditorChart",
      "orderBasketExecuted",
      "updateMarginRequiredForActiveBasketEditing",
      "exitFromAdjustments",
      "refreshGreeks",
      "subscribeToLiveFeed",
      "sendBidAskSpreadRequest",
      "addToBasket",
      // 'setBasketGroupForEditedBasket'
    ]),
    ...mapMutations(["updateDefaultBrokerId"]),
    executionBasketIdChanged(id) {
      this.basketGroupId = id;
      console.log("Execution basket id " + id);
    },
    getLotSize(instrument) {
      if (!instrument || instrument.length == 0)
        instrument = this.activeOptionChain;

      let key = `${instrument}-${this.selectedOptionChainExpiry}`.toUpperCase();

      let instrumentInfo = this.instrumentInfos[key];
      if (instrumentInfo) return instrumentInfo.lotSize;
      else return 0;
    },
    resetQuickExecutionPanels(panel_type) {
      if (panel_type == "straddle+") {
        if (this.quickExecutionPanelType == "straddle+")
          this.quickExecutionPanelType = "";
        else this.quickExecutionPanelType = panel_type;
      } else if (panel_type == "strangle") {
        if (this.quickExecutionPanelType == "strangle")
          this.quickExecutionPanelType = "";
        else this.quickExecutionPanelType = panel_type;
      } else if (panel_type == "expiry-day") {
        if (this.quickExecutionPanelType == "expiry-day")
          this.quickExecutionPanelType = "";
        else this.quickExecutionPanelType = panel_type;
      } else {
        this.quickExecutionPanelType = "";
      }

      if (
        this.quickExecutionPanelType ||
        this.quickExecutionPanelType.length > 0
      )
        this.quickExecutionPanelTypeVisible = true;
      else this.quickExecutionPanelTypeVisible = false;
    },
    quickExecutionLotsFocusOut(event) {
      if (event.target.value.length == 0 || event.target.value === 0)
        this.quickExecutionLots = 1;
    },
    removeBasketFromEditorInteral(basketId) {
      this.$simpleAlert
        .confirm(
          "This action will remove the current basket from the editor.",
          "Remove Basket",
          "question"
        )
        .then(() => {
          this.removeBasketFromEditor(basketId);
        })
        .catch(() => {});
    },
    formatExpiry(value) {
      return moment(value).format("DD-MMM-YYYY").toUpperCase();
    },
    isSelectedExpiry(orderExpiry, toBeSelected) {
      let value = this.formatExpiryDate(orderExpiry) == toBeSelected;
      return value;
    },
    async addOrderWithSymbol(
      instrument,
      atm,
      expiry,
      optionType,
      transactionType,
      quantity
    ) {
      let symbol = await Utils.getSymbolOptions(
        instrument,
        expiry,
        atm,
        optionType
      );
      await this.addOrder(
        "o",
        optionType,
        symbol,
        atm,
        transactionType,
        instrument,
        expiry,
        quantity
      );
    },
    async closeExecuteStrategyParamsModal(result, shouldExecute) {
      if (result == "cancel") {
        this.$modal.hide("execute-strategy-params-modal");
        return;
      }

      if (this.strategyParamsModel.brokerId == -1) {
        alert("Please select broker." + result);
        return;
      }

      this.$modal.hide("execute-strategy-params-modal");

      let expiryType = this.strategyParamsModel.expiry;

      this.selectedProduct = this.strategyParamsModel.product;
      this.isTesting =
        this.strategyParamsModel.executionType == "paper" ? true : false;

      switch (this.strategyParamsModel.strategyCode) {
        case "straddle":
          for (
            let index = 0;
            index < this.strategyParamsModel.selectedInstruments.length;
            index++
          ) {
            const instrument =
              this.strategyParamsModel.selectedInstruments[index];
            await this.executeStraddleBaskets(
              instrument,
              expiryType,
              this.strategyParamsModel.quantity,
              shouldExecute
            );
          }
          break;
      }
    },
    async executeStraddleBaskets(
      instrument,
      expiryType,
      lots,
      shouldExecute,
      groupId
    ) {
      instrument = instrument.toLowerCase();
      expiryType = expiryType.toLowerCase();

      var atm = await Utils.getAtm(instrument);
      var expiry = await Utils.getExpiry(instrument, expiryType);
      await this.createNewBasketForEditingInternal({
        instrument: instrument,
        groupId: groupId,
      });
      await this.addOrderWithSymbol(
        instrument,
        atm,
        expiry,
        "ce",
        this.strategyParamsModel.transactionType,
        lots
      );
      await this.addOrderWithSymbol(
        instrument,
        atm,
        expiry,
        "pe",
        this.strategyParamsModel.transactionType,
        lots
      );
      if (shouldExecute) {
        this.generateRandomStrategyName();
        this.executeBasketOrders(false);
      }
    },

    async executeExpiryDayBaskets(
      instrument,
      expiryType,
      lots,
      shouldExecute,      
      groupId
    ) {
      instrument = instrument.toLowerCase();
      expiryType = expiryType.toLowerCase();

      var expiry = await Utils.getExpiry(instrument, expiryType);
      await this.createNewBasketForEditingInternal({
        instrument: instrument,
        groupId: groupId,
      });


      let strikeUpDown = await Utils.getStrikeInterval(instrument);

      let strikeIncrementCount = 2;

      if (instrument.toLowerCase() == "sensex")
        strikeIncrementCount = 2;

      var atm = await Utils.getAtm(instrument);

      var strike = atm + (strikeUpDown * strikeIncrementCount);
      await this.addOrderWithSymbol(
        instrument,
        strike,
        expiry,
        'ce',
        this.quickExecutionTransactionType,
        lots
      );
      if (shouldExecute) {
        this.generateRandomStrategyName();
        this.executeBasketOrders(false);
      }

      strike = atm - (strikeUpDown * strikeIncrementCount);

      await this.addOrderWithSymbol(
        instrument,
        strike,
        expiry,
        'pe',
        this.quickExecutionTransactionType,
        lots
      );
      if (shouldExecute) {
        this.generateRandomStrategyName();
        this.executeBasketOrders(false);
      }      
    },

    formatExpiryDate(value) {
      return moment(value).format("DD-MMM-YYYY").toUpperCase();
    },
    isEmpty(obj) {
      return _.isEmpty(obj);
    },
    getBidAskSpreadMessage() {
      let goodSpreadLength = 1.5;

      if (this.activeBasketEditing.instrument === "banknifty")
        goodSpreadLength = 5;

      let goodOrWarningMessage =
        Math.abs(this.bidAskSpread.spread) > goodSpreadLength
          ? "WARNING -"
          : "Good -";

      let message = `${goodOrWarningMessage} ${this.bidAskSpread.symbol}
Bid: ${this.bidAskSpread.bidPrice}, Ask: ${this.bidAskSpread.askPrice}
Spread: ${this.bidAskSpread.spread}
      `;

      return message;
    },
    reverseTransactions() {
      if (_.isEmpty(this.activeBasketEditing)) return;

      this.activeBasketEditing.orders.forEach(async (order) => {
        order.transactionType = order.transactionType == "buy" ? "sell" : "buy";
        order.symbol = await Utils.getSymbolOptions(
          this.activeBasketEditing.instrument,
          order.expiry,
          order.strike,
          order.optionType
        );
        this.subscribeToLiveFeed([{
          Exchange: order.exchange,
          Symbol: order.symbol           
        }]);
        this.updateBasketEditorChartInternal(
          this.activeBasketEditing.instrument
        );
      });
    },
    async updateOrderAfterEdit(symbol) {
      if (_.isEmpty(this.activeBasketEditing)) return;

      let order = this.activeBasketEditing.orders.find(
        (x) => x.symbol === symbol
      );
      if (!order) return;

      if (order.instrumentType === "f") return;

      if (order.expiryEdited.length > 0)
        order.expiry = moment(order.expiryEdited, "DD-MMM-YYYY").toDate();

      let previousSymbol = order.symbol;

      // order.symbol = SymbolService.getSymbol({
      //   instrument: this.activeBasketEditing.instrument,
      //   expiry: order.expiry,
      //   strike: order.strike,
      //   optionType: order.optionType
      // })

      order.symbol = await Utils.getSymbolOptions(
        this.activeBasketEditing.instrument,
        order.expiry,
        order.strike,
        order.optionType
      );

      if (order.symbol != previousSymbol) {
        this.subscribeToLiveFeed([{
          Exchange: order.exchange,
          Symbol: order.symbol           
        }]);
        this.updateBasketEditorChartInternal(
          this.activeBasketEditing.instrument
        );
      }
    },
    adjustmentOnUpdated() {
      this.activeBasketEditing.executedBasketPayOffPrice = this.adjustmentOn
        ? "executed"
        : "latest";
      this.updateBasketEditorChartInternal(this.activeBasketEditing.instrument);
    },
    updateBasketEditorChartInternal(instrument) {
      if (this.payoffUpdateTimerId && this.payoffUpdateTimerId > 1)
        clearTimeout(this.payoffUpdateTimerId);

      if (this.showPayOff) {
        this.payoffUpdateTimerId = setTimeout(() => {
          this.updateBasketEditorChart(instrument);
        }, 500);
      } else {
        this.updateBasketEditorChart(instrument);
      }
    },
    refreshGreeksInternal(basketStatus) {
      this.refreshGreeks({
        basketStatus: basketStatus, // executed, editing
      });
    },
    // setBasketGroupInternal ( ) {
    //   this.setBasketGroupForEditedBasket({
    //     basketId: this.activeBasketEditing.id,
    //     groupId: this.activeBasketEditing.groupId
    //   })
    // },
    calculatePositionGreeks(type) {
      let greeks = {
        theta: 0,
        vega: 0,
        deltaPoints: 0,
        delta: 0,
        gamma: 0,
      };

      if (_.isEmpty(this.activeBasketEditing)) return greeks;
      if (!this.activeBasketEditing.orders) return greeks;
      if (this.activeBasketEditing.orders.length === 0) return greeks;

      if (type === "now") {
        this.activeBasketEditing.orders.forEach((order) => {
          greeks.theta = greeks.theta + order.greeksAtNow.theta;
          greeks.vega = greeks.vega + order.greeksAtNow.vega;
          greeks.deltaPoints = greeks.deltaPoints + order.greeksAtNow.delta;
          greeks.delta = greeks.delta + order.greeksAtNow.delta;
          greeks.gamma = greeks.gamma + order.greeksAtNow.gamma;
        });
      } else {
        if (!this.activeBasketEditing.isAdjusting) return greeks;

        this.activeBasketEditing.orders.forEach((order) => {
          greeks.theta = greeks.theta + order.greeksAtExecution.theta;
          greeks.vega = greeks.vega + order.greeksAtExecution.vega;
          greeks.deltaPoints =
            greeks.deltaPoints + order.greeksAtExecution.delta;
          greeks.delta = greeks.delta + order.greeksAtExecution.delta;
          greeks.gamma = greeks.gamma + order.greeksAtExecution.gamma;
        });
      }

      return greeks;
    },
    getUnderlyingChange(format = "points") {
      if (!this.isBasketEditorInAdjustmentMode) return 0;
      if (_.isEmpty(this.activeBasketEditing)) return 0;

      let underlyingSpotPrice = 0;
      if (this.activeBasketEditing.instrument === "nifty")
        underlyingSpotPrice = this.instruments["NIFTY 50"].Ltp;
      else if (this.activeBasketEditing.instrument === "banknifty")
        underlyingSpotPrice = this.instruments["NIFTY BANK"].Ltp;
      else if (this.activeBasketEditing.instrument === "sensex")
        underlyingSpotPrice = this.instruments["SENSEX"].Ltp;      
      else if (this.activeBasketEditing.instrument === "finnifty")
        underlyingSpotPrice = this.instruments["FINNIFTY"].Ltp;
      else if (this.activeBasketEditing.instrument === "midcpnifty")
        underlyingSpotPrice = this.instruments["MIDCPNIFTY"].Ltp;
      else return;

      let spotChange =
        underlyingSpotPrice - this.activeBasketEditing.underlyingPriceAtOpen;

      if (format === "points") return spotChange;
      else
        return (
          (spotChange / this.activeBasketEditing.underlyingPriceAtOpen) * 100
        );
    },
    IncludeAllInPayoff() {
      this.activeBasketEditing.orders.forEach((order) => {
        order.includeInPayoff = this.shouldIncludeAllInPayoff;
      });

      this.updateBasketEditorChartInternal(this.activeBasketEditing.instrument);
    },
    exitFromAdjustmentsOrClearBasketInternal() {
      // if (!this.isBasketEditorInAdjustmentMode) {
      //   this.exitFromAdjustments()
      //   return
      // }

      this.$simpleAlert
        .confirm(
          "This action will clear all the baskets from the editor.",
          "Reset Basket Editor",
          "question"
        )
        .then(() => {
          this.exitFromAdjustments();
        })
        .catch(() => {});
    },
    addOrderToPayoff() {
      let isAtleastOneSelected = false;
      for (let i = 0; i < this.activeBasketEditing.orders.length; i++) {
        if (this.activeBasketEditing.orders[i].includeInPayoff) {
          isAtleastOneSelected = true;
          break;
        }
      }

      this.shouldIncludeAllInPayoff = isAtleastOneSelected;
      this.updateBasketEditorChartInternal(this.activeBasketEditing.instrument);
    },
    addBasketToPayoff() {
      this.activeBasketEditing.orders.forEach((order) => {
        order.includeInPayoff = this.activeBasketEditing.includeInPayoff;
      });
      this.shouldIncludeAllInPayoff = this.activeBasketEditing.includeInPayoff;
      this.updateBasketEditorChartInternal(this.activeBasketEditing.instrument);
    },
    updateSelectedBrokerId() {
      this.updateDefaultBrokerId(this.selectedBrokerId);
      this.executionErrors = [];
    },
    async createNewBasketForEditingInternal(options) {
      await this.createNewBasketForEditing(options);
    },
    selectBasket(id) {
      this.setActiveBasketEditing(id);
    },
    isObjectEmpty(obj) {
      return _.isEmpty(obj);
    },
    getRequiredMargin() {
      // this.updateMarginRequiredForActiveBasketEditing(this.activeBasketEditing.id)
    },
    async adjustLegStrike(leg, direction) {
      if (leg.instrumentType === "f") return;

      var legIncrement = 0;
      if (this.activeBasketEditing.instrument === "nifty") legIncrement = 50;
      else if (this.activeBasketEditing.instrument === "banknifty")
        legIncrement = 100;
      else if (this.activeBasketEditing.instrument === "sensex")
        legIncrement = 100;      
      else if (this.activeBasketEditing.instrument === "finnifty")
        legIncrement = 50;
      else if (this.activeBasketEditing.instrument === "midcpnifty")
        legIncrement = 25;

      var strike = leg.strike;

      if (direction === "up") strike = leg.strike + legIncrement;
      else strike = leg.strike - legIncrement;

      // do we already have this strike in the basket for he same option type and expiry & base symbol
      var found = this.activeBasketEditing.orders.find(
        (x) =>
          x.optionType === leg.optionType &&
          x.strike == strike &&
          moment(x.expiry).isSame(leg.expiry)
      );
      if (found) {
        this.showWarning("This strike is already added.");
        return;
      }

      leg.strike = strike;

      // leg.symbol = SymbolService.getSymbol({ instrument: this.activeBasketEditing.instrument, expiry: leg.expiry, optionType: leg.optionType, strike: leg.strike })
      leg.symbol = await Utils.getSymbolOptions(
        this.activeBasketEditing.instrument,
        leg.expiry,
        leg.strike,
        leg.optionType
      );

      leg.priceWhenAddedToBasket = this.instruments[leg.symbol]
        ? this.instruments[leg.symbol].Ltp
        : 0;

      this.updateBasketEditorChartInternal(this.activeBasketEditing.instrument);
    },
    async adjustPositionStrike(direction) {
      var legIncrement = 0;
      if (this.activeBasketEditing.instrument === "nifty") legIncrement = 50;
      else if (this.activeBasketEditing.instrument === "banknifty")
        legIncrement = 100;
      else if (this.activeBasketEditing.instrument === "sensex")
        legIncrement = 100;      
      else if (this.activeBasketEditing.instrument === "finnifty")
        legIncrement = 50;
      else if (this.activeBasketEditing.instrument === "midcpnifty")
        legIncrement = 25;

      for (var i = 0; i < this.activeBasketEditing.orders.length; i++) {
        let leg = this.activeBasketEditing.orders[i];

        if (leg.instrumentType === "f") continue;

        if (direction === "up") leg.strike = leg.strike + legIncrement;
        else leg.strike = leg.strike - legIncrement;

        //  leg.symbol = SymbolService.getSymbol({ instrument: this.activeBasketEditing.instrument, expiry: leg.expiry, optionType: leg.optionType, strike: leg.strike })
        leg.symbol = await Utils.getSymbolOptions(
          this.activeBasketEditing.instrument,
          leg.expiry,
          leg.strike,
          leg.optionType
        );
        leg.priceWhenAddedToBasket = this.instruments[leg.symbol]
          ? this.instruments[leg.symbol].Ltp
          : 0;
      }

      this.updateBasketEditorChartInternal(this.activeBasketEditing.instrument);
      // this.getRequiredMargin()
    },
    quantityUpdated() {
      this.updateBasketEditorChartInternal(this.activeBasketEditing.instrument);
      // this.getRequiredMargin()
    },
    removeFromBasket(symbol) {
      var index = this.activeBasketEditing.orders.findIndex(
        (x) => x.symbol === symbol
      );
      this.activeBasketEditing.orders.splice(index, 1);

      this.updateBasketEditorChartInternal(this.activeBasketEditing.instrument);
      // this.getRequiredMargin()
    },
    getInTheMoneyBackgroundClass(strike, optionType) {
      var niftySpotLtp = this.niftySpot ? this.niftySpot.Ltp : 0;
      if (optionType == "ce") {
        if (strike <= niftySpotLtp) return "atm-row";
      } else if (strike > niftySpotLtp) return "atm-row";
    },
    calculatePositionSize() {
      let maxLossValue =
        this.activeBasketEditing.maxLossType === "percent"
          ? this.activeBasketEditing.capital *
            (this.activeBasketEditing.accountMaxLossSL / 100)
          : this.activeBasketEditing.accountMaxLossSL;
      this.activeBasketEditing.maxLossValue = maxLossValue;

      let maxLossInPoints = maxLossValue / this.activeBasketEditing.lotSize;
      this.activeBasketEditing.maxLossInPoints = maxLossInPoints;

      let positionSLPoints =
        this.activeBasketEditing.premiumSLType === "percent"
          ? this.activeBasketEditing.optionPrice *
            (this.activeBasketEditing.positionMaxLossSL / 100)
          : this.activeBasketEditing.positionMaxLossSL;
      this.activeBasketEditing.positionSLPoints = positionSLPoints;

      this.activeBasketEditing.lotsToTrade = maxLossInPoints / positionSLPoints;
    },
    clearBasket() {
      this.activeBasketEditing.orders = [];
      this.clearOrdersFromTheBasket(this.activeBasketEditing.id);
    },
    showWarning(message) {
      // this.$toasted.show(message)
      toast.error(message);
    },
    async addOrder(
      instrumentType,
      optionType,
      symbol,
      strike,
      transactionType,
      instrument,
      expiry,
      quantity
    ) {
      if (!instrumentType) instrumentType = "o";

      let order = {
        optionType,
        symbol,
        strike,
        transactionType,
        instrument: instrument,
        expiry: expiry,
        instrumentType: instrumentType,
        quantity: quantity,
      };

      await this.addToBasket(order);
    },
    async executeReadymadeStrategy(
      strategy,
      shouldExecute = false,
      addtionalData = ""
    ) {
      let instrument = this.activeOptionChain;
      let expiry = this.selectedOptionChainExpiry;

      if (expiry.length === 0) {
        this.showWarning("Please select the expiry from the Options Chain.");
        return;
      }

      this.resetQuickExecutionPanels();

      let atm = await Utils.getAtm(this.activeOptionChain);

      let strikeUpDown = await Utils.getStrikeInterval(instrument);

      if (strategy === "short-straddle") {
        await this.addStraddle(atm, instrument, expiry, shouldExecute, "sell");
      } else if (strategy === "long-straddle") {
        await this.addStraddle(atm, instrument, expiry, shouldExecute, "buy");
      } else if (strategy === "tripple-straddle") {
        await this.addTrippleStraddle(
          atm,
          instrument,
          expiry,
          strikeUpDown,
          shouldExecute,
          addtionalData
        );
      } else if (strategy === "basket-group-sl-straddle") {
        await this.addBasketGroupSLBasedStraddle(shouldExecute);
      } else if (strategy == "basket-group-straddle-all") {
        // this.addBasketGroupStraddleAllInstruments(shouldExecute)
        this.strategyParamsModel.strategyName = "Straddle";
        this.strategyParamsModel.strategyCode = "straddle";
        this.$modal.show("execute-strategy-params-modal");
      } else if (strategy == "expiry-day") {
        console.log("expiry-day");
      } else if (strategy === "basket-group-sl-calender") {
        await this.addBasketGroupSLBasedCalender(instrument, shouldExecute);
      } else if (
        strategy === "basket-group-sl-calender-straddle-spread-ratio"
      ) {
        await this.addBasketGroupSLBasedCalendarStraddleSpreadRatio(
          instrument,
          shouldExecute
        );
      } else if (strategy === "iron-fly") {
        await this.addIronFly(atm, instrument, expiry, strikeUpDown);
      } else if (strategy === "iron-condor") {
        await this.addIronCondor(atm, instrument, expiry, strikeUpDown);
      } else if (strategy === "strangle") {
        await this.addStrangle(atm, instrument, expiry, strikeUpDown);
      } else if (strategy === "long-pe-atm") {
        await this.addLong(
          atm,
          instrument,
          expiry,
          strikeUpDown,
          "pe",
          0,
          shouldExecute
        );
      } else if (strategy === "long-pe-atm+1") {
        await this.addLong(
          atm,
          instrument,
          expiry,
          strikeUpDown,
          "pe",
          1,
          shouldExecute
        );
      } else if (strategy === "long-pe-atm+2") {
        await this.addLong(
          atm,
          instrument,
          expiry,
          strikeUpDown,
          "pe",
          2,
          shouldExecute
        );
      } else if (strategy === "long-ce-atm") {
        await this.addLong(
          atm,
          instrument,
          expiry,
          strikeUpDown,
          "ce",
          0,
          shouldExecute
        );
      } else if (strategy === "long-ce-atm-1") {
        await this.addLong(
          atm,
          instrument,
          expiry,
          strikeUpDown,
          "ce",
          1,
          shouldExecute
        );
      } else if (strategy === "long-ce-atm-2") {
        await this.addLong(
          atm,
          instrument,
          expiry,
          strikeUpDown,
          "ce",
          2,
          shouldExecute
        );
      } else if (strategy === "long-futures") {
        await this.addFutures(instrument, expiry, "buy", shouldExecute);
      } else if (strategy === "short-futures") {
        await this.addFutures(instrument, expiry, "sell", shouldExecute);
      }
    },

    async quickExecutionPanelExecute(shouldExecute) {
      if (this.quickExecutionPanelType == "straddle+") {
        await this.quickExecuteStraddle(shouldExecute);
      } else if (this.quickExecutionPanelType == "expiry-day") {
        await this.quickExecuteExpiryDay(shouldExecute)
      }
    },

    async quickExecuteExpiryDay(shouldExecute) {
      //let instrument = this.activeOptionChain
      let expiry = this.selectedOptionChainExpiry;

      if (expiry.length === 0) {
        this.showWarning("Please select the expiry from the Options Chain.");
        return;
      }

      if (this.quickExecutionSelectedInstruments.length === 0) {
        this.showWarning("Please select instrument(s) to trade.");
        return;
      }

      if (
        shouldExecute == true &&
        this.executionMode == "LIVE" &&
        this.quickExecutionBroker &&
        this.quickExecutionBroker.length == 0
      ) {
        this.showWarning("Please select broker.");
        return;
      }

      this.checkAndSetSelectedBroker();

      this.resetQuickExecutionPanels("expiry-day");

      // this.basketGroupId = this.quickExecutionBasketGroupId

      if (this.quickExecutionSelectedInstruments.length > 0) {
        this.quickExecutionSelectedInstruments.forEach(async (instrument) => {
          await this.executeExpiryDayBaskets(
            instrument,
            this.quickExecutionExpiryType,
            this.quickExecutionLots,
            shouldExecute,
            this.basketGroupId
          );
        });
      }
    },

    async quickExecuteStraddle(shouldExecute) {
      //let instrument = this.activeOptionChain
      let expiry = this.selectedOptionChainExpiry;

      if (expiry.length === 0) {
        this.showWarning("Please select the expiry from the Options Chain.");
        return;
      }

      if (this.quickExecutionSelectedInstruments.length === 0) {
        this.showWarning("Please select instrument(s) to trade.");
        return;
      }

      if (
        shouldExecute == true &&
        this.executionMode == "LIVE" &&
        this.quickExecutionBroker &&
        this.quickExecutionBroker.length == 0
      ) {
        this.showWarning("Please select broker.");
        return;
      }

      this.checkAndSetSelectedBroker();

      this.resetQuickExecutionPanels("straddle+");

      // this.basketGroupId = this.quickExecutionBasketGroupId

      console.log(
        "quickExecutionSelectedInstruments " +
          this.quickExecutionSelectedInstruments
      );
      if (this.quickExecutionSelectedInstruments.length > 0) {
        this.quickExecutionSelectedInstruments.forEach(async (instrument) => {
          await this.executeStraddleBaskets(
            instrument,
            this.quickExecutionExpiryType,
            this.quickExecutionLots,
            shouldExecute,
            this.basketGroupId
          );
        });
      }
    },
    addLong(
      atm,
      instrument,
      expiry,
      strikeUpDown,
      optionType,
      atmUpDownStrikes,
      shouldExecute
    ) {
      let strike =
        optionType === "pe"
          ? atm + strikeUpDown * atmUpDownStrikes
          : atm - strikeUpDown * atmUpDownStrikes;

      this.createNewBasketForEditingInternal();

      let symbol = SymbolService.getSymbol({
        instrument: instrument,
        expiry: expiry,
        strike: strike,
        optionType: optionType,
      });
      this.addOrder("o", optionType, symbol, strike, "buy", instrument, expiry);

      if (shouldExecute) {
        this.generateRandomStrategyName();
        this.executeBasketOrders(false);
      }
    },

    async addFutures(instrument, expiry, transactionType, shouldExecute) {
      this.createNewBasketForEditingInternal();

      let symbol = await SymbolService.getSymbol({
        instrument: instrument,
        expiry: expiry,
        isFutures: true,
      });
      this.addOrder("f", "", symbol, 0, transactionType, instrument, expiry);

      if (shouldExecute) {
        this.generateRandomStrategyName();
        this.executeBasketOrders(false);
      }
    },
    checkAndSetSelectedBroker() {
      console.log("check and seletec broker");
      //if ((!this.defaultBrokerId || this.defaultBrokerId.length == 0) && this.executionMode == 'SIMULATION') {
      if (!this.defaultBrokerId || this.defaultBrokerId.length == 0) {
        this.updateDefaultBrokerId(this.primaryFeedSourceId);
      }
    },
    addBasketGroupSLBasedStraddle(shouldExecute) {
      this.checkAndSetSelectedBroker();

      this.$simpleAlert
        .prompt(
          "Please enter the multiplier (quantity (lots) for all orders).",
          "1",
          "Basket Group SL Based Straddle Strategy"
        )
        .then((multiplier) => {
          var executionCommand = {
            MessageType:
              "basket_group_sl_based_nf_and_bnf_straddle_execution_request",
            ExecutionType: shouldExecute ? 2 : 1, // 2 - Paper Mode, 1- Live Mode (c# enum)
            BrokerId: this.defaultBrokerId,
            Multiplier: multiplier, // Should be dialog box to enter for the multipler
          };

          this.sendMessage(JSON.stringify(executionCommand));
        })
        .catch(() => {});
    },

    executeExpiryDayMultiOrderCallPutShortWithFixedPercentageSL(shouldExecute) {
      this.checkAndSetSelectedBroker();

      this.$simpleAlert
        .prompt(
          "Please enter the multiplier (quantity (lots) for all orders).",
          "1",
          "Basket Group SL Based Straddle Strategy"
        )
        .then((multiplier) => {
          var executionCommand = {
            MessageType: "strategy_call_put_fixed_sl_based_execution_request",
            ExecutionType: shouldExecute ? 2 : 1, // 2 - Paper Mode, 1- Live Mode (c# enum)
            BrokerId: this.defaultBrokerId,
            Multiplier: multiplier, // Should be dialog box to enter for the multipler
          };

          this.sendMessage(JSON.stringify(executionCommand));
        })
        .catch(() => {});
    },

    addBasketGroupStraddleAllInstruments(shouldExecute) {
      this.checkAndSetSelectedBroker();

      this.$simpleAlert
        .prompt(
          "Please enter the multiplier (quantity (lots) for all orders).",
          "1",
          "Basket Group Straddle All Instruments Strategy"
        )
        .then((multiplier) => {
          var executionCommand = {
            MessageType:
              "basket_group_straddle_all_instruments_execution_request",
            ExecutionType: shouldExecute ? 2 : 1, // 2 - Paper Mode, 1- Live Mode (c# enum)
            BrokerId: this.defaultBrokerId,
            Multiplier: multiplier, // Should be dialog box to enter for the multipler
          };

          this.sendMessage(JSON.stringify(executionCommand));
        })
        .catch(() => {});
    },

    addBasketGroupSLBasedCalendarStraddleSpreadRatio(
      instrument,
      shouldExecute
    ) {
      this.checkAndSetSelectedBroker();

      this.$simpleAlert
        .prompt(
          "Please enter the multiplier (quantity (lots) for all orders).",
          "1",
          "Basket and Group SL Based Calendar Straddle Spread Ratio Strategy"
        )
        .then((multiplier) => {
          var executionCommand = {
            MessageType:
              "basket_and_group_sl_based_calendar_straddle_spread_ratio_execution_request",
            ExecutionType: shouldExecute ? 2 : 1, // 2 - Paper Mode, 1- Live Mode (c# enum)
            BrokerId: this.defaultBrokerId,
            Multiplier: multiplier, // Should be dialog box to enter for the multipler
            Instrument: instrument,
          };

          this.sendMessage(JSON.stringify(executionCommand));
        })
        .catch(() => {});
    },

    addBasketGroupSLBasedCalender(instrument, shouldExecute) {
      this.checkAndSetSelectedBroker();

      this.$simpleAlert
        .prompt(
          "Please enter the multiplier (quantity (lots) for all orders).",
          "1",
          "Basket Group SL Based Calender Strategy"
        )
        .then((multiplier) => {
          var executionCommand = {
            MessageType:
              "basket_group_sl_based_nf_and_bnf_calender_execution_request",
            ExecutionType: shouldExecute ? 2 : 1, // 2 - Paper Mode, 1- Live Mode (c# enum)
            BrokerId: this.defaultBrokerId,
            Multiplier: multiplier, // Should be dialog box to enter for the multipler
            Instrument: instrument,
          };

          this.sendMessage(JSON.stringify(executionCommand));
        })
        .catch(() => {});
    },

    async addTrippleStraddle(
      atm,
      instrument,
      expiry,
      strikeUpDown,
      shouldExecute,
      additionalData
    ) {
      // ATM-1 Straddle
      await this.createNewBasketForEditingInternal();

      let strikeDiff = additionalData === "no-skip-strike" ? 1 : 2;

      let atmMinusOne = atm - strikeUpDown * strikeDiff;

      // let symbol = SymbolService.getSymbol({
      //   instrument: instrument,
      //   expiry: expiry,
      //   strike: atmMinusOne,
      //   optionType: "pe"
      // })
      let symbol = await Utils.getSymbolOptions(
        instrument,
        expiry,
        atmMinusOne,
        "pe"
      );
      await this.addOrder(
        "o",
        "pe",
        symbol,
        atmMinusOne,
        "sell",
        instrument,
        expiry
      );

      // symbol = SymbolService.getSymbol({
      //   instrument: instrument,
      //   expiry: expiry,
      //   strike: atmMinusOne,
      //   optionType: "ce"
      // })
      symbol = await Utils.getSymbolOptions(
        instrument,
        expiry,
        atmMinusOne,
        "ce"
      );
      this.addOrder("o", "ce", symbol, atmMinusOne, "sell", instrument, expiry);

      if (shouldExecute) {
        await this.generateRandomStrategyName();
        await this.executeBasketOrders(false);
      }

      // ATM Straddle
      await this.createNewBasketForEditingInternal();

      // symbol = SymbolService.getSymbol({
      //   instrument: instrument,
      //   expiry: expiry,
      //   strike: atm,
      //   optionType: "pe"
      // })
      symbol = await Utils.getSymbolOptions(instrument, expiry, atm, "pe");
      await this.addOrder("o", "pe", symbol, atm, "sell", instrument, expiry);

      // symbol = SymbolService.getSymbol({
      //   instrument: instrument,
      //   expiry: expiry,
      //   strike: atm,
      //   optionType: "ce"
      // })
      symbol = await Utils.getSymbolOptions(instrument, expiry, atm, "ce");
      await this.addOrder("o", "ce", symbol, atm, "sell", instrument, expiry);

      if (shouldExecute) {
        await this.generateRandomStrategyName();
        await this.executeBasketOrders(false);
      }

      // ATM+1 Straddle
      await this.createNewBasketForEditingInternal();
      let atmPlsuOne = atm + strikeUpDown * strikeDiff;

      // symbol = SymbolService.getSymbol({
      //   instrument: instrument,
      //   expiry: expiry,
      //   strike: atmPlsuOne,
      //   optionType: "pe"
      // })
      symbol = await Utils.getSymbolOptions(
        instrument,
        expiry,
        atmPlsuOne,
        "pe"
      );
      await this.addOrder(
        "o",
        "pe",
        symbol,
        atmPlsuOne,
        "sell",
        instrument,
        expiry
      );

      // symbol = SymbolService.getSymbol({
      //   instrument: instrument,
      //   expiry: expiry,
      //   strike: atmPlsuOne,
      //   optionType: "ce"
      // })
      symbol = await Utils.getSymbolOptions(
        instrument,
        expiry,
        atmPlsuOne,
        "ce"
      );
      this.addOrder("o", "ce", symbol, atmPlsuOne, "sell", instrument, expiry);

      if (shouldExecute) {
        await this.generateRandomStrategyName();
        await this.executeBasketOrders(false);
      }
    },

    async addStrangle(atm, instrument, expiry, strikeUpDown) {
      await this.createNewBasketForEditingInternal();

      // let symbol = SymbolService.getSymbol({
      //   instrument: instrument,
      //   expiry: expiry,
      //   strike: atm - (strikeUpDown * 5),
      //   optionType: "pe"
      // })
      let symbol = await Utils.getSymbolOptions(
        instrument,
        expiry,
        atm - strikeUpDown * 5,
        "pe"
      );
      await this.addOrder(
        "o",
        "pe",
        symbol,
        atm - strikeUpDown * 5,
        "sell",
        instrument,
        expiry
      );

      // symbol = SymbolService.getSymbol({
      //   instrument: instrument,
      //   expiry: expiry,
      //   strike: atm + (strikeUpDown * 5),
      //   optionType: "ce"
      // })
      symbol = await Utils.getSymbolOptions(
        instrument,
        expiry,
        atm + strikeUpDown * 5,
        "ce"
      );
      await this.addOrder(
        "o",
        "ce",
        symbol,
        atm + strikeUpDown * 5,
        "sell",
        instrument,
        expiry
      );
    },

    async addIronCondor(atm, instrument, expiry, strikeUpDown) {
      await this.createNewBasketForEditingInternal();

      // let symbol = SymbolService.getSymbol({
      //   instrument: instrument,
      //   expiry: expiry,
      //   strike: atm - (strikeUpDown * 5),
      //   optionType: "pe"
      // })
      let symbol = await Utils.getSymbolOptions(
        instrument,
        expiry,
        atm - strikeUpDown * 5,
        "pe"
      );
      await this.addOrder(
        "o",
        "pe",
        symbol,
        atm - strikeUpDown * 5,
        "sell",
        instrument,
        expiry
      );

      // symbol = SymbolService.getSymbol({
      //   instrument: instrument,
      //   expiry: expiry,
      //   strike: atm + (strikeUpDown * 5),
      //   optionType: "ce"
      // })
      symbol = await Utils.getSymbolOptions(
        instrument,
        expiry,
        atm + strikeUpDown * 5,
        "ce"
      );
      await this.addOrder(
        "o",
        "ce",
        symbol,
        atm + strikeUpDown * 5,
        "sell",
        instrument,
        expiry
      );

      // symbol = SymbolService.getSymbol({
      //   instrument: instrument,
      //   expiry: expiry,
      //   strike: atm - (strikeUpDown * 7),
      //   optionType: "pe"
      // })
      symbol = await Utils.getSymbolOptions(
        instrument,
        expiry,
        atm - strikeUpDown * 7,
        "pe"
      );
      await this.addOrder(
        "o",
        "pe",
        symbol,
        atm - strikeUpDown * 7,
        "buy",
        instrument,
        expiry
      );

      // symbol = SymbolService.getSymbol({
      //   instrument: instrument,
      //   expiry: expiry,
      //   strike: atm + (strikeUpDown * 7),
      //   optionType: "ce"
      // })
      symbol = await Utils.getSymbolOptions(
        instrument,
        expiry,
        atm + strikeUpDown * 7,
        "ce"
      );
      await this.addOrder(
        "o",
        "ce",
        symbol,
        atm + strikeUpDown * 7,
        "buy",
        instrument,
        expiry
      );
    },

    async addIronFly(atm, instrument, expiry, strikeUpDown) {
      await this.createNewBasketForEditingInternal();

      // let symbol = SymbolService.getSymbol({
      //   instrument: instrument,
      //   expiry: expiry,
      //   strike: atm,
      //   optionType: "pe"
      // })
      let symbol = await Utils.getSymbolOptions(instrument, expiry, atm, "pe");
      await this.addOrder("o", "pe", symbol, atm, "sell", instrument, expiry);

      // symbol = SymbolService.getSymbol({
      //   instrument: instrument,
      //   expiry: expiry,
      //   strike: atm,
      //   optionType: "ce"
      // })
      symbol = await Utils.getSymbolOptions(instrument, expiry, atm, "ce");
      await this.addOrder("o", "ce", symbol, atm, "sell", instrument, expiry);

      let strikeUpDownRange = 4;

      // symbol = SymbolService.getSymbol({
      //   instrument: instrument,
      //   expiry: expiry,
      //   strike: atm - (strikeUpDown * strikeUpDownRange),
      //   optionType: "pe"
      // })
      symbol = await Utils.getSymbolOptions(
        instrument,
        expiry,
        atm - strikeUpDown * strikeUpDownRange,
        "pe"
      );
      await this.addOrder(
        "o",
        "pe",
        symbol,
        atm - strikeUpDown * strikeUpDownRange,
        "buy",
        instrument,
        expiry
      );

      // symbol = SymbolService.getSymbol({
      //   instrument: instrument,
      //   expiry: expiry,
      //   strike: atm + (strikeUpDown * strikeUpDownRange),
      //   optionType: "ce"
      // })
      symbol = await Utils.getSymbolOptions(
        instrument,
        expiry,
        atm + strikeUpDown * strikeUpDownRange,
        "ce"
      );
      await this.addOrder(
        "o",
        "ce",
        symbol,
        atm + strikeUpDown * strikeUpDownRange,
        "buy",
        instrument,
        expiry
      );
    },

    async addStraddle(
      atm,
      instrument,
      expiry,
      shouldExecute = false,
      transactionType = "sell",
      lots,
      groupId
    ) {
      if (groupId || groupId >= 1) {
        console.log("adding with basket group " + groupId);
        await this.createNewBasketForEditingInternal({
          instrument: instrument,
          groupId: groupId,
        });
      } else {
        groupId = 1;
        await this.createNewBasketForEditingInternal();
      }

      // let symbol = SymbolService.getSymbol({
      //   instrument: instrument,
      //   expiry: expiry,
      //   strike: atm,
      //   optionType: "pe"
      // })
      let symbol = await Utils.getSymbolOptions(instrument, expiry, atm, "pe");
      await this.addOrder(
        "o",
        "pe",
        symbol,
        atm,
        transactionType,
        instrument,
        expiry,
        lots
      );

      // symbol = SymbolService.getSymbol({
      //   instrument: instrument,
      //   expiry: expiry,
      //   strike: atm,
      //   optionType: "ce"
      // })
      symbol = await Utils.getSymbolOptions(instrument, expiry, atm, "ce");
      await this.addOrder(
        "o",
        "ce",
        symbol,
        atm,
        transactionType,
        instrument,
        expiry,
        lots
      );

      await this.generateRandomStrategyName();

      if (shouldExecute) {
        this.executeBasketOrders(false);
      } else {
        this.basketGroupId = groupId;
      }
    },
    createNewOrder() {
      // Param list
      // instrument, symbol, strike, optionType, expiry, transactionType, qty
      // Set default greeks
      // let  greeks = {}
      // let order = {
      //   id: Math.floor(Math.random() * 999999),
      //   optionType: payload.optionType,
      //   symbol: payload.symbol,
      //   strike: payload.strike,
      //   transactionType: payload.transactionType,
      //   quantity: 1,
      //   lotSize: SymbolService.getMarketLot(payload.instrument),
      //   priceWhenAddedToBasket: priceWhenAddedToBasket,
      //   expiry: payload.expiry,
      //   bsPrice: 0,
      //   includeInPayoff: true,
      //   greeksAtExecution: greeks,
      //   greeksAtNow: greeks,
      //   expiryEdited: expiry,
      //   status: 'old',
      //   executedPrice: 0,
      //   brokerageAndTaxes: 0,
      //   instrumentType: payload.instrumentType,
      //   isSquaredOff: false,
      //   isSelectedForAnalysisChart: true,
      //   underlyingPriceAtOpen: underlyingPrice,
      //   underlyingPriceAtClose: 0.0
      // }
    },
    executeBasketOrders(shouldConfirm = true) {
      this.executionErrors = [];

      if (this.activeBasketEditing.orders.length == 0) {
        this.showWarning(
          `No orders for basket ${
            this.activeBasketEditing.id
          }-${this.activeBasketEditing.instrument.toUpperCase()}. Basket was not executed.`
        );
        return;
      }

      if (this.strategyName.length == 0) {
        this.showWarning("Please set basket name.");
        return;
      }

      if (
        (!this.defaultBrokerId || this.defaultBrokerId.length == 0) &&
        this.executionMode == "LIVE"
      ) {
        this.showWarning("Please select a broker.");
        return;
      }

      var index = this.orderBaskets.findIndex(
        (x) => x.strategyName === this.strategyName
      );
      if (index > -1) {
        this.showWarning(
          "This strategy name is already added to executed basket."
        );
        return;
      }

      // Go trhough all orders in all baskets
      // if a mach for reverse trade is found where
      // symbol == symbol
      // broker == broker
      // product == product
      // transactionType != transactionType // reverse trade
      // status = complete
      // squaredOff = false

      if (!this.isTesting) {
        this.orderBaskets.forEach((orderBasket) => {
          if (orderBasket.isTesting) return;

          orderBasket.orders.forEach((executedOrder) => {
            this.activeBasketEditing.orders.forEach((orderToExecute) => {
              if (
                orderToExecute.symbol === executedOrder.symbol &&
                this.defaultBrokerId == executedOrder.brokerId &&
                this.selectedProduct == executedOrder.product &&
                executedOrder.status === "complete" &&
                executedOrder.isSquaredOff == false &&
                orderToExecute.transactionType != executedOrder.transactionType
              ) {
                this.executionErrors.push({
                  strategyName: orderBasket.strategyName,
                  message: `Symbol <b>${orderToExecute.symbol}</b> is already added to the strategy <b>${orderBasket.strategyName}</b>.
                    Executing this order will square off from that basket. You can try executing it with a different broker.`,
                  symbol: orderBasket.symbol,
                });
              }
            });
          });
        });
      }

      if (this.executionErrors.length > 0) return;

      if (this.executionMode != "LIVE") shouldConfirm = false;

      if (shouldConfirm) {
        this.$simpleAlert
          .confirm(
            "Are you sure to place the order? Have you added hedging positions as well?",
            "Place Order",
            "question"
          )
          .then(() => {
            this.executeBasketOrdersPostConfirmation();
            this.generateRandomStrategyName();
          })
          .catch(() => {});
      } else {
        this.executeBasketOrdersPostConfirmation();
        this.generateRandomStrategyName();
      }
    },
    onChartResize(event) {
      this.$refs.payoffChart.updateOptions({
        xaxis: { tickAmount: Math.ceil(event.srcElement.innerWidth / 140) },
      });
    },
    executeBasketOrdersPostConfirmation() {
      let strategyId = Math.floor(Math.random() * 999999);

      let brokerageAndTaxes = 0;

      let underlyingPrice = 0;

      let executedOn = moment(Date.now()).format("DD-MMM-YYYY HH:mm");
      if (this.showHideOptionsSimulator) {
        executedOn = moment(
          this.optionSimulatorParams.selectedTimeInUnix
        ).format("DD-MMM-YYYY HH:mm");
      }

      console.log("execute don: " + executedOn);

      if (this.activeBasketEditing.instrument === "nifty")
        underlyingPrice = this.instruments["NIFTY 50"]
          ? this.instruments["NIFTY 50"].Ltp
          : 0;
      else if (this.activeBasketEditing.instrument === "banknifty")
        underlyingPrice = this.instruments["NIFTY BANK"]
          ? this.instruments["NIFTY BANK"].Ltp
          : 0;
      else if (this.activeBasketEditing.instrument === "sensex")
        underlyingPrice = this.instruments["SENSEX"]
          ? this.instruments["SENSEX"].Ltp
          : 0;          
      else if (this.activeBasketEditing.instrument === "finnifty")
        underlyingPrice = this.instruments["FINNIFTY"]
          ? this.instruments["FINNIFTY"].Ltp
          : 0;
      else if (this.activeBasketEditing.instrument === "midcpnifty")
        underlyingPrice = this.instruments["MIDCPNIFTY"]
          ? this.instruments["MIDCPNIFTY"].Ltp
          : 0;

      let futuresPriceObj = this.instruments[this.futuresSymbol];
      let futuresPrice = 0;

      if (futuresPriceObj) futuresPrice = futuresPriceObj.Ltp;

      this.activeBasketEditing.orders.forEach((order) => {
        order.status = "pending";
        order.squaredOff = false;
        order.executedPrice = 0.0;
        order.brokerId = this.defaultBrokerId;
        order.pl = 0.0;
        order.isSquaredOff = false;
        order.checked = true;
        order.product = this.selectedProduct;
        order.squaredOffPrice = 0.0;
        order.strategyName = this.strategyName;
        (order.strategyId = strategyId), (order.reason = "");
        order.squareOffInitiated = false;

        order.executedOn = executedOn;

        order.editing = false;
        order.underlyingPriceAtOpen = underlyingPrice;
        order.futuresPriceAtOpen = futuresPrice;

        // TODO: order executed on server now... keep it pending
        // if (this.isTesting) {
        //   order.status = 'complete'
        //   order.executedPrice = this.instruments[order.symbol].Ltp
        // }

        // order.brokerageAndTaxes = SymbolService.calculateTaxesOptions(order.priceWhenAddedToBasket, order.quantity * order.lotSize, order.transactionType, order.broker, order.instrumentType)
        order.brokerageAndTaxes = 0;

        brokerageAndTaxes = brokerageAndTaxes + order.brokerageAndTaxes;
      });

      let orderBasket = {
        strategyName: this.strategyName,
        id: strategyId,
        groupId: this.basketGroupId,
        brokerId: this.defaultBrokerId,
        orders: JSON.parse(JSON.stringify(this.activeBasketEditing.orders)),
        isSquaredOff: false,
        instrument: this.activeBasketEditing.instrument,
        totalPnl: 0,
        isTesting: this.isTesting,

        stop: 0,
        target: 0,
        trail: 0, // this.activeBasketEditing.instrument == 'nifty' ? 25 : 50,
        isPerLegSL: true,
        isPerLegSLHitOpenNew: false,
        oppositeQtyToAdd: 0,
        isShiftToNextOTM: false,
        deltaBalanceStrikeDifference: 0, // Shifting straddle
        isPerLegSLReEntry: false,
        isStayOTM: false,
        shiftStrikes: false,
        ExecutionMode: 1,
        perLegSLType: 1, // 1-percent, 2-fixed
        stopTargetType: 1, // 1-points, 2-premium
        perLegSLValue: 30,

        isFuturesSL: false,
        futuresSLType: 1,

        slTargetBasedOnCandlePrice: false,
        fixedCandleSL: false,
        underlyingSLCandlePriceType: 1,
        underlyingSLCandlePriceCheckType: 2,
        underlyingSLCandlesLookbackCount: 3,

        persona: 2, // seller
        signal: 1, // buy

        futuresSLUnderlyingType: 1,
        futuresSLComparisionType: "<=", // <= or >=
        futuresTargetComparisionType: ">=", // <= or >=
        futuresSLValue: 1,
        futuresTargetValue: 1,

        checkExitTime: true,
        exitTime: "15:20:00",

        squareOffMode: "manual",
        isStopTargetHit: false,
        marginRequired: this.activeBasketEditing.marginRequired,
        isMultiExpiry: this.activeBasketEditing.isMultiExpiry,
        setupCost: this.activeBasketEditing.setupCost
          ? this.activeBasketEditing.setupCost
          : 0,
        breakEvens: this.activeBasketEditing.breakEvens,
        executedOn: executedOn,
        greeksAtNow: this.activeBasketEditing.greeksAtNow,
        greeksAtExecution: this.activeBasketEditing.greeksAtExecution,
        brokerageAndTaxes: brokerageAndTaxes,
      };

      this.orderBasketExecuted(orderBasket);

      var orders = this.activeBasketEditing.orders.map((order) => {
        return {
          Symbol: order.symbol,
          TransactionType:
            order.transactionType === "buy"
              ? TransactionType.BUY
              : TransactionType.SELL,
          Quantity: order.quantity * order.lotSize,
          BrokerId: this.defaultBrokerId,
          LotSize: order.lotSize,
          Product: this.selectedProduct,
          Tag: strategyId,
          StrategyId: strategyId,
          InstrumentType: order.instrumentType,
          OptionType: order.optionType,
          Strike: order.strike,
          BaseSymbol: this.activeBasketEditing.instrument,
        };
      });

      var executeOrderBasketCommand = {
        MessageType: "execute_basket_order_command",
        Orders: orders,
        IsTesting: this.isTesting,
        Basket: orderBasket,
      };

      //if (!this.isTesting)
      this.sendMessage(JSON.stringify(executeOrderBasketCommand));

      this.removeBasketFromEditor(this.activeBasketEditing.id);
    },
    generateRandomStrategyName() {
      this.strategyName = Utils.generateRandomStrategyName();
    },
    saveTestOrderBaskets() {
      var orderBasket = [];

      var order1 = {
        optionType: "ce",
        symbol: "NIFTY205188800CE",
        strike: 8800,
        transactionType: "sell",
        lotSize: SymbolService.getMarketLot("nifty"),
        executedPrice: 157.2,
        pl: 0.0,
        status: "complete",
        checked: true,
        quantity: 1,
        product: this.selectedProduct,
        squaredOffPrice: 0.0,
        strategyName: "Sample Strategy",
        reason: "",
      };

      var order2 = {
        optionType: "ce",
        symbol: "NIFTY205188850CE",
        strike: 8850,
        transactionType: "buy",
        lotSize: SymbolService.getMarketLot("nifty"),
        executedPrice: 133,
        pl: 0.0,
        status: "complete",
        checked: true,
        quantity: 1,
        product: this.selectedProduct,
        squaredOffPrice: 0.0,
        strategyName: "Sample Strategy",
        reason: "",
      };

      orderBasket.push(order1);
      orderBasket.push(order2);

      var strategyName = "Sample Strategy";
      var orderBasketMain = {
        strategyName: strategyName,
        broker: 3,
        orderBasket: JSON.parse(JSON.stringify(orderBasket)),
        isSquaredOff: false,
      };

      var isStrategyAlreadyAdded = this.orderBaskets.find(
        (x) => x.strategyName === strategyName
      );
      if (!isStrategyAlreadyAdded) this.orderBaskets.push(orderBasketMain);
    },
  },
  created() {},
  mounted() {
    this.isTesting = this.executionMode == "SIMULATION" ? true : false;

    //TOD:  Set this during the load. Should be sent from the server side
    // if (this.executionMode == 'SIMULATION') {
    //   this.defaultBrokerId = this.primaryFeedSourceId;
    // }

    this.selectedBrokerId = this.defaultBrokerId;
    this.generateRandomStrategyName();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.strike-incdcr-arrows {
  /* font-size: 1.3em; */
  margin-right: 5px;
  margin-left: 5px;
  text-decoration: none;
}

.content-cell-center {
  position: relative;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 50%;
}

.editor-button {
  padding: 5px !important;
}

.editor-button:hover {
  background-color: gray;
  color: blue;
}
</style>
