<template>
  <div class="m-1 pe-3" style="padding-bottom: 50px;">
    <!--
    <input  class="form-control" v-model="priceUpdateWhichSymbol"  type="text" />
    <input  class="form-control" v-model="priceUpdateWhatPrice" v-on:input="updateInstrumentPrice" type="number" />
    -->

    <!-- <TradingViewChart></TradingViewChart> -->
    <ul class="nav nav-tabs pt-2 ps-2 pe-2 mb-2 border border-2" style="background-color: ghostwhite;">
      <li class="nav-item " style="margin-right: 10px;margin-bottom: 5px;" v-for="basketGroup in basketGroups"
        v-bind:key="basketGroup.id" @mouseover="showTabByGroupId = basketGroup.id" @mouseout="showTabByGroupId = null">
        <a @click="selectBasketGroup(basketGroup)" class="nav-link basket-group-tab-title "
          :class="{ active: basketGroupId === basketGroup.id, 'test-tab-active': basketGroupId === basketGroup.id }"
          href="javascript:void(0);" :set="basketCount = getBasketCountInTheGroup(basketGroup.id)">
          {{ basketGroup.name }}
          <span class="ms-1 ">[{{ basketCount }}]</span>

          <span v-if="basketCount > 0">

            <span class="space-5"></span>|<span class="space-5"></span>

            <span v-if="basketGroup.net < 0 | basketGroup.net > 0" title="MTM" class=" " v-bind:class="{
              red: basketGroup.mtm < 0,
              green: basketGroup.mtm > 0,
            }">
              {{ basketGroup.mtm.toFixed(2) }}
              <span class="space-5"></span>|<span class="space-5"></span>
            </span>

            <span v-if="basketGroup.net < 0 | basketGroup.net > 0" title="Realized" class="" v-bind:class="{
              red: basketGroup.realized < 0,
              green: basketGroup.realized > 0,
            }">
              {{ basketGroup.realized.toFixed(2) }}
              <span class="space-5"></span>|<span class="space-5"></span>
            </span>

            <span v-if="basketGroup.net < 0 | basketGroup.net > 0" title="NET" class="" v-bind:class="{
              red: basketGroup.net < 0,
              green: basketGroup.net > 0,
            }">
              {{ basketGroup.net.toFixed(2) }}
            </span>
            <span class="material-icons" style="font-size:16px" v-if="basketGroup.stopTarget.enabled">
              front_hand
            </span>

            <span v-if="basketGroup.isAnyBasketScheduledForAlgoExecution" class="spinner-grow spinner-grow-sm"
              role="status" aria-hidden="true"></span>
          </span>
        </a>
      </li>

      <li class="nav-item">
        <a v-on:click.exact.prevent="exitBasketGroupsInternal()" title="Exit selected basket group" class="nav-link"
          href="javascript:void(0);">
          <span class="material-icons">
            free_cancellation
          </span>
        </a>
      </li>

      <li class="nav-item">
        <a v-on:click.exact.prevent="exitBasketGroupsInternal('all')" title="Exit all basket groups" class="nav-link"
          href="javascript:void(0);">
          <span class="material-icons">
            logout
          </span>
        </a>
      </li>

      <li class="nav-item">
        <a v-on:click.exact.prevent="addNewBasketGroupInternal()" v-on:click.ctrl.prevent="setBasketGroupInEditMode()"
          title="Click => Add New Basket Group, Ctrl+Click => Edit Basket Group Name" class="nav-link"
          href="javascript:void(0);">
          <span class="material-icons">
            library_add
          </span>
        </a>
      </li>

      <!-- <li class="nav-item">
        <a
          @click="saveBaskets"
          title="Save baskets"
          class="nav-link"
          href="javascript:void(0);"
        >
          <span class="material-icons">
            save
          </span>
        </a>
      </li>       -->

      <li class="nav-item" v-if="!isDefaultBasket()">
        <a v-on:click.exact.prevent="removeAllBasketGroupsInternal()"
          title="Remove all Basket Groups. Baskets within this group will be moved to default Basket Group."
          class="nav-link" href="javascript:void(0);">
          <span class="material-icons">
            auto_delete
          </span>
        </a>
      </li>

      <li class="nav-item" v-if="!isDefaultBasket()">
        <a v-on:click.exact.prevent="removeBasketGroupInternal()" v-on:click.ctrl.exact="removeBasketGroupInternal(true)"
          title="Click => Remove Selected Basket Group, Baskets within this group will be moved to default Basket Group. Ctrl+Click => Remove selected Basket Group and All Baskets within the Group"
          class="nav-link" href="javascript:void(0);">
          <span class="material-icons">
            clear
          </span>
        </a>
      </li>

      <li class="nav-item">
        <a href="javascript:void(0)" @click="sendTestMessage">H</a>
      </li>

    </ul>

    <div v-if="activeBasketGroup.editing">

      <form class="form-inline">
        <div class="form-group mb-2">
          <label for="staticEmail2" class="sr-only">Group Name</label>
          <input class="form-control" style="width:350px; display:inline" v-model="activeBasketGroup.name" type="text" />
        </div>
        <a @click="activeBasketGroup.editing = false; sendUpdateBasketGroupPropertiesInternal(activeBasketGroup.id, 'name', activeBasketGroup.name)"
          style="display:relative" title="Save Group" class="nav-link" href="javascript:void(0);">
          <span class="material-icons">
            save
          </span>
        </a>

      </form>
    </div>

    <!-- <hr style="height: 3px;border: none;color:gray;background-color: gray;" /> -->

    <RiskManagementProfile v-if="showHideRiskManagementProfile" />
    <AlgoSchedulerSettings v-if="showHideAlgoSchedulerSettings" />

    <template v-if="orderBaskets.length > 0">
      <ul class="nav nav-tabs ">
        <li class="nav-item " :class="{ 'basket-test-tab': orderBasket.isTesting }"
          v-for="orderBasket in orderBasketsFilteredByGroupId" v-bind:key="orderBasket.id"
          @mouseover="showTabByStrategyId = orderBasket.id" @mouseout="showTabByStrategyId = null">

          <div style="cursor: pointer" @click="selectBasket(orderBasket)" class="nav-link" data-bs-toggle="tab"
            role="tab" :class="{ active: strategyId === orderBasket.id }">
            {{ orderBasket.id }}-{{ orderBasket.strategyName }}-{{ orderBasket.instrument.toUpperCase() }},
            <span title="MTM" v-bind:class="{
              red: orderBasket.totalPnl < 0,
              green: orderBasket.totalPnl > 0,
            }" :set="(pnlSum = orderBasket.totalPnl + orderBasket.realizedPL)">
              {{ orderBasket ? orderBasket.totalPnl.toFixed(2) : 0 }}
            </span>

            <span class="space-5"></span>|<span class="space-5"></span>

            <span title="Realized" v-bind:class="{
              red: orderBasket.realizedPL < 0,
              green: orderBasket.realizedPL > 0,
            }">
              {{ orderBasket ? orderBasket.realizedPL.toFixed(2) : 0 }}
            </span>

            <span class="space-5"></span>|<span class="space-5"></span>

            <span title="Net" v-bind:class="{ red: pnlSum < 0, green: pnlSum > 0 }">
              {{ pnlSum.toFixed(2) }}
            </span>
            <span> &nbsp;{{ orderBasket.isTesting ? "| P " : "| L " }} </span>
            <span v-if="!orderBasket.isTesting"> | {{ getBrokerName(orderBasket.brokerId) }} </span>
            <span>
              <span title="Active Positions">[{{ orderBasket.orders.length }}]</span>
              <span title="Order Squared Off">[{{ orderBasket.squaredOffOrders.length }}]</span>
            </span>
            <span>
              {{ orderBasket.isSquaredOff ? "Done" : "" }}
            </span>

            <span v-if="orderBasket.strategyExecutionStatus === 'scheduled'" class="spinner-grow spinner-grow-sm"
              role="status" aria-hidden="true"></span>

            <span class="space-10"></span>
            <input v-model="orderBasket.includeInAccountPnl" @change="testFunction"
              title="Include in Account's Profit/Loss" class="form-check-input form-check-input-lg" type="checkbox" />
          </div>

        </li>

        <li class="nav-item">
          <a @click="addNewExecutedBasketInternal" title="Add new executed basket" class="nav-link"
            href="javascript:void(0);">
            <span class="material-icons">
              library_add
            </span>
          </a>
        </li>

        <li class="nav-item">
          <a @click="exportBaskets" title="Export Baskets" class="nav-link" href="javascript:void(0);">
            <span class="material-icons">
              cloud_download
            </span>
          </a>
        </li>

        <!-- <li class="nav-item">
          <a
            @click="saveBaskets"
            title="Save baskets"
            class="nav-link"
            href="javascript:void(0);"
          >
            <span class="material-icons">
              save
            </span>
          </a>
        </li> -->

        <!-- <li class="nav-item">
          <a @click="sendTestMessage" title="Send Test Message" class="nav-link" href="javascript:void(0);">
            <span class="material-icons">
              bug_report
            </span>
          </a>
        </li> -->

      </ul>

      <div v-if="activeExecutedBasket.status == 'new'">
        <div class="form-row align-items-center">
          <div class="col-auto">
            <div class="input-group">
              <input class="form-control" v-model="newBasketEditingOptions.strategyName" placeholder="strategy name" />
              <div class="input-group-append">
                <div class="input-group-text">
                  <a href="javascript:void(0)" @click="generateRandomStrategyName" title="Generate random strategy name"
                    style="height: 22px;">
                    <span class="material-icons">
                      loop
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <select v-model="newBasketEditingOptions.instrument" class="form-control" style="height:43px;width:100px;">
              <option value="nifty" :selected="activeExecutedBasket.instrument == 'nifty'">
                Nifty
              </option>
              <option value="banknifty" :selected="activeExecutedBasket.instrument == 'banknifty'">
                BankNifty
              </option>

              <option value="finnifty" :selected="activeExecutedBasket.instrument == 'finnifty'">
                FinNifty
              </option>              

              <option value="midcpnifty" :selected="activeExecutedBasket.instrument == 'midcpnifty'">
                Midcap Nifty
              </option>       
              
              <option value="sensex" :selected="activeExecutedBasket.instrument == 'sensex'">
                Sensex
              </option>               
            </select>
          </div>
          <div class="col-auto">
            <select v-model="newBasketEditingOptions.brokerId" class="form-control">
              <option v-for="broker in newBasketEditingOptions.brokers" v-bind:value="broker.id" v-bind:key="broker.id">
                {{ broker.name }}
              </option>
            </select>
          </div>
          <div class="col-auto">
            <div class="form-check mb-2">
              <input v-model="newBasketEditingOptions.isTesting" id="forTestingCheckBox" title="For Testing"
                class="form-check-input" type="checkbox" />
              <label class="ms-3 form-check-label" for="forTestingCheckBox">
                For Testing
              </label>
            </div>
          </div>
          <div class="col-auto">
            <input type="button" v-on:click.prevent="saveBasketInternal" class="btn btn-primary" value="Save Basket" />
          </div>
        </div>
      </div>


      <div v-if="!isObjectEmpty(activeExecutedBasket)">
        <div class=" container mt-2" v-if="activeExecutedBasket.showHideInfoPanel">
          <div class="row ">
            <div class="col-12 ps-0">
              <v-select style="width: 400px;" v-bind:clearable="false" v-model="activeExecutedBasket.groupId"
                @option:selected="setBasketGroupInternal" :options="basketGroups"
                :reduce="basketGroups => basketGroups.id" label="name" class="d-inline-block" />

              <div class="d-inline-block" v-if="!isDefaultBasket()">
                <button class="btn btn-default mb-1" title="Show/Hide Basket Stop Target Parameters"
                  @click="showHideBasketStopTargetParameters()"><i class="fa-brands fa-hive fa-xg"></i></button>
                <button class="btn btn-default mb-1" title="Save Basket Stop Target Parameters"
                  @click="updateBasketStopTargetParameters()"><i class="fa-solid fa-floppy-disk fa-lg"></i></button>
                <!-- <button class="btn btn-default mb-1" @click="addRemoveToMultiChartViewInternalForBasketGroup(1)" title="Add to Multi Chart View"><i class="fa-solid fa-folder-plus fa-lg"></i></button>
                <button class="btn btn-default mb-1" @click="addRemoveToMultiChartViewInternalForBasketGroup(2)" title="Remove from Multi Chart View"><i class="fa-solid fa-folder-minus fa-lg"></i> </button> -->
              </div>
            </div>
          </div>
        </div>
        <div v-if="activeExecutedBasket.showHideInfoPanel">
          <div>


            <div v-if="selectedBasketGroup && selectedBasketGroup.showHideBasketGroupHideStopTarget"
              class="p-10 mb-2 border border-2 pb-2 pt-1 container">

              <div class="row">

                <div class="col-2">

                </div>

                <div class="col-2">
                  <label>Multiplier</label>
                  <input class="form-control form-control-sm" type="number" placeholder="Multiplier"
                    v-model="selectedBasketGroup.stopTarget.multiplier" @focus="$event.target.select()"
                    @change="updateBasketSLPropsDebounced(selectedBasketGroup.id, 'multiplier', selectedBasketGroup.stopTarget.multiplier)">
                </div>

              </div>

              <div class="row">

                <div class="col-2">
                  <div><label>&nbsp;</label></div>
                  <div>
                    <input v-model="selectedBasketGroup.stopTarget.enabled" title="SL at Basket Group Level"
                      id="slAtBasketGroupLevel"
                      @change="updateBasketSLProps(selectedBasketGroup.id, 'enabled', selectedBasketGroup.stopTarget.enabled)"
                      class="form-check-input" type="checkbox" />
                    <label class="ms-3 form-check-label" for="slAtBasketGroupLevel">
                      SL at Basket Group
                    </label>
                  </div>
                </div>

                <div class="col-2">
                  <label>Stop</label>
                  <input class="form-control form-control-sm" type="number" placeholder="Stop"
                    v-model="selectedBasketGroup.stopTarget.stop" @focus="$event.target.select()"
                    @change="updateBasketSLPropsDebounced(selectedBasketGroup.id, 'stop', selectedBasketGroup.stopTarget.stop)">
                </div>

                <div class="col-2">
                  <label>Target</label>
                  <input class="form-control form-control-sm" type="number" placeholder="Target"
                    v-model="selectedBasketGroup.stopTarget.target" @focus="$event.target.select()"
                    @change="updateBasketSLPropsDebounced(selectedBasketGroup.id, 'target', selectedBasketGroup.stopTarget.target)">
                </div>

                <div class="col-2">
                  <label>Trail</label>
                  <input class="form-control form-control-sm" type="number" placeholder="Trail"
                    v-model="selectedBasketGroup.stopTarget.trail" @focus="$event.target.select()"
                    @change="updateBasketSLPropsDebounced(selectedBasketGroup.id, 'trail', selectedBasketGroup.stopTarget.trail)">
                </div>

              </div>

              <div class="row">

                <div class="col-2">
                  <div><label>&nbsp;</label></div>
                  <div>
                    <input v-model="selectedBasketGroup.stopTarget.isTrailing" title="Is Basket Group Trailing"
                      id="isBasketGroupTrailing"
                      @change="updateBasketSLProps(selectedBasketGroup.id, 'isTrailing', selectedBasketGroup.stopTarget.isTrailing)"
                      class="form-check-input" type="checkbox" />
                    <label class="ms-3 form-check-label" for="isBasketGroupTrailing">
                      Trailing
                    </label>
                  </div>
                </div>

                <div class="col-2">
                  <label>Trail Stop</label>
                  <input class="form-control form-control-sm" type="number" placeholder="Trail Stop"
                    v-model="selectedBasketGroup.stopTarget.trailStop" @focus="$event.target.select()"
                    @change="updateBasketSLPropsDebounced(selectedBasketGroup.id, 'trailStop', selectedBasketGroup.stopTarget.trailStop)">
                </div>

                <div class="col-2">
                  <label>Trail Target</label>
                  <input class="form-control form-control-sm" type="number" placeholder="Trail Target"
                    v-model="selectedBasketGroup.stopTarget.trailTarget" @focus="$event.target.select()"
                    @change="updateBasketSLPropsDebounced(selectedBasketGroup.id, 'trailTarget', selectedBasketGroup.stopTarget.trailTarget)">
                </div>

                <div class="col-2">
                  <label>Final Target</label>
                  <input class="form-control form-control-sm" type="number" placeholder="Final Target"
                    v-model="selectedBasketGroup.stopTarget.finalTarget" @focus="$event.target.select()"
                    @change="updateBasketSLPropsDebounced(selectedBasketGroup.id, 'finalTarget', selectedBasketGroup.stopTarget.finalTarget)"
                    title="When trailing, check final target, if hit, exit">
                </div>

              </div>

              <div class="row">

                <div class="col-2">
                  <div><label>&nbsp;</label></div>
                  <div>
                    <input v-model="selectedBasketGroup.stopTarget.reducingTrailStopGapp" title="Reducing Trail-Stop Gap"
                      id="reducingTrailStopGap"
                      @change="updateBasketSLProps(selectedBasketGroup.id, 'reducingTrailStopGap', selectedBasketGroup.stopTarget.reducingTrailStopGap)"
                      class="form-check-input" type="checkbox" />
                    <label class="ms-3 form-check-label" for="reducingTrailStopGap">
                      Trail Agressively
                    </label>
                  </div>
                </div>

                <div class="col-3">
                  <label>Agressive Trailing Gap Decrement %</label>
                  <input class="form-control form-control-sm" type="number" placeholder="Reduction %"
                    v-model="selectedBasketGroup.stopTarget.reducingTrailStopGapReductionPercent"
                    @focus="$event.target.select()"
                    @change="updateBasketSLPropsDebounced(selectedBasketGroup.id, 'reducingTrailStopGapReductionPercent', selectedBasketGroup.stopTarget.reducingTrailStopGapReductionPercent)">
                </div>

                <div class="col-3">
                  <label>Agressive Trailing Gap Limit %</label>
                  <input class="form-control form-control-sm" type="number" placeholder="Minimum Gap"
                    v-model="selectedBasketGroup.stopTarget.reducingTrailStopGapMinimumGapPercent"
                    @focus="$event.target.select()"
                    @change="updateBasketSLPropsDebounced(selectedBasketGroup.id, 'reducingTrailStopGapMinimumGapPercent', selectedBasketGroup.stopTarget.reducingTrailStopGapMinimumGapPercent)">
                </div>

              </div>

              <div class="row">

                <div class="col-2">
                  <div><label>&nbsp;</label></div>
                  <div>
                    <input v-model="selectedBasketGroup.stopTarget.protectTheProfit" title="Protect the Profit"
                      id="protectTheProfit"
                      @change="updateBasketSLProps(selectedBasketGroup.id, 'protectTheProfit', selectedBasketGroup.stopTarget.protectTheProfit)"
                      class="form-check-input" type="checkbox" />
                    <label class="ms-3 form-check-label" for="protectTheProfit">
                      Protect the Proft
                    </label>
                  </div>
                </div>

                <div class="col-2">
                  <label>When Profit Reaches</label>
                  <input class="form-control form-control-sm" type="number" placeholder="When Profit Reaches"
                    v-model="selectedBasketGroup.stopTarget.protectWhenProfitReaches" @focus="$event.target.select()"
                    @change="updateBasketSLPropsDebounced(selectedBasketGroup.id, 'protectWhenProfitReaches', selectedBasketGroup.stopTarget.protectWhenProfitReaches)">
                </div>

                <div class="col-2">
                  <label>Lock Minimum Profit At</label>
                  <input class="form-control form-control-sm" type="number" placeholder="Lock Minimum Profit At"
                    v-model="selectedBasketGroup.stopTarget.protectLockMinimumProfitAt" @focus="$event.target.select()"
                    @change="updateBasketSLPropsDebounced(selectedBasketGroup.id, 'protectLockMinimumProfitAt', selectedBasketGroup.stopTarget.protectLockMinimumProfitAt)">
                </div>

              </div>

              <div class="row">
                <div class="col-2">
                  <div><label>&nbsp;</label></div>
                  <div>
                    <input v-model="selectedBasketGroup.stopTarget.checkExitTime" title="Exit Time" id="slExitTime"
                      @change="updateBasketSLProps(selectedBasketGroup.id, 'checkExitTime', selectedBasketGroup.stopTarget.checkExitTime)"
                      class="form-check-input" type="checkbox" />
                    <label class="ms-3 form-check-label" for="slExitTime">
                      Auto Exit:
                    </label>
                  </div>
                </div>

                <div class="col-2">
                  <div><label>Exit Time</label></div>
                  <div>
                    <flat-pickr title="Press Enter or click outside to apply the selected time"
                      v-model="selectedBasketGroup.stopTarget.exitTime" :config="timePickerConfig"
                      class="form-control form-control-sm"
                      @change="updateBasketSLProps(selectedBasketGroup.id, 'exitTime', selectedBasketGroup.stopTarget.exitTime)"
                      placeholder="Exit Time" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="fs-sm  border border-2 p-2">
            <span>Executed on: <span class="space-10"></span>{{ basketExecutedOn }}</span>

            <span class="space-30"></span>

            <p class="" style="display: inline;">
              Max Loss:

              <span class="red">&#8377;
                <span v-if="getBreakEvens().maxLoss === 1">Unlimited</span>
                <span v-else>{{ formatWithThousandSeparator(getBreakEvens().maxLoss) }}</span>
              </span>

              <span class="space-30"></span>
              Max Profit:
              <span class="green">&#8377;
                <span v-if="getBreakEvens().maxProfit === -1">Unlimited</span>
                <span v-else>{{ formatWithThousandSeparator(getBreakEvens().maxProfit) }}</span>
              </span>

              <span class="space-30"></span>
              Breakeven(s):
              {{
                activeExecutedBasket.breakEvens &&
                activeExecutedBasket.breakEvens.breakEvens
                ? activeExecutedBasket.breakEvens.breakEvens.join("-")
                : "-"
              }}
            </p>

            <div>
              <div class=" ">
                <span>At Execution: </span>
                <span class="space-10"></span>

                <span>
                  Underlying (Spot):
                  <input v-if="activeExecutedBasket.editing" class="form-control" style="width:150px; display:inline"
                    v-model="activeExecutedBasket.underlyingPriceAtOpen" type="number" />
                  <span class="fs-6 fw-bold" v-else>{{ activeExecutedBasket.underlyingPriceAtOpen }}</span>
                  &nbsp;
                  <span title="Underlying change since when executed" v-bind:class="{
                    red: getUnderlyingChange('points') < 0,
                    green: getUnderlyingChange('points') > 0,
                  }">({{ getUnderlyingChange("points").toFixed(2) }},
                    {{ getUnderlyingChange("percent").toFixed(2) }}%)
                  </span>
                </span>

                <span class="space-10"></span>

                <span>
                  Underlying (Futures):
                  <input v-if="activeExecutedBasket.editing" class="form-control" style="width:150px; display:inline"
                    v-model="activeExecutedBasket.futuresPriceAtOpen" type="number" />
                  <span class="fs-6 fw-bold" v-else>{{ activeExecutedBasket.futuresPriceAtOpen }}</span>
                  &nbsp;
                  <span title="Underlying change since when executed" v-bind:class="{
                    red: getFuturesChange('points') < 0,
                    green: getFuturesChange('points') > 0,
                  }">({{ getFuturesChange("points").toFixed(2) }},
                    {{ getFuturesChange("percent").toFixed(2) }}%)
                  </span>
                </span>

                <span class="space-30"></span>
                <span>VIX:
                  <input v-if="activeExecutedBasket.editing" class="form-control" style="width:150px; display:inline"
                    v-model="activeExecutedBasket.vixWhenExecuted" type="number" />
                  <span class="fs-6 fw-bold" v-else>{{ activeExecutedBasket.vixWhenExecuted }} </span>
                  &nbsp;
                  <span title="VIX change since when executed" v-bind:class="{
                    red: getVIXChange('points') < 0,
                    green: getVIXChange('points') > 0,
                  }">({{ getVIXChange("points").toFixed(2) }},
                    {{ getVIXChange("percent").toFixed(2) }}%)
                  </span>
                </span>
              </div>

              <div class=" " v-if="activeExecutedBasket.isSquaredOff">
                <span>At Closing: </span>
                <span class="space-10"></span>
                <span>Underlying: {{ activeExecutedBasket.underlyingPriceAtClose }}
                  <span title="Underlying change since when executed" v-bind:class="{
                    red: getUnderlyingChange('points', 'yes') < 0,
                    green: getUnderlyingChange('points', 'yes') > 0,
                  }">({{ getUnderlyingChange("points", "yes").toFixed(2) }},
                    {{ getUnderlyingChange("percent", "yes").toFixed(2) }}%)</span></span>

                <span class="space-30"></span>
                <span>VIX: {{ activeExecutedBasket.VixAtSquarOff }}
                  <span title="VIX change since when executed" v-bind:class="{
                    red: getVIXChange('points', 'yes') < 0,
                    green: getVIXChange('points', 'yes') > 0,
                  }">({{ getVIXChange("points", "yes").toFixed(2) }},
                    {{ getVIXChange("percent", "yes").toFixed(2) }}%)</span></span>

                <span class="space-30"></span>
                <span>Closed on: {{ basketClosedOn }} </span>
              </div>
            </div>
          </div>

        </div>

        <div style="margin-top:15px;">
          <div class="float-start" style="margin-bottom:5px;">
            <button v-if="activeExecutedBasket.isSquaredOff != true &&
              activeExecutedBasket.status != 'new'
              " title="Square Off" style="margin-right:10px;" v-on:click.prevent="squareOff()" id="squareOff"
              class="btn btn-default btn-sm">
              <!-- <span class="material-icons">
                grid_off
              </span> -->
              <i class="fa-solid fa-right-from-bracket fa-xl"></i>
            </button>

            <button type="button" style="margin-right:10px;" title="Remove Basket" v-on:click.prevent="
              removeBasketFromExecutedBasketsInternal(
                activeExecutedBasket.id
              )
              " id="removeBasket" class="btn btn-default btn-sm">
              <!-- <span class="material-icons">
                remove_from_queue
              </span> -->
              <i class="fa-regular fa-trash-can fa-xl"></i>
            </button>

            <button v-if="activeExecutedBasket" style="margin-right:10px;"
              title="Add basket to payoff. Ctrl+Click => Clear all,add just this. Shift+Click => Remove just this one, Alt+Click => Add all"
              v-on:click.exact.prevent="
                addExecutedBasketToPayoff({
                  basket: activeExecutedBasket,
                  clear: false,
                })
                " v-on:click.ctrl.exact="
    addExecutedBasketToPayoff({
      basket: activeExecutedBasket,
      clear: true,
    })
    " v-on:click.shift.exact="
    removeExecutedBasketFromPayoff(
      activeExecutedBasket.id
    )
    " v-on:click.ctrl.shift.prevent="
    addExecutedBasketToPayoff({
      clear: false,
      addAll: true,
      groupId: activeBasketGroup.id
    })
    " v-on:click.alt.exact.prevent="
    addExecutedBasketToPayoff({ addAll: true, clear: false })
    " id="addToPayoff" class="btn btn-default btn-sm">
              <!-- <span class="material-icons">
                addchart
              </span> -->
              <i class="fa-solid fa-magnifying-glass-chart fa-xl"></i>
            </button>

            <button type="button" style="margin-right:10px;" title="Calculate Realized PnL and Points"
              v-on:click.prevent="calculateRealizedPnLAndPoints()" class="btn btn-default btn-sm">
              <!-- <span class="material-icons">
                games
              </span> -->
              <i class="fa-solid fa-calculator fa-xl"></i>
            </button>

            <button type="button" style="margin-right:10px;" title="Edit Basket" v-if="!activeExecutedBasket.editing"
              v-on:click.prevent="activeExecutedBasket.editing = true" class="btn btn-default btn-sm">
              <!-- <span class="material-icons">
                edit
              </span> -->
              <i class="fa-solid fa-pen-to-square fa-xl"></i>
            </button>

            <button type="button" style="margin-right:10px;" title="Save Basket Edits" v-if="activeExecutedBasket.editing"
              v-on:click.prevent="activeExecutedBasket.editing = false" class="btn btn-sm" v-bind:class="{
                'btn-default': activeExecutedBasket.editing == false,
                'btn-outline-primary': activeExecutedBasket.editing == true,
              }">
              <i class="fa-solid fa-check fa-xl"></i>
            </button>

            <button type="button" style="margin-right:10px;" title="Show/Hide Info Panel" v-on:click.prevent="
              activeExecutedBasket.showHideInfoPanel = !activeExecutedBasket.showHideInfoPanel
              " class="btn btn-sm" v-bind:class="{
    'btn-default':
      activeExecutedBasket.showHideInfoPanel == false,
    'btn-outline-primary': activeExecutedBasket.showHideInfoPanel == true,
  }">
              <i class="fa-solid fa-circle-info fa-xl"></i>
            </button>
            <button type="button" style="margin-right:10px;" title="Show/Hide Orders Panel"
              v-on:click.prevent="activeExecutedBasket.showHideOrdersPanel = !activeExecutedBasket.showHideOrdersPanel"
              class="btn btn-sm" v-bind:class="{
                'btn-default ':
                  activeExecutedBasket.showHideOrdersPanel == false,
                'btn-outline-primary': activeExecutedBasket.showHideOrdersPanel == true,
              }">
              <i class="fa-solid fa-list fa-xl"></i>
            </button>

            <button type="button " style="margin-right:10px;" title="Show/Hide Adjustment Trades" v-on:click.prevent="
              activeExecutedBasket.showHideAdjustments = !activeExecutedBasket.showHideAdjustments
              " class="btn btn-sm position-relative" v-bind:class="{
    'btn-default ':
      activeExecutedBasket.showHideAdjustments == false,
    'btn-outline-secondary': activeExecutedBasket.showHideAdjustments == true,
  }">
              <i class="fa-solid fa-list-check fa-xl"></i>

              <span title="Orders Squared Off"
                class="position-absolute top-0 fs-sm start-100 translate-middle badge rounded-pill text-bg-success">
                {{ activeExecutedBasket.squaredOffOrders ? activeExecutedBasket.squaredOffOrders.length : 0 }}
                <span class="visually-hidden">unread messages</span>
              </span>
            </button>

            <button type="button" style="margin-right:10px;" title="Show/Hide Algo Panel" v-on:click.prevent="
              activeExecutedBasket.showHideAlgoPanel = !activeExecutedBasket.showHideAlgoPanel
              " class="btn btn-sm" v-bind:class="{
    'btn-default':
      activeExecutedBasket.showHideAlgoPanel == false,
    'btn-outline-primary': activeExecutedBasket.showHideAlgoPanel == true,
  }">
              <!-- <span class="material-icons">
                auto_fix_high
              </span> -->
              <i class="fa-solid fa-wand-magic-sparkles fa-xl"></i>
            </button>

            <button type="button" style="margin-right:10px;" title="Show/Hide Technical Analysis Panel"
              v-on:click.prevent="
                activeExecutedBasket.showHideTechnicalAnalysisPanel = !activeExecutedBasket.showHideTechnicalAnalysisPanel
                " class="btn btn-sm" v-bind:class="{
    'btn-default':
      activeExecutedBasket.showHideTechnicalAnalysisPanel == false,
    'btn-outline-primary': activeExecutedBasket.showHideTechnicalAnalysisPanel == true,
  }">
              <i class="fa-solid fa-chart-simple fa-xl"></i>
            </button>

            <button @click="addPosition(false)" type="button" style="margin-right:10px;" title="Add new position manually"
              v-if="!activeExecutedBasket.isSquaredOff" v-on:click.prevent="activeExecutedBasket.editing = true"
              class="btn btn-default ">
              Add Position
            </button>

          </div>

          <div v-if="activeExecutedBasket.showHideOrdersPanel" class="float-end" style="margin-bottom:5px;">

            <div class="form-check form-check-inline">
              <input v-model="activeExecutedBasket.payoffShouldIncludeRealizedPnL" title="Realized P/L in Payoff"
                class="form-check-input" type="checkbox" id="realizedPnlInPayoffToggle" />
              <label class="form-check-label fs-sm" for="realizedPnlInPayoffToggle">Realized P/L in Payoff</label>
            </div>

            <div class="form-check form-check-inline">
              <input v-model="activeExecutedBasket.isMultiExpiry" title="Multi Expiry" class="form-check-input"
                type="checkbox" id="multiExpiryToggle" />
              <label class="form-check-label fs-sm" for="multiExpiryToggle">Multi Expiry</label>
            </div>

            <button type="button" title="Refresh live feeds subscription" v-on:click.prevent="
              subscribeToLiveFeedInternal(activeExecutedBasket.id)
              " class="btn btn-default btn-sm me-1">
              <i class="fa-solid fa-rss fa-xl"></i>
            </button>

            <button type="button" style="margin-right:10px;"
              title="Make a copy of the basket. Ctr+Click-> Make a copy and set to Paper Trade mode, Click-> Make an exact Copy"
              v-on:click.ctrl.prevent="
                duplicateBasket({
                  orderBasket: activeExecutedBasket,
                  isTesting: true,
                })
                " v-on:click.exact.prevent="
    duplicateBasket({
      orderBasket: activeExecutedBasket,
      isTesting: false,
    })
    " class="btn btn-default btn-sm">
              <i class="fa-regular fa-clone fa-xl"></i>
            </button>
          </div>
        </div>

        <div v-if="activeExecutedBasket.showHideOrdersPanel" class="container table-responsive m-0 p-0">
          <!-- <draggable :list="activeExecutedBasket.orders" group="executedOrderBasket" @start="drag=true" @end="drag=false"> -->
          <table class="table  text-center border border-2 ">
            <thead class="thead">
              <tr>
                <th>
                  Sq Off
                  <br />
                  <input v-if="!activeExecutedBasket.isSquaredOff" v-model="isAllOrdersSelected"
                    title="Select to Square Off" class="form-check-input" type="checkbox" />
                </th>
                <th>
                  <input v-model="isAllOrdersSelectedForAnalysisChart" title="Select to include in analysis chart"
                    class="form-check-input" type="checkbox" />
                </th>
                <th title="Include Pnl of this position in MTM calculation">Inc PnL</th>
                <th>Instrument</th>
                <th tile="Product">Prod</th>
                <th>Expiry</th>
                <!-- <th title="Position last updated">Updated</th> -->
                <th>Strike</th>
                <th>CE/PE</th>
                <th title="Buy/Sell">B/S</th>
                <th title="Lots (Qty)">Lots</th>
                <th title="Average Price">
                  <div style="width:40px">Avg Price</div>
                </th>
                <th tile="Current Price">
                  <div style="width:40px">Curr Price</div>
                </th>
                <th
                  v-if="activeExecutedBasket.isPerLegSL && activeExecutedBasket.strategyExecutionStatus === 'scheduled'">
                  Per Leg Stop</th>
                <th>Status</th>
                <th title="Pnl in points">
                  <div style="width:60px">P/L Pt</div>
                </th>
                <th title="Pnl">
                  <div style="width:60px">P/L</div>
                </th>
                <th>Action</th>
                <th v-if="activeExecutedBasket.showGreeks">IV</th>
                <th v-if="activeExecutedBasket.showGreeks">T</th>
                <th v-if="activeExecutedBasket.showGreeks">V</th>
                <th v-if="activeExecutedBasket.showGreeks">D</th>
                <th v-if="activeExecutedBasket.showGreeks">G</th>
              </tr>
            </thead>
            <!-- <draggable v-model="activeExecutedBasket.orders" tag="tbody" ghost-class="ghost"> -->
            <template v-if="activeExecutedBasket.orders.length > 0">
              <tr v-for="(item, strike) in activeExecutedBasket.orders" v-bind:key="strike"
                :set="(visibility = item.status === 'rejected' ? true : false)">
                <td>
                  <input v-if="item.status === 'complete' && !item.isSquaredOff" v-model="item.checked"
                    class="form-check-input" type="checkbox" />
                </td>
                <td>
                  <input v-if="item.status === 'complete'" v-model="item.isSelectedForAnalysisChart"
                    class="form-check-input" type="checkbox" />
                </td>
                <td>
                  <input v-if="item.status === 'complete'" v-model="item.isSelectedForAnalysisChartIncludePnL"
                    class="form-check-input" type="checkbox" />
                </td>
                <td>
                  {{ activeExecutedBasket.instrument.toUpperCase() }}
                </td>
                <td>
                  <span v-if="!item.editing">{{
                    item.product == "i" ? "MIS" : "NRML"
                  }}</span>
                  <select v-if="item.editing" v-model="item.product" class="form-select form-select-sm">
                    <option value="i" :selected="item.product == 'i'">
                      MIS
                    </option>
                    <option value="d" :selected="item.product == 'd'">
                      NRML
                    </option>
                  </select>
                </td>
                <td :title="item.symbol">
                  <select v-if="item.editing" v-model="item.expiryEdited" class="form-select form-select-sm">
                    <template v-if="activeExecutedBasket.instrument == 'finnifty'">
                      <option v-for="expiry in finNiftyExpiries" :selected="isSelectedExpiry(item.expiry, expiry)"
                        v-bind:value="expiry" v-bind:key="expiry">
                        {{ expiry }}
                      </option>
                    </template>
                    <template v-if="activeExecutedBasket.instrument == 'midcpnifty'">
                      <option v-for="expiry in midcapNiftyExpiries" :selected="isSelectedExpiry(item.expiry, expiry)"
                        v-bind:value="expiry" v-bind:key="expiry">
                        {{ expiry }}
                      </option>
                    </template>
                    <template v-else-if="activeExecutedBasket.instrument == 'banknifty'">
                      <option v-for="expiry in bankNiftyExpiries" :selected="isSelectedExpiry(item.expiry, expiry)"
                        v-bind:value="expiry" v-bind:key="expiry">
                        {{ expiry }}
                      </option>
                    </template>
                    <template v-else>
                      <option v-for="expiry in expiries" :selected="isSelectedExpiry(item.expiry, expiry)"
                        v-bind:value="expiry" v-bind:key="expiry">
                        {{ expiry }}
                      </option>
                    </template>

                  </select>
                  <span v-else>{{ formatExpiry(item.expiry) }}</span>
                </td>
                <!-- <td>
                  <span>{{ formatToDateTime(item.executedOn) }}</span>
                </td> -->
                <td>
                  <span v-if="item.instrumentType != 'f'">
                    <input v-if="item.editing" class="form-control" style="width:100px" v-model="item.strike"
                      type="number" :title="item.symbol" />
                    <span v-else :title="item.symbol">{{ item.strike }}</span>
                  </span>
                </td>
                <td>
                  <span v-if="!item.editing">{{ item.optionType.toUpperCase() }}</span>
                  <select v-if="item.editing" v-model="item.optionType" class="form-select form-select-sm"
                    style="height:43px;width:100px;">
                    <option value="ce" :selected="item.optionType == 'ce'">
                      CE
                    </option>
                    <option value="pe" :selected="item.optionType == 'pe'">
                      PE
                    </option>
                  </select>
                </td>
                <td>
                  <span v-if="!item.editing"
                    :class="item.transactionType == 'buy' ? 'text-bg-success' : 'text-bg-danger'">{{
                      item.transactionType.toUpperCase() }}</span>
                  <select v-if="item.editing" v-model="item.transactionType" class="form-select form-select-sm">
                    <option value="buy" :selected="item.transactionType == 'buy'">
                      Buy
                    </option>
                    <option value="sell" :selected="item.transactionType == 'sell'">
                      Sell
                    </option>
                  </select>
                </td>
                <td>
                  <input v-if="item.editing" class="form-control" style="width:100px" v-model="item.quantity"
                    type="number" :title="item.quantity * item.lotSize" />
                  <span v-else>{{ item.quantity }} ({{
                    item.quantity * item.lotSize
                  }})</span>
                </td>
                <td>
                  <input v-if="item.editing" class="form-control" style="width:100px" v-model="item.executedPrice"
                    type="number" />
                  <span v-else>{{ item && item.executedPrice ? parseFloat(item.executedPrice).toFixed(2) : 0 }}</span>
                </td>
                <td @mouseenter="sendBidAskSpreadRequest(item.symbol)">
                  <span :title="bidAskSpread.loading
                    ? 'Loading...'
                    : getBidAskSpreadMessage()
                    ">
                    {{
                      instruments[item.symbol]
                      ? instruments[item.symbol].Ltp.toFixed(2)
                      : 0
                    }}
                  </span>
                </td>

                <td
                  v-if="activeExecutedBasket.isPerLegSL && activeExecutedBasket.strategyExecutionStatus === 'scheduled'">
                  <span v-if="item.transactionType === 'sell'">{{ getPerLegStop(item, item.transactionType).toFixed(2) }}</span>
                  <span v-else>{{ getPerLegStop(item, item.transactionType).toFixed(2) }}</span>
                </td>

                <td>
                  <span v-if="!item.editing" :title="item.reason">{{
                    item.status.toUpperCase()
                  }}</span>
                  <select v-if="item.editing" v-model="item.statusEditing" class="form-select form-select-sm">
                    <option value="pending" :selected="item.statusEditing == 'pending'">
                      PENDING
                    </option>
                    <option value="complete" :selected="item.statusEditing == 'complete'">
                      COMPLETE
                    </option>
                    <option value="rejected" :selected="item.statusEditing == 'rejected'">
                      REJECTED
                    </option>
                    <option value="new" :selected="item.statusEditing == 'new'">
                      NEW
                    </option>
                  </select>
                </td>
                <td class="text-right">
                  <span v-bind:class="{ red: item.pl < 0, green: item.pl > 0 }">
                    {{ item && item.plPoints ? item.plPoints.toFixed(2) : 0 }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-bind:class="{ red: item.pl < 0, green: item.pl > 0 }">{{
                    item ? item.pl.toFixed(2) : 0
                  }}</span>
                </td>

                <td>
                  <div>
                    <a href="javascript:void(0)" @click="setEdit(item.symbol)" v-if="!item.editing" title="Edit position">
                      <span class="material-icons">
                        edit
                      </span>
                    </a>
                    <a href="javascript:void(0)" @click="saveEdits(item.id)" v-if="item.editing" title="Save the editing">
                      <span class="material-icons">
                        done_outline
                      </span>
                    </a>

                    <a href="javascript:void(0)" v-on:click.exact.prevent="removePosition(item.id)"
                      v-on:click.ctrl.prevent="moveToSquaredOffOrders(item.id)"
                      v-if="!item.editing || item.status == 'new'"
                      title="Click -> Remove Position, Ctrl+Click -> Move to Squared Off Orders">
                      <span class="material-icons" style="color:red">
                        highlight_off
                      </span>
                    </a>

                    <a href="javascript:void(0)" @click="item.editing = false" v-if="item.editing && item.status != 'new'"
                      title="Cancel Edit">
                      <span class="material-icons" style="color:red">
                        close
                      </span>
                    </a>
                  </div>
                </td>

                <td v-if="activeExecutedBasket.showGreeks">
                  {{ item.greeksAtNow.iv.toFixed(4) }}
                </td>
                <td v-if="activeExecutedBasket.showGreeks">
                  {{ item.greeksAtNow.theta.toFixed(4) }}
                </td>
                <td v-if="activeExecutedBasket.showGreeks">
                  {{ item.greeksAtNow.vega.toFixed(4) }}
                </td>
                <td v-if="activeExecutedBasket.showGreeks" :title="item.greeksAtNow.delta">
                  {{ item.greeksAtNow.delta.toFixed(4) }}
                </td>
                <td v-if="activeExecutedBasket.showGreeks">
                  {{ item.greeksAtNow.gamma.toFixed(4) }}
                </td>
              </tr>

              <tr v-if="activeExecutedBasket.showPremiumAndPointsForOneLot">
                <td colspan="7">&nbsp;</td>
                <td colspan="2" class="text-right">
                  <div class="form-check form-switch" title="Position Premium Calculation Basis">
                    <input class="form-check-input"
                      @click="activeExecutedBasket.showPremiumAndPointsForOneLot = !activeExecutedBasket.showPremiumAndPointsForOneLot"
                      v-model="activeExecutedBasket.showPremiumAndPointsForOneLot" type="checkbox" role="switch"
                      id="lotsPremiumCalculationType" />
                    <label class="form-check-label" for="lotsPremiumCalculationType">{{
                      activeExecutedBasket.showPremiumAndPointsForOneLot == true ?
                      'Position Premium Calculation: 1 Lot' : 'Position Premium Calculation: All Lots' }}</label>
                  </div>
                </td>
                <td>
                  {{
                    activeExecutedBasket.atOpenCombinedPremium
                    ? parseFloat(activeExecutedBasket.atOpenCombinedPremium).toFixed(2)
                    : 0
                  }}
                </td>
                <td>
                  {{
                    activeExecutedBasket.currentCombinedPremium
                    ? parseFloat(activeExecutedBasket.currentCombinedPremium).toFixed(2)
                    : 0
                  }}
                </td>

                <td
                  :colspan="activeExecutedBasket.isPerLegSL && activeExecutedBasket.strategyExecutionStatus === 'scheduled' ? 2 : 1"
                  class="text-right">MTM</td>

                <td class="text-right">
                  <p v-bind:class="{
                    red: activeExecutedBasket.totalPnl < 0,
                    green: activeExecutedBasket.totalPnl > 0,
                  }" class="p-no-margin">
                    {{
                      activeExecutedBasket && activeExecutedBasket.totalPnlPoints
                      ? parseFloat(activeExecutedBasket.totalPnlPoints).toFixed(2)
                      : 0
                    }}
                  </p>
                </td>
                <td class="text-right">
                  <p v-bind:class="{
                        red: activeExecutedBasket.totalPnl < 0,
                        green: activeExecutedBasket.totalPnl > 0,
                      }" class="p-no-margin">
                    {{
                      activeExecutedBasket && activeExecutedBasket.totalPnl
                      ? parseFloat(activeExecutedBasket.totalPnl).toFixed(2)
                      : 0
                    }}
                  </p>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="14">
                  <h5 class="mt-2">
                    All positions are squared off. Please check the square off
                    table.
                  </h5>
                </td>
              </tr>
            </template>
            <!-- </draggable> -->
            <tr v-if="!activeExecutedBasket.showPremiumAndPointsForOneLot">
              <td colspan="7">&nbsp;</td>
              <td colspan="2">
                <!-- <VueToggles title="Position Premium Calculation Basis" height="30" width="90" checkedText="1 Lot"
                  uncheckedText="All Lots" checkedBg="lightgreen" checkedColor="blue" fontWeight="bold"
                  uncheckedColor="black" uncheckedBg="lightgrey"
                  :value="activeExecutedBasket.showPremiumAndPointsForOneLot"
                  @click="activeExecutedBasket.showPremiumAndPointsForOneLot = !activeExecutedBasket.showPremiumAndPointsForOneLot" /> -->

                <div class="form-check form-switch" title="Position Premium Calculation Basis">
                  <input class="form-check-input"
                    @click="activeExecutedBasket.showPremiumAndPointsForOneLot = !activeExecutedBasket.showPremiumAndPointsForOneLot"
                    v-model="activeExecutedBasket.showPremiumAndPointsForOneLot" type="checkbox" role="switch"
                    id="lotsPremiumCalculationType" />
                  <label class="form-check-label" for="lotsPremiumCalculationType">{{
                    activeExecutedBasket.showPremiumAndPointsForOneLot == true ?
                    'Position Premium Calculation: 1 Lot' : 'Position Premium Calculation: All Lots' }}</label>
                </div>

              </td>
              <td>
                {{
                  activeExecutedBasket.atOpenCombinedPremiumActualsLots
                  ? parseFloat(activeExecutedBasket.atOpenCombinedPremiumActualsLots).toFixed(2)
                  : 0
                }}
              </td>
              <td>
                {{
                  activeExecutedBasket.currentCombinedPremiumActualsLots
                  ? parseFloat(activeExecutedBasket.currentCombinedPremiumActualsLots).toFixed(2)
                  : 0
                }}
              </td>

              <td
                :colspan="activeExecutedBasket.isPerLegSL && activeExecutedBasket.strategyExecutionStatus === 'scheduled' ? 2 : 1"
                class="text-right">MTM</td>

              <td class="text-right">
                <p v-bind:class="{
                  red: activeExecutedBasket.totalPnl < 0,
                  green: activeExecutedBasket.totalPnl > 0,
                }" class="p-no-margin">
                  {{
                    activeExecutedBasket && activeExecutedBasket.totalPnlPointsActualLots
                    ? parseFloat(activeExecutedBasket.totalPnlPointsActualLots).toFixed(2)
                    : 0
                  }}
                </p>
              </td>
              <td class="text-right">
                <p v-bind:class="{
                      red: activeExecutedBasket.totalPnl < 0,
                      green: activeExecutedBasket.totalPnl > 0,
                    }" class="p-no-margin">
                  {{
                    activeExecutedBasket && activeExecutedBasket.totalPnl
                    ? parseFloat(activeExecutedBasket.totalPnl).toFixed(2)
                    : 0
                  }}
                </p>
              </td>
            </tr>

            <tr>
              <td :colspan="activeExecutedBasket.isSquaredOff ? 15 : 4">
                <span class="bold" v-bind:class="{ red: activeExecutedBasket.isSquaredOff }">

                  <input v-model="activeExecutedBasket.isSquaredOff" class="form-check-input" type="checkbox"
                    id="isBasketSquaredOff" />
                  <label class="ms-3 form-check-label" for="isBasketSquaredOff">
                    Basket Squared Off ?
                  </label>
                </span>
              </td>

              <td colspan="4" v-if="!activeExecutedBasket.isSquaredOff">
                <table class="noBorderTable">
                  <tr>
                    <td class="bold">
                      Margin Required: <br />
                      <span class="red">
                        <span title="Actual Margin">&#8377;-{{
                          Number(
                            activeExecutedBasket.marginRequired.toFixed(2)
                          ).toLocaleString()
                        }},</span>&nbsp;
                        <span title="Actual Margin - Setup Cost">&#8377;-{{
                          Number(
                            (
                              activeExecutedBasket.marginRequired -
                              activeExecutedBasket.setupCost
                            ).toFixed(2)
                          ).toLocaleString()
                        }}</span>
                      </span>
                    </td>
                    <td>
                      <p style="width:5px">&nbsp;</p>
                    </td>
                    <td colspan="1" style="vertical-align: middle;">
                      <a type="button" title="Refresh margin" style="padding-top:5px" @click="getRequiredMargin"
                        href="javascript:void(0)">
                        <span class="material-icons">
                          refresh
                        </span>
                      </a>
                    </td>
                    <td>&nbsp;</td>
                    <td>
                      <p style="margin-left:50px;font-weight:bold;margin-bottom:0px" :set="(totalSetupCost =
                        activeExecutedBasket.setupCost +
                        activeExecutedBasket.realizedPL)
                        ">
                        Setup Cost: <br />
                        <span v-bind:class="{
                          red: activeExecutedBasket.setupCost < 0,
                          green: activeExecutedBasket.setupCost > 0,
                        }" title="Setup Cost (premium credit or debit)">
                          &#8377;
                          {{
                            Number(
                              activeExecutedBasket.setupCost.toFixed(2)
                            ).toLocaleString()
                          }} </span>,
                        <span v-if="activeExecutedBasket.realizedPL &&
                            activeExecutedBasket.realizedPL !== 0
                            " v-bind:class="{
      red: totalSetupCost < 0,
      green: totalSetupCost > 0,
    }" title="Total Setup Cost (includes realized PnL)">
                          &#8377;
                          {{
                            Number(totalSetupCost.toFixed(2)).toLocaleString()
                          }} </span>,
                        <span class="red" title="Brokerage and Taxes">
                          &#8377;
                          {{ activeExecutedBasket.brokerageAndTaxes.toFixed(2) }}
                        </span>
                      </p>
                    </td>
                    <td colspan="1" style="vertical-align: middle;">
                      <a type="button" title="Refresh setup cost" style="padding-top:5px" @click="
                        updateActiveExecutedBasketSetupCostAndMultiExpiryFlag();
                      updateBrokerageAndTaxes();
                      " href="javascript:void(0)">
                        <span class="material-icons">
                          refresh
                        </span>
                      </a>
                    </td>
                  </tr>
                </table>
              </td>

              <td v-if="!activeExecutedBasket.isSquaredOff"
                :colspan="activeExecutedBasket.isPerLegSL && activeExecutedBasket.strategyExecutionStatus === 'scheduled' ? 3 : 2">
                <div v-if="activeExecutedBasket.orders.length > 0">
                  <button v-if="activeExecutedBasket.isTesting" type="button" class="btn btn-default "
                    @click="resetExecutedPrice" title="Reset Executed Price">
                    <i class="fa-solid fa-rotate fa-xl"></i>
                  </button>
                  <span class="space-5"></span>
                  <button v-if="activeExecutedBasket.isTesting" type="button" class="btn btn-default "
                    @click="convertToLivePosition" title="Convert to Live Positions">
                    <i class="fa-solid fa-right-to-bracket fa-xl"></i>
                  </button>
                </div>
              </td>

              <td colspan="2" class="text-right " v-if="!activeExecutedBasket.isSquaredOff">
                <p class="p-no-margin" title="">Realized </p>             
                <p class="p-no-margin" title="">Net</p>
                <!--
                  <p class="p-no-margin" title="Impact Cost (Includes Best Bid-Ask Spread + Brokerage)">Impact Cost:</P>
                  <p class="p-no-margin" title="Fina P/L. (After deducting Impact Cost)">Net P/L:</P>
                  -->
                <p class="p-no-margin" title="Return on Capital (Capital includes Margin + Setup Cost + Brokerage)">
                  .
                </p>
              </td>

              <td class="text-right" v-if="!activeExecutedBasket.isSquaredOff">
                <p v-bind:class="{
                  red: activeExecutedBasket.realizedPL < 0,
                  green: activeExecutedBasket.realizedPL > 0,
                }" class="p-no-margin">
                  {{
                    activeExecutedBasket && activeExecutedBasket.realizedPLPoints
                    ? activeExecutedBasket.realizedPLPoints.toFixed(2)
                    : 0
                  }}
                </p>
                <p title="If this is green but value is in Minus, position orders might have different quantity ratio"
                  v-bind:class="{
                        red:
                          activeExecutedBasket.totalPnl +
                          activeExecutedBasket.realizedPL <=
                          0,
                        green:
                          activeExecutedBasket.totalPnl +
                          activeExecutedBasket.realizedPL >
                          0,
                      }" class="p-no-margin">
                  {{
                    activeExecutedBasket &&
                    activeExecutedBasket.totalPnlPoints +
                    activeExecutedBasket.realizedPLPoints
                    ? (
                      activeExecutedBasket.totalPnlPoints +
                      activeExecutedBasket.realizedPLPoints
                    ).toFixed(2)
                    : 0
                  }}
                </p>
                <p class="p-no-margin">
                  .
                </p>
              </td>
              <td class="text-right" v-if="!activeExecutedBasket.isSquaredOff">
                <p v-bind:class="{
                  red: activeExecutedBasket.realizedPL < 0,
                  green: activeExecutedBasket.realizedPL > 0,
                }" class="p-no-margin">
                  {{
                    activeExecutedBasket && activeExecutedBasket.realizedPL
                    ? activeExecutedBasket.realizedPL.toFixed(2)
                    : 0
                  }}
                </p>
                <p v-bind:class="{
                      red:
                        activeExecutedBasket.totalPnl +
                        activeExecutedBasket.realizedPL <=
                        0,
                      green:
                        activeExecutedBasket.totalPnl +
                        activeExecutedBasket.realizedPL >
                        0,
                    }" class="p-no-margin">
                  {{
                    activeExecutedBasket &&
                    activeExecutedBasket.totalPnl +
                    activeExecutedBasket.realizedPL
                    ? (
                      activeExecutedBasket.totalPnl +
                      activeExecutedBasket.realizedPL
                    ).toFixed(2)
                    : 0
                  }}
                </p>
                <!--
                  <p class="p-no-margin" title="Click to Refresh" @click="RefreshImpactCost()">
                    <span v-bind:class="{red: activeExecutedBasket.totalPnl<0, green: activeExecutedBasket.totalPnl > 0 }">
                      350.00
                    </span>
                  </p>
                  <p class="p-no-margin">
                    <span v-bind:class="{red: activeExecutedBasket.totalPnl<0, green: activeExecutedBasket.totalPnl > 0 }">
                      350.00
                    </span>
                  </p>
                  -->
                <p class="p-no-margin">
                  <span v-bind:class="{
                        red: activeExecutedBasket.totalPnl < 0,
                        green: activeExecutedBasket.totalPnl > 0,
                      }">.</span>
                </p>
              </td>

              <td colspan="5" v-if="activeExecutedBasket.showGreeks"></td>
            </tr>
          </table>

          <!-- </draggable> -->

          <table class="table table-sm text-center" v-if="activeExecutedBasket.squaredOffOrders.length > 0 &&
            activeExecutedBasket.showHideAdjustments == true
            ">
            <thead class="thead">
              <tr>
                <th>
                  <input v-model="isAllSquaredOffOrdersSelectedForAnalysisChart"
                    title="Select to include position in analysis chart" class="form-check-input" type="checkbox" />
                </th>
                <th title="Include Pnl in Analysis">Inc PnL</th>
                <th>Instrument</th>
                <th title="Product">Prod</th>
                <th>Expiry</th>
                <th title="Executed on">Exe On</th>
                <th title="Underlying at Open">@Open</th>
                <th title="Underlying at Close">@Close</th>
                <th title="Underlying Difference (Open-Close)">Diff</th>
                <th>Strike</th>
                <th>PE/CE</th>
                <th title="Buy/Sell">B/S</th>
                <th title="Lots (Qty)">Lots</th>
                <th title="Executed Price">Exe Price</th>
                <th title="Squared Off Price">Sq Price</th>
                <th>Ltp</th>
                <th>Status</th>
                <th>Is Sq Off</th>
                <th title="Pnl in Points">P/L Pt</th>
                <th title="Pnl">P/L</th>
                <th>Action</th>
              </tr>
            </thead>
            <tr v-for="(item, strike) in activeExecutedBasket.squaredOffOrders" v-bind:key="strike"
              :set="(visibility = item.status === 'rejected' ? true : false)" :class="{
                rejected: item.status === 'rejected',
                completed: item.status === 'complete',
                pending: item.status === 'pending',
                'squared-off': item.status.isSquaredOff,
              }">
              <td>
                <input v-if="item.status === 'complete'" v-model="item.isSelectedForAnalysisChart"
                  class="form-check-input" type="checkbox" />
              </td>
              <td>
                <input v-if="item.status === 'complete'" v-model="item.isSelectedForAnalysisChartIncludePnL"
                  class="form-check-input" type="checkbox" />
              </td>
              <td>
                {{ activeExecutedBasket.instrument.toUpperCase() }}
              </td>
              <td>
                <span v-if="!item.editing">{{
                  item.product == "i" ? "MIS" : "NRML"
                }}</span>
                <select v-if="item.editing" v-model="item.product" class="form-select form-select-sm">
                  <option value="i" :selected="item.product == 'i'">
                    MIS
                  </option>
                  <option value="d" :selected="item.product == 'd'">
                    NRML
                  </option>
                </select>
              </td>
              <td :title="item.symbol">
                <select v-if="item.editing" v-model="item.expiryEdited" class="form-select form-select-sm">
                  <template v-if="activeExecutedBasket.instrument == 'finnifty'">
                    <option v-for="expiry in finNiftyExpiries" :selected="isSelectedExpiry(item.expiry, expiry)"
                      v-bind:value="expiry" v-bind:key="expiry">
                      {{ expiry }}
                    </option>
                  </template>
                  <template v-if="activeExecutedBasket.instrument == 'midcpnifty'">
                    <option v-for="expiry in midcapNiftyExpiries" :selected="isSelectedExpiry(item.expiry, expiry)"
                      v-bind:value="expiry" v-bind:key="expiry">
                      {{ expiry }}
                    </option>
                  </template>
                  <template v-if="activeExecutedBasket.instrument == 'banknifty'">
                    <option v-for="expiry in bankNiftyExpiries" :selected="isSelectedExpiry(item.expiry, expiry)"
                      v-bind:value="expiry" v-bind:key="expiry">
                      {{ expiry }}
                    </option>
                  </template>
                  <template v-else>
                    <option v-for="expiry in expiries" :selected="isSelectedExpiry(item.expiry, expiry)"
                      v-bind:value="expiry" v-bind:key="expiry">
                      {{ expiry }}
                    </option>
                  </template>
                </select>
                <span v-else>{{ formatExpiry(item.expiry) }}</span>
              </td>
              <td>
                <span>{{ formatToDateTime(item.executedOn) }}</span>
              </td>
              <td>
                <span>{{ item.underlyingPriceAtOpen ? item.underlyingPriceAtOpen.toFixed(2) : 0 }}</span>
              </td>
              <td>
                <span>{{ item.underlyingPriceAtClose ? item.underlyingPriceAtClose.toFixed(2) : 0 }}</span>
              </td>
              <td>
                <span>{{
                  (
                    item.underlyingPriceAtOpen - item.underlyingPriceAtClose
                  ).toFixed(2)
                }}</span>
              </td>
              <td>
                <span v-if="item.instrumentType != 'f'">
                  <input v-if="item.editing" class="form-control" style="width:100px" v-model="item.strike" type="number"
                    :title="item.symbol" />
                  <span v-else :title="item.symbol">{{ item.strike }}</span>
                </span>
              </td>
              <td>
                <span v-if="!item.editing">{{ item.optionType }}</span>
                <select v-if="item.editing" v-model="item.optionType" class="form-select form-select-sm">
                  <option value="ce" :selected="item.optionType == 'ce'">
                    CE
                  </option>
                  <option value="pe" :selected="item.optionType == 'pe'">
                    PE
                  </option>
                </select>
              </td>
              <td>
                <span v-if="!item.editing">{{ item.transactionType }}</span>
                <select v-if="item.editing" v-model="item.transactionType" class="form-select form-select-sm">
                  <option value="buy" :selected="item.transactionType == 'buy'">
                    BUY
                  </option>
                  <option value="sell" :selected="item.transactionType == 'sell'">
                    SELL
                  </option>
                </select>
              </td>
              <td>
                <input v-if="item.editing" class="form-control" v-model="item.quantity" type="number"
                  :title="item.quantity * item.lotSize" />
                <span v-else>{{ item.quantity }} ({{ item.quantity * item.lotSize }})</span>
              </td>
              <td>
                <input v-if="item.editing" class="form-control" v-model="item.executedPrice" type="number" />
                <span v-else>{{ item.executedPrice ? parseFloat(item.executedPrice).toFixed(2) : 0 }}</span>
              </td>
              <td>
                <!-- <span v-if="activeExecutedBasket.isTesting">0</span> -->
                <span>
                  <input v-if="item.editing" class="form-control" v-model="item.squaredOffPrice" type="number" />
                  <span v-else>{{ item.squaredOffPrice ? parseFloat(item.squaredOffPrice).toFixed(2) : 0 }}</span>
                </span>
              </td>
              <td>
                <span>
                  {{
                    instruments[item.symbol]
                    ? instruments[item.symbol].Ltp.toFixed(2)
                    : 0
                  }}
                </span>
              </td>
              <td>
                <span :title="item.reason">{{ item.status }}</span>
              </td>
              <td>
                <span v-if="activeExecutedBasket.isTesting">NA</span>
                <span v-else>
                  <span>{{ item.isSquaredOff ? "Yes" : "No" }}</span>
                </span>
              </td>
              <td class="text-right">
                <span v-bind:class="{ red: item.pl < 0, green: item.pl > 0 }">{{
                  item
                  ? (item.pl / (item.quantity * item.lotSize)).toFixed(2)
                  : 0
                }}</span>
              </td>
              <td class="text-right">
                <span v-bind:class="{ red: item.pl < 0, green: item.pl > 0 }">{{
                  item ? item.pl.toFixed(2) : -1
                }}</span>
              </td>

              <td>
                <div>
                  <a href="javascript:void(0)" @click="setEdit(item.symbol, true)" v-if="!item.editing"
                    title="Edit position">
                    <span class="material-icons">
                      edit
                    </span>
                  </a>
                  <a href="javascript:void(0)" @click="saveEdits(item.id, true)" v-if="item.editing"
                    title="Save the editing">
                    <span class="material-icons">
                      done_outline
                    </span>
                  </a>
                  <a href="javascript:void(0)" @click="removePosition(item.id, true)"
                    v-if="!item.editing || item.status == 'new'" title="Remove position">
                    <span class="material-icons" style="color:red">
                      highlight_off
                    </span>
                  </a>

                  <a href="javascript:void(0)" @click="item.editing = false" v-if="item.editing && item.status != 'new'"
                    title="Cancel Edit">
                    <span class="material-icons" style="color:red">
                      close
                    </span>
                  </a>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="17"></td>
              <td class="text-right">
                <p v-bind:class="{
                  red: activeExecutedBasket.realizedPL < 0,
                  green: activeExecutedBasket.realizedPL > 0,
                }" class="p-no-margin">
                  {{
                    activeExecutedBasket && activeExecutedBasket.realizedPLPoints
                    ? activeExecutedBasket.realizedPLPoints.toFixed(2)
                    : 0
                  }}
                </p>
              </td>
              <td class="text-right">
                <p v-bind:class="{
                      red: activeExecutedBasket.realizedPL < 0,
                      green: activeExecutedBasket.realizedPL > 0,
                    }" class="p-no-margin">
                  {{
                    activeExecutedBasket && activeExecutedBasket.realizedPL
                    ? activeExecutedBasket.realizedPL.toFixed(2)
                    : 0
                  }}
                  HHh
                </p>
              </td>
              <td>
                <!-- TO BE DONE LATER  [Adding a position from option Chain]
                    <a href="javascript:void(0)" @click="addPosition" title="Add new position manually">
                      <span class="material-icons md-36">
                      rule
                      </span>
                    </a>
                    -->
                <a href="javascript:void(0)" @click="addPosition(true)" title="Add new position">
                Add Hello kjhjk
                  <span class="material-icons md-36">
                    playlist_add
                  </span>
                </a>
              </td>
            </tr>
          </table>

          <div class="border border-1 p-3">
            <div class="form-row">
              <div class="form-group">
                <button v-if="activeExecutedBasket.isSquaredOff != true &&
                  activeExecutedBasket.status != 'new'
                  " title="Square Off" style="margin-right:10px;" v-on:click.prevent="
    squareOff()
    " id="squareOff" class="btn btn-default btn-sm">
                  <i class="fa-solid fa-right-from-bracket fa-xl"></i>
                </button>

                <button type="button" style="margin-right:10px;" title="Remove Basket" v-on:click.prevent="
                  removeBasketFromExecutedBasketsInternal(
                    activeExecutedBasket.id
                  )
                  " id="removeBasket" class="btn btn-default btn-sm">
                  <i class="fa-regular fa-trash-can fa-xl"></i>
                </button>

                <button v-if="activeExecutedBasket" style="margin-right:10px;"
                  title="Add basket to payoff. Ctrl+Click => Clear all,add just this. Shift+Click => Remove just this one, Alt+Click => Add all"
                  v-on:click.exact.prevent="
                    addExecutedBasketToPayoff({
                      basket: activeExecutedBasket,
                      clear: false,
                    })
                    " v-on:click.ctrl.exact="
    addExecutedBasketToPayoff({
      basket: activeExecutedBasket,
      clear: true,
    })
    " v-on:click.shift.exact="
    removeExecutedBasketFromPayoff(
      activeExecutedBasket.id
    )
    " v-on:click.ctrl.shift.prevent="
    addExecutedBasketToPayoff({
      clear: false,
      addAll: true,
      groupId: activeBasketGroup.id
    })
    " v-on:click.alt.prevent="
    addExecutedBasketToPayoff({ addAll: true, clear: false })
    " id="addToPayoff" class="btn btn-default btn-sm">
                  <i class="fa-solid fa-magnifying-glass-chart fa-xl"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div v-if="activeExecutedBasket.showHideAlgoPanel" class="container border border-2 mt-3 p-2 mb-2 me-2">
        <div class="row">
          <div class="col-2">
            <select title="Stop-Target Type" v-model="activeExecutedBasket.stopTargetType" class="form-select" :disabled="activeExecutedBasket.isSquaredOff ||
              activeExecutedBasket.isTrailing
              " @change="stopTargetTypeChanged()">
              <option value="1" :selected="activeExecutedBasket.stopTargetType == '1'">
                Points
              </option>
              <option value="2" :selected="activeExecutedBasket.stopTargetType == '2'">
                Premium
              </option>
            </select>
          </div>
          <div class="col-2">
            <input :title="getStopExpectedLossPerLot()" :disabled="activeExecutedBasket.isSquaredOff ||
              activeExecutedBasket.isTrailing
              " class="form-control form-control-sm" v-model="activeExecutedBasket.stop" id="labelStop"
              placeholder="stop" type="number" />
          </div>
          <div class="col-2">
            <input :title="getStopExpectedProfitPerLot()" :disabled="activeExecutedBasket.isSquaredOff ||
              activeExecutedBasket.isTrailing
              " class="form-control form-control-sm" v-model="activeExecutedBasket.target" id="labelTarget"
              placeholder="target" type="number" />
          </div>
          <div class="col-2">
            <input title="Trail" :disabled="activeExecutedBasket.isSquaredOff ||
              activeExecutedBasket.isTrailing
              " class="form-control form-control-sm" v-model="activeExecutedBasket.trail" id="labelTrail"
              placeholder="trail" min="0" type="number" />
          </div>
        </div>

        <div class="row">
          <div class="col-2">
            <input v-model="activeExecutedBasket.isPerLegSL"
              title="If not set, SL will be based on combined premium. Once SL hit, all legs will be squared off else if set leg adjustment will be done to keep delta neutral"
              class="form-check-input" type="checkbox" id="perLegSL" />
            <label class="ms-3 form-check-label" for="perLegSL">
              Per Leg SL
            </label>
          </div>

          <div class="col-2">
            <select title="Per Leg SL Type" v-model="activeExecutedBasket.perLegSLType" class="form-select"
              :disabled="activeExecutedBasket.stopTargetType == 2">
              <option value="1" :selected="activeExecutedBasket.perLegSLType == '1'">
                Percent
              </option>
              <option value="2" :selected="activeExecutedBasket.perLegSLType == '2'">
                Fixed
              </option>
            </select>
          </div>
          <div class="col-2">
            <input title="Per Leg SL Value" :disabled="activeExecutedBasket.isSquaredOff ||
              activeExecutedBasket.isTrailing ||
              activeExecutedBasket.stopTargetType == 2
              " class="form-control form-control-sm" v-model="activeExecutedBasket.perLegSLValue" id="labelTrail"
              placeholder="Per Leg SL Value" min="0" type="number" style="width: 150px;" />
          </div>    
        </div>

        <div class="row">
          <div class="col-4">
            <input v-model="activeExecutedBasket.isShiftToNextOTM"
              :disabled="activeExecutedBasket.stopTargetType == 2"
              title="Once Leg SL hit, should open a next OTM leg" class="form-check-input"
              id="shiftOTM" type="checkbox" />
            <label class="ms-3 form-check-label" for="shiftOTM">
              Shift to OTM - Trending Side
            </label>
          </div>
          <div class="col-2">
            <input title="Opp side qty to add" :disabled="!activeExecutedBasket.isShiftToNextOTM" class="form-control form-control-sm" v-model="activeExecutedBasket.oppositeQtyToAdd" id="oppositeQtyToAdd"
              placeholder="oppositeQtyToAdd" min="0" type="number" style="width: 100px;" />
          </div>            
        </div>

        <div class="row">
          <div class="col-4">
            <input v-model="activeExecutedBasket.isTrendChaser"
              :disabled="activeExecutedBasket.stopTargetType == 2"
              title="Once Leg SL hit, should open a next OTM leg" class="form-check-input"
              id="trendChaser" type="checkbox" />
            <label class="ms-3 form-check-label" for="trendChaser">
              Trend Chaser - Buyer
            </label>
          </div>

          <div class="col-1">
            <input title="Per Leg SL Value" :disabled="!activeExecutedBasket.isTrendChaser" class="form-control form-control-sm" v-model="activeExecutedBasket.trendChaserPerLegSLValue" id="trendChaserPerLegSLValue"
              placeholder="Per Leg SL" min="0" type="number" style="width: 100px;" />
          </div>   
          
          <div class="col-1">
            <input title="Add Qty @ Percent Profit Interval" :disabled="!activeExecutedBasket.isTrendChaser" class="form-control form-control-sm" v-model="activeExecutedBasket.trendChaserAtQtyAddPercentProfitInterval" id="trendChaserAtQtyAddPercentProfitInterval"
              placeholder="+Qty @ $ $ Interval" min="0" type="number" style="width: 100px;" />
          </div>    
          
          <div class="col-1">
            <input title="Exit at Drawdown %" :disabled="!activeExecutedBasket.isTrendChaser" class="form-control form-control-sm" v-model="activeExecutedBasket.trendChaserExitAtDrawdown" id="trendChaserExitAtDrawdown"
              placeholder="Exit @ Drawdown %" min="0" type="number" style="width: 100px;" />
          </div>     
          
          <div class="col-4">
            <input v-model="activeExecutedBasket.trendChaserReopenOnOppositDrawdownHit" :disabled="!activeExecutedBasket.isTrendChaser"
              id="trendChaserReopenOnOppositDrawdownHit" title="Reopen Opposit on Other Leg Drawdown Hit" class="form-check-input" type="checkbox" />
            <label class="ms-3 form-check-label" for="trendChaserReopenOnOppositDrawdownHit">
              Reopen opposit on other Leg Drawdown Hit
            </label>
          </div>          
        </div>        

        <div class="row">
          <div class="col-4">
            <input v-model="activeExecutedBasket.isPerLegSLHitOpenNew"
              :disabled="activeExecutedBasket.stopTargetType == 2"
              title="Once Leg SL hit, should open a new leg keeping delta balanced" class="form-check-input"
              id="balanceDelta" type="checkbox" />
            <label class="ms-3 form-check-label" for="balanceDelta">
              Balance Delta
            </label>
          </div>

          <div class="col-2" v-if="activeExecutedBasket.isPerLegSLHitOpenNew">
            <input
              title="Strike difference to balance delta (0 would shift the Straddle to the direction of the market, if any of the leg SL hit)"
              :disabled="activeExecutedBasket.isSquaredOff ||
                activeExecutedBasket.isTrailing ||
                activeExecutedBasket.stopTargetType == 2
                " class="form-control form-control-sm" v-model="activeExecutedBasket.deltaBalanceStrikeDifference"
              id="labelTrail" placeholder="Delta Balance Strike Difference" min="0" type="number"
              :step="activeExecutedBasket.instrument === 'nifty' ? 50 : 100" style="width: 150px;" />
          </div>          
        </div>        
                
        <div class="row">
          <div class="col-4">
            <input v-model="activeExecutedBasket.isStayOTM"
              title="If set and when near month leg becomes ITM, it will be shifted to next available OTM strike if no positions opened to that strike"
              class="form-check-input" type="checkbox" id="stayOtm" />
            <label class="ms-3 form-check-label" for="stayOtm">
              Stay OTM - Both Sides (Current/Near Month)
            </label>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <input v-model="activeExecutedBasket.isPerLegSLReEntry" :disabled="activeExecutedBasket.stopTargetType == 2"
              title="Once Leg SL hit, should open a new leg keeping delta balanced" class="form-check-input" id="reEntry"
              type="checkbox" />
            <label class="ms-3 form-check-label" for="reEntry">
              Re Entry
            </label>
          </div>
        </div>        

        <div class="row">
          <div class="col-6">
            <input v-model="activeExecutedBasket.shiftStrikes" :disabled="activeExecutedBasket.stopTargetType == 2"
              title="If set and when near month leg becomes ITM, it will be shifted to next available OTM strike if no positions opened to that strike"
              class="form-check-input" type="checkbox" id="shiftStraddle" />
            <label class="ms-3 form-check-label" for="shiftStraddle">
              Shift Straddle (Current) when spot reaches to (since last adjustment)
            </label>
          </div>

          <div class="col-2">
            <input title="Shift All Strikes Spot Percent" class="form-control"
              v-model="activeExecutedBasket.shiftAllStrikesPercent" placeholder="%" min="0" type="number"
              style="width: 100px;" />
          </div>
          percent

        </div>

        <div class="row">
          <div class="col-3">
            <input v-model="activeExecutedBasket.isFuturesSL" :disabled="activeExecutedBasket.stopTargetType == 2"
              title="SL based on futures price" class="form-check-input" type="checkbox"
              id="sltargetBasedOnFuturesSpot" />
            <label class="ms-3 form-check-label" for="sltargetBasedOnFuturesSpot">
              SL/Target based on {{ parseInt(activeExecutedBasket.futuresSLUnderlyingType) === 1 ? 'Spot' :
                'Futures' }}
            </label>
          </div>

          <div class="col-1">
            <input class="form-check-input" activeExecutedBasket.futuresSLUnderlyingType type="radio"
              id="slTargetBaskedOnSpot" name="considerSpotFuturesAsUnderlying" title="Consider Spot as underlying"
              v-bind:value="1" :disabled="!activeExecutedBasket.isFuturesSL" />
            <label class="form-check-label" for="slTargetBaskedOnSpot">
              Spot
            </label>
          </div>


          <div class="col-2">
            <input class="form-check-input" activeExecutedBasket.futuresSLUnderlyingType type="radio"
              id="slTargetBaskedOnFutures" name="considerSpotFuturesAsUnderlying" title="Consider Spot as underlying"
              v-bind:value="2" :disabled="!activeExecutedBasket.isFuturesSL" />
            <label class="form-check-label" for="slTargetBaskedOnFutures">
              Futures
            </label>
          </div>

          <div style="font-weight: bold;font-size:1.1em;" class="col-3">
            {{ futuresSLUnderlyingPrice }}
            <span title="Underlying change since when executed" v-bind:class="{
              red: futuresSLUnderlyingChange('points') < 0,
              green: futuresSLUnderlyingChange('points') > 0,
            }">({{ futuresSLUnderlyingChange("points").toFixed(2) }},
              {{ futuresSLUnderlyingChange("percent").toFixed(2) }}%)
            </span>
            <label class="space-10"></label>
          </div>


          <div class="col-2">
            <select title="Futures SL Type" v-model="activeExecutedBasket.futuresSLType" class="form-select"
              :disabled="!activeExecutedBasket.isFuturesSL">
              <option value="1" :selected="activeExecutedBasket.futuresSLType == 1">
                Percent
              </option>
              <option value="2" :selected="activeExecutedBasket.futuresSLType == 2">
                Value
              </option>

            </select>

          </div>

          <div class="col-2">
            <input v-model="activeExecutedBasket.slTargetBasedOnCandlePrice" :disabled="!activeExecutedBasket.isFuturesSL"
              id="slTargetUseCandle" title="SL/Target based on candle price" class="form-check-input" type="checkbox" />
            <label class="ms-3 form-check-label" for="slTargetUseCandle">
              Use Candle Price
            </label>
          </div>

          <div class="col-auto">
            <input v-model="activeExecutedBasket.fixedCandleSL" :disabled="!activeExecutedBasket.isFuturesSL"
              id="slTargetFixedCandle" title="Use fixed candle SL, once set, will not be dynamically updated again"
              class="form-check-input" type="checkbox" />
            <label class="ms-3 form-check-label" for="slTargetFixedCandle">
              Fixed Candle SL
            </label>
          </div>

          <div class="col-2" v-if="activeExecutedBasket.slTargetBasedOnCandlePrice == true">
            <select title="Candle Price Type" v-model="activeExecutedBasket.underlyingSLCandlePriceType"
              class="form-select" :disabled="!activeExecutedBasket.isFuturesSL">
              <option value="1" :selected="activeExecutedBasket.underlyingSLCandlePriceType == 1">
                Low/High
              </option>
              <option value="2" :selected="activeExecutedBasket.underlyingSLCandlePriceType == 2">
                Close
              </option>

            </select>

          </div>


          <div class="col-2" v-if="activeExecutedBasket.slTargetBasedOnCandlePrice == true">
            <input title="Candles Look back Count" :disabled="!activeExecutedBasket.isFuturesSL"
              class="form-control form-control-sm" v-model="activeExecutedBasket.underlyingSLCandlesLookbackCount"
              placeholder="Count" min="2" type="number" style="width: 80px;" />
          </div>

          <div class="col-2" v-if="activeExecutedBasket.slTargetBasedOnCandlePrice == true">
            <select title="Persona" v-model="activeExecutedBasket.persona" class="form-select "
              :disabled="!activeExecutedBasket.isFuturesSL">
              <option value="1" :selected="activeExecutedBasket.persona == 1">
                Buyer
              </option>
              <option value="2" :selected="activeExecutedBasket.persona == 2">
                Seller
              </option>
            </select>
          </div>

          <div class="col-2" v-if="activeExecutedBasket.slTargetBasedOnCandlePrice == true">
            <select title="Signal" v-model="activeExecutedBasket.signal" class="form-select"
              :disabled="!activeExecutedBasket.isFuturesSL">
              <option value="1" :selected="activeExecutedBasket.signal == 1">
                Buy
              </option>
              <option value="2" :selected="activeExecutedBasket.signal == 2">
                Sell
              </option>
            </select>
          </div>


          <div style="font-weight: bold;">
            <label class="space-10"></label>
            SL {{ futuresSL }}:
            <label class="space-10"></label>
          </div>

          <div class="col-2">
            <select title="Hit Stop Loss when Futures value is <= or >= the SL value"
              v-model="activeExecutedBasket.futuresSLComparisionType" class="form-select"
              :disabled="!activeExecutedBasket.isFuturesSL">
              <option value="<=" :selected="activeExecutedBasket.futuresSLComparisionType == '<='">
                &lt;=
              </option>
              <option value=">=" :selected="activeExecutedBasket.futuresSLComparisionType == '>='">
                &gt;=
              </option>
            </select>
          </div>

          <div class="col-2">
            <input title="Futures SL Value" :disabled="!activeExecutedBasket.isFuturesSL"
              class="form-control form-control-sm" v-model="activeExecutedBasket.futuresSLValue" placeholder="SL" min="0"
              type="number" style="width: 150px;" />
          </div>


          <div style="font-weight: bold;">
            <label class="space-10"></label>
            Target {{ futuresTarget }}:
            <label class="space-10"></label>
          </div>

          <div class="col-2">
            <select title="Hit Target when Futures value is <= or >= the Target value"
              v-model="activeExecutedBasket.futuresTargetComparisionType" class="form-select"
              :disabled="!activeExecutedBasket.isFuturesSL">
              <option value="<=" :selected="activeExecutedBasket.futuresTargetComparisionType == '<='">
                &lt;=
              </option>
              <option value=">=" :selected="activeExecutedBasket.futuresTargetComparisionType == '>='">
                &gt;=
              </option>
            </select>
          </div>

          <div class="col-2">
            <input title="Futures Target Value" :disabled="!activeExecutedBasket.isFuturesSL"
              class="form-control form-control-sm" v-model="activeExecutedBasket.futuresTargetValue" placeholder="Target"
              min="0" type="number" style="width: 150px;" />
          </div>

        </div>

        <div class="row">
          <div class="col-2">
            <input v-model="activeExecutedBasket.checkExitTime" title="Exit Time" class="form-check-input" id="exitTime3"
              type="checkbox" />
            <label class="ms-3 form-check-label" for="exitTime3">
              Exit time:
            </label>
          </div>
          <div class="col-1">
            <flat-pickr title="Press Enter or click outside to apply the selected time"
              v-model="activeExecutedBasket.exitTime" :config="timePickerConfig" class="form-control"
              placeholder="Exit Time" />
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <label class="bold red">{{
              activeExecutedBasket.strategyExecutionStatus
            }}</label>
          </div>
          <div v-if="activeExecutedBasket.isTrailing" class="col-3">
            <label class="space-10"></label>
            <label class="bold red">Trailing, New Stop:
              {{ activeExecutedBasket.newStop.toFixed(2) }}, Current Premium:
              {{ activeExecutedBasket.currentCombinedPremium.toFixed(2) }}, New Target:
              {{ activeExecutedBasket.newTarget.toFixed(2) }}, Trail By:
              {{ activeExecutedBasket.trailBy.toFixed(2) }}</label>
          </div>
        </div>


        <div class="row">
          <div  class="col-1">
            <a type="button" href="javascript:void(0)" @click="scheduleStrategy()" class="btn btn-success"
              title="Schedule Strategy">
              <span class="material-icons" v-if="activeExecutedBasket.strategyExecutionStatus !== 'scheduled'">
                play_arrow
              </span>
              <span v-else class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            </a>
          </div>

          <div class="col-1">
            <a type="button" href="javascript:void(0)" @click="stopStrategy()" class="btn btn-secondary"
              title="Stop Strategy">
              <span class="material-icons">
                stop
              </span>
            </a>
          </div>
        </div>

        <div class="row ">
          <div class="col-12">
            <textarea v-model="strategyLogMessageByLineBreaks" class="form-control mt10" rows="1" readonly st></textarea>
          </div>
        </div>


      </div>



      <div style="margin-top: 10px;" class="text-center" v-if="activeExecutedBasket.showHideTechnicalAnalysisPanel">
        <div>
          <a type="button" href="javascript:void(0)" style="margin-bottom:5px; margin-right:10px;"
            @click="requestPositionsTechnicalAnalysisData()" class="btn btn-primary" title="Refresh Chart">
            <span class="material-icons">
              legend_toggle
            </span>
          </a>

          <span v-if="!activeExecutedBasket.isSquaredOff || activeExecutedBasket.orders.length > 0">
            <a type="button" href="javascript:void(0)" style="margin-bottom:5px; margin-right:10px;"
              @click="watchForVWAPCrossoverSignals(1)" class="btn btn-secondary" title="Notify For Crossover Signals">
              <span class="material-icons">
                notifications_active
              </span>
            </a>

            <a type="button" href="javascript:void(0)" style="margin-bottom:5px" @click="watchForVWAPCrossoverSignals(2)"
              class="btn btn-secondary" title="Remove From Notification">
              <span class="material-icons">
                notifications_off
              </span>
            </a>

            <span class="space-10"></span>
            <a type="button" href="javascript:void(0)" style="margin-bottom:5px"
              @click="addRemoveToMultiChartViewInternal(1)" class="btn btn-secondary" title="Add to Multi Chart View">
              <span class="material-icons">
                add_photo_alternate
              </span>
            </a>
            <span class="space-10"></span>
            <a type="button" href="javascript:void(0)" style="margin-bottom:5px"
              @click="addRemoveToMultiChartViewInternal(2)" class="btn btn-secondary"
              title="Remove from Multi Chart View">
              <span class="material-icons">
                cancel_presentation
              </span>
            </a>

            <span class="space-10"></span>

            <select v-model="activeExecutedBasket.chartInterval" class="" style="height:43px;width:150px;padding:5px;"
              title="Interval" @change="requestPositionsTechnicalAnalysisData">
              <option value="1" :selected="activeExecutedBasket.chartInterval === 1">
                1 Minute
              </option>
              <option value="3" :selected="activeExecutedBasket.chartInterval === 3">
                3 Minutes
              </option>
              <option value="5" :selected="activeExecutedBasket.chartInterval === 5">
                5 Minutes
              </option>
              <option value="10" :selected="activeExecutedBasket.chartInterval === 10">
                10 Minutes
              </option>
              <option value="15" :selected="activeExecutedBasket.chartInterval === 15">
                15 Minutes
              </option>
              <option value="30" :selected="activeExecutedBasket.chartInterval === 30">
                30 Minutes
              </option>
              <option value="60" :selected="activeExecutedBasket.chartInterval === 60">
                1 Hour
              </option>
            </select>

            <span class="space-10"></span>

            <select v-model="activeExecutedBasket.chartType" class="" style="height:43px;width:150px;padding:5px;"
              title="Chart Type" @change="requestPositionsTechnicalAnalysisData">
              <option value="spline" :selected="activeExecutedBasket.chartType === 'spline'">
                Spline
              </option>
              <option value="candlestick" :selected="activeExecutedBasket.chartInterval === 'candlestick'">
                Candle
              </option>
            </select>

          </span>

        </div>


        <TechnicalAnalysis style="height:600px; width:100%;" ref="technicalAnalysisChart"
          :ohlc="activeExecutedBasket.positionTechnicalAnalysisData ? activeExecutedBasket.positionTechnicalAnalysisData.OHLC : []"
          :min="activeExecutedBasket.positionTechnicalAnalysisData ? activeExecutedBasket.positionTechnicalAnalysisData.Min : 0"
          :max="activeExecutedBasket.positionTechnicalAnalysisData ? activeExecutedBasket.positionTechnicalAnalysisData.Max : 0"
          :volume="activeExecutedBasket.positionTechnicalAnalysisData ? activeExecutedBasket.positionTechnicalAnalysisData.Volume : []"
          :chartTitle="activeExecutedBasket.positionTechnicalAnalysisData ? activeExecutedBasket.positionTechnicalAnalysisData.Title : ''"
          :showHideStopTarget="this.activeExecutedBasket.showHideAlgoPanel"
          :stop="activeExecutedBasket.stopTargetType == 1 ? activeExecutedBasket.atOpenCombinedPremium + Math.abs(activeExecutedBasket.stop) : activeExecutedBasket.stop"
          :target="activeExecutedBasket.stopTargetType == 1 ? activeExecutedBasket.atOpenCombinedPremium - activeExecutedBasket.target : activeExecutedBasket.target"
          :executed="activeExecutedBasket.atOpenCombinedPremium" :isTrailing="activeExecutedBasket.isTrailing"
          :chartType="activeExecutedBasket.chartType"
          :showVolume="activeExecutedBasket.orders.some(x => x.instrumentType === 'f') ? true : false"
          :showNavigator="false"></TechnicalAnalysis>

        <!--
        <TechnicalAnalysisTesting v-if="activeExecutedBasket.orders.length > 0"
          style="height:100%; width:100%;"

        ></TechnicalAnalysisTesting>
        -->
      </div>

      <div v-show="!isEmpty(activeExecutedBasket) && showPayOff && executedBasketPayoffBaskets.length > 0" class="card">
        <div class="card-body">

          <select title="Just add the basket to the payoff again to refresh the prices"
            v-model="activeExecutedBasket.executedBasketPayOffPrice" class="form-select form-select-sm">
            <option value="executed" :selected="activeExecutedBasket.executedBasketPayOffPrice == 'executed'">
              Executed Price
            </option>
            <option value="latest" :selected="activeExecutedBasket.executedBasketPayOffPrice == 'latest'">
              Latest Price
            </option>
          </select>
          <br />

          <apexchart height="450px" type="line" :options="executedBasketsChartOptions.options"
            :series="executedBasketsChartOptions.series"></apexchart>

        </div>
      </div>
    </template>
    <div v-else>Executed Order Basket is empty.</div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapState, mapActions, mapMutations } from "vuex";
// import draggable from 'vuedraggable'
import { TransactionType, BrokerMap } from "../constants";
import "status-indicator/styles.css";
import SymbolService from "@/services/SymbolService";
import Utils from "@/services/Utils";
import TechnicalAnalysis from "@/components/TechnicalAnalysis";
import RiskManagementProfile from "@/components/RiskManagementProfile";
import AlgoSchedulerSettings from "@/components/AlgoSchedulerSettings";
// import TradingViewChart from "@/components/TradingViewChart";
// import TechnicalAnalysisTesting from "@/components/TechnicalAnalysisForTesting";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
  name: "ExecutedOrderBaskets",
  components: {
    // draggable
    TechnicalAnalysis,
    // TechnicalAnalysisTesting
    RiskManagementProfile,
    AlgoSchedulerSettings,
    // TradingViewChart,
    flatPickr,
  },
  data() {
    return {
      strategyId: 0,
      strategyName: "",
      basketGroupId: 1,
      broker: -1,
      brokerMap: BrokerMap,
      isAllOrdersSelected: true,
      isAllOrdersSelectedForAnalysisChart: true,
      isAllSquaredOffOrdersSelectedForAnalysisChart: false,
      showPayOff: true,
      charOptions: {},
      priceUpdateWhichSymbol: "",
      priceUpdateWhatPrice: 0,
      newBasketEditingOptions: {
        strategyName: "",
        brokers: [],
        broker: -1,
        instrument: "nifty",
        isTesting: true,
      },
      timerTechnicalAnalysisChartUpdate: null,
      timePickerConfig: {
        wrap: true,
        enableTime: true,
        enableSeconds: false,
        noCalendar: true,
        minuteIncrement: 1,
        time_24hr: false
      }
    };
  },
  computed: {
    ...mapState({
      trackingId: (state) => state.trackingId,
      instruments: (state) => state.instruments,
      bidAskSpread: (state) => state.bidAskSpread,
      orderBaskets: (state) => state.orderBaskets,
      basketGroups: (state) => state.basketGroups,
      activeBasketGroup: (state) => state.activeBasketGroup,
      activeExecutedBasket: (state) => state.activeExecutedBasket,
      niftyAtmStrike: (state) => state.niftyAtmStrike,
      bankNiftyAtmStrike: (state) => state.bankNiftyAtmStrike,
      finNiftyAtmStrike: (state) => state.finNiftyAtmStrike,
      midcapNiftyAtmStrike: (state) => state.midcapNiftyAtmStrike,
      executeNewOrderFromOptionChainTrigger: (state) =>
        state.executeNewOrderFromOptionChainTrigger,
      newOrderToBeExecuted: (state) => state.newOrderToBeExecuted,
      executedBasketsMaxProfitLossBreakEvens: (state) =>
        state.executedBasketsMaxProfitLossBreakEvens,
      executedBasketsChartOptions: (state) => state.executedBasketsChartOptions,
      executedBasketPayoffBaskets: (state) => state.executedBasketPayoffBaskets,
      orderBasketsEditing: (state) => state.orderBasketsEditing,
      activeBasketEditing: (state) => state.activeBasketEditing,
      expiries: (state) => state.expiries,
      monthlies: state => state.monthlies,
      finNiftyExpiries: (state) => state.finNiftyExpiries,
      finNiftyMonthlies: state => state.finNiftyMonthlies,
      midcapNiftyExpiries: (state) => state.midcapNiftyExpiries,
      midcapNiftyMonthlies: state => state.midcapNiftyMonthlies,
      bankNiftyExpiries: (state) => state.bankNiftyExpiries,
      bankNiftyMonthlies: state => state.bankNiftyMonthlies,
      squareOffStrategy: (state) => state.botMessages.squareOffStrategy,
      squareOffStrategyList: (state) => state.botMessages.squareOffStrategyList,
      squareOffStrategyConfirmed: (state) =>
        state.botMessages.squareOffStrategyConfirmed,
      showHideOptionsSimulator: state => state.showHideOptionsSimulator,
      optionSimulatorParams: state => state.optionSimulatorParams,
      requestPositionsTechnicalAnalysisDataTriggered: state => state.requestPositionsTechnicalAnalysisDataTriggered,
      showHideChartsInOptionChain: state => state.showHideChartsInOptionChain,
      showHideRiskManagementProfile: state => state.showHideRiskManagementProfile,
      showHideAlgoSchedulerSettings: state => state.showHideAlgoSchedulerSettings,
      brokers: state => state.brokers,
    }),
    activeBrokers() {
      var activeBrokers = this.brokers.filter(x => x.isTradingSessionActive)
      return activeBrokers;
    },
    selectedBasketGroup() {
      return this.basketGroups.find(
        (x) => {
          return parseInt(x.id) === parseInt(this.activeExecutedBasket.groupId)
        }
      );
    },
    futuresSLUnderlyingPrice() {
      let underlyingPrice = 0.0

      if (parseInt(this.activeExecutedBasket.futuresSLUnderlyingType) === 1) {
        if (this.activeExecutedBasket.instrument == 'nifty')
          underlyingPrice = this.instruments['NIFTY 50'] ? this.instruments['NIFTY 50'].Ltp : 0
        else if (this.activeExecutedBasket.instrument == 'banknifty')
          underlyingPrice = this.instruments['NIFTY BANK'] ? this.instruments['NIFTY BANK'].Ltp : 0
        else if (this.activeExecutedBasket.instrument == 'sensex')
          underlyingPrice = this.instruments['SENSEX'] ? this.instruments['SENSEX'].Ltp : 0        
        else if (this.activeExecutedBasket.instrument == 'finnifty')
          underlyingPrice = this.instruments['FINNIFTY'] ? this.instruments['FINNIFTY'].Ltp : 0
        else if (this.activeExecutedBasket.instrument == 'midcpnifty')
          underlyingPrice = this.instruments['MIDCPNIFTY'] ? this.instruments['MIDCPNIFTY'].Ltp : 0
      }
      else
        underlyingPrice = this.instruments[this.futuresSymbol] ? this.instruments[this.futuresSymbol].Ltp : 0

      return underlyingPrice
    },
    futuresSL() {
      let message = ""
      if (this.activeExecutedBasket.futuresSLType == 1) {

        let underlyingPriceAtOpen = this.activeExecutedBasket.underlyingPriceAtOpen
        if (parseInt(this.activeExecutedBasket.futuresSLUnderlyingType) === 2)
          underlyingPriceAtOpen = this.activeExecutedBasket.futuresPriceAtOpen

        if (this.activeExecutedBasket.futuresSLComparisionType === '<=') {
          message = `${(underlyingPriceAtOpen - (underlyingPriceAtOpen * (this.activeExecutedBasket.futuresSLValue / 100))).toFixed(0)}`;
        } else {
          message = `${(underlyingPriceAtOpen + (underlyingPriceAtOpen * (this.activeExecutedBasket.futuresSLValue / 100))).toFixed(0)}`;
        }
      }

      return message
    },
    futuresTarget() {
      let message = ""
      if (this.activeExecutedBasket.futuresSLType == 1) {

        let underlyingPriceAtOpen = this.activeExecutedBasket.underlyingPriceAtOpen
        if (parseInt(this.activeExecutedBasket.futuresSLUnderlyingType) === 2)
          underlyingPriceAtOpen = this.activeExecutedBasket.futuresPriceAtOpen

        if (this.activeExecutedBasket.futuresTargetComparisionType === '<=') {
          message = `${(underlyingPriceAtOpen - (underlyingPriceAtOpen * (this.activeExecutedBasket.futuresTargetValue / 100))).toFixed(0)}`;
        } else {
          message = `${(underlyingPriceAtOpen + (underlyingPriceAtOpen * (this.activeExecutedBasket.futuresTargetValue / 100))).toFixed(0)}`;
        }
      }

      return message
    },
    futuresSymbol() {
      // is this nifty or bank nifty
      // get current month
      return SymbolService.getFuturesSymbolCurrent(this.activeExecutedBasket.instrument, this.monthlies[0])
    },
    orderBasketsFilteredByGroupId() {
      if (_.isEmpty(this.activeBasketGroup)) return this.orderBaskets

      if (parseInt(this.activeBasketGroup.id) === 10)
        return this.orderBaskets
      else
        return this.orderBaskets.filter(x => parseInt(x.groupId) === parseInt(this.activeBasketGroup.id))
    },
    strategyLogMessageByLineBreaks() {
      if (!this.activeExecutedBasket) return;
      if (!this.activeExecutedBasket.strategyExecutionMessages) return;

      return this.activeExecutedBasket.strategyExecutionMessages.map((item) => {
        return item + "\n";
      });
    },
    basketExecutedOn() {
      if (_.isEmpty(this.activeExecutedBasket)) return "NOT SET";
      return moment(this.activeExecutedBasket.executedOn).format(
        "ddd, DD-MMM-YYYY HH:mm"
      );
    },
    basketClosedOn() {
      if (_.isEmpty(this.activeExecutedBasket)) return "NOT SET";
      return moment(this.activeExecutedBasket.squaredOffOn).format(
        "ddd, DD-MMM-YYYY HH:mm"
      );
    },
  },
  watch: {
    requestPositionsTechnicalAnalysisDataTriggered: function () {
      this.requestPositionsTechnicalAnalysisData()
    },
    executeNewOrderFromOptionChainTrigger: function () {
      if (!this.activeExecutedBasket) return;

      // Call executeNewOrder
      // Open dialog box
      // Allow to enter quantity
      // Send execute order command
      // Once execution successfull, update the bsket as well
      this.executeNewOrderInternal();
    },
    squareOffStrategyConfirmed: function (strategyName) {
      strategyName = strategyName.split(";")[0];

      let basket = this.orderBaskets.find(
        (x) => x.strategyName === strategyName
      );
      if (!basket) {
        this.sendClientNotification(
          `Sorry, we did not find the ${strategyName} strategy.`
        );
        return;
      }

      this.squareOff(strategyName, "auto");
    },
    squareOffStrategyList: function () {
      let buttonsText = "";
      this.orderBaskets.forEach((basket) => {
        if (!(basket.isTesting || basket.isSquaredOff))
          buttonsText += `sq:${basket.strategyName
            }: ₹ ${basket.totalPnl.toFixed(2)} ${BrokerMap[basket.broker]};`;
      });

      buttonsText = buttonsText + `sq:cancel:`;

      let message = `
Select strategy to square off.

*WARNING*
Ensure only one web client is active otherwise multiple square off commands will be fired
`;

      let reply = `interactive-keyboard^$${message}^$${buttonsText}`;
      this.sendClientNotification(reply);
    },
    squareOffStrategy: function (strategyName) {
      strategyName = strategyName.split(";")[0];

      let basket = this.orderBaskets.find(
        (x) => x.strategyName === strategyName
      );
      if (!basket) {
        this.sendClientNotification(
          `Sorry, we did not find the ${strategyName} strategy.`
        );
        return;
      }
      // First we need to send the confirmation
      //
      let message = `
Okay!
Before we square off, please confirm the following details:

Strategy....: *${strategyName}*
P/L............: *${basket.totalPnl.toFixed(2)}*
No of Legs..: *${basket.orders.length}*
Max Profit..: *${basket.breakEvens.maxProfit.toFixed(2)}*
Max Loss....: *${basket.breakEvens.maxLoss.toFixed(2)}*

*If you still want to go ahead, please select Yes and we will square off the strategy [${strategyName}] for you.*

      `;
      // Show the interactive keayboard
      // format is
      let reply = `interactive-keyboard^$${message}^$sqc:Yes:${basket.strategyName};sqc:No:`;
      this.sendClientNotification(reply);
    },
    isAllOrdersSelected: function (value) {
      if (!this.activeExecutedBasket) return;

      this.activeExecutedBasket.orders.forEach((order) => {
        if (!order.isSquaredOff) order.checked = value;
      });
    },
    isAllOrdersSelectedForAnalysisChart: function (value) {
      if (!this.activeExecutedBasket) return;

      this.activeExecutedBasket.orders.forEach((order) => {
        order.isSelectedForAnalysisChart = value;
        order.isSelectedForAnalysisChartIncludePnL = true
      });
    },
    isAllSquaredOffOrdersSelectedForAnalysisChart: function (value) {
      if (!this.activeExecutedBasket) return;

      this.activeExecutedBasket.squaredOffOrders.forEach((order) => {
        order.isSelectedForAnalysisChart = value;
        order.isSelectedForAnalysisChartIncludePnL = true
      });
    },
  },
  methods: {
    ...mapActions([
      "sendTestMessage",
      "sendMessage",
      "removeBasketFromExecutedBaskets",
      "triggerFeedArrived",
      "addExecutedBasketToPayoff",
      "removeExecutedBasketFromPayoff",
      "sendSaveExecutedOrderBasketsCommand",
      "addPositionToExecutedBasket",
      "removePositionFromExecutedBasket",
      "addNewExecutedBasket",
      "setActiveExecutedBasket",
      "saveBasket",
      "subscribeToLiveFeed",
      "updateMarginRequiredForActiveExecutedBasket",
      "adjustExecutedBasket",
      "refreshGreeks",
      "updateActiveExecutedBasketSetupCostAndMultiExpiryFlag",
      "sendClientNotification",
      "showMessageAlert",
      "updateBrokerageAndTaxesForBasket",
      "sendBidAskSpreadRequest",
      "duplicateBasket",
      "calculateRealizedPnLAndPoints",
      "executeNewOrder",
      "orderBasketExecuted",
      "sendAddRemoveExecutedOrderBasketsCommand",
      "addRemoveToMultChartView",
      "sendSaveMultiChartViewBasketsCommand",
      "setActiveBasketGroup",
      "addNewBasketGroup",
      "exitBasketGroups",
      "removeBasketGroup",
      "removeAllBasketGroups",
      "setBasketGroup",
      "sendTestMessage",
      "sendUpdateBasketProperties",
      "sendUpdateBasketGroupProperties",
      "sendUpdateBasketGroupSLProperties"
    ]),
    ...mapMutations(["UpdateAccountPnl"]),
    isSelectedExpiry(orderExpiry, toBeSelected) {
      let value = this.formatExpiryDate(orderExpiry) == toBeSelected;
      return value;
    },
    formatExpiry(value) {
      return moment(value).format('DD-MMM-YYYY').toUpperCase()
    },
    futuresSLUnderlyingChange(changeType) {
      let underlyingType = 'spot'

      if (this.activeExecutedBasket.futuresSLUnderlyingType === 1)
        underlyingType = 'spot'
      else
        underlyingType = 'futures'

      if (underlyingType == 'spot') {
        if (changeType == 'points')
          return this.getUnderlyingChange("points")
        else
          return this.getUnderlyingChange("percent")
      } else {
        if (changeType == 'points')
          return this.getFuturesChange("points")
        else
          return this.getFuturesChange("percent")
      }
    },
    subscribeToLiveFeedInternal(strategyId) {
      let basket = this.orderBaskets.find(
        (x) => parseInt(x.id) === parseInt(strategyId)
      );

      let symbols = basket.orders.map((x) => ({
        Exchange: x.exchange,
        Symbol: x.symbol         
      }));

      if (basket.isPerLegSLReEntry) {
        let symbols2 = basket.squaredOffOrders.map((x) => ({
          Exchange: x.exchange,
          Symbol: x.symbol           
        }));

        for (let i = 0; i < symbols2.length; i++)
          symbols.push(symbols2[i])
      }

      this.subscribeToLiveFeed(symbols);
    },
    testFunction() {
      console.log('test function called')
    },
    updateBasketStopTargetParameters() {
      if (_.isEmpty(this.selectedBasketGroup)) return;


      let parameters = []
      Object.entries(this.selectedBasketGroup.stopTarget).map(([key, value]) => {
        parameters.push({
          PropertyName: key,
          PropertyValue: value
        })
      })

      this.sendUpdateBasketGroupSLProperties({
        BasketGroupId: this.selectedBasketGroup.id,
        UpdateType: 2,
        UpdateParamaters: parameters,
      })

    },
    updateBasketSLPropsDebounced: _.debounce(function (basketGroupId, propName, propValue) {
      this.sendUpdateBasketGroupSLProperties({
        BasketGroupId: basketGroupId,
        UpdateType: 1,
        UpdateParamater: {
          PropertyName: propName,
          PropertyValue: propValue
        },
      })
    }, 400),
    updateBasketSLProps(basketGroupId, propName, propValue) {
      this.sendUpdateBasketGroupSLProperties({
        BasketGroupId: basketGroupId,
        UpdateType: 1,
        UpdateParamater: {
          PropertyName: propName,
          PropertyValue: propValue
        },
      })
    },
    sendUpdateBasketGroupPropertiesInternal(basketGroupId, propName, propValue) {
      this.sendUpdateBasketGroupProperties({
        BasketGroupId: basketGroupId,
        UpdateType: 1,
        UpdateParamater: {
          PropertyName: propName,
          PropertyValue: propValue
        },
      })
    },
    stopTargetTypeChanged() {
      if (this.activeExecutedBasket.stopTargetType == 2) {
        // Check, do we have any short positions, if no, do not allow schedulin this strategy
        this.activeExecutedBasket.isPerLegSL = false
        this.activeExecutedBasket.isStayOTM = false
        this.activeExecutedBasket.shiftStrikes = false
        if (!this.activeExecutedBasket.orders.some(x => x.transactionType == "sell")) {
          this.showWarning("Position does not have any short positions. This can not be scheduled for auto execution.")
        }
      } else {
        this.activeExecutedBasket.isPerLegSL = true
        this.activeExecutedBasket.isStayOTM = false
        this.activeExecutedBasket.shiftStrikes = false
      }
    },
    stopStrategy() {

      let parameters = {
        StrategyId: this.activeExecutedBasket.id,
        StrategyName: this.activeExecutedBasket.strategyName,
        PlacedOn: new Date(),
      };

      let stopStrategy = {
        MessageType: "algo_stop_strategy_request",
        Parameters: parameters,
      };

      this.sendMessage(JSON.stringify(stopStrategy));
    },
    resetExecutedPrice() {
      this.$simpleAlert.confirm('Are you sure to reset executed price?', 'Reset Executed Price', 'question')
        .then(() => {
          this.activeExecutedBasket.orders.forEach(order => {
            order.executedPrice = this.instruments[order.symbol].Ltp
          });

          this.sendAddRemoveExecutedOrderBasketsCommand({
            id: this.activeExecutedBasket.id,
            action: 3, //Update
            basket: this.activeExecutedBasket
          })
        })
        .catch(() => {

        })
    },
    convertToLivePosition() {
      this.executionErrors = []

      if (this.activeExecutedBasket.orders.length == 0) {
        this.showWarning('Order basket is empty.')
        return
      }

      let strategyId = Math.floor(Math.random() * 999999)
      let strategyName = this.activeExecutedBasket.strategyName + "-ex-" + strategyId;

      var index = this.orderBaskets.findIndex(x => x.strategyName === strategyName)
      if (index > -1) {
        this.showWarning('This strategy name is already added to executed basket.')
        return
      }

      // Go trhough all orders in all baskets
      // if a mach for reverse trade is found where
      // symbol == symbol
      // broker == broker
      // product == product
      // transactionType != transactionType // reverse trade
      // status = complete
      // squaredOff = false

      this.orderBaskets.forEach(orderBasket => {
        if (orderBasket.isTesting) return

        orderBasket.orders.forEach(executedOrder => {
          this.activeExecutedBasket.orders.forEach(orderToExecute => {
            if (orderToExecute.symbol === executedOrder.symbol
              && this.selectedBroker == executedOrder.brokerId
              && this.selectedProduct == executedOrder.product
              && executedOrder.status === 'complete'
              && executedOrder.isSquaredOff == false
              && orderToExecute.transactionType != executedOrder.transactionType) {
              this.executionErrors.push({
                strategyId: orderBasket.strategyId,
                strategyName: orderBasket.strategyName,
                message: `Symbol <b>${orderToExecute.symbol}</b> is already added to the strategy <b>${orderBasket.strategyName}</b>.
                  Executing this order will square off from that basket. You can try executing it with a different broker.`,
                symbol: orderBasket.symbol
              })
            }
          });
        });
      });

      if (this.executionErrors.length > 0) return

      this.$simpleAlert.confirm('Are you sure to convert to live position?', 'Place Order - Convert to Live', 'question')
        .then(() => {
          var orders = this.activeExecutedBasket.orders.map(order => {
            return {
              Symbol: order.symbol,
              TransactionType: order.transactionType === 'buy' ? TransactionType.BUY : TransactionType.SELL,
              Quantity: order.quantity * order.lotSize,
              BrokerId: this.activeExecutedBasket.brokerId,
              LotSize: order.lotSize,
              Product: order.product,
              Tag: strategyId,
              StrategyId: strategyId,
              InstrumentType: order.instrumentType,
              OptionType: order.optionType,
              Strike: order.strike,
              BaseSymbol: this.activeExecutedBasket.instrument
            };
          })

          var executeOrderBasketCommand = {
            MessageType: 'execute_basket_order_command',
            Orders: orders,
            BasketId: this.activeExecutedBasket.id,
            IsTesting: this.activeExecutedBasket.isTesting
          }

          this.sendMessage(JSON.stringify(executeOrderBasketCommand))

          let underlyingPrice = 0

          if (this.activeExecutedBasket.instrument === 'nifty')
            underlyingPrice = this.instruments['NIFTY 50'] ? this.instruments['NIFTY 50'].Ltp : 0
          else if (this.activeExecutedBasket.instrument === 'banknifty')
            underlyingPrice = this.instruments['NIFTY BANK'] ? this.instruments['NIFTY BANK'].Ltp : 0
          else if (this.activeExecutedBasket.instrument === 'sensex')
            underlyingPrice = this.instruments['SENSEX'] ? this.instruments['SENSEX'].Ltp : 0          
          else if (this.activeExecutedBasket.instrument === 'finnifty')
            underlyingPrice = this.instruments['FINNIFTY'] ? this.instruments['FINNIFTY'].Ltp : 0
          else if (this.activeExecutedBasket.instrument === 'midcpnifty')
            underlyingPrice = this.instruments['MIDCPNIFTY'].Ltp ? this.instruments['MIDCPNIFTY'] : 0

          let executedOn = moment(Date.now()).format('DD-MMM-YYYY HH:mm')
          if (this.showHideOptionsSimulator) {
            executedOn = moment(this.optionSimulatorParams.selectedTimeInUnix).format('DD-MMM-YYYY HH:mm')
          }

          let executedBasket = JSON.parse(JSON.stringify(this.activeExecutedBasket));

          executedBasket.orders.forEach(order => {
            order.status = 'pending'
            order.squaredOff = false
            order.executedPrice = 0.0
            order.pl = 0.0
            order.isSquaredOff = false
            order.checked = true
            order.squaredOffPrice = 0.0
            order.strategyName = strategyName
            order.strategyId = strategyId
            order.reason = ''
            order.squareOffInitiated = false
            order.executedOn = executedOn
            order.editing = false
            order.underlyingPriceAtOpen = underlyingPrice
          })

          this.orderBasketExecuted({
            strategyName: strategyName,
            id: strategyId,
            brokerId: executedBasket.brokerId,
            orders: executedBasket.orders,
            isSquaredOff: false,
            groupId: executedBasket.groupId,
            instrument: executedBasket.instrument,
            totalPnl: 0,
            isTesting: false,

            stopTargetType: executedBasket.stopTargetType,
            stop: executedBasket.stop,
            target: executedBasket.target,
            trail: executedBasket.trail,
            isPerLegSL: executedBasket.isPerLegSL,
            isPerLegSLHitOpenNew: executedBasket.isPerLegSLHitOpenNew,
            oppositeQtyToAdd: executedBasket.oppositeQtyToAdd,
            isShiftToNextOTM: executedBasket.isShiftToNextOTM,
            isPerLegSLReEntry: executedBasket.isPerLegSLReEntry,
            isStayOTM: executedBasket.isStayOTM,
            shiftStrikes: executedBasket.shiftStrikes,
            perLegSLType: executedBasket.perLegSLType,
            perLegSLValue: executedBasket.perLegSLValue,
            deltaBalanceStrikeDifference: executedBasket.deltaBalanceStrikeDifference,
            ExecutionMode: executedBasket.ExecutionMode,
            squareOffMode: 'manual',
            isStopTargetHit: false,
            marginRequired: executedBasket.marginRequired,
            isMultiExpiry: executedBasket.isMultiExpiry,
            setupCost: executedBasket.setupCost,
            breakEvens: executedBasket.breakEvens,
            executedOn: executedOn,
            greeksAtNow: executedBasket.greeksAtNow,
            greeksAtExecution: executedBasket.greeksAtExecution,
            brokerageAndTaxes: executedBasket.brokerageAndTaxes,

            isFuturesSL: executedBasket.isFuturesSL,
            futuresSLType: executedBasket.futuresSLType,

            slTargetBasedOnCandlePrice: executedBasket.slTargetBasedOnCandlePrice,
            fixedCandleSL: executedBasket.fixedCandleSL,
            underlyingSLCandlePriceType: executedBasket.underlyingSLCandlePriceType,
            underlyingSLCandlePriceCheckType: executedBasket.underlyingSLCandlePriceCheckType,
            underlyingSLCandlesLookbackCount: executedBasket.underlyingSLCandlesLookbackCount,

            persona: executedBasket.persona,
            signal: executedBasket.signal,

            futuresSLComparisionType: executedBasket.futuresSLComparisionType,
            futuresTargetComparisionType: executedBasket.futuresTargetComparisionType,
            futuresSLValue: executedBasket.futuresSLValue,
            futuresTargetValue: executedBasket.futuresTargetValue,
            futuresSLUnderlyingType: executedBasket.futuresSLUnderlyingType,
            exitTime: executedBasket.exitTime,
            checkExitTime: executedBasket.checkExitTime,
          })
        })
        .catch(() => {

        })
    },
    scheduleStrategy() {
      if (
        this.activeExecutedBasket.isPerLegSL == false &&
        this.activeExecutedBasket.stop === 0 &&
        this.activeExecutedBasket.target === 0
      ) {
        this.showWarning("Please enter stop & target values (in points).");
        return;
      }

      var orderBasket = this.orderBaskets.find(
        (x) => x.id === this.activeExecutedBasket.id
      );
      orderBasket.stop = this.activeExecutedBasket.stop;
      orderBasket.target = this.activeExecutedBasket.target;
      orderBasket.trail = this.activeExecutedBasket.trail;

      let parameters = {
        Stop: parseFloat(this.activeExecutedBasket.stop),
        StrategyManager: "StraddleStrategyManager",
        StopTargetType: parseInt(this.activeExecutedBasket.stopTargetType),
        Target: parseFloat(this.activeExecutedBasket.target),
        IsTrailingEnabled:
          parseFloat(this.activeExecutedBasket.trail) === 0 ? false : true,
        TrailingStep: parseFloat(this.activeExecutedBasket.trail),
        BrokerId: this.activeExecutedBasket.brokerId,
        IsTesting: this.activeExecutedBasket.isTesting,
        StrategyId: this.activeExecutedBasket.id,
        StrategyName: this.activeExecutedBasket.strategyName,
        IsPerLegSL: this.activeExecutedBasket.isPerLegSL,
        IsPerLegSLHitOpenNew: this.activeExecutedBasket.isPerLegSLHitOpenNew,
        OppositeQtyToAdd: this.activeExecutedBasket.oppositeQtyToAdd,
        IsShiftToNextOTM: this.activeExecutedBasket.isShiftToNextOTM,
        DeltaBalanceStrikeDifference: this.activeExecutedBasket.deltaBalanceStrikeDifference,
        ExecutionMode: this.activeExecutedBasket.ExecutionMode,
        isPerLegSLReEntry: this.activeExecutedBasket.isPerLegSLReEntry,
        IsStayOTM: this.activeExecutedBasket.isStayOTM,
        ShiftStrikes: this.activeExecutedBasket.shiftStrikes,
        PerLegSLType: this.activeExecutedBasket.perLegSLType,
        PerLegSLValue: this.activeExecutedBasket.perLegSLValue,
        BaseSymbol: this.activeExecutedBasket.instrument,
        Legs: [],
        LegsSquaredOff: [],

        IsFuturesSL: this.activeExecutedBasket.isFuturesSL,
        FuturesSLUnderlyingType: this.activeExecutedBasket.futuresSLUnderlyingType,
        FuturesSLComparisionType: this.activeExecutedBasket.futuresSLComparisionType,
        FuturesSLType: this.activeExecutedBasket.futuresSLType,

        SLTargetBasedOnCandlePrice: this.activeExecutedBasket.slTargetBasedOnCandlePrice,
        FixedCandleSL: this.activeExecutedBasket.fixedCandleSL,
        UnderlyingSLCandlePriceType: this.activeExecutedBasket.UnderlyingSLCandlePriceType,
        UnderlyingSLCandlePriceCheckType: this.activeExecutedBasket.underlyingSLCandlePriceCheckType,
        UnderlyingSLCandlesLookbackCount: this.activeExecutedBasket.underlyingSLCandlesLookbackCount,

        Persona: this.activeExecutedBasket.persona,
        Signal: this.activeExecutedBasket.signal,

        FuturesTargetComparisionType: this.activeExecutedBasket.futuresTargetComparisionType,
        FuturesSLValue: this.activeExecutedBasket.futuresSLValue,
        FuturesTargetValue: this.activeExecutedBasket.futuresTargetValue,
        FuturesPriceAtOpen: this.activeExecutedBasket.futuresPriceAtOpen,
        SpotPriceAtOpen: this.activeExecutedBasket.underlyingPriceAtOpen,
        ExitTime: this.activeExecutedBasket.exitTime,
        CheckExitTime: this.activeExecutedBasket.checkExitTime,
      };

      this.activeExecutedBasket.orders.forEach((order) => {
        if (order.status === "complete")
          parameters.Legs.push({
            OrderId: order.id,
            Symbol: order.symbol,
            TransactionType: order.transactionType,
            Quantity: order.quantity,
            BrokerId: order.brokerId,
            IsSquaredOff: order.isSquaredOff,
            SquaredOffOn: order.SquaredOffOn,
            Status: order.status,
            ExecutedPrice: order.executedPrice,
            OriginalExecutedPrice: order.executedPrice,
            Product: order.product,
            SquaredOffPrice: order.squaredOffPrice,
            InstrumentType: order.instrumentType,
            LotSize: order.lotSize,
            OptionType: order.optionType,
            Strike: order.strike,
            BaseSymbol: this.activeExecutedBasket.instrument,
            Expiry: order.expiry,
          });
      });

      if (parameters.IsPerLegSL == false && parameters.Legs.length === 0) {
        this.showWarning("No active orders to schedule for algo execution.");
        return;
      }

      this.activeExecutedBasket.squaredOffOrders.forEach((order) => {
        parameters.LegsSquaredOff.push({
          OrderId: order.id,
          Symbol: order.symbol,
          TransactionType: order.transactionType,
          Quantity: order.quantity,
          BrokerId: order.brokerId,
          IsSquaredOff: order.isSquaredOff,
          SquaredOffOn: order.squaredOffOn,
          Status: order.status,
          ExecutedPrice: order.executedPrice,
          Product: order.product,
          SquaredOffPrice: order.squaredOffPrice,
          InstrumentType: order.instrumentType,
          LotSize: order.lotSize,
          OptionType: order.optionType,
          Strike: order.strike,
          BaseSymbol: this.activeExecutedBasket.instrument,
          Expiry: order.expiry,
        });
      });

      let scheduleStrategy = {
        MessageType: "algo_schedule_strategy_request",
        Parameters: parameters,
      };

      this.sendMessage(JSON.stringify(scheduleStrategy));
      // this.sendSaveExecutedOrderBasketsCommand();

      this.sendAddRemoveExecutedOrderBasketsCommand({
        id: this.activeExecutedBasket.id,
        action: 3, //Update
        basket: this.activeExecutedBasket
      })

    },
    getBidAskSpreadMessage() {
      let goodSpreadLength = 1.5;

      if (this.activeExecutedBasket.instrument === "banknifty")
        goodSpreadLength = 5;

      let goodOrWarningMessage =
        Math.abs(this.bidAskSpread.spread) > goodSpreadLength
          ? "WARNING -"
          : "Good -";

      let message = `${goodOrWarningMessage} ${this.bidAskSpread.symbol}
Bid: ${this.bidAskSpread.bidPrice}, Ask: ${this.bidAskSpread.askPrice}
Spread: ${this.bidAskSpread.spread}
      `;

      return message;
    },
    formatExpiryDate(value) {
      return moment(value)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    formatToDateTime(value) {
      return moment(value)
        .format("DD-MMM-YYYY HH:mm")
        .toUpperCase();
    },
    refreshGreeksInternal(basketStatus) {
      this.refreshGreeks({
        basketStatus: basketStatus, // executed, editing
      });
    },
    updateBrokerageAndTaxes() {
      this.updateBrokerageAndTaxesForBasket({
        id: this.activeExecutedBasket.id,
        type: "executed",
      });
    },
    isEmpty(obj) {
      return _.isEmpty(obj)
    },
    getStopExpectedLossPerLot() {
      let returnText = "Stop (Expected Loss Per Lot): "

      let expectedLossInPoints = (this.activeExecutedBasket.atOpenCombinedPremium - this.activeExecutedBasket.stop).toFixed(2)
      let expectedLossInValue = (expectedLossInPoints * this.activeExecutedBasket.lotSize).toFixed(2)

      if (this.activeExecutedBasket.stopTargetType == 1)
        returnText += (this.activeExecutedBasket.stop * this.activeExecutedBasket.lotSize).toFixed(2)
      else
        returnText += `${expectedLossInValue} (${expectedLossInPoints})`

      return returnText;
    },
    showHideBasketStopTargetParameters() {

      if (!this.selectedBasketGroup) return;

      this.selectedBasketGroup.showHideBasketGroupHideStopTarget = !this.selectedBasketGroup.showHideBasketGroupHideStopTarget;

      this.sendUpdateBasketGroupProperties({
        BasketGroupId: this.selectedBasketGroup.id,
        UpdateType: 1,
        UpdateParamater: {
          PropertyName: "showHideBasketGroupHideStopTarget",
          PropertyValue: this.selectedBasketGroup.showHideBasketGroupHideStopTarget,
        }
      })
    },
    getPerLegStop(item, transactionType) {

      

    
      let stop = 0.0;
      let executedPrice = item.executedPrice

      if (this.activeExecutedBasket.isShiftToNextOTM) {
        // get the first executed order from the squared off list matching with this symbol & expiry
        var filteredOrders = this.activeExecutedBasket.squaredOffOrders.filter(x => x.symbol == item.symbol && x.expiry == item.expiry)
        if (filteredOrders.length > 0) {
          var sorted = _.sortBy(filteredOrders, a => a.squaredOffOn)
          // console.log(`Sordeed list ${JSON.stringify(sorted)}`)
          executedPrice = sorted[0].executedPrice
        }
      }
      if(transactionType == "sell") {
      stop = (executedPrice + (executedPrice *
      (this.activeExecutedBasket.perLegSLValue / 100)))
      }
      else {
      stop = (executedPrice - (executedPrice *
      (this.activeExecutedBasket.perLegSLValue / 100)))
      }

      return stop;
    },
    getStopExpectedProfitPerLot() {
      let returnText = "Target (Expected Profit Per Lot): "

      let expectedProfitInPoints = (this.activeExecutedBasket.atOpenCombinedPremium - this.activeExecutedBasket.target).toFixed(2)
      let expectedProfitInValue = (expectedProfitInPoints * this.activeExecutedBasket.lotSize).toFixed(2)

      if (this.activeExecutedBasket.stopTargetType == 1)
        returnText += (this.activeExecutedBasket.target * this.activeExecutedBasket.lotSize).toFixed(2)
      else
        returnText += `${expectedProfitInValue} (${expectedProfitInPoints})`

      return returnText
    },
    setEdit(symbol, isForSquaredOffOrder) {
      if (_.isEmpty(this.activeExecutedBasket)) return;

      let order;
      if (!isForSquaredOffOrder)
        order = this.activeExecutedBasket.orders.find(
          (x) => x.symbol === symbol
        );
      else
        order = this.activeExecutedBasket.squaredOffOrders.find(
          (x) => x.symbol === symbol
        );

      if (!order) return;

      order.editing = true;

      if (order.expiry.length > 0)
        order.expiryEdited = this.formatExpiryDate(order.expiry);
    },
    calculatePositionGreeks(type) {
      let greeks = {
        theta: 0,
        vega: 0,
        delta: 0,
        deltaPoints: 0,
        gamma: 0,
      };

      if (_.isEmpty(this.activeExecutedBasket)) return greeks;
      if (!this.activeExecutedBasket.orders) return greeks;
      if (this.activeExecutedBasket.orders.length === 0) return greeks;

      let orders = this.activeExecutedBasket.orders.filter(
        (x) => x.isSquaredOff == false
      );
      if (type === "now") {
        orders.forEach((order) => {
          greeks.theta = greeks.theta + order.greeksAtNow.theta;
          greeks.vega = greeks.vega + order.greeksAtNow.vega;
          greeks.deltaPoints = greeks.deltaPoints + order.greeksAtNow.delta;
          greeks.delta = greeks.delta + order.greeksAtNow.delta;
          greeks.gamma = greeks.gamma + order.greeksAtNow.gamma;
        });
      } else {
        orders.forEach((order) => {
          greeks.theta = greeks.theta + order.greeksAtExecution.theta;
          greeks.vega = greeks.vega + order.greeksAtExecution.vega;
          greeks.deltaPoints =
            greeks.deltaPoints + order.greeksAtExecution.delta;
          greeks.delta = greeks.delta + order.greeksAtExecution.delta;
          greeks.gamma = greeks.gamma + order.greeksAtExecution.gamma;
        });
      }

      return greeks;
    },
    formatWithThousandSeparator(value) {
      if (!isNaN(value) && value > 0) {
        return new Intl.NumberFormat('en-IN', {}).format(value)
      } else if (!isNaN(value) && value < 0) {
        return new Intl.NumberFormat('en-IN', {}).format(value)
      } else {
        return value
      }
    },
    getBreakEvens() {
      let emptyBreakEvenObject = {
        maxLoss: -1,
        maxProfit: 1,
        breakEvens: [],
      };

      if (_.isEmpty(this.activeExecutedBasket)) return emptyBreakEvenObject;
      if (!this.activeExecutedBasket.breakEvens) return emptyBreakEvenObject;

      return this.activeExecutedBasket.breakEvens;
    },
    getUnderlyingChange(format = "points", atClosing = "no") {
      if (_.isEmpty(this.activeExecutedBasket)) return 0;

      let underlyingSpotPrice = 0;
      if (this.activeExecutedBasket.instrument === "nifty") {
        let nifty50Spot = this.instruments["NIFTY 50"];
        underlyingSpotPrice = nifty50Spot ? nifty50Spot.Ltp : 0;
      } else if (this.activeExecutedBasket.instrument === "banknifty") {
        let niftyBankSpot = this.instruments["NIFTY BANK"];
        underlyingSpotPrice = niftyBankSpot ? niftyBankSpot.Ltp : 0;
      } else if (this.activeExecutedBasket.instrument === "sensex") {
        let niftyBankSpot = this.instruments["SENSEX"];
        underlyingSpotPrice = niftyBankSpot ? niftyBankSpot.Ltp : 0;      
      } else if (this.activeExecutedBasket.instrument === "finnifty") {
        let finNiftySpot = this.instruments["FINNIFTY"];
        underlyingSpotPrice = finNiftySpot ? finNiftySpot.Ltp : 0;
      } else if (this.activeExecutedBasket.instrument === "midcpnifty") {
        let midcapNiftySpot = this.instruments["MIDCPNIFTY"];
        underlyingSpotPrice = midcapNiftySpot ? midcapNiftySpot.Ltp : 0;
      } else return 0;

      let underlyingPriceAtOpenOrClosing = 0.0;

      if (atClosing === "no")
        underlyingPriceAtOpenOrClosing = this.activeExecutedBasket
          .underlyingPriceAtOpen;
      else
        underlyingPriceAtOpenOrClosing = this.activeExecutedBasket
          .underlyingPriceAtClose;

      let spotChange = underlyingSpotPrice - underlyingPriceAtOpenOrClosing;

      if (format === "points") return spotChange;
      else return (spotChange / underlyingPriceAtOpenOrClosing) * 100;
    },
    getFuturesChange(format = "points", atClosing = "no") {
      if (_.isEmpty(this.activeExecutedBasket)) return 0;

      let underlyingSpotPrice = 0;
      let futuresLtp = this.instruments[this.futuresSymbol];
      underlyingSpotPrice = futuresLtp ? futuresLtp.Ltp : 0;

      let underlyingPriceAtOpenOrClosing = 0.0;

      if (atClosing === "no")
        underlyingPriceAtOpenOrClosing = this.activeExecutedBasket
          .futuresPriceAtOpen;
      else
        underlyingPriceAtOpenOrClosing = this.activeExecutedBasket
          .futuresPriceAtClose;

      let spotChange = underlyingSpotPrice - underlyingPriceAtOpenOrClosing;

      if (format === "points") return spotChange;
      else return (spotChange / underlyingPriceAtOpenOrClosing) * 100;
    },
    getVIXChange(format = "points", atClosing = "no") {
      if (_.isEmpty(this.activeExecutedBasket)) return 0;
      // if (!this.activeExecutedBasket.vixWhenExecuted) return -1

      let vixSpotPrice = this.instruments["INDIA VIX"] ? this.instruments["INDIA VIX"].Ltp : 0;

      let vixAtOpenOrClosing = 0.0;
      if (atClosing === "no")
        vixAtOpenOrClosing = this.activeExecutedBasket.vixWhenExecuted;
      else vixAtOpenOrClosing = this.activeExecutedBasket.VixAtSquarOff;

      let vixSpotChange = vixSpotPrice - vixAtOpenOrClosing;

      if (format === "points") return vixSpotChange;
      else return (vixSpotChange / vixAtOpenOrClosing) * 100;
    },
    getRequiredMargin() {
      // this.updateMarginRequiredForActiveExecutedBasket(
      //   this.activeExecutedBasket.id
      // );
    },
    saveBaskets() {
      this.sendSaveExecutedOrderBasketsCommand();
    },
    exportBaskets() {
      var csvContent =
        "Strategy,Expiry,Strike,Option Type, Transaction Type, Executed Priced, Quantity, Ltp, Margin Used, P/L, P/L in Points, Premium Credit" +
        "\n";
      this.orderBaskets.forEach((basket) => {
        basket.orders.forEach((order) => {
          csvContent +=
            basket.strategyName +
            "," +
            moment(order.expiry)
              .format("DD-MMM-YYYY")
              .toUpperCase() +
            "," +
            order.strike +
            "," +
            order.optionType +
            "," +
            order.transactionType.toUpperCase() +
            "," +
            order.executedPrice +
            "," +
            order.quantity * order.lotSize +
            "," +
            (this.instruments[order.symbol] ? this.instruments[order.symbol].Ltp : 0) +
            "," +
            basket.marginRequired +
            "," +
            (parseFloat(basket.realizedPL) + parseFloat(basket.totalPnl)) +
            "," +
            (parseFloat(basket.realizedPL) + parseFloat(basket.totalPnl)) /
            parseFloat(order.lotSize) +
            "," +
            basket.setupCost +
            "\n";
        });
      });
      Utils.downloadCSV(csvContent, "dowload.csv", "text/csv;encoding:utf-8");
    },
    // This exports the total open positions so it can be easility tallied with broker book
    exportPositions() {
      var csvContent =
        "Strategy,Expiry,Strike,Option Type, Transaction Type, Executed Priced, Quantity, Ltp, Margin Used, P/L, P/L in Points, Premium Credit" +
        "\n";
      this.orderBaskets.forEach((basket) => {
        basket.orders.forEach((order) => {
          csvContent +=
            basket.strategyName +
            "," +
            moment(order.expiry)
              .format("DD-MMM-YYYY")
              .toUpperCase() +
            "," +
            order.strike +
            "," +
            order.optionType +
            "," +
            order.transactionType.toUpperCase() +
            "," +
            order.executedPrice +
            "," +
            order.quantity * order.lotSize +
            "," +
            (this.instruments[order.symbol] ? this.instruments[order.symbol].Ltp : 0) +
            "," +
            basket.marginRequired +
            "," +
            (parseFloat(basket.realizedPL) + parseFloat(basket.totalPnl)) +
            "," +
            (parseFloat(basket.realizedPL) + parseFloat(basket.totalPnl)) /
            parseFloat(order.lotSize) +
            "," +
            basket.setupCost +
            "\n";
        });
      });
      Utils.downloadCSV(csvContent, "dowload.csv", "text/csv;encoding:utf-8");
    },
    adjustExecutedBasketInternal(strategyId) {
      // Do we have any active basket in the editor
      if (
        !_.isEmpty(this.activeBasketEditing) &&
        this.activeExecutedBasket.instrument != this.activeBasketEditing.instrument
      ) {
        this.showMessageAlert(`Current editor basket instrument (${this.activeBasketEditing.instrument.toUpperCase()}) is different than one being added (${this.activeExecutedBasket.instrument.toUpperCase()}). Can not add to the editor for adjustments.`);
        return
      }
      this.adjustExecutedBasket(strategyId);
    },
    getROI(orderBasket) {
      let basket = {};

      if (orderBasket) basket = orderBasket;
      else {
        if (!this.activeExecutedBasket) return 0;
        basket = this.activeExecutedBasket;
      }

      let taxes = basket.brokerageAndTaxes ? basket.brokerageAndTaxes : 0.0;

      return (
        ((basket.totalPnl + basket.realizedPL) /
          (basket.marginRequired + taxes)) *
        100
      ).toFixed(2);
    },
    saveBasketInternal() {
      if (this.newBasketEditingOptions.strategyName.length === 0) {
        alert("Please enter unique strategy name");
        return;
      }

      var findIndex = this.orderBaskets.findIndex(
        (x) =>
          x.strategyName === this.newBasketEditingOptions.strategyName &&
          x.id != this.activeExecutedBasket.id
      );
      if (findIndex !== -1) {
        alert("Basket with this strategy name is already added");
        return;
      }

      if (!this.newBasketEditingOptions.brokerId || this.newBasketEditingOptions.brokerId.length == 0) {
        alert("Please select broker");
        return;
      }

      this.saveBasket({
        id: this.activeExecutedBasket.id,
        strategyName: this.newBasketEditingOptions.strategyName,
        instrument: this.newBasketEditingOptions.instrument,
        brokerId: this.newBasketEditingOptions.brokerId,
        isTesting: this.newBasketEditingOptions.isTesting,
      });
    },
    addNewExecutedBasketInternal() {
      this.addNewExecutedBasket();
    },
    removeBasketFromExecutedBasketsInternal(id) {

      if (_.isEmpty(this.activeExecutedBasket)) return;

      var basketGroup = this.basketGroups.find(x => parseInt(x.id) === parseInt(this.activeExecutedBasket.groupId))
      if (!_.isEmpty(basketGroup) && basketGroup.stopTarget.enabled) {
        this.showMessageAlert("Basket Group SL based Algo is running. Please disable the algo before removing the basket group or any basket within the group.")
        return;
      }

      this.$simpleAlert.confirm(
        "Are you sure want to remove this basket?",
        "Remove Basket",
        "question"
      ).then(() => {
        this.stopStrategy();
        this.removeBasketFromExecutedBaskets(id);
      })
        .catch(() => {

        })
    },
    moveToSquaredOffOrders(id) {
      let order = this.activeExecutedBasket.orders.find((x) => x.id === id);

      if (!order) return;

      this.$simpleAlert.confirm(
        "Are you sure want to move this position to Squared Off Order book?",
        "Move to Squared Off Orders",
        "warning"
      ).then(() => {
        let index = this.activeExecutedBasket.orders.findIndex(
          (x) => x.id === id
        );
        if (index === -1) return;

        this.activeExecutedBasket.orders.splice(index, 1);

        order.squaredOffPrice = this.instruments[order.symbol] ? this.instruments[order.symbol].Ltp : 0;
        order.isSquaredOff = true;

        let underlyingPrice = 0;

        if (this.activeExecutedBasket.instrument === "nifty")
          underlyingPrice = this.instruments["NIFTY 50"] ? this.instruments["NIFTY 50"].Ltp : 0;
        else if (this.activeExecutedBasket.instrument === "banknifty")
          underlyingPrice = this.instruments["NIFTY BANK"] ? this.instruments["NIFTY BANK"].Ltp : 0;
        else if (this.activeExecutedBasket.instrument === "sensex")
          underlyingPrice = this.instruments["SENSEX"] ? this.instruments["SENSEX"].Ltp : 0;        
        else if (this.activeExecutedBasket.instrument === "finnifty")
          underlyingPrice = this.instruments["FINNIFTY"] ? this.instruments["FINNIFTY"].Ltp : 0;
        else if (this.activeExecutedBasket.instrument === "midcpnifty")
          underlyingPrice = this.instruments["MIDCPNIFTY"] ? this.instruments["MIDCPNIFTY"].Ltp : 0;

        let futuresPriceObj = this.instruments[this.futuresSymbol]
        let futuresPrice = 0

        if (futuresPriceObj)
          futuresPrice = futuresPriceObj.Ltp

        order.underlyingPriceAtClose = underlyingPrice;
        order.futuresPriceAtClose = futuresPrice

        this.activeExecutedBasket.squaredOffOrders.push(order);

        // this.calculateRealizedPnLAndPoints();
      })
        .catch(() => {

        })
    },
    removePosition(id, isForSquaredOffOrder) {
      let order;
      if (!isForSquaredOffOrder)
        order = this.activeExecutedBasket.orders.find((x) => x.id === id);
      else
        order = this.activeExecutedBasket.squaredOffOrders.find(
          (x) => x.id === id
        );

      if (!order) return;

      if (order.status === "new" && !isForSquaredOffOrder) {
        if (
          this.activeExecutedBasket.status == "new" &&
          this.activeExecutedBasket.orders.length === 1
        ) {
          alert(
            "Can not be removed. Please add at lease 1 position to the basket"
          );
          return;
        }

        this.removePositionFromExecutedBasket({
          strategyId: this.activeExecutedBasket.id,
          id: id,
        });
        return;
      }

      this.$simpleAlert.confirm(
        "Are you sure want to remove this position?",
        "Remove Position",
        "warning"
      ).then(() => {
        if (!isForSquaredOffOrder) {
          this.removePositionFromExecutedBasket({
            strategyId: this.activeExecutedBasket.id,
            id: id,
          });
          this.addExecutedBasketToPayoff({
            basket: this.activeExecutedBasket,
            clear: true,
          });
          this.updateActiveExecutedBasketSetupCostAndMultiExpiryFlag();
          // this.getRequiredMargin();
        } else {
          let index = this.activeExecutedBasket.squaredOffOrders.findIndex(
            (x) => x.id === id
          );
          if (index === -1) return;

          this.activeExecutedBasket.squaredOffOrders.splice(index, 1);

          // this.calculateRealizedPnLAndPoints();
        }
      })
        .catch(() => {

        })
    },
    addPosition(isForSquaredOffOrder) {
      // just add a new order row object,
      // let user set the values,
      // once done
      // push it to order basket
      // how to add?
      // Add from option chain and manually add (when position is added from the trading terminal)
      this.addPositionToExecutedBasket({
        strategyId: this.activeExecutedBasket.id,
        isForSquaredOffOrder: isForSquaredOffOrder,
      });
    },
    saveEdits(rowId, isForSquaredOffOrder) {
      let order;
      if (!isForSquaredOffOrder)
        order = this.activeExecutedBasket.orders.find((x) => x.id == rowId);
      else
        order = this.activeExecutedBasket.squaredOffOrders.find(
          (x) => x.id == rowId
        );

      if (!order) return;

      /*
      if (!order.brokerageAndTaxes)
        order.brokerageAndTaxes = 0
*/
      // TODO: Maintain a list of order status updated received from the server for specific positoon.
      // Once clicked display in a dialog alll those messages
      /*
      if (order.status !== 'new') {
        order.editing = false
        this.sendSaveExecutedOrderBasketsCommand()
        return
      }
      */

      if (order.strike === 0) {
        // TODO: maintain a list of uer fiendly and system symbol map
        // so  that user can select symbol directly from the dropdown
        alert("Strike is not set.");
        return;
      }

      if (order.executedPrice === 0) {
        // TODO: maintain a list of uer fiendly and system symbol map
        // so  that user can select symbol directly from the dropdown
        alert("Executed price is not set.");
        return;
      }

      if (order.expiryEdited.length === 0) {
        // TODO: maintain a list of uer fiendly and system symbol map
        // so  that user can select symbol directly from the dropdown
        alert("Expiry is not set.");
        return;
      }

      if (order.statusEditing !== "complete") {
        // TODO: maintain a list of uer fiendly and system symbol map
        // so  that user can select symbol directly from the dropdown
        if (!isForSquaredOffOrder) {
          alert("Status is not set to complete.");
          return;
        }
      }

      if (!isForSquaredOffOrder) {
        if (order.status == "new")
          // order.priceWhenAddedToBasket = this.instruments[order.symbol].Ltp
          order.priceWhenAddedToBasket = order.executedPrice;

        order.expiry = moment(order.expiryEdited, "DD-MMM-YYYY").toDate();

        order.symbol = SymbolService.getSymbol({
          instrument: this.activeExecutedBasket.instrument,
          expiry: order.expiry,
          strike: order.strike,
          optionType: order.optionType,
        });

        let underlyingPrice = 0;

        if (this.activeExecutedBasket.instrument === "nifty")
          underlyingPrice = this.instruments["NIFTY 50"] ? this.instruments["NIFTY 50"].Ltp : 0;
        else if (this.activeExecutedBasket.instrument === "banknifty")
          underlyingPrice = this.instruments["NIFTY BANK"] ? this.instruments["NIFTY BANK"].Ltp : 0;
        else if (this.activeExecutedBasket.instrument === "sensex")
          underlyingPrice = this.instruments["SENSEX"] ? this.instruments["SENSEX"].Ltp : 0;        
        else if (this.activeExecutedBasket.instrument === "finnifty")
          underlyingPrice = this.instruments["FINNIFTY"] ? this.instruments["FINNIFTY"].Ltp : 0;
        else if (this.activeExecutedBasket.instrument === "midcpnifty")
          underlyingPrice = this.instruments["MIDCPNIFTY"] ? this.instruments["MIDCPNIFTY"].Ltp : 0;

        let futuresPriceObj = this.instruments[this.futuresSymbol]
        let futuresPrice = 0

        if (futuresPriceObj)
          futuresPrice = futuresPriceObj.Ltp

        order.underlyingPriceAtOpen = underlyingPrice;
        order.underlyingPriceAtClose = 0;

        order.futuresPriceAtOpen = futuresPrice;
        order.futuresPriceAtClose = 0;

        order.status = order.statusEditing;
        order.editing = false;

        // is this multi expiry
        if (this.activeExecutedBasket.orders.length >= 2) {
          let sortedOrders = this.activeExecutedBasket.orders.sort(
            (a, b) =>
              new Date(a.expiry).getTime() - new Date(b.expiry).getTime()
          );
          if (
            sortedOrders[0].expiry !==
            sortedOrders[sortedOrders.length - 1].expiry
          )
            this.activeExecutedBasket.isMultiExpiry = true;
          else this.activeExecutedBasket.isMultiExpiry = false;
        }

        // TODO Calculate
        let savedBasket = this.orderBaskets.find(
          (x) => parseInt(x.id) === parseInt(this.activeExecutedBasket.id)
        );
        if (!savedBasket.brokerageAndTaxes) {
          savedBasket.brokerageAndTaxes = 0;
        }

        savedBasket.orders.forEach(o => {
          if (!o.brokerageAndTaxes) o.brokerageAndTaxes = 0;
        });

        // Few more thisn we need to do here
        // calculate the greeks
        // calculate the position greeks
        // calculate the break evens

        // this.getRequiredMargin();
      } else {
        order.status = "complete";
        order.editing = false;
      }

      this.updateActiveExecutedBasketSetupCostAndMultiExpiryFlag();
      this.subscribeToLiveFeed([{
        Exchange: order.exchange,
        Symbol: order.symbol         
      }]);

      // this.calculateRealizedPnLAndPoints();

      // TODO Calculate the greeks
      // let greeks = SymbolService.getGreeks(ltp, underlyingPrice, order.strike, order.expiry, state.riskFreeInterest, order.optionType, order.transactionType)
      // await this.addExecutedBasketToPayoff({basket:this.activeExecutedBasket, clear:true})
      // this.sendSaveExecutedOrderBasketsCommand();

      this.sendAddRemoveExecutedOrderBasketsCommand({
        id: this.activeExecutedBasket.id,
        action: 3, //Update
        basket: this.activeExecutedBasket
      })
    },
    generateRandomStrategyName() {
      this.newBasketEditingOptions.strategyName = Utils.generateRandomStrategyName();
    },
    isObjectEmpty(obj) {
      return _.isEmpty(obj);
    },
    getLotSize(instrument) {
      return SymbolService.getMarketLot(instrument);
    },
    getBasketCountInTheGroup(groupId) {
      if (_.isEmpty(this.orderBaskets)) return 0

      if (parseInt(groupId) === 10)
        return this.orderBaskets.length
      else
        return this.orderBaskets.reduce((a, c) => parseInt(c.groupId) === parseInt(groupId) ? ++a : a, 0);
    },
    setBasketGroupInEditMode() {
      if (this.isDefaultBasket()) return;

      this.activeBasketGroup.editing = true;
    },
    addNewBasketGroupInternal() {
      this.addNewBasketGroup()
    },
    isDefaultBasket() {
      let returnValue = true;

      if (_.isEmpty(this.activeBasketGroup)) return returnValue;

      let groupId = parseInt(this.activeBasketGroup.id)

      if (groupId !== 0 && parseInt(groupId) <= 500) {
        returnValue = true;
      } else {
        returnValue = false;
      }

      return returnValue;
    },
    exitBasketGroupsInternal(exitType) {
      let message = "Are you sure want to exit Basket Groups?"
      let title = "Exit Basket Groups?"

      this.$simpleAlert.confirm(
        message,
        title,
        "question"
      ).then(() => {
        let payload = {}
        if (exitType == 'all') {
          payload.exitType = 1
        } else {
          if (!this.activeBasketGroup || _.isEmpty(this.activeBasketGroup)) {
            this.showWarning("Please select a basket group to exit.")
            return;
          }
          payload.exitType = 2
          payload.groupId = this.activeBasketGroup.id
        }
        this.exitBasketGroups(payload)
      })
        .catch(() => {

        })
    },
    removeAllBasketGroupsInternal() {
      let message = "[WARNING] Are you sure want to remove all Basket Groups and Baskets?"
      let title = "[WARNING] Remove All Basket Groups and Order Baskets"

      this.$simpleAlert.confirm(
        message,
        title,
        "question"
      ).then(() => {
        this.removeAllBasketGroups()
      })
        .catch(() => {

        })
    },

    removeBasketGroupInternal(removeBaskets) {
      if (_.isEmpty(this.activeBasketGroup)) return;

      if (this.activeBasketGroup.stopTarget.enabled) {
        this.showMessageAlert("Basket Group SL based Algo is running. Please disable the algo before removing the basket group or any basket within the group.")
        return
      }

      let groupId = parseInt(this.activeBasketGroup.id)

      if (this.isDefaultBasket()) {
        this.showMessageAlert("Default basket groups can not be removed.");
        return
      }

      let message = "Are you sure want to remove this group?";
      let title = "Remove Basket Group"
      if (removeBaskets) {
        message = "[WARNING] Are you sure want to remove this Basket Group and all Baskets within this group?"
        title = "[WARNING] Remove Basket Group and Order Baskets"
      }

      this.$simpleAlert.confirm(
        message,
        title,
        "question"
      ).then(() => {
        this.removeBasketGroup({
          groupId: groupId,
          removeBaskets: removeBaskets
        })
      })
        .catch(() => {

        })
    },
    selectBasketGroup(basketGroup) {
      if (!_.isEmpty(this.activeBasketGroup) && basketGroup.id === this.activeBasketGroup.id) return

      this.basketGroupId = basketGroup.id
      // create a compueted property of orderBaskets
      this.setActiveBasketGroup(basketGroup)
    },
    setBasketGroupInternal() {
      this.setBasketGroup({
        basketId: this.activeExecutedBasket.id,
        groupId: this.activeExecutedBasket.groupId
      })
    },
    selectBasket(basket) {
      this.strategyId = basket.id

      if (basket.id === this.activeExecutedBasket.id) return;

      if (basket.status == "new") {
        this.newBasketEditingOptions.strategyName = basket.strategyName;
        this.newBasketEditingOptions.brokerId = basket.brokerId;
        this.newBasketEditingOptions.instrument = basket.instrument;
        this.newBasketEditingOptions.isTesting = basket.isTesting;
      }

      // this.setActiveExecutedBasket(basket.strategyName).then(()=> this.requestPositionsTechnicalAnalysisData());
      this.setActiveExecutedBasket(basket.id);
    },
    updateInstrumentPrice() {
      if (this.instruments[this.priceUpdateWhichSymbol])
        this.instruments[this.priceUpdateWhichSymbol].Ltp = parseFloat(this.priceUpdateWhatPrice);
    },

    showWarning(message) {
      this.$simpleAlert.fire({
        title: "Warning",
        text: message,
        type: "warning",
        timer: 3000,
      });
    },

    getBrokerName(brokerId) {     
      var broker = this.brokers.find(x => x.id == brokerId)
      return broker ? broker.name : "NA";
    },

    executeNewOrderInternal() {
      // Add new order to executed basket, with all default aparameters & pending for execution state
      // send the execution command
      // wait for the response, rest existing system will work
      // Update brokerage and taxes
      // update payoff graph
      // Send a flag, so that order basket is also saved at the server end

      if (this.newOrderToBeExecuted.instrument !== this.activeExecutedBasket.instrument) {
        this.showMessageAlert(`Please select valid instrument to execute.</br> You are executing ${this.newOrderToBeExecuted.instrument} to ${this.activeExecutedBasket.instrument} basket.`);
        return;
      }

      this.$simpleAlert.prompt(
        this.newOrderToBeExecuted.symbol +
        " : Please enter the quantity (in lots).",
        "1",
        "Place " +
        this.newOrderToBeExecuted.transactionType.toUpperCase() +
        " Order for basket: " +
        this.activeExecutedBasket.strategyName
      ).then((qty) => {
        let product = "d";
        if (this.activeExecutedBasket.orders.length > 0)
          product = this.activeExecutedBasket.orders[0].product;
        else if (this.activeExecutedBasket.squaredOffOrders.length > 0)
          product = this.activeExecutedBasket.squaredOffOrders[0].product;

        this.newOrderToBeExecuted.brokerId = this.activeExecutedBasket.brokerId;
        this.newOrderToBeExecuted.product = product;
        this.newOrderToBeExecuted.quantity = qty;

        this.executeNewOrder(this.newOrderToBeExecuted);
      })
        .catch(() => {

        })
    },

    squareOff() {
      // let basket = this.orderBaskets.find(
      //   (x) => x.strategyName === strategyName
      // );
      // if (!basket) {
      //   this.showMessageAlert(`Strategy [${strategyName}] not found.`);
      //   return;
      // }

      if (this.activeExecutedBasket.isSquaredOff) return;

      // Do not square off if
      //  1- order is pending or rejected
      // place ! - exclamation in nav tab to show that not all order is executed
      // if all items are executed, display, check button in green
      // show total pl in tab header or seperate rows with strategy name and PL

      let filteredOrders = [];

      // if (mode === "auto")
      //   filteredOrders = basket.orders.filter(
      //     (order) => order.status === "complete" && order.isSquaredOff == false
      //   );
      // else {
      filteredOrders = this.activeExecutedBasket.orders.filter(
        (order) =>
          order.checked &&
          order.status === "complete" &&
          order.isSquaredOff == false
      );

      if (filteredOrders.length == 0) {
        this.showWarning("No active/selected positions to square off.");
        return;
      }
      // }

      filteredOrders.forEach((x) => {
        x.squareOffInitiated = true;
      });

      // show confirmation only if mode is manual

      if (this.activeExecutedBasket.squareOffMode === "manual") {
        this.$simpleAlert.confirm(
          "Are you sure to Square off strategy '" +
          this.activeExecutedBasket.strategyName +
          "'?",
          "Square Off",
          "question"
        ).then(() => {
          let orders = filteredOrders.map((order) => {
            return {
              Symbol: order.symbol,
              TransactionType:
                order.transactionType === "buy"
                  ? TransactionType.SELL
                  : TransactionType.BUY, // Reverse the transaction type
              Quantity: order.quantity * order.lotSize,
              BrokerId: order.brokerId,
              Product: order.product,
              Tag: this.activeExecutedBasket.id,
              StrategyId: this.activeExecutedBasket.id,
              InstrumentType: order.instrumentType,
              OptionType: order.optionType,
              Strike: order.strike,
              BaseSymbol: this.activeExecutedBasket.instrument,
            };
          });

          let executeOrderBasketCommand = {
            MessageType: "execute_basket_order_command",
            Orders: orders,
            BasketId: this.activeExecutedBasket.id,
            IsTesting: this.activeExecutedBasket.isTesting
          };

          this.sendMessage(JSON.stringify(executeOrderBasketCommand));

          if (this.activeExecutedBasket.isTesting) {
            let underlyingPrice = 0.0;
            if (this.activeExecutedBasket.instrument === "nifty")
              underlyingPrice = this.instruments["NIFTY 50"] ? this.instruments["NIFTY 50"].Ltp : 0;
            else if (this.activeExecutedBasket.instrument === "banknifty")
              underlyingPrice = this.instruments["NIFTY BANK"] ? this.instruments["NIFTY BANK"].Ltp : 0;
            else if (this.activeExecutedBasket.instrument === "sensex")
              underlyingPrice = this.instruments["SENSEX"] ? this.instruments["SENSEX"].Ltp : 0;            
            else if (this.activeExecutedBasket.instrument === "finnifty")
              underlyingPrice = this.instruments["FINNIFTY"] ? this.instruments["FINNIFTY"].Ltp : 0;
            else if (this.activeExecutedBasket.instrument === "midcpnifty")
              underlyingPrice = this.instruments["MIDCPNIFTY"] ? this.instruments["MIDCPNIFTY"].Ltp : 0;

            let futuresPriceObj = this.instruments[this.futuresSymbol]
            let futuresPrice = 0

            if (futuresPriceObj)
              futuresPrice = futuresPriceObj.Ltp

            filteredOrders.forEach((order) => {
              order.isSquaredOff = true;
              order.squaredOffPrice = this.instruments[order.symbol] ? this.instruments[order.symbol].Ltp : 0;
              order.squareOffInitiated = true;
              order.underlyingPriceAtClose = underlyingPrice;
              order.futuresPriceAtClose = futuresPrice

              if (order.transactionType === "sell") {
                order.plPoints = order.executedPrice - order.squaredOffPrice;
              } else {
                order.plPoints = order.squaredOffPrice - order.executedPrice;
              }
              order.realizedPL =
                order.plPoints * order.lotSize * order.quantity;
              order.pl = order.realizedPL;

              // Add to squared off orders list
              this.activeExecutedBasket.squaredOffOrders.push(order);

              let index = this.activeExecutedBasket.orders.findIndex(
                (x) => x.id === order.id
              );
              if (index !== -1) {
                this.activeExecutedBasket.orders.splice(index, 1);
              }
            });

            console.log(`squareOff: Sending send AddRemoveExecutedOrderBasketsCommand`)
            this.sendAddRemoveExecutedOrderBasketsCommand({
              id: this.activeExecutedBasket.id,
              action: 3, //Update
              basket: this.activeExecutedBasket
            })
          } else {
            // this.sendMessage(JSON.stringify(executeOrderBasketCommand));
          }
        })
          .catch(() => {

          })
      }

      // Square off mode is not applicable
      /*
        else {
        let orders = filteredOrders.map(order => {
            return {
              Symbol: order.symbol,
              TransactionType: order.transactionType === 'buy' ? TransactionType.SELL : TransactionType.BUY, // Reverse the transaction type
              Quantity: order.quantity * order.lotSize,
              Broker: order.broker,
              Product: order.product,
              Tag: order.strategyName
            };
        })

        let executeOrderBasketCommand = {
          MessageType: 'execute_basket_order_command',
          Orders: orders
        }

        if (basket.isTesting)
          console.log('Testing')
        else
          this.sendMessage(JSON.stringify(executeOrderBasketCommand))
      }*/
    },
    addRemoveToMultiChartViewInternalForBasketGroup(action) {

      // get all orderBaskets for the selected group...
      // and process as usual

      let baskets = this.orderBaskets.filter(x => parseInt(x.groupId) == parseInt(this.activeBasketGroup.id))

      if (baskets == null) {
        this.showMessageAlert("No baskets to add to the chart.");
        return;
      }


      let filteredOrders = [];

      for (let i = 0; i < baskets.length; i++) {
        let basket = baskets[i]
        if (basket.isSquaredOff == false) {
          filteredOrders = [...filteredOrders, ...basket.orders.filter(
            (order) => order.status === "complete" && order.isSquaredOff == false && order.isSelectedForAnalysisChart == true
          )];
        } else {
          filteredOrders = [...filteredOrders, ...basket.squaredOffOrders.filter(
            (order) => order.status === "complete" && order.isSquaredOff == true && order.isSelectedForAnalysisChart == true
          )];
        }
      }


      let orders = filteredOrders.map((order) => {
        return {
          Symbol: order.symbol,
          TransactionType:
            order.transactionType === "buy"
              ? TransactionType.BUY
              : TransactionType.SELL,
          Quantity: order.quantity,
          BrokerId: order.brokerId,
          LotSize: order.lotSize,
          Product: order.product,
          Tag: this.activeBasketGroup.id,
          InstrumentType: order.instrumentType,
          OptionType: order.optionType,
          Strike: order.strike,
          Expiry: order.expiry,
          BaseSymbol: order.instrument,
        };
      });

      if (orders.length == 0) {
        // this.showMessageAlert("No orders selected to add to the multi chart view.");
        return;
      }

      this.addRemoveToMultChartView({
        id: this.activeBasketGroup.id,
        strategyName: this.activeBasketGroup.name,
        orders: orders,
        ohlc: [],
        volume: [],
        min: 0,
        max: 0,
        showHideStopTarget: false,
        stop: 0,
        taregt: 0,
        executed: 0,
        chartTitle: '',
        action: action,
        isLoading: false,
        chartInterval: "1",
        chartType: 'spline',
      })
    },
    addRemoveToMultiChartViewInternal(action) {
      let filteredOrders = [];

      if (this.activeExecutedBasket.isSquaredOff == false) {
        filteredOrders = this.activeExecutedBasket.orders.filter(
          (order) => order.status === "complete" && order.isSquaredOff == false && order.isSelectedForAnalysisChart == true
        );
      } else {
        filteredOrders = this.activeExecutedBasket.squaredOffOrders.filter(
          (order) => order.status === "complete" && order.isSquaredOff == true && order.isSelectedForAnalysisChart == true
        );
      }

      let orders = filteredOrders.map((order) => {
        return {
          Symbol: order.symbol,
          TransactionType:
            order.transactionType === "buy"
              ? TransactionType.BUY
              : TransactionType.SELL,
          Quantity: order.quantity,
          BrokerId: this.activeExecutedBasket.brokerId,
          LotSize: order.lotSize,
          Product: this.activeExecutedBasket.product,
          Tag: this.activeExecutedBasket.id,
          InstrumentType: order.instrumentType,
          OptionType: order.optionType,
          Strike: order.strike,
          Expiry: order.expiry,
          BaseSymbol: this.activeExecutedBasket.instrument,
        };
      });

      if (orders.length == 0) {
        // this.showMessageAlert("No orders selected to add to the multi chart view.");
        return;
      }

      this.addRemoveToMultChartView({
        id: this.activeExecutedBasket.id,
        strategyName: this.activeExecutedBasket.strategyName,
        orders: orders,
        ohlc: [],
        volume: [],
        min: 0,
        max: 0,
        showHideStopTarget: false,
        stop: 0,
        taregt: 0,
        executed: 0,
        chartTitle: '',
        action: action,
        isLoading: false,
        chartInterval: this.activeExecutedBasket.chartInterval,
        chartType: this.activeExecutedBasket.chartType,
      })
    },
    watchForVWAPCrossoverSignals(action) {

      let filteredOrders = [];

      if (this.activeExecutedBasket.isSquaredOff == false) {
        filteredOrders = this.activeExecutedBasket.orders.filter(
          (order) => order.status === "complete" && order.isSquaredOff == false && order.isSelectedForAnalysisChart == true
        );
      } else {
        filteredOrders = this.activeExecutedBasket.squaredOffOrders.filter(
          (order) => order.status === "complete" && order.isSquaredOff == true && order.isSelectedForAnalysisChart == true
        );
      }

      let orders = filteredOrders.map((order) => {
        return {
          Symbol: order.symbol,
          TransactionType: order.transactionType,
          Quantity: order.quantity,
          Broker: this.selectedBroker,
          LotSize: order.lotSize,
          Product: this.selectedProduct,
          Tag: this.activeExecutedBasket.id,
          StrategyId: this.activeExecutedBasket.id,
          InstrumentType: order.instrumentType,
          OptionType: order.optionType,
          Strike: order.strike,
          Expiry: order.expiry,
          BaseSymbol: this.activeExecutedBasket.instrument,
        };
      });

      if (orders.length == 0) {
        // this.showMessageAlert("No orders selected to add to the notification.");
        return;
      }

      // First send to remove the notifications
      var requestChartData = {
        MessageType: "watch_for_vwap_crossover_signals_request",
        Orders: [],
        StrategyId: this.activeExecutedBasket.id,
        StrategyName: this.activeExecutedBasket.strategyName,
        Action: 2 // first remove
      };

      // then add
      this.sendMessage(JSON.stringify(requestChartData)).then(() => {
        var requestChartData = {
          MessageType: "watch_for_vwap_crossover_signals_request",
          Orders: orders,
          StrategyId: this.activeExecutedBasket.id,
          StrategyName: this.activeExecutedBasket.strategyName,
          Action: action
        };

        this.sendMessage(JSON.stringify(requestChartData));
      });
    },
    requestPositionsTechnicalAnalysisData() {
      if (!this.activeExecutedBasket.showHideTechnicalAnalysisPanel) return;

      // if (this.$refs.technicalAnalysisChart)
      //   this.$refs.technicalAnalysisChart.showLoading();

      let filteredOrders = [];

      if (this.activeExecutedBasket.isSquaredOff == false) {
        filteredOrders = this.activeExecutedBasket.orders.filter(
          (order) => order.status === "complete" && order.isSquaredOff == false && order.isSelectedForAnalysisChart
        );
      } else {
        filteredOrders = this.activeExecutedBasket.squaredOffOrders.filter(
          (order) => order.status === "complete" && order.isSquaredOff == true && order.isSelectedForAnalysisChart
        );
      }

      let orders = filteredOrders.map((order) => {
        return {
          Symbol: order.symbol,
          TransactionType:
            order.transactionType === "buy"
              ? TransactionType.BUY
              : TransactionType.SELL,
          Quantity: order.quantity,
          Broker: this.selectedBroker,
          LotSize: order.lotSize,
          Product: this.selectedProduct,
          Tag: this.activeExecutedBasket.id,
          StrategyId: this.activeExecutedBasket.id,
          InstrumentType: order.instrumentType,
          OptionType: order.optionType,
          Strike: order.strike,
          Expiry: order.expiry,
          BaseSymbol: this.activeExecutedBasket.instrument,
        };
      });

      if (orders.length == 0) {
        // this.showMessageAlert("No orders selected to display on the chart.");
        this.$refs.technicalAnalysisChart.hideLoading();
        return;
      }

      // Send positions data too server
      // symbol, option type, transaction type, expiry, strike

      var requestChartData = {
        MessageType: "positions_technical_analysis_chart_data_request",
        TrackingId: this.trackingId,
        Orders: orders,
        Interval: this.activeExecutedBasket.chartInterval,
        ShouldConsiderStopTargetForYAxisRange: this.activeExecutedBasket.showHideAlgoPanel,
        Stop: this.activeExecutedBasket.stop,
        Target: this.activeExecutedBasket.target,
        AtOpenCombinedPremium: this.activeExecutedBasket.atOpenCombinedPremium,
        StrategyId: this.activeExecutedBasket.id,
        StrategyType: 1, // 1 - basket, 2 - basket group
        StopTargetType: this.activeExecutedBasket.stopTargetType // 1 - points, 2 - premium
      };

      if (this.showHideOptionsSimulator) {
        requestChartData.IsSimulationHistoryRequest = true
        requestChartData.HistoryForDate = moment(this.optionSimulatorParams.onDate).format('DD-MMM-YYYY HH:mm')
        requestChartData.HistoryFrom = moment(this.optionSimulatorParams.onDate).set({ hour: 9, minute: 15 }).format('DD-MMM-YYYY HH:mm')
        requestChartData.HistoryTo = moment(this.optionSimulatorParams.selectedTimeInUnix).format('DD-MMM-YYYY HH:mm')
      }

      this.sendMessage(JSON.stringify(requestChartData));
    },
  },
  created() {
    this.newBasketEditingOptions.brokers = Utils.getBrokers();
  },
  mounted() {
    this.timerTechnicalAnalysisChartUpdate = setInterval(() => {
      if (!_.isEmpty(this.activeExecutedBasket))
        this.requestPositionsTechnicalAnalysisData()
    }, 90000);

    // setInterval(() => {
    //   if (!_.isEmpty(this.activeExecutedBasket) && this.showHideChartsInOptionChain)
    //     this.addExecutedBasketToPayoff({
    //       basket: this.activeExecutedBasket,
    //       clear: true,
    //     })

    // }, 300000);
  },
  beforeUnmount() {
    if (this.timerTechnicalAnalysisChartUpdate != null)
      clearInterval(this.timerTechnicalAnalysisChartUpdate)
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .nav-tabs {
  border: 0;
}

.vue-tabs .nav-tabs {
  border-bottom: 0px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: lightyellow;
  border-color: gray;
}

.nav-tabs .nav-link.test-tab-active {
  background-color: #E1FEF8;
  border-color: gray;
} */

.p-no-margin {
  margin: 0;
}

.noBorderTable table,
tr,
td {
  border: none !important;
}

.tab-title {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /*width: 850px;
  height: 50px; */
  display: flex;
  /*CSS3*/
  align-items: center;
  /*Vertical align*/
  justify-content: center;
  /*horizontal align*/
  /*border: 1px solid lightgray;*/
}

.basket-group-tab-title {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /*width: 850px;
  height: 50px; */
  display: flex;
  /*CSS3*/
  align-items: center;
  /*Vertical align*/
  justify-content: center;
  /*horizontal align*/
  border: 1px solid lightgray;
}

/* .tab-title:hover {
  border: 0px solid #000;
  background-color: lightyellow;
}

.basket-group-tab-title:hover {
  border: 0px solid #000;
  background-color: rgb(247, 239, 239);
} */

/* .basket-test-tab {
  background-color: #E1FEF8;
} */
</style>
