<template>
  <div class="thin-border" style="padding:20px;margin-top:20px;margin-bottom:20px;margin-right:5px;">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Id</th>
          <th scope="col">Name</th>
          <th scope="col">Instrument</th>
          <th scope="col">SL Type</th>
          <th scope="col">Stop</th>

          <th scope="col">Target</th>
          <th scope="col">Trail</th>
          <th scope="col">Per Leg SL</th>
          <th scope="col">Per Leg SL Type</th> 
          <th scope="col">Per Leg SL Value</th>

          <th scope="col">Delta Balance</th>
          <th scope="col">Re Entry</th>

          <th scope="col">Futures Based SL</th>
          <th scope="col">Fut SL Condition</th> 
          <th scope="col">Fut SL Value</th>        
          <th scope="col">Fut Target Condition</th> 
          <th scope="col">Fut Target Value</th>
          <th scope="col">Default</th>
          <th scope="col">Schdule on Execution</th>
          <th scope="col">Action</th>            
        </tr>
      </thead>
      <tbody>
        <!-- eslint-disable-next-line vue/no-v-for-template-key -->
        <template v-for="profile in riskManagementProfiles" v-bind:key="profile.Id">
          <tr>
            <th scope="row">{{profile.Id}}</th>
            <td>{{profile.Name}}</td>
            <td>{{profile.Instrument}}</td>
            <td>{{profile.StopTargetType == 1 ? 'Points' : 'Premium' }}</td>
            <td>{{profile.Stop}}</td>
            <td>{{profile.Target}}</td>
            <td>{{profile.Trail}}</td>
            <td>{{profile.IsPerLegSL}}</td>
            <td>{{profile.PerLegSLType == 1 ? 'Percent' : 'Fixed'}}</td>
            <td>{{profile.PerLegSLValue}}</td>
            <td>{{profile.UseDeltaBalanceAdjustment}}</td>
            <td>{{profile.UseReEntryAdjustment}}</td>
            <td>{{profile.FuturesBasedSLTarget}}</td>
            <td>{{profile.FuturesSLCondition}}</td>
            <td>{{profile.FuturesSLValue}}</td>
            <td>{{profile.FuturesTargetCondition}}</td>
            <td>{{profile.FuturesTargetValue}}</td>
            <td>{{profile.IsDefault}}</td>
            <td>{{profile.ScheduleOnExecution}}</td>          
            <td>

              <a
                href="javascript:void(0)"
                @click="editProfile(profile)"
                title="Edit Profile"
              >
                <span class="material-icons">
                  edit
                </span>
              </a>
              <a
                href="javascript:void(0)"
                @click="deleteProfile(profile)"
                title="Delete Profile"
              >
                <span class="material-icons">
                  delete
                </span>
              </a>

              <a
                href="javascript:void(0)"
                @click="addNewProfile()"
                title="Add New Profile"
              >
                <span class="material-icons md-36">
                  playlist_add
                </span>
              </a>
            </td>                   
          </tr>
          <tr v-bind:key="profile.Id + profile.Name" v-if="profile.Editing">
            <td colspan="20">
              <RiskManagementProfileEditor  :profile="profile" :action="profile.Action" v-on:formClosed="addNewFormClosed(profile)"/>
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    <RiskManagementProfileEditor :profile="getNewProfile()" v-on:formClosed="addNewFormClosed()" :action="1" v-if="addNewProfileFlag" />  
    <button class="btn btn-primary" v-on:click.prevent="addNewProfile()" v-if="!addNewProfileFlag">Add New Profile</button>     
  </div>
</template>

<script>
/* eslint-disable */
import RiskManagementProfileEditor from "@/components/RiskManagementProfileEditor";
import { mapState, mapActions /*, mapMutations */ } from "vuex";

export default {
  name: 'RiskManagementProfile',
  components: {
    RiskManagementProfileEditor
  },
  data() {
    return {
      addNewProfileFlag: false
    };
  },
  computed: {
    ...mapState({
      riskManagementProfiles: state => state.riskManagementProfiles,
    }),
    property() {
      return ''
    },
  },
  watch: {
    watchTest: function () {
    },
  },
  methods: {
    ...mapActions([
      "sendMessage",
    ]),
    addNewFormClosed (profile) {
      console.log("Add new form close called")
      this.addNewProfileFlag = false
      if (profile)
        profile.Editing = false
    },
    editProfile(profile) {
      profile.Editing = true
      profile.Action = 2      
    },
    getNewProfile () {
      return {
        Id: 1,
        Name: '',
        Instrument: '',
        StopTargetType: 1,
        Stop: 0,
        Target: 0,
        Trail: 0,
        IsPerLegSL: false,
        IsStayOTM: false,
        ShiftStrikes: false,
        PerLegSLType: 1,
        PerLegSLValue: 0,
        UseDeltaBalanceAdjustment: false,
        UseReEntryAdjustment: false,
        FuturesBasedSLTarget: false,
        FuturesSLCondition: '<=',
        FuturesSLValue: 0,
        FuturesTargetCondition: '>=',
        FuturesTargetValue: 0,
        Editing: false,
        IsDefault: false,
        ScheduleOnExecution: false
      }
    },
    deleteProfile(profile) {
      this.$simpleAlert.confirm('Do you want to delete this profile?',  'Delete Risk Profile', 'question')
          .then(() => {
            profile.Editing = false
            let deleteProfile = {
              MessageType: "update_risk_management_profile_request",
              Action: 3,
              Profile: profile
            };

            this.sendMessage(JSON.stringify(deleteProfile));                     
          })
          .catch(() => {
          
          })             
    },
    addNewProfile() {
      this.addNewProfileFlag = true
    },
  },
  mounted () {
    // get all the risk profiles
    let requestProfiles = {
      MessageType: "risk_management_profile_request",
    };

    this.sendMessage(JSON.stringify(requestProfiles));    
  }
}
</script>

<style scoped>
.thin-border {
  border: 1px solid gray;
}
</style>
