export const TransactionType = Object.freeze({
  BUY: 1,
  SELL: 2
})

export const Broker = Object.freeze({
  AliceBlue: 1,
  SAS: 8,
  Zerodha: 4,
  Dhan: 9
})

var brokerMap = {}
brokerMap[1] = 'AliceBlue';
brokerMap[8] = 'SAS';
// brokerMap[3] = 'SASWebApi';
brokerMap[4] = 'Zerodha';
brokerMap[9] = "Dhan";
// Per executed order basis, not considered basket order etc
// once a trade is executed 
var brokerageMap = {}
brokerageMap[1] = 15  //'AliceBlue';
brokerageMap[8] = 9  // 'SASOnline';
brokerageMap[4] = 20  //'Zerodha';
brokerageMap[3] = 9
brokerageMap[9] = 20 

export const BrokerMap = brokerMap
export const BrokerageMap = brokerageMap

