<template>
  <div>
    <div class="row">
      <div v-if="showHideOptionChain === true" class="col-sm-3" style="padding-right:2px;padding-left:10px;">
        <!-- <keep-alive> -->
        <component v-if="isBrokerConnected || executionMode == 'SIMULATION' || primaryFeedSourceId.length > 0" v-bind:is="currentOptionChain">
          <loading
          v-model:active="isLoadingOptionChain"
          :can-cancel="false"
          :is-full-page="false"
        />
        </component>

        <div v-if="isDataLoading" class="m-5 text-center">
          <h4>Loading option chain...</h4>
        </div>
        
        <div v-else>
          <div v-if="!(isBrokerConnected == true ||  (primaryFeedSourceId && primaryFeedSourceId.length > 0))" class="align-content-center">
            <Brokers :compact="true" class="p-2 border border-1" title="Setup Brokers"/>           
          </div>
        </div>
        <!-- </keep-alive> -->
      </div>
      <div v-bind:class="showHideOptionChainClass" style="padding-left: 0px;">
        <div>
          <!-- <MenuBar></MenuBar> -->
          <!-- <iframe  src="https://in.tradingview.com/" width="100%" height="900px" origin-when-cross-origin></iframe>    -->
          <OrderBasketEditor v-show="showHideBasketEditor"></OrderBasketEditor>
          <!-- <OptionsSimulatorEx v-show="showHideOptionsSimulator"></OptionsSimulatorEx> -->
          <ExecutedOrderBaskets v-show="showHideExecutedOrderBaskets" :orderBaskets="orderBaskets"></ExecutedOrderBaskets>
          <!-- <OIChangeAnalysis v-show="showHideOIChangeAnalysis"></OIChangeAnalysis> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import axios from 'axios';
import { mapState, mapActions } from 'vuex'
import ExecutedOrderBaskets from '../components/ExecutedOrderBaskets'
import OptionChainNifty from '@/components/OptionChainNifty'
import OptionChainBankNifty from '@/components/OptionChainBankNifty'
import OptionChainSensex from '@/components/OptionChainSensex'
import OptionChainFinNifty from '@/components/OptionChainFinNifty'
import OptionChainMidcapNifty from '@/components/OptionChainMidcapNifty'
// import MenuBar from '@/components/MenuBarEx'
import OrderBasketEditor from '@/components/OrderBasketEditor'
import Brokers from '@/components/BrokerManagement/Brokers.vue'
// import OptionsSimulator from '@/components/OptionsSimulator'
// import OptionsSimulatorEx from '@/components/OptionsSimulatorEx'
// import OIChangeAnalysis from '@/components/OIChangeAnalysis'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
  name: 'Dashboard',
  components: {
    ExecutedOrderBaskets,
    'OptionChain-nifty': OptionChainNifty,
    'OptionChain-banknifty': OptionChainBankNifty,
    'OptionChain-sensex': OptionChainSensex,    
    'OptionChain-finnifty': OptionChainFinNifty,
    'OptionChain-midcpnifty': OptionChainMidcapNifty,
    Brokers,
    // MenuBar,
    OrderBasketEditor,
    // OptionsSimulatorEx,
    // OIChangeAnalysis,
    Loading
  },
  data() {
    return {
      isDataLoading: false,
    }
  },
  computed: {
    ...mapState({
      showHideExecutedOrderBaskets: state => state.showHideExecutedOrderBaskets,
      marketStatus: state => state.marketStatus,
      isBrokerConnected: state => state.isBrokerConnected,
      showHideBasketEditor: state => state.showHideBasketEditor,
      executionMode: state => state.executionMode,
      // showHideOptionsSimulator: state => state.showHideOptionsSimulator,
      showHideOIChangeAnalysis: state => state.showHideOIChangeAnalysis,
      orderBaskets: state => state.orderBaskets,
      orderBasketsEditing: state => state.orderBasketsEditing,
      activeOptionChain: state => state.activeOptionChain,
      isLoadingOptionChain: state => state.isLoadingOptionChain,
      isLoadingExecutedOrderBasketsFromServer: state => state.isLoadingExecutedOrderBasketsFromServer,
      showHideOptionChain: state => state.showHideOptionChain,
      primaryFeedSourceId: state => state.primaryFeedSourceId
    }),
    currentOptionChain() {
      return 'OptionChain-' + this.activeOptionChain
    },
    showHideOptionChainClass() {
      return {
        'col-sm-9': this.showHideOptionChain,
        'col-sm-12': !this.showHideOptionChain,
      }
    }
  },
  methods: {
    ...mapActions([
      'requestOrderBasketsFromServer',
      'brokerConnectionStatusRequest',
      'getAvailableMargin',
      'getGlobalSettings',
      'getOptionChainExpiries',
      'getExpiries',
      'getInstrumentInfos',
      'setFeedSourceId',
    ]), 
  },
  async mounted() {
    this.isDataLoading = true
    setTimeout(async () => {
      

      await this.getGlobalSettings();
      await this.brokerConnectionStatusRequest()

      if (this.executionMode == "LIVE")
        await this.getExpiries()

      await this.requestOrderBasketsFromServer()
      await this.getOptionChainExpiries()
      await this.getInstrumentInfos()

      this.isDataLoading = false
    }, 2000);
  },
  created() {

  }
}
</script>

<style scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.strike-incdcr-arrows {
  /* font-size: 1.3em; */
  margin-right: 5px;
  margin-left: 5px;
  text-decoration: none;
}

.col-sm-9 {
  padding-right: 0px;
}
</style>
