<template>
  <div style="margin:5px;">

    <vue-tabs type="pills">
      <hr />
      <v-tab title="Strategy Details">
        <form>
          <div class="form-row">
            <div class="form-group col-md-9">
              <div>
                <label for="algoType">Algo Type</label>
              </div>
              <div>
                <select title="Algo Type" v-model="settingObj.AlgoType" class="form-control">
                  <option v-for="algoType in algoTypes" :key="algoType.value" :value="algoType.value"
                    :selected="settingObj.AlgoType == algoType.value">
                    {{ algoType.text }}
                  </option>
                </select>
              </div>
            </div>

          </div>
          <div class="form-row">
            <div class="form-group col-md-3">
              <label>Transaction Type</label>
              <select title="Transaction Type" v-model="settingObj.TransactionType" class="form-control">
                <option value="buy" :selected="settingObj.TransactionType == 'buy'">
                  Buy
                </option>
                <option value="sell" :selected="settingObj.TransactionType == 'sell'">
                  Sell
                </option>
              </select>
            </div>

            <div class="form-group col-md-3">
              <div>
                <label>Execution Type</label>
              </div>
              <div>
                <select title="Execution Type" v-model="settingObj.ExecutionType" class="form-control">
                  <option value="1" :selected="settingObj.ExecutionType == 1">
                    Paper
                  </option>
                  <option value="2" :selected="settingObj.ExecutionType == 2">
                    Live
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group col-md-3">
              <label>Product</label>
              <select title="Product" v-model="settingObj.Product" class="form-control">
                <option value="i" :selected="settingObj.Product == 'i'">
                  Intraday
                </option>
                <option value="d" :selected="settingObj.Product == 'd'">
                  Delivery
                </option>
              </select>
            </div>

          </div>

          <div class="form-row">
            <div class="form-group col-md-3">
              <div>
                <label>Entry Time</label>
              </div>
              <div>
                <flat-pickr
                      title="Press Enter or click outside to apply the selected time"
                      v-model="settingObj.EntryTime"
                      :config="timePickerConfig"
                      class="form-control"
                      placeholder="Entry Time"
                    />                
              </div>
            </div>

            <div class="form-group col-md-3">
              <div>
                <label>Exit Time</label>
              </div>
              <div>
                <flat-pickr
                      title="Press Enter or click outside to apply the selected time"
                      v-model="settingObj.ExitTime"
                      :config="timePickerConfig"
                      class="form-control"
                      placeholder="Exit Time"
                    />                                
              </div>
            </div>

            <div class="form-group col-md-3">
              <div>
                <label>Broker</label>
              </div>
              <div>
                <select title="Per Leg SL Type" v-model="settingObj.Broker" class="form-control">
                  <option value="8" :selected="settingObj.Broker == 8">
                    SAS Online
                  </option>
                  <option value="1" :selected="settingObj.Broker == 1">
                    Alice Blue
                  </option>
                </select>
              </div>
            </div>

          </div>

          <div class="form-row">
            <div class="form-group col-md-3">
              <label>Expiry</label>
              <select title="Expiry" v-model="settingObj.Expiry" class="form-control">
                <option value="current-weekly" :selected="settingObj.Expiry == 'current-weekly'">
                  Current Weekly
                </option>
                <option value="next-weekly" :selected="settingObj.Expiry == 'next-weekly'">
                  Next Weekly
                </option>
                <option value="current-monthly" :selected="settingObj.Expiry == 'current-monthly'">
                  Current Monthly
                </option>
                <option value="next-monthly" :selected="settingObj.Expiry == 'next-monthly'">
                  Next Monthly
                </option>
              </select>
            </div>

            <div class="form-group col-md-3">
              <div>
                <label>Instrument</label>
              </div>
              <div>
                <v-select multiple v-model="settingObj.Instruments" :options="settingObj.AvailableInstruments" />

                <!-- <select title="Instrument" v-model="settingObj.Instrument" class="form-control">
                  <option value="all" :selected="settingObj.Instrument == 'all'">
                    All
                  </option>
                  <option value="nifty" :selected="settingObj.Instrument == 'nifty'">
                    Nifty
                  </option>
                  <option value="banknifty" :selected="settingObj.Instrument == 'banknifty'">
                    Bank Nifty
                  </option>
                  <option value="finnifty" :selected="settingObj.Instrument == 'finnifty'">
                    FinNifty
                  </option>
                  <option value="midcpnifty" :selected="settingObj.Instrument == 'midcpnifty'">
                    Mid Cap Nifty
                  </option>                  
                </select> -->
              </div>
            </div>
          </div>

          <button v-on:click.prevent="closeForm()" class="btn btn-secondary">Close</button>
          <label class="space-10"></label>
          <button class="btn btn-primary" v-on:click.prevent="saveForm()">Save</button>
        </form>
      </v-tab>
      <v-tab title="Basket Group Risk Details">
        <div style="margin:5px;">
          <form>

            <div class="form-row">
              <div class="form-group  col-md-2"></div>
              <div class="form-group col-md-3">
                <div>
                  <label>Multiplier</label>
                </div>
                <div>
                  <input class="form-control" placeholder="Multiplier" v-model="settingObj.Multiplier"
                    @focus="$event.target.select()">
                </div>
              </div>
            </div>

            <div class="form-row">

              <div class="form-group  col-md-2">
                <div><label>&nbsp;</label></div>
                <div>
                  <input v-model="settingObj.StopTarget.enabled" title="SL at Basket Group Level"
                    :disabled="executionMode == 'SIMULATION'" class="form-check-input" @change="IncludeAllInPayoff()"
                    type="checkbox" />
                  <label class="ms-3 form-check-label" for="OEaddTpPayoff">
                    SL at Basket Group
                  </label>
                </div>
              </div>

              <div class="form-group col-md-3">
                <label>Stop</label>
                <input class="form-control" v-model="settingObj.StopTarget.stop" type="number" placeholder="Stop"
                  @focus="$event.target.select()">
              </div>

              <div class="form-group col-md-3">
                <label>Target</label>
                <input class="form-control" v-model="settingObj.StopTarget.target" type="number" placeholder="Target"
                  @focus="$event.target.select()">
              </div>

              <div class="form-group col-md-3">
                <label>Trail</label>
                <input class="form-control" v-model="settingObj.StopTarget.trail" type="number" placeholder="Trail"
                  @focus="$event.target.select()">
              </div>

            </div>

            <div class="form-row">

              <div class="form-group  col-md-2">
                <div><label>&nbsp;</label></div>
                <div>
                  <input v-model="settingObj.StopTarget.isTrailing" title="Is Basket Group Trailing"
                    class="form-check-input" type="checkbox" />
                  <label class="ms-3 form-check-label">
                    Trailing
                  </label>
                </div>
              </div>

              <div class="form-group col-md-3">
                <label>Trail Stop</label>
                <input class="form-control" disabled v-model="settingObj.StopTarget.trailStop" type="number"
                  placeholder="Trail Stop" @focus="$event.target.select()">
              </div>

              <div class="form-group col-md-3">
                <label>Trail Target</label>
                <input class="form-control" disabled v-model="settingObj.StopTarget.trailTarget" type="number"
                  placeholder="Trail Target" @focus="$event.target.select()">
              </div>

              <div class="form-group col-md-3">
                <label>Final Target</label>
                <input class="form-control" disabled v-model="settingObj.StopTarget.finalTarget" type="number"
                  placeholder="Final Target" @focus="$event.target.select()"
                  title="When trailing, check final target, if hit, exit">
              </div>

            </div>

            <div class="form-row">

              <div class="form-group  col-md-2">
                <div><label>&nbsp;</label></div>
                <div>
                  <input v-model="settingObj.StopTarget.reducingTrailStopGap" title="Reducing Trail-Stop Gap"
                    class="form-check-input" type="checkbox" />
                  <label class="ms-3 form-check-label">
                    Trail Agressively
                  </label>

                </div>
              </div>

              <div class="form-group col-md-3">
                <label>Agressive Trailing Gap Decrement %</label>
                <input class="form-control" v-model="settingObj.StopTarget.reducingTrailStopGapReductionPercent"
                  type="number" placeholder="Reduction %" @focus="$event.target.select()">
              </div>

              <div class="form-group col-md-3">
                <label>Agressive Trailing Gap Limit %</label>
                <input class="form-control" v-model="settingObj.StopTarget.reducingTrailStopGapMinimumGapPercent"
                  type="number" placeholder="Minimum Gap" @focus="$event.target.select()">
              </div>

            </div>

            <div class="form-row">

              <div class="form-group  col-md-2">
                <div><label>&nbsp;</label></div>
                <div>
                  <input v-model="settingObj.StopTarget.protectTheProfit" title="Protect the Profit"
                    class="form-check-input" type="checkbox" />
                  <label class="ms-3 form-check-label">
                    Protect the Proft
                  </label>
                </div>
              </div>

              <div class="form-group col-md-3">
                <label>When Profit Reaches</label>
                <input class="form-control" v-model="settingObj.StopTarget.protectWhenProfitReaches" type="number"
                  placeholder="When Profit Reaches" @focus="$event.target.select()">
              </div>

              <div class="form-group col-md-3">
                <label>Lock Minimum Profit At</label>
                <input class="form-control" type="number" v-model="settingObj.StopTarget.protectLockMinimumProfitAt"
                  placeholder="Lock Minimum Profit At" @focus="$event.target.select()">
              </div>

            </div>
          </form>

          <button v-on:click.prevent="closeForm()" class="btn btn-secondary">Close</button>
          <label class="space-10"></label>
          <button class="btn btn-primary" v-on:click.prevent="saveForm()">Save</button>
        </div>


      </v-tab>
    </vue-tabs>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions /*, mapMutations */ } from "vuex";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { VueTabs, VTab } from 'vue-nav-tabs/dist/vue-tabs.js'

export default {
  name: 'AlgoSchedulerSettingEditorEditor',
  components: {
    flatPickr,
    VueTabs,
    VTab,
  },
  props: ['algoSetting', 'action'],
  data() {
    return {
      timePickerConfig: {
        wrap: true,
          enableTime: true,
          enableSeconds: false,
          noCalendar: true,
          minuteIncrement: 1,
          time_24hr: false
      },      
      settingObj: { ...this.algoSetting },
      algoTypes: [{
        text: 'Basket Group SL Straddle',
        value: 1
      },
      {
        text: 'Spot Percent Range Straddle',
        value: 2
      },
      {
        text: 'Per Leg SL Straddle',
        value: 3
      },
      {
        text: 'Per Leg SL Momentum Straddle',
        value: 4
      },
      {
        text: 'OI Change Crossover Spread',
        value: 5
      },
      {
        text: 'Straddle Re Entry',
        value: 6
      },
      {
        text: 'Basket Group SL Calender Spread',
        value: 7
      },
      {
        text: 'Basket Group Straddle All',
        value: 9
      },
      ]
    };
  },
  methods: {
    ...mapActions([
      "sendMessage",
    ]),
    closeForm() {
      // this.$simpleAlert.confirm('Do you want to close this form?', 'Update Algo Scheduler Setting', 'question')
      //   .then(() => {
      this.algoSetting.Editing = false
      this.$emit('formClosed')
      // })
    },
    saveForm() {
      this.settingObj.Editing = false
      let saveSettingMessage = {
        MessageType: "update_algo_scheduler_setting_request",
        Action: this.action,
        Setting: this.settingObj
      };

      this.sendMessage(JSON.stringify(saveSettingMessage));
      this.algoSetting.Editing = false

      let requestAlgoSchedulerSettings = {
        MessageType: "get_algo_scheduler_settings_request",
      };

      this.sendMessage(JSON.stringify(requestAlgoSchedulerSettings));
      this.$emit('formClosed')
    }
  }
}
</script>

<style scoped></style>
`