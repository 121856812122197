<template>
  <div style="margin:5px;">

    <form>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="name">Name</label>
          <input class="form-control" id="name" placeholder="Name" v-model="profileObj.Name"
            @focus="$event.target.select()">
        </div>
        <div class="form-group col-md-6">
          <label for="instrument">Instrument</label>
          <select title="Instrument" v-model="profileObj.Instrument" class="form-control">
            <option value="nifty" :selected="profileObj.Instrument == 'nifty'">
              Nifty
            </option>
            <option value="banknifty" :selected="profileObj.Instrument == 'banknifty'">
              Bank Nifty
            </option>
            <option value="sensex" :selected="profileObj.Instrument == 'sensex'">
              Sensex
            </option>            
          </select>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-3">
          <label>Positional SL Type</label>
          <select title="Instrument" v-model="profileObj.StopTargetType" class="form-control">
            <option value="1" :selected="profileObj.StopTargetType == 1">
              Points
            </option>
            <option value="2" :selected="profileObj.StopTargetType == 2">
              Premium
            </option>
          </select>
        </div>

        <div class="form-group col-md-3">
          <label>Stop</label>
          <input class="form-control" placeholder="Stop" v-model="profileObj.Stop" @focus="$event.target.select()">
        </div>

        <div class="form-group col-md-3">
          <label>Target</label>
          <input class="form-control" placeholder="Target" v-model="profileObj.Target" @focus="$event.target.select()">
        </div>

        <div class="form-group col-md-3">
          <label>Trail</label>
          <input class="form-control" placeholder="Trail" v-model="profileObj.Trail" @focus="$event.target.select()">
        </div>

      </div>

      <div class="form-row">

        <div class="form-group  col-md-2">
          <div><label>&nbsp;</label></div>
          <div>
            <input v-model="profileObj.IsPerLegSL" title="Is Per Leg SL Applicable" class="form-check-input"
              type="checkbox" />
            <label class="ms-3 form-check-label">
              Is Per Leg SL
            </label>
          </div>
        </div>

        <div class="form-group col-md-2">
          <label>Per Leg SL Type</label>
          <select title="Per Leg SL Type" :disabled="!profileObj.IsPerLegSL" v-model="profileObj.PerLegSLType"
            class="form-control">
            <option value="1" :selected="profileObj.PerLegSLType == 1">
              Percent
            </option>
            <option value="2" :selected="profileObj.PerLegSLType == 2">
              Fixed
            </option>
          </select>
        </div>

        <div class="form-group col-md-2">
          <label>Per SL Value</label>
          <input class="form-control" placeholder="Per Leg SL Value" v-model="profileObj.PerLegSLValue"
            :disabled="!profileObj.IsPerLegSL" @focus="$event.target.select()">
        </div>

        <div class="form-group col-md-2">
          <div><label>&nbsp;</label></div>

          <div>
            <input v-model="profileObj.UseDeltaBalanceAdjustment" title="Use Delta Balance Adjustment"
              class="form-check-input" type="checkbox" :disabled="!profileObj.IsPerLegSL" />
            <label class="ms-3 form-check-label">
              Use Delta Balance Adjustment
            </label>

          </div>
        </div>

        <div class="form-group col-md-2">
          <div><label>&nbsp;</label></div>
          <div>
            <input v-model="profileObj.UseReEntryAdjustment" :disabled="!profileObj.IsPerLegSL"
              title="Use Re Entry Adjustment" class="form-check-input" type="checkbox" />
            <label class="ms-3 form-check-label">
              Use Re Entry Adjustment
            </label>
          </div>
        </div>


      </div>

      <div class="form-row">

        <div class="form-group  col-md-2">
          <div><label>&nbsp;</label></div>
          <div>
            <input v-model="profileObj.FuturesBasedSLTarget" title="Is Futures Based SL" class="form-check-input"
              type="checkbox" />
            <label class="ms-3 form-check-label">
              Futures Based SL
            </label>
          </div>
        </div>

        <div class="form-group col-md-2">
          <label>Futures SL Comparison Type</label>
          <select title="Futures SL Comparision Type" :disabled="!profileObj.FuturesBasedSLTarget"
            v-model="profileObj.FuturesSLCondition" class="form-control">
            <option value="<=" :selected="profileObj.FuturesSLCondition == '<='">
              &lt;=
            </option>
            <option value=">=" :selected="profileObj.FuturesSLCondition == '>='">
              &gt;=
            </option>
          </select>
        </div>

        <div class="form-group col-md-2">
          <label>Futures SL Value</label>
          <input class="form-control" placeholder="Futures SL Value" v-model="profileObj.FuturesSLValue"
            :disabled="!profileObj.FuturesBasedSLTarget" @focus="$event.target.select()">
        </div>

        <div class="form-group col-md-2">
          <label>Futures Target Comparison Type</label>
          <select title="Futures SL Comparision Type" :disabled="!profileObj.FuturesBasedSLTarget"
            v-model="profileObj.FuturesTargetCondition" class="form-control">
            <option value="<=" :selected="profileObj.FuturesTargetCondition == '<='">
              &lt;=
            </option>
            <option value=">=" :selected="profileObj.FuturesTargetCondition == '>='">
              &gt;=
            </option>
          </select>
        </div>

        <div class="form-group col-md-2">
          <label>Futures Target Value</label>
          <input class="form-control" placeholder="Futures Target Value" v-model="profileObj.FuturesTargetValue"
            :disabled="!profileObj.FuturesBasedSLTarget" @focus="$event.target.select()">
        </div>


      </div>

      <div class="form-row">
        <div class="form-group  col-md-2">
        </div>
        <div class="form-group  col-md-2">
          <div><label>&nbsp;</label></div>
          <div>
            <input v-model="profileObj.IsDefault" title="Is Default Profile" class="form-check-input" type="checkbox" />
            <label class="ms-3 form-check-label">
              Default Profile
            </label>
          </div>
        </div>

        <div class="form-group  col-md-2">
          <div><label>&nbsp;</label></div>
          <div>
            <input v-model="profileObj.ScheduleOnExecution" title="Schedule on Execution" class="form-check-input"
              type="checkbox" />
            <label class="ms-3 form-check-label">
              Schedule on Execution
            </label>
          </div>
        </div>
      </div>


      <button class="btn btn-primary" v-on:click.prevent="saveForm()">Save</button>
      <label class="space-10"></label>
      <button v-on:click.prevent="closeForm()" class="btn btn-secondary">Close</button>
    </form>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions /*, mapMutations */ } from "vuex";

export default {
  name: 'RiskManagementProfileEditor',
  props: ['profile', 'action'],
  data() {
    return {
      profileObj: { ...this.profile },
    };
  },
  methods: {
    ...mapActions([
      "sendMessage",
    ]),
    closeForm() {
      this.$simpleAlert.confirm('Do you want to close this form?', 'Update Risk Profile', 'question')
        .then(() => {
          this.profile.Editing = false
          this.$emit('formClosed')
        })
        .catch(() => {
          
        })        
    },
    saveForm() {
      this.profileObj.Editing = false
      let saveProfile = {
        MessageType: "update_risk_management_profile_request",
        Action: this.action,
        Profile: this.profileObj
      };

      this.sendMessage(JSON.stringify(saveProfile));
      this.profile.Editing = false
      this.$emit('formClosed')
    }
  }
}
</script>

<style scoped></style>
