<template>
  <div class="text-center p15">
    <h4>Global Settings</h4>
    <hr class="hr" />

    <form>
      <div class="form-row">

        <div class="form-group col-md-6">
          <div>
            <label>Global SL</label>
          </div>
          <div>
            <input class="form-control" placeholder="Global SL" v-model="settings.globalSL"
              @focus="$event.target.select()">
          </div>
        </div>

        <div class="form-group col-md-6">
          <div>
            <label>Global Target</label>
          </div>
          <div>
            <input class="form-control" placeholder="Global Target" v-model="settings.globalTarget"
              @focus="$event.target.select()">
          </div>
        </div>

      </div>

      <div class="form-row">
        <div class="form-group  col-md-2">
          <div><label>&nbsp;</label></div>
          <div>
            <input v-model="settings.checkGlobalExit" title="Is Global Exit enabled?" class="form-check-input"
              type="checkbox" />
            <label class="ms-3 form-check-label">
              Enable Global Exit
            </label>
          </div>
        </div>

      </div>
    </form>

    <hr class="hr" />

    <button class="btn btn-primary" style="width: 100px;" v-on:click.prevent="saveForm()">Save</button>
    <label class="space-10"></label>
    <button v-on:click.prevent="closeForm()" style="width: 100px;" class="btn btn-secondary">Close</button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'GlobalSettings',
  data() {
    return {
      settings: {},
    }
  },
  computed: {
    ...mapState({
      accountPnL: state => state.accountPnL,
      realizedPnL: state => state.realizedPnL,
      globalSettings: state => state.globalSettings,
    })
  },
  methods: {
    ...mapActions([
      'saveGlobalSettings'
    ]),
    closeForm() {
      this.$emit("close")
    },
    saveForm() {

      let netPnL = this.accountPnL + this.realizedPnL;

      if (this.settings.globalSL > netPnL) {
        alert("SL should be less than net pnl")
        return;
      }

      if (this.settings.globalTarget < netPnL) {
        alert("Target should be greater than net pnl")
        return;
      }

      this.saveGlobalSettings(this.settings)
      this.$emit("close")
    }
  },
  mounted() {
    this.settings = JSON.parse(JSON.stringify(this.globalSettings))
  }
}
</script>

<style scoped></style>
