<template>
  <div class="container-fluid">
    <div class="layout gap-4">
      <main class="layout-main px-4 m-5">
        <router-view></router-view>
      </main>
      <aside class="layout-sidebar border-end mt-5">
        <ul class="list-group border-0" style="width:150px;">
          <li class="list-group-item list-group-item-action">
            <router-link to="/settings/general-settings">General Settings</router-link>
          </li>
          <li class="list-group-item list-group-item-action">
            <router-link to="/settings/brokers">Manage Brokers</router-link>
          </li>
          <li class="list-group-item list-group-item-action">
            <router-link to="/settings/holidays">Holidays</router-link>
          </li>
        </ul>
      </aside>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import axios from 'axios';
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Settings',
  components: {
  },
  computed: {
    ...mapState({
      executionMode: state => state.executionMode,
    }),
  },
  methods: {
    ...mapActions([
    ]),
  },
  mounted() {
  },
}
</script>

<style scoped></style>
