<template>
  <div class="thin-border" style="padding:20px;margin-top:20px;margin-bottom:20px;margin-right:5px;">
    <table class="table">
      <thead>
        <tr>
          <th>Enabled</th>
          <th scope="col">Id</th>
          <th scope="col">Algo Type</th>
          <th scope="col">Instrument</th>
          <th scope="col">Execution</th>
          <th scope="col">Entry Time</th>
          <th scope="col">Exit Time</th>
          <th scope="col">Broker</th>
          <th scope="col">Multiplier</th>
          <th scope="col">Basket Group Stop/Target</th>
          <th scope="col">Aggressive Trailing</th>
          <th scope="col">Profit Protection</th>
        </tr>
      </thead>
      <tbody>
        <!-- eslint-disable-next-line vue/no-v-for-template-key -->
        <template v-for="settingFor in algoSchedulerSettings" :key="settingFor.Id">
          <tr>
            <td>
              <input v-model="settingFor.Enabled" @change="enableDisableAlgoScheduler(settingFor)"
                title="Enable/Disable Algo Execution" class="form-check-input" type="checkbox" />
            </td>
            <td scope="row">{{ settingFor.Id }}</td>
            <td>{{ getAlgoTypeName(settingFor.AlgoType) }}</td>
            <td>{{ (settingFor.Instrument == null || settingFor.Instrument.length == 0 ? "Both" :
              settingFor.Instrument.toUpperCase()) }}</td>
            <td>{{ settingFor.ExecutionType == 1 ? "Paper" : "Live" }}</td>
            <td>{{ settingFor.EntryTime }}</td>
            <td>{{ settingFor.ExitTime }}</td>
            <td>{{ getBrokerName(settingFor.Broker) }}</td>
            <td>{{ settingFor.Multiplier }}</td>

            <td><label title="Stop">{{ settingFor.StopTarget.stop }}</label> /
              <label title="Target">{{ settingFor.StopTarget.target }}</label> /
              <label title="Trail">{{ settingFor.StopTarget.trail }}</label> /
              <label title="Final Target">{{ settingFor.StopTarget.finalTarget }}</label>
            </td>

            <td><label title="Gap Decrement %">{{ settingFor.StopTarget.reducingTrailStopGapReductionPercent }}</label> /
              <label title="Gap Limit %">{{ settingFor.StopTarget.reducingTrailStopGapMinimumGapPercent }}</label>
            </td>

            <td><label title="When Profit Reaches">{{ settingFor.StopTarget.protectWhenProfitReaches }}</label> /
              <label title="Lock Minimum Profit At">{{ settingFor.StopTarget.protectLockMinimumProfitAt }}</label>
            </td>

            <td>
              <a href="javascript:void(0)" @click="editSetting(settingFor)" title="Edit Setting">
                <span class="material-icons">
                  edit
                </span>
              </a>
              <a href="javascript:void(0)" @click="deleteSetting(settingFor)" title="Delete Setting">
                <span class="material-icons">
                  delete
                </span>
              </a>

              <a href="javascript:void(0)" @click="addNewSetting()" title="Add New Setting">
                <span class="material-icons md-36">
                  playlist_add
                </span>
              </a>
            </td>
          </tr>


          <tr v-bind:key="settingFor.IdRandom" v-if="checkIfEditAllowed(settingFor.Id)">
            <td colspan="23">
              <AlgoSchedulerSettingEditor :algoSetting="settingFor" :action="settingFor.Action"
                v-on:formClosed="addNewFormClosed(settingFor.Id)" />
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    <AlgoSchedulerSettingEditor :algoSetting="getNewSetting()" v-on:formClosed="addNewFormClosed()" :action="1"
      v-if="addNewSettingFlag" />
    <button class="btn btn-primary" v-on:click.prevent="addNewSetting()" v-if="!addNewSettingFlag">Add New
      Schedule</button>
  </div>
</template>

<script>
/* eslint-disable */
import AlgoSchedulerSettingEditor from "@/components/AlgoSchedulerSettingEditor";
import { mapState, mapActions /*, mapMutations */ } from "vuex";

export default {
  name: 'AlgoSchedulerSetting',
  components: {
    AlgoSchedulerSettingEditor
  },
  data() {
    return {
      addNewSettingFlag: false
    };
  },
  computed: {
    ...mapState({
      algoSchedulerSettings: state => state.algoSchedulerSettings,
    }),
    property() {
      return ''
    },
  },
  watch: {
    watchTest: function () {
    },
  },
  methods: {
    ...mapActions([
      "sendMessage",
    ]),
    getBrokerName(brokerId) {
      if (brokerId == 1)
        return "Alice Blue";
      else if (brokerId == 8)
        return "SAS Online";
      else
        return "Invalid Broker";
    },
    getAlgoTypeName(algoType) {
      if (algoType == 1)
        return "Basket Group SL";
      else if (algoType == 2)
        return "Spot Percent Range Straddle";
      else if (algoType == 3)
        return "Per Leg SL Straddle";
      else if (algoType == 4)
        return "Per Leg SL Momentum Straddle";
      else if (algoType == 5)
        return "OI Change Crossover Skewed Straddle";
      else if (algoType == 6)
        return "Straddle Re Entry";
      else if (algoType == 7)
        return "Basket Group SL Calender Spread"
      else if (algoType == 9)
        return "Basket Group Straddle All"
      else
        return "Invalid Algo Type";
    },
    addNewFormClosed(id) {
      console.log("Add new form close called")
      this.addNewSettingFlag = false

      let settingObj = this.algoSchedulerSettings.find(x => x.Id === id)
      if (!settingObj) return;

      settingObj.Editing = false
    },
    enableDisableAlgoScheduler(settingObj) {
      let saveSettingMessage = {
        MessageType: "update_algo_scheduler_setting_request",
        Action: 2,
        Setting: settingObj
      };

      this.sendMessage(JSON.stringify(saveSettingMessage));
    },
    editSetting(setting) {

      // let setting = this.algoSchedulerSettings.find(x => x.Id === id)
      if (!setting) return;

      setting.Editing = true
      setting.Action = 2
    },
    checkIfEditAllowed(id) {
      let setting = this.algoSchedulerSettings.find(x => x.Id === id)
      if (!setting) return;

      return setting.Editing
    },
    getNewSetting() {
      return {
        Id: 1,
        AlgoType: 1,
        Instrument: 'all',
        Instruments: ['nifty', 'banknifty', 'finnifty', 'midcpnifty'],
        AvailableInstruments: ['nifty', 'banknifty', 'finnifty', 'midcpnifty'],
        EntryTime: "09:00:00",
        ExitTime: "15:20:00",
        ExecutionType: 1, // 1 - Paper, 2 - Live
        Broker: 8, // SAS API
        Multiplier: 1,
        Product: "i",
        Expiry: "current-weekly",
        TransactionType: "buy",
        Editing: false,
        StopTarget: {
          enabled: false,
          multiplier: 1,
          stop: -2000,
          target: 3000,
          trail: 1000,
          isTrailing: false,
          trailStop: 0,
          trailTarget: 0,
          finalTarget: 0,
          reducingTrailStopGap: false,
          reducingTrailStopGapReductionPercent: 10,
          reducingTrailStopGapMinimumGapPercent: 25,
          protectTheProfit: false,
          protectWhenProfitReaches: 1500,
          protectLockMinimumProfitAt: 500,
        }
      }
    },
    deleteSetting(setting) {
      this.$simpleAlert.confirm('Do you want to delete this setting?', 'Delete Algo Scheduler Setting', 'question')
        .then(() => {
          setting.Editing = false
          let deleteSettingMessage = {
            MessageType: "update_algo_scheduler_setting_request",
            Action: 3,
            Setting: setting
          };

          this.sendMessage(JSON.stringify(deleteSettingMessage));
        })
        .catch(() => {
          
        })
    },
    addNewSetting() {
      this.addNewSettingFlag = true
    },
  },
  mounted() {
    // get all the risk profiles
    let requestAlgoSchedulerSettings = {
      MessageType: "get_algo_scheduler_settings_request",
    };

    this.sendMessage(JSON.stringify(requestAlgoSchedulerSettings));
  }
}
</script>

<style scoped>
.thin-border {
  border: 1px solid gray;
}
</style>
