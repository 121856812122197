<template>
  <div class="container-fluid position-relative g-0">   
    <n-notification-provider>
      <n-message-provider>
        <nav class="navbar fixed-top" style="background-color: currentColor">
          <div class="container-fluid">
            <a class="navbar-brand position-relative" href="#" title="OptionEdge">
              <img src="/images/logo.png" width="36" alt="Logo" />
            </a>

            <div class="nav-item me-auto fs-sm">
              <div class="ms-auto">
                <span class="d-inline-block dark-separator" style="width: 170px;" title="INDIA VIX">
                  VIX:
                  <span v-bind:class="{ 'dark-red': indiaVIXSpotChange < 0, 'green-dark': indiaVIXSpotChange > 0 }"
                    class="fs-6 fw-bold">{{ indiaVIXSpot ? indiaVIXSpot.Ltp.toFixed(2) : - 1 }}</span>
                  &nbsp;
                  <span v-bind:class="{ 'dark-red': indiaVIXSpotChange < 0, 'green-dark': indiaVIXSpotChange > 0 }">{{
                    indiaVIXSpotChange.toFixed(2) }}</span>

                  <span
                    v-bind:class="{ 'dark-red': indiaVIXSpotChange < 0, 'green-dark': indiaVIXSpotChange > 0, 'dark-separator': indiaVIXSpotChange == 0 }">
                    ({{ indiaVIXSpotChangePercent.toFixed(2) }}%)</span>

                </span>



                <i class="fa-solid fa-circle fa-2xs ms-2 me-5" style="color:gray"></i>

                <span class="d-inline-block dark-separator" title="NIFTY 50" style="width: 210px;">
                  NF:
                  <span v-bind:class="{ 'dark-red': niftySpotChange < 0, 'green-dark': niftySpotChange > 0 }"
                    class="fs-6 fw-bold">{{
                      niftySpot ?
                      niftySpot.Ltp.toFixed(2) : 0 }}</span>
                  &nbsp;
                  <span v-bind:class="{ 'dark-red': niftySpotChange < 0, 'green-dark': niftySpotChange > 0 }">{{
                    niftySpotChange.toFixed(2)
                  }}</span>

                  <span v-bind:class="{ 'dark-red': niftySpotChange < 0, 'green-dark': niftySpotChange > 0 }">
                    ({{ niftySpotChangePercent.toFixed(2) }}%)</span>
                  &nbsp;
                  <a href="https://in.tradingview.com/chart/33OhTdL3/?symbol=NSE%3ANIFTY" title="Nifty Chart" target="_blank">
                    <i class="fa-solid fa-chart-simple " style="color: darkolivegreen;"></i>
                  </a>
                </span>

                <i class="fa-solid fa-circle fa-2xs ms-2 me-5" style="color:gray"></i>

                <span class="d-inline-block dark-separator" style="width: 210px;" title="BANKNIFTY">
                  BNF:
                  <span v-bind:class="{ 'dark-red': bankNiftySpotChange < 0, 'green-dark': bankNiftySpotChange > 0 }"
                    class="fs-6 fw-bold">{{
                      bankNiftySpot ?
                      bankNiftySpot.Ltp.toFixed(2) : 0 }}</span>
                  &nbsp;
                  <span v-bind:class="{ 'dark-red': bankNiftySpotChange < 0, 'green-dark': bankNiftySpotChange > 0 }">{{
                    bankNiftySpotChange.toFixed(2) }}</span>
                  <span v-bind:class="{ 'dark-red': bankNiftySpotChange < 0, 'green-dark': bankNiftySpotChange > 0 }">
                    ({{ bankNiftySpotChangePercent.toFixed(2) }}%)</span>
                  &nbsp;
                  <a title="Banknifty Chart" href="https://in.tradingview.com/chart/33OhTdL3/?symbol=NSE%3ABANKNIFTY" target="_blank">
                    <i class="fa-solid fa-chart-simple " style="color: darkolivegreen;"></i>
                  </a>
                </span>
              </div>

              <div class="ms-auto">
                <span class="d-inline-block dark-separator" style="width: 210px;" title="FINNIFTY">
                  FNF:
                  <span v-bind:class="{ 'dark-red': finNiftySpotChange < 0, 'green-dark': finNiftySpotChange > 0 }"
                    class="fs-6 fw-bold">{{
                      finNiftySpot ?
                      finNiftySpot.Ltp.toFixed(2) : 0 }}</span>
                  &nbsp;
                  <span v-bind:class="{ 'dark-red': finNiftySpotChange < 0, 'green-dark': finNiftySpotChange > 0 }">{{
                    finNiftySpotChange.toFixed(2) }}</span>

                  <span v-bind:class="{ 'dark-red': finNiftySpotChange < 0, 'green-dark': finNiftySpotChange > 0 }">
                    ({{ finNiftySpotChangePercent.toFixed(2) }}%)</span>
                  &nbsp;
                  <a title="Finifty Chart" href="https://in.tradingview.com/chart/33OhTdL3/?symbol=NSE%3AFINNIFTY1%21" target="_blank">
                    <i class="fa-solid fa-chart-simple " style="color: darkolivegreen;"></i>
                  </a>
                </span>

                <i class="fa-solid fa-circle fa-2xs ms-2 me-5" style="color:gray"></i>

                <span class="d-inline-block dark-separator" style="width: 210px;" title="MIDCPNIFTY">
                  MNF:
                  <span v-bind:class="{ 'dark-red': midcapNiftySpotChange < 0, 'green-dark': midcapNiftySpotChange > 0 }"
                    class="fs-6 fw-bold">{{
                      midcapNiftySpot ?
                      midcapNiftySpot.Ltp.toFixed(2) : 0 }}</span>
                  &nbsp;
                  <span
                    v-bind:class="{ 'dark-red': midcapNiftySpotChange < 0, 'green-dark': midcapNiftySpotChange > 0 }">{{
                      midcapNiftySpotChange.toFixed(2) }}</span>

                  <span v-bind:class="{ 'dark-red': midcapNiftySpotChange < 0, 'green-dark': midcapNiftySpotChange > 0 }">
                    ({{ midcapNiftySpotChangePercent.toFixed(2) }}%)</span>
                  &nbsp;
                  <a  title="Midcap Nifty Chart" href="https://in.tradingview.com/chart/33OhTdL3/?symbol=NSE%3AMIDCPNIFTY1%21" target="_blank">
                    <i class="fa-solid fa-chart-simple " style="color: darkolivegreen;"></i>
                  </a>
                </span>

                <i class="fa-solid fa-circle fa-2xs ms-2 me-5" style="color:gray"></i>

                <span class="d-inline-block dark-separator" style="width: 240px;" title="SENSEX">
                  SENSEX:
                  <span v-bind:class="{ 'dark-red': sensexSpotChange < 0, 'green-dark': sensexSpotChange > 0 }"
                    class="fs-6 fw-bold">{{
                      sensexSpot ?
                      sensexSpot.Ltp.toFixed(2) : 0 }}</span>
                  &nbsp;
                  <span
                    v-bind:class="{ 'dark-red': sensexSpotChange < 0, 'green-dark': sensexSpotChange > 0 }">{{
                      sensexSpotChange.toFixed(2) }}</span>

                  <span v-bind:class="{ 'dark-red': sensexSpotChange < 0, 'green-dark': sensexSpotChange > 0 }">
                    ({{ sensexSpotChangePercent.toFixed(2) }}%)</span>
                  &nbsp;
                  <a  title="Sensex Chart" href="https://in.tradingview.com/chart/33OhTdL3/?symbol=BSE%3ASENSEX" target="_blank">
                    <i class="fa-solid fa-chart-simple " style="color: darkolivegreen;"></i>
                  </a>
                </span>                
              </div>
            </div>

            <div class="nav-item me-auto fs-4 text-center shadow-sm dark-separator" style="width: 500px;"
              data-bs-toggle="modal" data-bs-target="#globalSettingModal">
              <span title="MTM" class="d-inline-block " style="width: 140px;"
                v-bind:class="{ 'dark-red': accountPnL < 0, 'green-dark': accountPnL > 0 }">
                <b>&#8377; {{ formatWithThousandSeparator(accountPnL ? accountPnL.toFixed(2) : 0) }}</b>
              </span>
              <span class="space-30"></span>
              <span title="Realized" class="d-inline-block " style="width: 150px;"
                v-bind:class="{ 'dark-red': realizedPnL < 0, 'green-dark': realizedPnL > 0 }">
                &#8377; {{ formatWithThousandSeparator(realizedPnL ? realizedPnL.toFixed(2) : 0) }}
              </span>
              <span class="space-30"></span>
              <span style="width: 140px;cursor: pointer;" class="d-inline-block " @click="showGlobalSettingsModal">
                <span title="Global Stop Loss" v-if="globalSettings.checkGlobalExit && globalSettings.globalSL !== 0">
                  <span style="font-size: .8em;" class="red">G-SL: &#8377; {{
                    formatWithThousandSeparator(globalSettings.globalSL.toFixed(2)) }}</span>
                  <span class="space-70"></span>
                </span>
                <span title="Net (Realized + MTM)"
                  v-bind:class="{ 'dark-red': (accountPnL + realizedPnL) < 0, 'green-dark': (accountPnL + realizedPnL) > 0 }">
                  &#8377; {{ ((accountPnL + realizedPnL) ? formatWithThousandSeparator((accountPnL +
                    realizedPnL).toFixed(2))
                    : 0)
                  }}</span>
                <span title="Global Target" v-if="globalSettings.checkGlobalExit && globalSettings.globalTarget !== 0">
                  <span class="space-70"></span>
                  <span style="font-size: .8em;" class="green-dark">G-T: &#8377; {{
                    formatWithThousandSeparator(globalSettings.globalTarget.toFixed(2)) }}</span>
                </span>
              </span>

            </div>

            <div class="nav-item me-3">
              <span class="" v-bind:class="{ red: accountPnL < 0, green: accountPnL > 0 }">
                <!-- <i class="fa-solid fa-globe me-5 fa-xl text-primary position-relative" title="Active Brokers">
                <span class="position-absolute fs-xs top-0 start-100 translate-middle badge rounded-pill text-bg-success">
                  2
                  <span class="visually-hidden">unread messages</span>
                </span>
              </i> -->
                <span :title="activeBrokers.length + ' broker session active'" class="badge text-bg-success fs-6 me-4">{{
                  activeBrokers.length }}</span>

                <i v-if="isBrokerConnected" class="fa-solid fa-circle  me-4 text-success" title="Feeds Connected"></i>
                <div v-else title="Feeds disconnected or feed source not set"
                  class="spinner-grow spinner-grow-sm text-danger me-3" role="status">
                </div>


                <i class="fa-solid fa-circle  me-2 " title="Browser Connection Status"
                  :class="isClientConnected === true ? 'text-success' : 'text-danger'"></i>
              </span>
            </div>

            <div class=" text-secondary text-danger me-4 fw-bold">
              {{ executionMode == 'LIVE' ? 'LIVE' : 'SIM' }}
            </div>

            <router-link to="/settings" class="me-3">
              <i class="fa-solid fa-gear fa-lg" title="Settings"></i>
            </router-link>

            <router-link to="/dashboard">
              <i class="fa-solid fa-house fa-lg" title="Dashboard"></i>
            </router-link>

            <button class="navbar-toggler btn btn-primary btn-sm ms-2" type="button" data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel">
              <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="offcanvasNavbarLabel">OptionEdge</h5>
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <div class="offcanvas-body">
                <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                  <li class="nav-item">
                    <a class="nav-link active" aria-current="page" href="#">Home</a>
                  </li>
                  <li class="nav-item">
                    <!-- <a class="nav-link" href="/dashboard">Link</a> -->
                    <router-link class="nav-link" to="/dashboard">Dashboard</router-link>
                  </li>
                  <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                      aria-expanded="false">
                      Dropdown
                    </a>
                    <ul class="dropdown-menu">
                      <li><a class="dropdown-item" href="#">Action</a></li>
                      <li><a class="dropdown-item" href="#">Another action</a></li>
                      <li>
                        <hr class="dropdown-divider">
                      </li>
                      <li><a class="dropdown-item" href="#">Something else here</a></li>
                    </ul>
                  </li>
                </ul>
                <form class="d-flex" role="search">
                  <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                  <button class="btn btn-primary" type="submit">Search</button>
                </form>
              </div>
            </div>
          </div>
        </nav>

        <OptionsSimulatorEx v-if="showHideOptionsSimulator && primaryFeedSourceId == 'HISTORY'"></OptionsSimulatorEx>

        <div style="padding-top:60px;">
          <router-view />
        </div>

        <div class="modal fade" tabindex="-1" id="globalSettingModal" aria-labelledby="globalSettingModal"
          aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <!-- <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div> -->
              <GlobalSettings :settings="globalSettings" />
              <div class="modal-footer">
                <button type="button" class="btn btn-subtle" data-bs-dismiss="modal">Close</button>
                <!-- <button type="button" class="btn btn-primary">Confirm</button> -->
              </div>
            </div>
          </div>
        </div>

        <notifications position="bottom left" width="700" />
      </n-message-provider>
    </n-notification-provider>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import OptionsSimulatorEx from '@/components/OptionsSimulatorEx'
import GlobalSettings from '@/components/GlobalSettings'

export default {
  name: 'AppVue',
  components: {
    OptionsSimulatorEx,
    GlobalSettings
  },
  data() {
    return {
      bgColor: '#778899',
      position: 'top-right',
    }
  },
  computed: {
    ...mapState({
      instruments: state => state.instruments,
      socket: state => state.$socket,
      executionMode: state => state.executionMode,
      primaryFeedSourceId: state => state.primaryFeedSourceId,
      brokers: state => state.brokers,
      showHideOptionsSimulator: state => state.showHideOptionsSimulator,
      accountPnL: state => state.accountPnL,
      realizedPnL: state => state.realizedPnL,
      isClientConnected: state => state.socket.isConnected,
      isBrokerConnected: state => state.isBrokerConnected,
      globalSettings: state => state.globalSettings,
      niftySpot: state => state.instruments['NIFTY 50'],
      bankNiftySpot: state => state.instruments['NIFTY BANK'],
      sensexSpot: state => state.instruments['SENSEX'],
      finNiftySpot: state => state.instruments['FINNIFTY'],
      midcapNiftySpot: state => state.instruments['MIDCPNIFTY'],
      indiaVIXSpot: state => state.instruments['INDIA VIX'],
      niftySpotChange: state => {
        var spot = state.instruments['NIFTY 50']
        var change = spot ? (spot.Ltp - spot.Close) : 0
        return change
      },
      niftySpotChangePercent: state => {
        var spot = state.instruments['NIFTY 50']
        var percent = spot ? (((spot.Ltp - spot.Close) / spot.Ltp) * 100) : 0
        return percent
      },
      bankNiftySpotChange: state => {
        var spot = state.instruments['NIFTY BANK']
        var change = spot ? (spot.Ltp - spot.Close) : 0
        return change
      },
      sensexSpotChange: state => {
        var spot = state.instruments['SENSEX']
        var change = spot ? (spot.Ltp - spot.Close) : 0
        return change
      },      
      bankNiftySpotChangePercent: state => {
        var spot = state.instruments['NIFTY BANK']
        var percent = spot ? (((spot.Ltp - spot.Close) / spot.Ltp) * 100) : 0
        return percent
      },
      sensexSpotChangePercent: state => {
        var spot = state.instruments['SENSEX']
        var percent = spot ? (((spot.Ltp - spot.Close) / spot.Ltp) * 100) : 0
        return percent
      },      
      finNiftySpotChange: state => {
        var spot = state.instruments['FINNIFTY']
        var change = spot ? (spot.Ltp - spot.Close) : 0
        return change
      },
      finNiftySpotChangePercent: state => {
        var spot = state.instruments['FINNIFTY']
        var percent = spot ? (((spot.Ltp - spot.Close) / spot.Ltp) * 100) : 0
        return percent
      },
      midcapNiftySpotChange: state => {
        var spot = state.instruments['MIDCPNIFTY']
        var change = spot ? (spot.Ltp - spot.Close) : 0
        return change
      },
      midcapNiftySpotChangePercent: state => {
        var spot = state.instruments['MIDCPNIFTY']
        var percent = spot ? (((spot.Ltp - spot.Close) / spot.Ltp) * 100) : 0
        return percent
      },
      indiaVIXSpotChange: state => {
        var spot = state.instruments['INDIA VIX']
        var change = spot ? (spot.Ltp - spot.Close) : 0
        return change
      },
      indiaVIXSpotChangePercent: state => {
        var spot = state.instruments['INDIA VIX']
        var percent = spot ? (((spot.Ltp - spot.Close) / spot.Ltp) * 100) : 0
        return percent
      },
    }),
    activeBrokers() {
      var activeBrokers = this.brokers.filter(x => x.isTradingSessionActive)
      return activeBrokers;
    }
  },
  methods: {
    ...mapActions([
      'sendMessage',
    ]),
    formatWithThousandSeparator(value) {
      if (!isNaN(value) && value > 0) {
        return new Intl.NumberFormat('en-IN', {}).format(value)
      } else if (!isNaN(value) && value < 0) {
        return new Intl.NumberFormat('en-IN', {}).format(value)
      } else {
        return value
      }
    },
    gotoHome() {
      this.$router.push('/landing')
    },
    gotoDashboard() {
      this.$router.push('/dashboard')
    },
    gotoOIChangeAnalysis() {
      this.$router.push('/oi-change-analysis')
    },
    gotoAdvanceMultiChart() {
      this.$router.push('/advance-multi-chart')
    },
  },
}
</script>

<style>
html {
  font-size: 14px;
}

.dark-separator {
  color: #c8ebfb;
}

.form-control {
  font-size: inherit !important;
}

.vs__dropdown-menu {
  z-index: 99999 !important;
}

.vs11__dropdown-menu {
  z-index: 99999 !important;
}

.tabs__link {
  padding: 5px 10px !important;
}

/* ADMIN LTE */
.info-box {
  /*box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);*/
  border-radius: .25rem;
  background: #fff;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1rem;
  min-height: 80px;
  padding: .5rem;
  position: relative;
}

.info-box .info-box-icon {
  border-radius: .25rem;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  /* font-size: 1.875rem; */
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  width: 300px;
}

.elevation-1 {
  box-shadow: 0px 0px 9px rgba(10, 80, 40, .15), 11px 10px 17px 5px rgba(0, 0, 1, .24) !important
}

.bg-info {
  background-color: honeydew !important;
}

.info-box .info-box-content {
  -ms-flex: 1;
  flex: 1;
  padding: 5px 10px;
}

.info-box .info-box-text,
.info-box .progress-description {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.info-box .info-box-number {
  display: block;
  font-weight: 700;
}

.vue-notification.success {
  background: #056420 !important;
  border-left-color: #02230b !important;
}

.vue-notification.warn {
  background: #b0632c !important;
  border-left-color: #403506 !important;
}

/* .vue-notification {
  font-size: 1.5em !important;
} */

.red {
  color: red !important
}

.dark-red {
  color: coral !important
}

.red-25 {
  color: #f79ea1 !important
}

.green-dark {
  color: limegreen !important
}

.green {
  color: green !important
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.bold {
  font-weight: bold;
}

.p5 {
  padding: 5px;
}

.p10 {
  padding: 10px;
}

.p15 {
  padding: 15px;
}

.bm-burger-button {
  top: 16px !important;
  left: 16px !important;
}

.grey {
  color: grey
}

body {
  overflow-y: scroll;
  overflow-x: hidden;
}

/* .col-sm-6, .col-sm-7, .col-sm-5, .col-sm-4, .col-sm-8 {
  padding-left: 2px !important;
  padding-right: 2px !important;
} */

/* .material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; } */

.green-notice {
  color: green;
  font-weight: bold;
  margin-bottom: 0px;
}

/* .table th, .table td { 
  vertical-align: middle !important;
} */
/*
#top-right-wrapper {
  right: 1vw !important;
  top: 2.1vw !important;
}
*/

.block {
  display: block;
}

.space-70 {
  display: inline-block;
  width: 70px;
  height: 2px;
}

.space-50 {
  display: inline-block;
  width: 50px;
  height: 2px;
}

.space-30 {
  display: inline-block;
  width: 30px;
  height: 2px;
}

.space-10 {
  display: inline-block;
  width: 10px;
  height: 2px;
}

.space-5 {
  display: inline-block;
  width: 5px;
  height: 2px;
}

.space-2 {
  display: inline-block;
  width: 2px;
  height: 2px;
}

.space-1 {
  display: inline-block;
  width: 1px;
  height: 2px;
}

.optionChainStrikePriceWarning {
  background-color: #ccffcc !important;
}

.chart-icon {
  margin-right: 10px !important;
}

.mt5 {
  margin-top: 5px;
}

.mt10 {
  margin-top: 10px;
}

.fix-at-bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #f0f0f5;
  text-align: center;
}

/* .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
    padding-right: 5px;
    padding-left: 5px;
} */

.atm-row {
  background-color: bisque !important;
}

.atm-strike-row {
  background-color: bisque !important;
  font-weight: bold !important;
  /* font-size: 1.5rem; */
}

.non-atm-strike-row {
  background-color: lightgray !important;
  font-weight: bold !important;
}

.rejected {
  background-color: bisque !important;
}

.completed {
  background-color: lightgreen !important;
}

.pending {
  background-color: lightgrey !important;
}

.squared-off {
  background-color: rgb(233, 238, 243) !important;
}
</style>
