import ChartOptions from '@/services/ChartOptions'
import SymbolService from '../services/SymbolService'
import _ from 'lodash'
import moment from 'moment'
import MarginService from '@/services/MarginService'
import { TransactionType } from '../constants'
import utils from '../services/Utils'
import { useToast } from 'vue-toastification'

//import app from '@/main'
// import store from '../store/'
import Utils from '../services/Utils'

// https://github.com/Maronato/vue-toastification
const toast = useToast()

let actions = {
  showMessageAlert({ state }, message) {
    state.quantity
    toast.error(message)
  },
  brokerConnectionStatusRequest({ dispatch }) {
    let command = {
      MessageType: 'broker_connection_status_request'
    }

    dispatch('sendMessage', JSON.stringify(command))
  },
  
  async getRecentAvailableFeedDate({ state }) {
    state.recentAvailableFeedDate = await Utils.getRecentAvailableFeedDate()
  },

  requestPositionsTechnicalAnalysisDataTrigger({ state }) {
    state.requestPositionsTechnicalAnalysisDataTriggered = Math.floor(Math.random() * 99999)
  },
  sendTestMessage({ dispatch }) {

    let request = {
      MessageType: 'test_message_client_request',
    }

    dispatch('sendMessage', JSON.stringify(request))
  },
  
  getAvailableMargin({ dispatch }, broker) {
    let request = {
      MessageType: 'get_available_margin_request',
      Broker: broker
    }

    dispatch('sendMessage', JSON.stringify(request))
  },
  calculateRealizedPnLAndPoints({ state }) {
    state.orderBaskets.forEach(basket => {
      let realizedPL = 0
      let realizedPLPoints = 0

      if (basket.orders && basket.orders.length === 0) {
        basket.totalPnl = 0
        basket.totalPnlPoints = 0
      }

      basket.squaredOffOrders.forEach(order => {
        if (order.transactionType === 'sell') {
          order.plPoints = order.executedPrice - order.squaredOffPrice
        } else {
          order.plPoints = order.squaredOffPrice - order.executedPrice
        }
        order.realizedPL = order.plPoints * order.lotSize * order.quantity
        order.pl = order.realizedPL
        realizedPLPoints += order.plPoints
        order.plPoints = order.plPoints * order.quantity
        realizedPL += order.realizedPL
      })

      basket.realizedPL = realizedPL
      basket.realizedPLPoints = realizedPLPoints
    })

    // if (state.activeExecutedBasket) {
    //   dispatch("sendAddRemoveExecutedOrderBasketsCommand", {
    //     id: state.activeExecutedBasket.id,
    //     action: 3, // update
    //     basket: state.activeExecutedBasket
    //   })
    // }

  },
  sendClientNotification({ dispatch }, message) {
    let request = {
      MessageType: 'send_bot_client_notification_request',
      Message: message
    }

    dispatch('sendMessage', JSON.stringify(request))
  },
  saveGlobalSettings({ dispatch }, payload) {
    let request = {
      MessageType: 'save_global_settings_request',
      Settings: payload
    }

    dispatch('sendMessage', JSON.stringify(request))
  },
  duplicateBasket({ state, dispatch }, payload) {
    let basket = payload.orderBasket
    let basketCopy = JSON.parse(JSON.stringify(basket));
    basketCopy.strategyName = basket.strategyName + '-dup-' + Math.floor(Math.random() * 99999)
    basketCopy.id = Math.floor(Math.random() * 999999)

    // Update strategy name of orders
    basketCopy.orders.forEach(order => {
      order.strategyName = basketCopy.strategyName
      order.strategyId = basketCopy.id
    })

    // Removed any algo scheduled

    basketCopy.strategyExecutionStatus = 'stopped'  // scheduled, stopped
    basketCopy.strategyExecutionMessages = []

    basketCopy.isTrailing = false
    basketCopy.newStop = 0
    basketCopy.newTarget = 0
    basketCopy.trailBy = 0

    // Preserve SL settings when copying
    // basketCopy.stop = 0
    // basketCopy.target = 0
    // basketCopy.trail = 0
    // basketCopy.isPerLegSL = false
    // basketCopy.perLegSLType = 1  // 1-percent, 2-fixed
    // basketCopy.stopTargetType = 1 // 1-points, 2-premium
    // basketCopy.perLegSLValue = 0          

    if (payload.isTesting) {
      basketCopy.isTesting = true
    }

    state.orderBaskets.push(basketCopy)

    console.log(`duplicateBasket: Sending send AddRemoveExecutedOrderBasketsCommand`)
    dispatch("sendAddRemoveExecutedOrderBasketsCommand", {
      id: basketCopy.id,
      action: 1, //Remove
      basket: basketCopy
    })
  },
  sendBidAskSpreadRequest({ state, dispatch }, symbol) {
    let request = {
      MessageType: 'bar',
      s: symbol
    }

    state.bidAskSpread.loading = true

    dispatch('sendMessage', JSON.stringify(request))
  },
  sendGetRequiredMarginRequest({ dispatch }, payload) {
    let request = {
      MessageType: 'get_required_margin_request',
      MarginInput: {
        Tag: payload.tag,
        Legs: payload.legs
      }
    }

    dispatch('sendMessage', JSON.stringify(request))
  },
  sendUpdateBasketProperties({ state, dispatch }, payload) {
    let request = {
      MessageType: 'update_basket_properties_request',
      TrackingId: state.trackingId,
      PropertyInfo: {
        Id: payload.BasketId,
        UpdateType: payload.UpdateType, // 1 - single, 2 - multiple
        UpdateParamater: payload.UpdateParamater,
        UpdateParamaters: payload.UpdateParamaters,
      }
    }

    dispatch('sendMessage', JSON.stringify(request))
  },
  sendUpdateBasketGroupProperties({ state, dispatch }, payload) {
    let request = {
      MessageType: 'update_basket_group_properties_request',
      TrackingId: state.trackingId,
      PropertyInfo: {
        Id: payload.BasketGroupId,
        UpdateType: payload.UpdateType, // 1 - single, 2 - multiple
        UpdateParamater: payload.UpdateParamater,
        UpdateParamaters: payload.UpdateParamaters,
      }
    }

    dispatch('sendMessage', JSON.stringify(request))
  },
  sendUpdateBasketGroupSLProperties({ state, dispatch }, payload) {
    let request = {
      MessageType: 'update_basket_group_sl_properties_request',
      TrackingId: state.trackingId,
      PropertyInfo: {
        Id: payload.BasketGroupId,
        UpdateType: payload.UpdateType, // 1 - single, 2 - multiple
        UpdateParamater: payload.UpdateParamater,
        UpdateParamaters: payload.UpdateParamaters,
      }
    }

    dispatch('sendMessage', JSON.stringify(request))
  },
  subscribeToLiveFeed({ dispatch }, symbols) {
    let request = {
      MessageType: 'live_feed_symbol_subscription_request',
      Symbols: symbols
    }

    dispatch('sendMessage', JSON.stringify(request))
  },
  sendMessage: function ({state}, message) {
    state.$socket.send(message)
  },
  requestOrderBasketsFromServer({ state, dispatch }) {
    if (state.isExecutedOrderBasketsRefreshedFromServer) return
    state.isLoadingExecutedOrderBasketsFromServer = true

    let getExecutedOrderBasketsRequest = {
      MessageType: 'get_executed_order_baskets_request',
      TrackingId: state.trackingId
    }

    dispatch('sendMessage', JSON.stringify(getExecutedOrderBasketsRequest))
  },
  addExecutedBasketToPayoff({ state, dispatch }, payload) {

    if (payload.addAll) {
      state.executedBasketPayoffBaskets = []
      if (payload.groupId) {
        state.orderBaskets.forEach(x => {
          if (x.includeInAccountPnl && x.groupId === payload.groupId)
            state.executedBasketPayoffBaskets.push(JSON.parse(JSON.stringify(x)))
        })
      } else {
        state.orderBaskets.forEach(x => {
          if (x.includeInAccountPnl)
            state.executedBasketPayoffBaskets.push(JSON.parse(JSON.stringify(x)))
        })
      }
    }
    else {
      if (payload.clear)
        state.executedBasketPayoffBaskets = []

      if (!_.isEmpty(payload.basket)) {
        let index = state.executedBasketPayoffBaskets.findIndex(x => parseInt(x.id) === parseInt(payload.basket.id))
        if (index !== -1 && payload.basket.includeInAccountPnl) return

        state.executedBasketPayoffBaskets.push(JSON.parse(JSON.stringify(payload.basket)))
      }
    }

    dispatch('updateExecutedBasketPayoff')

  },
  removeExecutedBasketFromPayoff({ state, dispatch }, strategyId) {
    var index = state.executedBasketPayoffBaskets.findIndex(x => parseInt(x.id) === parseInt(strategyId))
    if (index === -1) return

    state.executedBasketPayoffBaskets.splice(index, 1)

    dispatch('updateExecutedBasketPayoff')
  },
  async updateMarginRequiredForActiveBasketEditing({ state }, id) {

    if (state.showHideOptionsSimulator) return
    console.log('updateMarginRequiredForActiveBasketEditing ' + id)
    // let basket = state.orderBasketsEditing.find(x => x.id == id)
    // if (!basket) return

    // let response = await MarginService.getRequiredMargin({
    //   instrument: basket.instrument,
    //   isExecuted: false,
    //   orders: basket.orders.filter(x => x.includeInPayoff)
    // })

    // let margin = response.data && response.data.margin ? parseFloat(response.data.margin) : 0

    // basket.marginRequired = margin
    // if (state.activeBasketEditing)
    //   state.activeBasketEditing.marginRequired = margin
  },
  async updateMarginRequiredForActiveExecutedBasket({ state }, id) {

    if (state.showHideOptionsSimulator) return

    console.log('updateMarginRequiredForActiveExecutedBasket ' + id)
    // let basket = state.orderBaskets.find(x => x.id == id)
    // if (!basket) return

    // let margin = 0

    // let orders = basket.orders.filter(x => x.isSquaredOff == false)
    // if (orders.length > 0) {
    //   let response = await MarginService.getRequiredMargin({
    //     instrument: basket.instrument,
    //     isExecuted: true,
    //     orders: orders
    //   })

    //   margin = response.data && response.data.margin ? parseFloat(response.data.margin) : 0
    // }

    // basket.marginRequired = margin

  },
  updateExecutedBasketPayoff({ state }) {


    // Today will be different if running in the option simulator
    // This will be used to build the calendar strategies properly in the option simulator
    let today = moment.now()
    if (state.showHideOptionsSimulator)
      today = state.optionSimulatorParams.onDate

    let nearestStrike = 0
    let strikeUpDownRange = 0
    let strikeIncrementStep = 0
    let instrument = 'nifty'
    let underlyingAtNow = 0

    if (state.executedBasketPayoffBaskets.length === 0) return

    instrument = state.executedBasketPayoffBaskets[0].instrument

    if (instrument == 'nifty') {
      nearestStrike = state.niftyAtmStrike
      strikeUpDownRange = state.strikeUpDownRangeNifty
      strikeIncrementStep = 10
      underlyingAtNow = state.instruments["NIFTY 50"].Ltp
    }
    else if (instrument == 'banknifty') {
      nearestStrike = state.bankNiftyAtmStrike
      strikeUpDownRange = state.strikeUpDownRangeBankNifty
      strikeIncrementStep = 50
      underlyingAtNow = state.instruments["NIFTY BANK"].Ltp
    }
    else if (instrument == 'sensex') {
      nearestStrike = state.sensexAtmStrike
      strikeUpDownRange = state.strikeUpDownRangeSensex
      strikeIncrementStep = 50
      underlyingAtNow = state.instruments["SENSEX"].Ltp
    }    
    else if (instrument == 'finnifty') {
      nearestStrike = state.finNiftyAtmStrike
      strikeUpDownRange = state.strikeUpDownRangeFinNifty
      strikeIncrementStep = 10
      underlyingAtNow = state.instruments["FINNIFTY"].Ltp
    }
    else if (instrument == 'midcpnifty') {
      nearestStrike = state.midcapNiftyAtmStrike
      strikeUpDownRange = state.strikeUpDownRangeMidcapNifty
      strikeIncrementStep = 10
      underlyingAtNow = state.instruments["MIDCPNIFTY"].Ltp
    }

    let xSeries = []
    let start = nearestStrike - strikeUpDownRange
    start = 10 * Math.round(start / 10);
    let end = nearestStrike + strikeUpDownRange
    end = 50 * Math.round(end / 50);

    let isStrikeMatched = false

    let ySeriesData = []
    let maxProfitLossBreakEvens = []
    let maxProfitLossBreakEvensData = {}

    let legPlSum = 0

    // fill xeries
    for (let strike = start; strike <= end; strike += strikeIncrementStep) {
      xSeries.push(strike)
    }

    state.executedBasketPayoffBaskets.forEach(x => {

      let underlyingPrice = 0
      if (x.executedBasketPayOffPrice == "executed") {
        underlyingPrice = x.underlyingPriceAtOpen
        // console.log('Underyling price set when executed: ' + underlyingPrice)
      }
      else {
        // console.log('Getting the latest price for underlying ')
        if (x.instrument === 'nifty')
          underlyingPrice = state.instruments["NIFTY 50"].Ltp
        else if (x.instrument === 'banknifty')
          underlyingPrice = state.instruments["NIFTY BANK"].Ltp
        else if (x.instrument === 'sensex')
          underlyingPrice = state.instruments["SENSEX"].Ltp        
        else if (x.instrument === 'finnifty')
          underlyingPrice = state.instruments["FINNIFTY"].Ltp
        else if (x.instrument === 'midcpnifty')
          underlyingPrice = state.instruments["MIDCPNIFTY"].Ltp
      }

      let yseries = {
        name: x.strategyName,
        data: []
      }

      maxProfitLossBreakEvensData = {
        id: x.id,
        plAtSelectedStrikes: [],
        stikeBreakEvenMap: [],
        maxProfit: 0,
        maxLoss: 0,
        breakEvens: [],
        setupCost: 0
      }

      for (let strike = start; strike <= end; strike += strikeIncrementStep) {
        legPlSum = 0

        // add update realized profit
        if (x.payoffShouldIncludeRealizedPnL)
          legPlSum += x.realizedPL

        //if (x.orders.length == 0)
        //continue

        x.orders.forEach(order => {

          if (order.status == 'new') return

          if (order.isSelectedForAnalysisChart == false) {
            // Do we need ro asdd P/L to the overall p/l, even if not part o analysis
            if (order.isSelectedForAnalysisChartIncludePnL == false) return

            legPlSum += order.pl
            return
          }


          let legPl = 0
          let sign

          // if squared off, just calculate the profit/loss and add to the legplsum
          // else calculate the potenial p/l based on the strike 
          if (order.isSquaredOff) {
            if (order.transactionType === 'buy') {
              legPl = order.squaredOffPrice - order.executedPrice
            } else {
              legPl = order.executedPrice - order.squaredOffPrice
            }

            legPl = legPl * order.lotSize * order.quantity
          }
          else {
            let price = 0
            if (x.executedBasketPayOffPrice == "latest") {
              // console.log('Getting th latets price for order')
              // This is what if we executed this strategy at current price
              // allow us to see the remaining profit or loss we would make
              // if we hold this till expiry
              price = state.instruments[order.symbol].Ltp
            }
            else {
              price = order.executedPrice
              // console.log('Order price is set to executed price ie ' + price)
            }

            // if (x.isMultiExpiry && x.executedBasketPayOffPrice === 'executed') {
            if (x && x.isMultiExpiry) {
              if (!order.isSquaredOff) {
                today = moment(order.executedOn)
                order.bsPrice = SymbolService.getBlackScholesPrice(x, order, today, price, strike, underlyingPrice, state.riskFreeInterest)
              }
              // console.log('isMultiExpiry ttrue, tody is set to executed on and bsPrice is: ' + today + ' : ' + order.bsPrice)
            } else {

              if (order.instrumentType === 'f') {
                legPl = strike - price
              }
              else {
                if (order.optionType === 'ce') {
                  legPl = (Math.max(strike - order.strike, 0) - price)
                } else {
                  legPl = (Math.max(order.strike - strike, 0) - price)
                }
              }

              sign = order.transactionType === 'buy' ? Math.sign((Math.abs(order.quantity))) : Math.sign((-Math.abs(order.quantity)))
              legPl = legPl * sign * order.lotSize * order.quantity
            }
          }

          legPlSum += legPl

          if (order.strike == strike)
            isStrikeMatched = true
        })

        if (x && x.isMultiExpiry && !x.isSquaredOff) {
          legPlSum = SymbolService.getFinalBlackScholesPriceOfABasket(x)
          // console.log('is multi expiry: legpnl some is ' + legPlSum)
        }

        yseries.data.push(legPlSum)

        if (isStrikeMatched) {
          legPlSum = Math.trunc(legPlSum)
          // plAtSelectedStrikes.push(legPlSum)              
          maxProfitLossBreakEvensData.plAtSelectedStrikes.push(legPlSum)
          maxProfitLossBreakEvensData.stikeBreakEvenMap.push({
            strike: strike,
            pl: legPlSum
          })

          isStrikeMatched = false
        }
      }

      if (yseries.data.length > 0) {
        ySeriesData.push(yseries)
        maxProfitLossBreakEvens.push(maxProfitLossBreakEvensData)
      }
    })

    // at zero strike
    state.executedBasketPayoffBaskets.forEach(x => {
      let strike = 0
      let legPlSum = 0

      maxProfitLossBreakEvensData = maxProfitLossBreakEvens.find(maxPL => parseInt(maxPL.id) == parseInt(x.id))
      if (!maxProfitLossBreakEvensData) return

      x.orders.forEach(order => {
        if (order.status == 'new') return

        if (order.isSelectedForAnalysisChart == false) {
          // Do we need ro asdd P/L to the overall p/l, even if not part o analysis
          if (order.isSelectedForAnalysisChartIncludePnL == false) return

          legPlSum += order.pl
          return
        }


        let legPl = 0
        let sign

        let price = 0
        if (x.executedBasketPayOffPrice == "latest")
          price = state.instruments[order.symbol].Ltp
        else
          price = order.executedPrice

        if (order.instrumentType === 'f') {
          legPl = strike - price
        }
        else {
          if (order.optionType === 'ce') {
            legPl = (Math.max(strike - order.strike, 0) - price)
          } else {
            legPl = (Math.max(order.strike - strike, 0) - price)
          }
        }

        sign = order.transactionType === 'buy' ? Math.sign((Math.abs(order.quantity))) : Math.sign((-Math.abs(order.quantity)))
        legPl = legPl * sign * order.lotSize * order.quantity

        legPlSum += legPl

      })

      legPlSum = Math.trunc(legPlSum)

      // add update realized profit
      if (x.payoffShouldIncludeRealizedPnL)
        legPlSum += x.realizedPL

      maxProfitLossBreakEvensData.plAtSelectedStrikes.splice(0, 0, legPlSum)
      maxProfitLossBreakEvensData.stikeBreakEvenMap.splice(0, 0, {
        strike: strike,
        pl: legPlSum
      })

    })

    state.executedBasketPayoffBaskets.forEach(x => {
      let strike = 1000000
      let legPlSum = 0

      maxProfitLossBreakEvensData = maxProfitLossBreakEvens.find(maxPL => parseInt(maxPL.id) == parseInt(x.id))
      if (!maxProfitLossBreakEvensData) return

      x.orders.forEach(order => {

        if (order.status == 'new') return

        if (order.isSelectedForAnalysisChart == false) {
          // Do we need ro asdd P/L to the overall p/l, even if not part o analysis
          if (order.isSelectedForAnalysisChartIncludePnL == false) return

          legPlSum += order.pl
          return
        }


        let legPl = 0
        let sign

        let price = 0
        if (x.executedBasketPayOffPrice == "latest")
          price = state.instruments[order.symbol].Ltp
        else
          price = order.executedPrice

        if (order.instrumentType === 'f') {
          legPl = strike - price
        }
        else {
          if (order.optionType === 'ce') {
            legPl = (Math.max(strike - order.strike, 0) - price)
          } else {
            legPl = (Math.max(order.strike - strike, 0) - price)
          }
        }

        sign = order.transactionType === 'buy' ? Math.sign((Math.abs(order.quantity))) : Math.sign((-Math.abs(order.quantity)))
        legPl = legPl * sign * order.lotSize * order.quantity

        legPlSum += legPl
      })

      legPlSum = Math.trunc(legPlSum)

      // add update realized profit
      if (x.payoffShouldIncludeRealizedPnL)
        legPlSum += x.realizedPL

      maxProfitLossBreakEvensData.plAtSelectedStrikes.push(legPlSum)
      maxProfitLossBreakEvensData.stikeBreakEvenMap.push({
        strike: strike,
        pl: legPlSum
      })
    })

    maxProfitLossBreakEvens.forEach(x => {

      let basket = state.orderBaskets.find(b => parseInt(b.id) === parseInt(x.id))

      let maxProfit = -1
      let maxLoss = 1

      if (basket && basket.isMultiExpiry) {
        maxProfit = Math.max(...x.stikeBreakEvenMap.map(o => o.pl), 0);
        if (maxProfit < 0)
          maxProfit = 0

        maxLoss = basket.setupCost

      } else {
        if (x.plAtSelectedStrikes[x.plAtSelectedStrikes.length - 1] > x.plAtSelectedStrikes[x.plAtSelectedStrikes.length - 2] ||
          x.plAtSelectedStrikes[0] > x.plAtSelectedStrikes[1])
          maxProfit = -1
        else {
          let strikesCloned = [...x.plAtSelectedStrikes]
          strikesCloned.splice(strikesCloned.length - 1)
          maxProfit = Math.max(...strikesCloned)
        }

        if (x.plAtSelectedStrikes[x.plAtSelectedStrikes.length - 1] < x.plAtSelectedStrikes[x.plAtSelectedStrikes.length - 2] ||
          x.plAtSelectedStrikes[0] < x.plAtSelectedStrikes[1])
          maxLoss = 1
        else {
          let strikesCloned = [...x.plAtSelectedStrikes]
          strikesCloned.splice(strikesCloned.length - 1)
          maxLoss = Math.min(...strikesCloned)
        }
      }

      let breakEvens = []

      for (let i = 0; i < x.stikeBreakEvenMap.length; i++) {
        if (i >= 1) {
          let previousPl = x.stikeBreakEvenMap[i - 1].pl
          let currentPl = x.stikeBreakEvenMap[i].pl
          var sign = Math.sign(currentPl) == Math.sign(previousPl) ? 0 : 1
          if (sign == 1) {
            let previousStrike = x.stikeBreakEvenMap[i - 1].strike
            let currentStrike = x.stikeBreakEvenMap[i].strike
            let breakEventPoint = previousStrike + (currentStrike - previousStrike) * (0 - previousPl) / (currentPl - previousPl)
            breakEvens.push(Math.trunc(breakEventPoint))
          }
        }
      }

      x.maxProfit = maxProfit
      x.maxLoss = maxLoss
      x.breakEvens = breakEvens
      x.setupCost = basket.setupCost

      basket.breakEvens = x
    })

    let totalPnlYSeries = {
      name: 'Total PnL',
      data: []
    }

    if (ySeriesData.length > 0)
      totalPnlYSeries.data = Array(ySeriesData[0].data.length).fill(0)

    ySeriesData.forEach(x => {
      for (let i = 0; i < x.data.length; i++) {
        totalPnlYSeries.data[i] = totalPnlYSeries.data[i] + x.data[i]
      }
    })

    ySeriesData.push(totalPnlYSeries)

    state.executedBasketsMaxProfitLossBreakEvens = maxProfitLossBreakEvens
    state.executedBasketsChartOptions.options = ChartOptions.executedPayoff('PayOffExecutedBaskets', xSeries, underlyingAtNow, strikeIncrementStep)
    state.executedBasketsChartOptions.series = ySeriesData
  },
  showHideOptionChain({ state }) {
    state.showHideOptionChain = !state.showHideOptionChain
  },
  triggerFeedArrived({ state }) {
    state.feedArrived = Math.random()
  },
  setBrokers({state}, brokers) {
    state.brokers = brokers
  },
  setFeedSourceId({state}, payload) {
    if (payload.type == "PRIMARY") {
      state.primaryFeedSourceId = payload.id
    } else if (payload.type == "STANDBY") {
      state.standbyFeedSourceId = payload.id
    }
  },
  requestOptionChain: function ({ state, commit }, payload) {
    state.$socket.send(JSON.stringify({
      MessageType: 'option_chain_request',
      Instrument: payload.instrument,
      Expiry: payload.expiry
    }))

    commit("OptionChainRequested")
  },
  requestOptionsSimulatorOptionChain: function ({ state }, payload) {
    
    // DO NOT ENABLE LOADING FOR SIMULATOR
    // IT JUST UNNECESSARY DISPLAYES THE LOADER.. SINCE READING FROM DB IS VERY FAST
    // state.isLoadingOptionChain = true;

    let request = {}

    if (payload.simulatorType == 'ex') {
      request = {
        MessageType: 'options_simulator_option_chain_request',
        ForExpiry: payload.forExpiry,
        Instrument: payload.instrument,
        StrikeInterval: payload.strikeInterval,
        StrikeUpDownMaxRange: payload.strikeUpDownMaxRange,
        OnTimeStamp: payload.onTimeStamp,
      }
    } else {
      request = {
        MessageType: 'options_simulator_option_chain_request',
        Symbol: payload.symbol,
        TimeStamp: payload.timestamp,
        Expiry: payload.expiry
      }
    }

    state.$socket.send(JSON.stringify(request))

    if (state.accountPnL)
      console.log('')
  },
  async getInstrumentInfos({state}) {
    state.instrumentInfos = await utils.getInstrumentInfos()
  },
  async optionChainInstrumentChanged({ state, dispatch }) {
    let forDate = Date.now();

    if (state.executionMode == "SIMULATION") {
      let momentDate = moment(state.optionSimulatorParams.onDate)
      console.log('optionChainInstrumentChanged: ' +  momentDate.format())
      console.log('moment date timestamp : ' + momentDate.unix())
      forDate = momentDate.unix()
    }

    state.optionChainExpiries = await utils.getExpiries(forDate, state.activeOptionChain, "all")
    state.selectedOptionChainExpiry = state.optionChainExpiries[0]

    dispatch('setActiveOptionChain', { instrument: state.activeOptionChain, expiry: state.selectedOptionChainExpiry })    
  },  
  setActiveOptionChain({ state, dispatch }, payload) {
    state.activeOptionChain = payload.instrument

    if (state.executionMode == 'LIVE') {
      dispatch('requestOptionChain', {
        instrument: payload.instrument,
        expiry: payload.expiry
      })
    } else {
      // notify OptionSimulatorComponent to fetch option chain for selected expiry
      if (state.activeOptionChain == "nifty") {
        state.optionSimulatorParams.strikeIntervalValue = state.optionSimulatorParams.strikeIntervalNifty

        if (!state.optionSimulatorParams.strikeUpDownMaxRangeNiftyUpdated)
          state.optionSimulatorParams.strikeUpDownMaxRangeValue = state.optionSimulatorParams.strikeUpDownMaxRangeNifty

        state.optionSimulatorParams.strikeMaxRangeStep = state.optionSimulatorParams.strikeMaxRangeStepNifty
        state.optionSimulatorParams.selectedSymbol = "NIFTY 50"
      } else if (state.activeOptionChain == "banknifty") {
        state.optionSimulatorParams.strikeIntervalValue = state.optionSimulatorParams.strikeIntervalNiftyBank

        if (!state.optionSimulatorParams.strikeUpDownMaxRangeNiftyBankUpdated)
          state.optionSimulatorParams.strikeUpDownMaxRangeValue = state.optionSimulatorParams.strikeUpDownMaxRangeNiftyBank

        state.optionSimulatorParams.strikeMaxRangeStep = state.optionSimulatorParams.strikeMaxRangeStepNiftyBank
        state.optionSimulatorParams.selectedSymbol = "NIFTY BANK"
      } else if (state.activeOptionChain == "sensex") {
        state.optionSimulatorParams.strikeIntervalValue = state.optionSimulatorParams.strikeIntervalNiftyBank

        if (!state.optionSimulatorParams.strikeUpDownMaxRangeSensexUpdated)
          state.optionSimulatorParams.strikeUpDownMaxRangeValue = state.optionSimulatorParams.strikeUpDownMaxRangeSensex

        state.optionSimulatorParams.strikeMaxRangeStep = state.optionSimulatorParams.strikeMaxRangeStepSensex
        state.optionSimulatorParams.selectedSymbol = "SENSEX"        
      } else if (state.activeOptionChain == "finnifty") {
        state.optionSimulatorParams.strikeIntervalValue = state.optionSimulatorParams.strikeIntervalFinNifty

        if (!state.optionSimulatorParams.strikeUpDownMaxRangeFinNiftyUpdated)
          state.optionSimulatorParams.strikeUpDownMaxRangeValue = state.optionSimulatorParams.strikeUpDownMaxRangeFinNifty

        state.optionSimulatorParams.strikeMaxRangeStep = state.optionSimulatorParams.strikeMaxRangeStepFinNifty
        state.optionSimulatorParams.selectedSymbol = "FINNIFTY"
      } else if (state.activeOptionChain == "midcpnifty") {
        state.optionSimulatorParams.strikeIntervalValue = state.optionSimulatorParams.strikeIntervalMidcapNifty

        if (!state.optionSimulatorParams.strikeUpDownMaxRangeMidcapNiftyUpdated)
          state.optionSimulatorParams.strikeUpDownMaxRangeValue = state.optionSimulatorParams.strikeUpDownMaxRangeMidcapNifty

        state.optionSimulatorParams.strikeMaxRangeStep = state.optionSimulatorParams.strikeMaxRangeStepMidcapNifty
        state.optionSimulatorParams.selectedSymbol = "MIDCPNIFTY"
      }

      dispatch('requestOptionsSimulatorOptionChain', {
        simulatorType: 'ex', // ex - new, ops - opst
        onTimeStamp: moment(state.optionSimulatorParams.selectedTimeInUnix).format('DD-MMM-YYYY HH:mm'),
        forExpiry: state.selectedOptionChainExpiry,
        instrument: state.activeOptionChain,
        strikeInterval: state.optionSimulatorParams.strikeIntervalValue,
        strikeUpDownMaxRange: state.optionSimulatorParams.strikeUpDownMaxRangeValue
      })
    }
  },
  sendSubscribedSymbolsLtpRefreshRequest({state}) {
    state.$socket.send(JSON.stringify({
      MessageType: 'subscribed_symbols_ltp_refresh_request'
    }))
  },

  // showHideOIChangeAnalysisUpdate({state}) {
  //   state.showHideOIChangeAnalysis = !state.showHideOIChangeAnalysis
  // },
  showHideBasketEditorUpdate({ state }) {
    state.showHideBasketEditor = !state.showHideBasketEditor
  },
  showHideOptionsSimulatorUpdate({ state }) {
    state.showHideOptionsSimulator = !state.showHideOptionsSimulator
  },
  showHideChartsInOptionChainAction({ state }) {
    state.showHideChartsInOptionChain = !state.showHideChartsInOptionChain
  },
  showHideRiskManagementProfileAction({ state }) {
    state.showHideRiskManagementProfile = !state.showHideRiskManagementProfile
  },
  showHideAlgoSchedulerSettingsAction({ state }) {
    state.showHideAlgoSchedulerSettings = !state.showHideAlgoSchedulerSettings
  },
  showHideExecutedOrderBasketsUpdate({ state }) {
    state.showHideExecutedOrderBaskets = !state.showHideExecutedOrderBaskets
  },
  showHideBrokerInfoPanel({ state }) {
    state.brokerInfoPanelDisplayStatus = !state.brokerInfoPanelDisplayStatus
  },
  createNewBasketForEditing({ state }, options) {
    console.log('Creating order basket ' + Date.now() + ' options ' + JSON.stringify(options))
    let executedOn = moment(Date.now()).format('DD-MMM-YYYY HH:mm')
    if (state.showHideOptionsSimulator) {
      executedOn = moment(state.optionSimulatorParams.selectedTimeInUnix).format('DD-MMM-YYYY HH:mm')
    }

    let instrument = state.activeOptionChain
    let groupId = 1
    if (options) {
      if (options.instrument)
        instrument = options.instrument
      if (options.groupId && options.groupId >= 1)
        groupId = options.groupId
    }

    console.log('settting group i as ' + groupId)
    state.activeBasketEditing = {
      id: Math.floor(Math.random() * 100000) + 1,
      groupId: groupId,
      squaredOffOrders: [],
      realizedPL: 0.0,
      realizedPLPoints: 0.0,
      totalPnlPoints: 0.0,
      totalPnlPointsActualLots: 0.0,

      atOpenCombinedPremium: 0.0,
      atCloseCombinedPremium: 0.0,
      currentCombinedPremium: 0.0,

      showPremiumAndPointsForOneLot: true,

      atOpenCombinedPremiumActualsLots: 0.0,
      atCloseCombinedPremiumActualsLots: 0.0,
      currentCombinedPremiumActualsLots: 0.0,

      payoffShouldIncludeRealizedPnL: true,
      isSentForBasketStopSquareOff: false,
      isSelectedForAnalysisChart: true,
      isSelectedForAnalysisChartIncludePnL: true,

      orders: [],
      instrument: instrument,
      isTesting: false,
      isSquaredOff: false,
      stop: 0,
      target: 0,
      trail: 0,
      isPerLegSL: false,
      isPerLegSLHitOpenNew: false,
      oppositeQtyToAdd: 0,
      isShiftToNextOTM: false,
      deltaBalanceStrikeDifference: 0,
      ExecutionMode: 1,
      isPerLegSLReEntry: false,
      isStayOTM: false,
      perLegSLType: 1, // 1-percent, 2-fixed
      stopTargetType: 1, // 1-points, 2-premium
      perLegSLValue: 0,
      shiftStrikes: false,

      isFuturesSL: false,
      futuresSLType: 1,

      slTargetBasedOnCandlePrice: false,
      fixedCandleSL: false,
      underlyingSLCandlePriceType: 1,
      underlyingSLCandlePriceCheckType: 2,
      underlyingSLCandlesLookbackCount: 3,

      persona: 2, // seller
      signal: 1, // buy      

      futuresSLUnderlyingType: 1, // spot =1, futures = 2
      futuresSLComparisionType: '<=', // <= or >=
      futuresTargetComparisionType: '>=', // <= or >=
      futuresSLValue: 1,
      futuresTargetValue: 1,

      checkExitTime: false,
      exitTime: '15:20:00',

      squareOffMode: 'manual', // manual or auto
      lotSize: 0,
      isStopTargetHit: false,
      executedBasketPayOffPrice: 'executed', // executed or latest
      underlyingPriceAtOpen: 0,
      futuresPriceAtOpen: 0,
      vixWhenExecuted: 0,
      includeInPayoff: true,
      marginRequired: 0,
      isMultiExpiry: false,
      setupCost: 0,
      isAdjusting: false,
      executedOn: executedOn,
      showGreeks: false,
      brokerageAndTaxes: 0,
      squaredOffOn: new Date(),
      underlyingPriceAtClose: 0.0,
      futuresPriceAtClose: 0.0,
      VixAtSquarOff: 0.0,
      includeInAccountPnl: true,

      capital: 100000,
      maxLossType: 'percent',
      accountMaxLossSL: 1,
      optionPrice: 300,
      premiumSLType: 'percent',
      positionMaxLossSL: 20,
      lotsToTrade: 0,

      isTrailing: false,
      newStop: 0,
      newTarget: 0,
      trailBy: 0,

      editing: false,

      showHideAdjustments: true,
      showHideAlgoPanel: true,
      showHideTechnicalAnalysisPanel: false,
      showHideOrdersPanel: true,
      showHideInfoPanel: true,

      positionTechnicalAnalysisData: {},
      showHidePortfolioSummaryTabs: true,
      isExpanded: false,

      chartInterval: 1,
      chartType: 'spline',

      bsPrice: 0,
    }

    state.orderBasketsEditing.push(state.activeBasketEditing)
  },
  async refreshGreeks({ state }, payload) {
    // payload.id => id of the basket
    // payload.basketStatus => executed or editing

    if (payload.basketStatus === 'editing') {
      if (!state.activeBasketEditing) return
      if (!state.activeBasketEditing.orders) return

      // Get greek with new code
      let response = await MarginService.getGreeks({
        instrument: state.activeBasketEditing.instrument,
        isExecuted: false,
        expiry: state.expiries[0],
        orders: state.activeBasketEditing.orders.filter(x => x.includeInPayoff)
      })

      let greeksRs = response.data.greeks

      state.activeBasketEditing.orders.forEach(order => {

        let query = `${order.transactionType === 'buy' ? '+' : '-'}${order.quantity}x`

        query += ` ${moment(order.expiry).format('DDMMMYYYY').toUpperCase()}`
        query += ` ${order.strike}${order.optionType.toUpperCase()}`

        let greek = greeksRs.filter(x => x.Position === query)[0]
        let greekFormat = {
          delta: greek.Delta,
          theta: greek.Theta / 100,
          gamma: greek.Gamma / 100,
          vega: greek.Vega / 100,
          iv: parseFloat(greek.IV.toFixed(2))
        }

        order.greeksAtNow = greekFormat
        order.greeksAtExecution = greekFormat
      })
    } else {
      if (!state.activeExecutedBasket) return
      if (!state.activeExecutedBasket.orders) return

      // Get greek with new code
      let response = await MarginService.getGreeks({
        instrument: state.activeExecutedBasket.instrument,
        isExecuted: false,
        expiry: state.expiries[0],
        orders: state.activeExecutedBasket.orders.filter(x => x.includeInPayoff)
      })

      let greeksRs = response.data.greeks

      state.activeExecutedBasket.orders.forEach(order => {
        let query = `${order.transactionType === 'buy' ? '+' : '-'}${order.quantity}x`

        query += ` ${moment(order.expiry).format('DDMMMYYYY').toUpperCase()}`
        query += ` ${order.strike}${order.optionType.toUpperCase()}`

        let greek = greeksRs.filter(x => x.Position === query)[0]
        let greekFormat = {
          delta: greek.Delta,
          theta: greek.Theta / 100,
          gamma: greek.Gamma / 100,
          vega: greek.Vega / 100,
          iv: parseFloat(greek.IV.toFixed(2))
        }

        order.greeksAtNow = greekFormat
      })
    }
  },

  executeNewOrderBasketTrigger({ state, dispatch }, payload) {

    if (Object.keys(state.activeExecutedBasket).length === 0) {
      dispatch('showMessageAlert', `Please select an executed basket to execute this order and add to that.`)
      return
    }

    state.newOrderToBeExecuted = payload

    state.executeNewOrderFromOptionChainTrigger = Date.now()
  },

  executeNewOrder({ state, dispatch }, payload) {

    var executionErrors = []

    state.orderBaskets.forEach(orderBasket => {
      orderBasket.orders.forEach(executedOrder => {
        if (payload.symbol === executedOrder.symbol
          && payload.brokerId == executedOrder.brokerId
          && payload.product == executedOrder.product
          && executedOrder.status === 'complete'
          && executedOrder.isSquaredOff == false
          && executedOrder.isTesting == false
          && payload.transactionType != executedOrder.transactionType) {
          executionErrors.push(`Symbol <b>${payload.symbol}</b> is already added to the strategy <b>${orderBasket.strategyName}</b>.
              Executing this order will square off from that basket. You can try executing it with a different broker.`)
        }
      });
    })

    if (executionErrors.length > 0) {
      executionErrors.forEach(message => {
        dispatch('showMessageAlert', message)
      })
      return
    }

    // Do we have the same symbol already add
    // show the alert and dont add it again
    var orderExisting = state.activeExecutedBasket.orders.find(x => x.symbol === payload.symbol && x.status != 'rejected')
    if (orderExisting) {
      dispatch('showMessageAlert', `Can not execute this order. There already exists the active position for this symbol [${payload.symbol}]`)
      return
    }

    let priceWhenAddedToBasket = state.instruments[payload.symbol].Ltp
    let expiry = moment(payload.expiry).format('DD-MMM-YYYY').toUpperCase()

    let underlyingPrice = 0
    let exchange = "NFO";

    if (payload.instrument === 'nifty')
      underlyingPrice = state.instruments['NIFTY 50'].Ltp
    else if (payload.instrument === 'banknifty')
      underlyingPrice = state.instruments['NIFTY BANK'].Ltp
    else if (payload.instrument === 'sensex') {
      underlyingPrice = state.instruments['SENSEX'].Ltp;
      exchange = "BFO";
    }     
    else if (payload.instrument === 'finnifty')
      underlyingPrice = state.instruments['FINNIFTY'].Ltp
    else if (payload.instrument === 'midcpnifty')
      underlyingPrice = state.instruments['MIDCPNIFTY'].Ltp

    let futuresPriceObj = state.instruments[SymbolService.getFuturesSymbolCurrent(payload.instrument, state.monthlies[0])]
    let futuresPrice = 0

    if (futuresPriceObj)
      futuresPrice = futuresPriceObj.Ltp

    let greeks = SymbolService.getGreeks(priceWhenAddedToBasket, underlyingPrice, payload.strike, payload.expiry, state.riskFreeInterest, payload.optionType, payload.transactionType, payload.instrumentType)

    let executedOn = moment(Date.now()).format('DD-MMM-YYYY HH:mm')
    if (state.showHideOptionsSimulator) {
      executedOn = moment(state.optionSimulatorParams.selectedTimeInUnix).format('DD-MMM-YYYY HH:mm')
    }

    let order = {
      id: Math.floor(Math.random() * 999999),
      optionType: payload.optionType,
      symbol: payload.symbol,
      strike: payload.strike,
      transactionType: payload.transactionType,
      quantity: payload.quantity,
      lotSize: SymbolService.getMarketLot(payload.instrument),
      priceWhenAddedToBasket: priceWhenAddedToBasket,
      expiry: payload.expiry,
      bsPrice: 0,
      includeInPayoff: true,
      greeksAtExecution: greeks,
      greeksAtNow: greeks,
      expiryEdited: expiry,
      instrumentType: payload.instrumentType,
      underlyingPriceAtOpen: underlyingPrice,
      underlyingPriceAtClose: 0.0,
      futuresPriceAtOpen: futuresPrice,
      futuresPriceAtClose: 0.0,
      status: 'pending',
      squaredOff: false,
      executedPrice: state.instruments[payload.symbol].Ltp,
      brokerId: payload.brokerId, // get from the old order active or squared off
      pl: 0.0,
      isSquaredOff: false,
      isSelectedForAnalysisChart: true,
      isSelectedForAnalysisChartIncludePnL: true,
      exchange: exchange,
      checked: false,
      product: payload.product, // get from old order or squaed off order
      squaredOffPrice: 0.0,
      strategyName: state.activeExecutedBasket.strategyName,
      strategyId: state.activeExecutedBasket.id,
      reason: '',
      squareOffInitiated: false,
      executedOn: executedOn,
      editing: false,
    }

    // order.brokerageAndTaxes = SymbolService.calculateTaxesOptions(order.priceWhenAddedToBasket, order.quantity * order.lotSize, order.transactionType, order.broker, order.instrumentType)
    order.brokerageAndTaxes = 0
    state.activeExecutedBasket.orders.push(order)

    dispatch('subscribeToLiveFeed', [{
      Exchange: order.exchange,
      Symbol: payload.symbol
    }])

    // Finally send for execution
    let executeOrderBasketCommand = {
      MessageType: 'execute_basket_order_command',
      Orders: [{
        Exchange: order.exchange,
        Symbol: order.symbol,
        TransactionType: order.transactionType === 'buy' ? TransactionType.BUY : TransactionType.SELL, // Reverse the transaction type
        Quantity: order.quantity * order.lotSize,
        BrokerId: order.brokerId,
        Product: order.product,
        Tag: order.strategyId,
        StrategyId: order.strategyId,
        InstrumentType: order.instrumentType,
        OptionType: order.optionType,
        Strike: order.strike,
        BaseSymbol: order.instrumentType
      }],
      IsTesting: state.activeExecutedBasket.isTesting,
      BasketId: state.activeExecutedBasket.id,
    }

    // Order whenther test or real, executed on server... no need for client side checl
    // if (state.activeExecutedBasket.isTesting) {
    //   order.status = 'complete'
    //   order.executedPrice = state.instruments[order.symbol].Ltp

    //   dispatch("sendAddRemoveExecutedOrderBasketsCommand", {
    //     id: state.activeExecutedBasket.id,
    //     action: 3, 
    //     basket: state.activeExecutedBasket
    //   })         
    // }
    console.log(`executeNewOrder: Sending send AddRemoveExecutedOrderBasketsCommand`)
    dispatch("sendAddRemoveExecutedOrderBasketsCommand", {
      id: state.activeExecutedBasket.id,
      action: 3,
      basket: state.activeExecutedBasket
    })
    dispatch("sendMessage", JSON.stringify(executeOrderBasketCommand))
  },
  showHideFuturesPanelAction({ state }) {
    state.showHideFuturesPanel = !state.showHideFuturesPanel
  },
  showHideOptionsChainPanelAction({ state }) {
    state.showHideOptionsChainPanel = !state.showHideOptionsChainPanel
  },
  addToBasket({ state, dispatch }, payload) {
    if (!state.instruments[payload.symbol]) return
    let priceWhenAddedToBasket = state.instruments[payload.symbol].Ltp
    let expiry = moment(payload.expiry).format('DD-MMM-YYYY').toUpperCase()

    let underlyingPrice = 0
    let exchange = "NFO";

    if (payload.instrument === 'nifty')
      underlyingPrice = state.instruments['NIFTY 50'] ? state.instruments['NIFTY 50'].Ltp : 0
    else if (payload.instrument === 'banknifty')
      underlyingPrice = state.instruments['NIFTY BANK'] ? state.instruments['NIFTY BANK'].Ltp : 0
    else if (payload.instrument === 'sensex') {
      underlyingPrice = state.instruments['SENSEX'] ? state.instruments['SENSEX'].Ltp : 0;
      exchange = "BFO"; 
    }
    else if (payload.instrument === 'finnifty')
      underlyingPrice = state.instruments['FINNIFTY'] ? state.instruments['FINNIFTY'].Ltp : 0
    else if (payload.instrument === 'midcpnifty')
      underlyingPrice = state.instruments['MIDCPNIFTY'] ? state.instruments['MIDCPNIFTY'].Ltp : 0

    let futuresPriceObj = state.instruments[SymbolService.getFuturesSymbolCurrent(payload.instrument, state.monthlies[0])]
    let futuresPrice = 0

    if (futuresPriceObj)
      futuresPrice = futuresPriceObj.Ltp

    if (state.activeBasketEditing && state.activeBasketEditing.orders) {

      if (state.activeBasketEditing.orders.length > 0)
        if (state.activeBasketEditing.instrument !== payload.instrument) {
          dispatch('showMessageAlert', `Can not add leg to the basket since instrument[${payload.instrument}] is different than other instruments [${state.activeBasketEditing.instrument}] added to this basket. `)
          return
        }

      var order = state.activeBasketEditing.orders.find(x => x.symbol === payload.symbol)
      let greeks = SymbolService.getGreeks(priceWhenAddedToBasket, underlyingPrice, payload.strike, payload.expiry, state.riskFreeInterest, payload.optionType, payload.transactionType, payload.instrumentType)

      if (!order) {
        state.activeBasketEditing.orders.push({
          id: Math.floor(Math.random() * 999999),
          optionType: payload.optionType,
          symbol: payload.symbol,
          strike: payload.strike,
          transactionType: payload.transactionType,
          quantity: payload.quantity ? payload.quantity : 1,
          lotSize: SymbolService.getMarketLot(payload.instrument),
          priceWhenAddedToBasket: priceWhenAddedToBasket,
          expiry: payload.expiry,
          bsPrice: 0,
          includeInPayoff: true,
          greeksAtExecution: greeks,
          greeksAtNow: greeks,
          expiryEdited: expiry,
          status: 'old',
          executedPrice: priceWhenAddedToBasket,
          brokerageAndTaxes: 0,
          instrumentType: payload.instrumentType,
          isSquaredOff: false,
          isSelectedForAnalysisChart: true,
          isSelectedForAnalysisChartIncludePnL: true,
          exchange: exchange,
          underlyingPriceAtOpen: underlyingPrice,
          underlyingPriceAtClose: 0.0,
          futuresPriceAtOpen: futuresPrice,
          futuresPriceAtClose: 0.0,
        })

        dispatch('subscribeToLiveFeed', [{
          Exchange: exchange,
          Symbol: payload.symbol          
        }])
      }
      else {
        order.quantity = parseInt(order.quantity) + 1
        dispatch('showMessageAlert', `${payload.symbol} is already added. Increasing the quantity.`)
      }
    } else {
      // Create a new oder basket for editing
      // make it active
      // any add to basket should be added to this one
      let greeks = SymbolService.getGreeks(priceWhenAddedToBasket, underlyingPrice, payload.strike, payload.expiry, state.riskFreeInterest, payload.optionType, payload.transactionType, payload.instrumentType)

      let executedOn = moment(Date.now()).format('DD-MMM-YYYY HH:mm')
      if (state.showHideOptionsSimulator) {
        executedOn = moment(state.optionSimulatorParams.selectedTimeInUnix).format('DD-MMM-YYYY HH:mm')
      }

      state.activeBasketEditing = {
        id: Math.floor(Math.random() * 100000) + 1,
        groupId: 1,
        instrument: payload.instrument,
        isSquaredOff: false,
        isTesting: false,
        stop: 0,
        target: 0,
        trail: 0,
        isPerLegSL: false,
        isPerLegSLHitOpenNew: false,
        isShiftToNextOTM: false,
        oppositeQtyToAdd: 0,
        deltaBalanceStrikeDifference: 0,
        ExecutionMode: 1,
        isPerLegSLReEntry: false,
        isStayOTM: false,
        perLegSLType: 1, // 1-percent, 2-fixed
        stopTargetType: 1, // 1-points, 2-premium
        perLegSLValue: 0,
        shiftStrikes: false,

        isFuturesSL: false,
        futuresSLType: 1, // Percent, Value, Candle

        slTargetBasedOnCandlePrice: false,
        fixedCandleSL: false,
        underlyingSLCandlePriceType: 1,
        underlyingSLCandlePriceCheckType: 2,
        underlyingSLCandlesLookbackCount: 3,

        persona: 2, // seller
        signal: 1, // buy

        futuresSLUnderlyingType: 1,
        futuresSLComparisionType: '<=', // <= or >=
        futuresTargetComparisionType: '>=', // <= or >=
        futuresSLValue: 1,
        futuresTargetValue: 1,

        checkExitTime: true,
        exitTime: '15:20:00',

        squareOffMode: 'manual',
        lotSize: SymbolService.getMarketLot(payload.instrument),
        isStopTargetHit: false,
        executedBasketPayOffPrice: 'executed',
        underlyingPriceAtOpen: underlyingPrice,
        vixWhenExecuted: 0,
        includeInPayoff: true,
        marginRequired: 0,
        isMultiExpiry: false,
        setupCost: 0,
        isAdjusting: false,
        executedOn: executedOn,
        showGreeks: false,
        brokerageAndTaxes: 0,
        squaredOffOn: new Date(),
        underlyingPriceAtClose: 0.0,
        futuresPriceAtOpen: futuresPrice,
        futuresPriceAtClose: 0.0,
        VixAtSquarOff: 0.0,
        includeInAccountPnl: true,
        squaredOffOrders: [],
        realizedPL: 0.0,
        realizedPLPoints: 0.0,
        totalPnlPoints: 0.0,
        totalPnlPointsActualLots: 0.0,

        atOpenCombinedPremium: 0.0,
        atCloseCombinedPremium: 0.0,
        currentCombinedPremium: 0.0,

        showPremiumAndPointsForOneLot: true,

        atOpenCombinedPremiumActualsLots: 0.0,
        atCloseCombinedPremiumActualsLots: 0.0,
        currentCombinedPremiumActualsLots: 0.0,

        payoffShouldIncludeRealizedPnL: true,
        isSentForBasketStopSquareOff: false,

        isSelectedForAnalysisChart: true,
        isSelectedForAnalysisChartIncludePnL: true,

        capital: 100000,
        maxLossType: 'percent',
        accountMaxLossSL: 1,
        optionPrice: 300,
        premiumSLType: 'percent',
        positionMaxLossSL: 20,
        lotsToTrade: 0,

        isTrailing: false,
        newStop: 0,
        newTarget: 0,
        trailBy: 0,
        editing: false,
        showHideAdjustments: true,
        showHideAlgoPanel: true,
        showHideTechnicalAnalysisPanel: false,
        showHideOrdersPanel: true,
        showHideInfoPanel: true,

        positionTechnicalAnalysisData: {},
        showHidePortfolioSummaryTabs: true,
        chartInterval: 1,
        chartType: 'spline',

        isExpanded: false,

        orders: [{
          id: Math.floor(Math.random() * 999999),
          optionType: payload.optionType,
          symbol: payload.symbol,
          strike: payload.strike,
          transactionType: payload.transactionType,
          quantity: payload.quantity ? payload.quantity : 1,
          lotSize: SymbolService.getMarketLot(payload.instrument),
          priceWhenAddedToBasket: state.instruments[payload.symbol].Ltp,
          executedPrice: state.instruments[payload.symbol].Ltp,
          executedOn: executedOn,
          editing: false,
          expiry: payload.expiry,
          bsPrice: 0,
          includeInPayoff: true,
          greeksAtExecution: greeks,
          greeksAtNow: greeks,
          expiryEdited: expiry,
          status: 'old',
          brokerageAndTaxes: 0,
          instrumentType: payload.instrumentType,
          isSquaredOff: false,
          strategyId: state.activeBasketEditing.id,
          isSelectedForAnalysisChart: true,
          isSelectedForAnalysisChartIncludePnL: true,
          exchange: exchange,
          underlyingPriceAtOpen: underlyingPrice,
          underlyingPriceAtClose: 0.0,
          futuresPriceAtOpen: futuresPrice,
          futuresPriceAtClose: 0.0,
        }],
      }

      dispatch('subscribeToLiveFeed', [{
        Exchange: exchange,
        Symbol: payload.symbol        
      }])

      state.orderBasketsEditing.push(state.activeBasketEditing)
    }

    dispatch('updateEditedBasketsSetupCostAndMultiExpiryFlag')
    dispatch('updateBasketEditorChart', payload.instrument)
    dispatch("updateMarginRequiredForActiveBasketEditing", state.activeBasketEditing.id)
  },
  updateEditedBasketsSetupCostAndMultiExpiryFlag({ state }) {
    if (state.orderBasketsEditing.length === 0) return

    state.orderBasketsEditing.forEach(basket => {
      // if (basket.isAdjusting) return

      let orders = basket.orders.filter(x => x.includeInPayoff)
      // sum of all debits - sum of all credits
      let longs = orders.filter(x => x.transactionType === 'buy' && x.instrumentType === 'o')
      let shorts = orders.filter(x => x.transactionType === 'sell' && x.instrumentType === 'o')

      let debits = 0
      for (let i = 0; i < longs.length; i++) {
        let symbol = longs[i].symbol
        let ltp = state.instruments[symbol] ? state.instruments[symbol].Ltp : 0 
        let price = basket.executedBasketPayOffPrice === "executed" ? ltp : longs[i].priceWhenAddedToBasket; 
        debits += price * longs[i].lotSize * longs[i].quantity
      }

      let credits = 0
      for (let i = 0; i < shorts.length; i++) {
        let symbol = shorts[i].symbol
        let ltp = state.instruments[symbol] ? state.instruments[symbol].Ltp : 0 
        let price = basket.executedBasketPayOffPrice === "executed" ? ltp : shorts[i].priceWhenAddedToBasket
        credits += price * shorts[i].lotSize * shorts[i].quantity
      }

      basket.setupCost = debits - credits
      // change the sign
      // so a negative vaues signifies, debits, and postive credits
      basket.setupCost = -basket.setupCost

      console.log('setup cost', basket.setupCost)

      if (orders.length >= 2) {
        // remove all futures
        let plainOrders = orders.filter(x => x.instrumentType != 'f')

        let sortedOrders = plainOrders.sort((a, b) => new Date(a.expiry).getTime() - new Date(b.expiry).getTime());
        if (sortedOrders[0].expiry !== sortedOrders[sortedOrders.length - 1].expiry)
          basket.isMultiExpiry = true
        else
          basket.isMultiExpiry = false
      }
    })
  },
  updateActiveExecutedBasketSetupCostAndMultiExpiryFlag({ state }) {
    if (_.isEmpty(state.activeExecutedBasket)) return
    if (state.activeExecutedBasket.orders && state.activeExecutedBasket.orders.length === 0) return

    let basket = state.activeExecutedBasket

    if (!basket.orders) return;

    // sum of all debits - sum of all credits
    let orders = basket.orders.filter(x => x.isSquaredOff == false)
    let longs = orders.filter(x => x.transactionType === 'buy' && x.instrumentType === 'o' && x.isSelectedForAnalysisChart)
    let shorts = orders.filter(x => x.transactionType === 'sell' && x.instrumentType === 'o' && x.isSelectedForAnalysisChart)

    let debits = 0
    for (let i = 0; i < longs.length; i++) {
      let price = state.activeExecutedBasket.executedBasketPayOffPrice === "executed" ? longs[i].executedPrice : state.instruments[longs[i].symbol].Ltp
      debits += price * longs[i].lotSize * longs[i].quantity
    }

    let credits = 0
    for (let i = 0; i < shorts.length; i++) {
      let price = state.activeExecutedBasket.executedBasketPayOffPrice === "executed" ? shorts[i].executedPrice : state.instruments[shorts[i].symbol].Ltp
      credits += price * shorts[i].lotSize * shorts[i].quantity
    }

    state.activeExecutedBasket.setupCost = (-Math.abs(debits)) + credits
    // change the sign
    // so a negative vaues signifies, debits, and postive credits
    state.activeExecutedBasket.setupCost = basket.setupCost

    if (orders.length >= 2) {
      let plainOrders = orders.filter(x => x.instrumentType != 'f')

      let sortedOrders = plainOrders.sort((a, b) => new Date(a.expiry).getTime() - new Date(b.expiry).getTime());
      var date1 = moment(new Date(sortedOrders[0].expiry))
      var date2 = moment(new Date(sortedOrders[sortedOrders.length - 1].expiry))
      if (date1.isSame(date2))
        state.activeExecutedBasket.isMultiExpiry = false
      else
        state.activeExecutedBasket.isMultiExpiry = true
    }

    // dispatch("sendAddRemoveExecutedOrderBasketsCommand", {
    //   id: state.activeExecutedBasket.id,
    //   action: 3, //Add
    //   basket: state.activeExecutedBasket
    // })       
  },
  /*
  updateExecutedBasketsSetupCostAndMultiExpiryFlag({state}) {        

    state.orderBaskets.forEach(basket => {
      // sum of all debits - sum of all credits
      let longs = basket.orders.filter(x=> x.transactionType === 'buy')
      let shorts = basket.orders.filter(x=> x.transactionType === 'sell')

      let debits = 0          
      for (let i=0; i<longs.length; i++) {
        // let price = payload.setupCostOn === "executed" ? longs[i].executedPrice : longs[i].priceWhenAddedToBasket
        debits += state.instruments[longs[i].symbol].Ltp * longs[i].lotSize * longs[i].quantity
      }

      let credits = 0
      for (let i=0; i<shorts.length; i++)  {
        // let price = payload.setupCostOn === "executed" ? shorts[i].executedPrice : shorts[i].priceWhenAddedToBasket
        credits += state.instruments[shorts[i].symbol].Ltp * shorts[i].lotSize * shorts[i].quantity
      }

      basket.setupCost = debits - credits
      // change the sign
      // so a negative vaues signifies, debits, and postive credits
      basket.setupCost = -basket.setupCost      

      if(basket.orders.length >= 2) {
        let sortedOrders = basket.orders.sort((a,b)=> new Date(a.expiry).getTime()- new Date(b.expiry).getTime());
        if (sortedOrders[0].expiry !== sortedOrders[sortedOrders.length-1].expiry)
          basket.isMultiExpiry = true
        else
          basket.isMultiExpiry = false
      }
    })  
  },
  */
  addPositionToExecutedBasket({ state, dispatch }, payload) {
    let basketId = payload.strategyId
    var basket = state.orderBaskets.find(x => parseInt(x.id) == parseInt(basketId))
    if (!basket) return;

    // console.log(dispatch)

    let executedOn = moment(Date.now()).format('DD-MMM-YYYY HH:mm')
    if (state.showHideOptionsSimulator) {
      executedOn = moment(state.optionSimulatorParams.selectedTimeInUnix).format('DD-MMM-YYYY HH:mm')
    }

    var exchange = "NFO";

    var expiry = moment(state.expiries[0], 'DD-MMM-YYYY').toDate()
   
    if (basket.instrument == 'sensex') {
      expiry = moment(state.sensexExpiries[0], 'DD-MMM-YYYY').toDate()
      exchange = "BFO"
    }    
    else if (basket.instrument == 'finnifty')
      expiry = moment(state.finNiftyExpiries[0], 'DD-MMM-YYYY').toDate()
    else if (basket.instrument == 'banknifty')
      expiry = moment(state.bankNiftyExpiries[0], 'DD-MMM-YYYY').toDate()
    else if (basket.instrument == 'midcpnifty')
      expiry = moment(state.midcapNiftyExpiries[0], 'DD-MMM-YYYY').toDate()

    // Create a util  method to get new order
    // push a new order with a flag
    let order = {
      id: Math.floor(Math.random() * 999999),
      optionType: 'ce',
      symbol: '',
      strike: 0,
      transactionType: 'sell',
      quantity: 1,
      lotSize: basket.lotSize,
      priceWhenAddedToBasket: 0,
      isSelectedForAnalysisChart: true,
      isSelectedForAnalysisChartIncludePnL: true,
      executedOn: executedOn,
      editing: true,
      status: payload.isForSquaredOffOrder ? 'complete' : 'new',
      statusEditing: 'new', // This will be temporariliy used during edting. once editing done and validated, it will be set to 'complete' with status field
      squaredOff: payload.isForSquaredOffOrder ? true : false,
      executedPrice: 0.0,
      brokerId: basket.brokerId,
      pl: 0.0,
      isSquaredOff: payload.isForSquaredOffOrder ? true : false,
      checked: false,
      product: 'i',
      squaredOffPrice: 0.0,
      strategyName: basket.strategyName,
      strategyId: basket.id,
      reason: '',
      squareOffInitiated: false,
      bsPrice: 0,
      includeInPayoff: true,
      brokerageAndTaxes: 0,
      instrumentType: 'o',
      exchange: exchange,
      underlyingPriceAtOpen: 0,
      underlyingPriceAtClose: 0,
      futuresPriceAtOpen: 0,
      futuresPriceAtClose: 0,
      greeksAtExecution: {
        iv: 0,
        delta: 0,
        theta: 0,
        vega: 0,
        gamma: 0
      },
      greeksAtNow: {
        iv: 0,
        delta: 0,
        theta: 0,
        vega: 0,
        gamma: 0
      },
      expiryEdited: '',
      expiry: expiry
    }

    if (payload.isForSquaredOffOrder)
      basket.squaredOffOrders.push(order)
    else
      basket.orders.push(order)

    dispatch("sendSaveExecutedOrderBasketsCommand")

  },
  removePositionFromExecutedBasket({ state, dispatch }, payload) {
    let basket = state.orderBaskets.find(x => parseInt(x.id) == parseInt(payload.strategyId))
    if (!basket) return

    let index = basket.orders.findIndex(x => x.id === payload.id)
    if (index === -1) return;

    basket.orders.splice(index, 1)

    // dispatch("sendSaveExecutedOrderBasketsCommand")
    console.log(`removePositionFromExecutedBasket: Sending send AddRemoveExecutedOrderBasketsCommand`)
    dispatch("sendAddRemoveExecutedOrderBasketsCommand", {
      id: basket.id,
      action: 3, //Add
      basket: basket
    })


  },
  setActiveBasketEditing({ dispatch, state }, id) {
    var orderBasketEditing = state.orderBasketsEditing.find(x => x.id == id)
    if (!orderBasketEditing) {
      console.log('No Active basket for editing')
      return
    }

    dispatch('updateBrokerageAndTaxesForBasket', {
      id: id,
      type: 'editing'
    })

    state.activeBasketEditing = orderBasketEditing
  },
  setActiveBasketGroup({ state }, basketGroup) {
    state.activeBasketGroup = basketGroup
    state.activeExecutedBasket = {}
  },
  setActiveBasketGroupId({ state }, basketGroupId) {
    state.activeBasketGroupId = basketGroupId
  },
  setActiveExecutedBasket({ state, dispatch }, strategyId) {
    var activeExecutedBasket = state.orderBaskets.find(x => parseInt(x.id) == parseInt(strategyId))

    dispatch('updateBrokerageAndTaxesForBasket', {
      id: strategyId,
      type: 'executed'
    })

    state.activeExecutedBasket = activeExecutedBasket

    if (activeExecutedBasket.stop === 0 && activeExecutedBasket.target === 0) {
      if (activeExecutedBasket.instrument === "nifty") {
        activeExecutedBasket.stop = -300
        activeExecutedBasket.target = 1000
      } else if (activeExecutedBasket.instrument === "banknifty") {
        activeExecutedBasket.stop = -500
        activeExecutedBasket.target = 1500
      } else if (activeExecutedBasket.instrument === "sensex") {
        activeExecutedBasket.stop = -500
        activeExecutedBasket.target = 1500        
      } else if (activeExecutedBasket.instrument === "finnifty") {
        activeExecutedBasket.stop = -300
        activeExecutedBasket.target = 1000
      } else if (activeExecutedBasket.instrument === "midcpnifty") {
        activeExecutedBasket.stop = -300
        activeExecutedBasket.target = 1000
      }
      activeExecutedBasket.isPerLegSL = true
      activeExecutedBasket.isPerLegSLHitOpenNew = false
      activeExecutedBasket.oppositeQtyToAdd = 0
      activeExecutedBasket.isShiftToNextOTM = false,
      activeExecutedBasket.deltaBalanceStrikeDifference = 0
      activeExecutedBasket.isPerLegSLReEntry = true
      activeExecutedBasket.isStayOTM = false
      activeExecutedBasket.shiftStrikes = false
      activeExecutedBasket.perLegSLType = '1'
      activeExecutedBasket.stopTargetType = '1'
      activeExecutedBasket.perLegSLValue = 50
    }
  },
  removeBasketFromEditor({ state, dispatch }, id) {
    var index = state.orderBasketsEditing.findIndex(x => x.id === id)

    if (index === -1) return

    state.orderBasketsEditing.splice(index, 1)

    if (state.orderBasketsEditing.length > 0) {
      state.activeBasketEditing = state.orderBasketsEditing[0]
      dispatch('updateBasketEditorChart', state.activeBasketEditing.instrument)
    }
    else
      state.activeBasketEditing = {}
  },
  removeBasketFromExecutedBaskets({ state, dispatch }, id) {
    var index = state.orderBaskets.findIndex(x => x.id == id)

    if (index === -1) return

    state.orderBaskets.splice(index, 1)

    console.log(`removeBasketFromExecutedBaskets: Sending send AddRemoveExecutedOrderBasketsCommand`)
    dispatch("sendAddRemoveExecutedOrderBasketsCommand", {
      id: id,
      action: 2 //Remove
    })

    state.activeExecutedBasket = {}

  },
  removeFromMultiChartAnalysisData({ state, dispatch }, basketId) {
    if (!basketId) {
      state.multiChartViewBaskets = []
      return
    }

    let index = state.multiChartViewBaskets.findIndex(x => x.id == basketId);
    if (index != -1) {
      state.multiChartViewBaskets.splice(index, 1);
      dispatch("sendSaveMultiChartViewBasketsCommand");
    }
  },
  addRemoveToMultChartView({ state, dispatch }, basket) {
    let index = state.multiChartViewBaskets.findIndex(x => parseInt(x.id) === parseInt(basket.id));
    if (index != -1) {
      // state.multiChartViewBaskets.splice(index,1);
      let multiChartViewBasket = state.multiChartViewBaskets[index];
      multiChartViewBasket.orders = JSON.stringify(JSON.parse(basket.orders))
    }
    else {
      state.multiChartViewBaskets.push(basket);
    }

    dispatch("sendSaveMultiChartViewBasketsCommand");
  },
  orderBasketExecuted({ state, dispatch }, payload) {

    // Calculate the setup cost on the executed price
    let longs = payload.orders.filter(x => x.transactionType === 'buy' && x.instrumentType === 'o')
    let shorts = payload.orders.filter(x => x.transactionType === 'sell' && x.instrumentType === 'o')

    let debits = 0
    for (let i = 0; i < longs.length; i++) {
      // let price = payload.setupCostOn === "executed" ? longs[i].executedPrice : longs[i].priceWhenAddedToBasket
      debits += state.instruments[longs[i].symbol].Ltp * longs[i].lotSize * longs[i].quantity
    }

    let credits = 0
    for (let i = 0; i < shorts.length; i++) {
      // let price = payload.setupCostOn === "executed" ? shorts[i].executedPrice : shorts[i].priceWhenAddedToBasket
      credits += state.instruments[shorts[i].symbol].Ltp * shorts[i].lotSize * shorts[i].quantity
    }

    let setupCost = debits - credits
    // change the sign
    // so a negative vaues signifies, debits, and postive credits
    setupCost = -setupCost

    let underlyingPrice = 0
    let exchange = "NFO";

    if (payload.instrument === 'nifty')
      underlyingPrice = state.instruments['NIFTY 50'] ? state.instruments['NIFTY 50'].Ltp : 0
    else if (payload.instrument === 'banknifty')
      underlyingPrice = state.instruments['NIFTY BANK'] ? state.instruments['NIFTY BANK'].Ltp : 0
    else if (payload.instrument === 'sensex') {
      underlyingPrice = state.instruments['SENSEX'] ? state.instruments['SENSEX'].Ltp : 0
      exchange = "BFO"
    }
    else if (payload.instrument === 'finnifty')
      underlyingPrice = state.instruments['FINNIFTY'] ? state.instruments['FINNIFTY'].Ltp : 0
    else if (payload.instrument === 'midcpnifty')
      underlyingPrice = state.instruments['MIDCPNIFTY'] ? state.instruments['MIDCPNIFTY'].Ltp : 0

    let futuresPriceObj = state.instruments[SymbolService.getFuturesSymbolCurrent(payload.instrument, state.monthlies[0])]
    let futuresPrice = 0

    if (futuresPriceObj)
      futuresPrice = futuresPriceObj.Ltp

    let vix = state.instruments['INDIA VIX'].Ltp

    let executedOn = moment(Date.now()).format('DD-MMM-YYYY HH:mm')
    if (state.showHideOptionsSimulator) {
      executedOn = moment(state.optionSimulatorParams.selectedTimeInUnix).format('DD-MMM-YYYY HH:mm')
    }

    let basket = {
      id: payload.id ? payload.id : Math.floor(Math.random() * 999999),
      groupId: payload.groupId,
      strategyName: payload.strategyName,
      brokerId: payload.brokerId,
      squaredOffOrders: [],

      realizedPL: 0.0,
      realizedPLPoints: 0.0,
      totalPnlPoints: 0.0,
      totalPnlPointsActualLots: 0.0,

      atOpenCombinedPremium: 0.0,
      atCloseCombinedPremium: 0.0,
      currentCombinedPremium: 0.0,

      showPremiumAndPointsForOneLot: true,

      atOpenCombinedPremiumActualsLots: 0.0,
      atCloseCombinedPremiumActualsLots: 0.0,
      currentCombinedPremiumActualsLots: 0.0,

      payoffShouldIncludeRealizedPnL: true,
      isSentForBasketStopSquareOff: false,
      isSelectedForAnalysisChart: true,
      isSelectedForAnalysisChartIncludePnL: true,

      orders: payload.orders,
      isSquaredOff: payload.isSquaredOff,
      instrument: payload.instrument,
      totalPnl: payload.totalPnl,
      isTesting: payload.isTesting,

      stop: payload.stop,
      target: payload.target,
      trail: payload.trail,
      isPerLegSL: payload.isPerLegSL,
      isPerLegSLHitOpenNew: payload.isPerLegSLHitOpenNew,
      isShiftToNextOTM: payload.isShiftToNextOTM,
      oppositeQtyToAdd: payload.oppositeQtyToAdd,
      deltaBalanceStrikeDifference: payload.deltaBalanceStrikeDifference,
      ExecutionMode: payload.ExecutionMode,
      isPerLegSLReEntry: payload.isPerLegSLReEntry,
      isStayOTM: payload.isStayOTM,
      shiftStrikes: payload.shiftStrikes,
      perLegSLType: payload.perLegSLType, // 1-percent, 2-fixed
      stopTargetType: payload.stopTargetType, // 1-points, 2-premium
      perLegSLValue: payload.perLegSLValue,

      isFuturesSL: payload.isFuturesSL,
      futuresSLType: payload.futuresSLType,

      slTargetBasedOnCandlePrice: payload.slTargetBasedOnCandlePrice,
      fixedCandleSL: payload.fixedCandleSL,
      underlyingSLCandlePriceType: payload.underlyingSLCandlePriceType,
      underlyingSLCandlePriceCheckType: payload.underlyingSLCandlePriceCheckType,
      underlyingSLCandlesLookbackCount: payload.underlyingSLCandlesLookbackCount,

      persona: payload.persona,
      signal: payload.signal,

      futuresSLComparisionType: payload.futuresSLComparisionType, // <= or >=
      futuresTargetComparisionType: payload.futuresTargetComparisionType, // <= or >=
      futuresSLValue: payload.futuresSLValue,
      futuresSLUnderlyingType: payload.futuresSLUnderlyingType,
      futuresTargetValue: payload.futuresTargetValue,

      checkExitTime: true,
      exitTime: payload.exitTime,

      squareOffMode: 'manual',
      lotSize: SymbolService.getMarketLot(payload.instrument),
      isStopTargetHit: false,
      executedBasketPayOffPrice: 'executed',
      underlyingPriceAtOpen: underlyingPrice,
      futuresPriceAtOpen: futuresPrice,
      futuresPriceAtClose: 0,
      showGreeks: false,
      brokerageAndTaxes: payload.brokerageAndTaxes,
      vixWhenExecuted: vix,
      executedOn: executedOn,
      marginRequired: payload.marginRequired,
      status: 'old',
      isMultiExpiry: payload.isMultiExpiry,
      setupCost: setupCost,
      isAdjusting: false,
      breakEvens: payload.breakEvens,
      squaredOffOn: new Date(),
      underlyingPriceAtClose: 0.0,
      VixAtSquarOff: 0.0,
      includeInAccountPnl: true,

      capital: 100000,
      maxLossType: 'percent',
      accountMaxLossSL: 1,
      optionPrice: 300,
      premiumSLType: 'percent',
      positionMaxLossSL: 20,
      lotsToTrade: 0,

      strategyExecutionStatus: '', // scheduled, stopped
      strategyExecutionMessages: [],
      showLogMessages: false,

      isTrailing: false,
      newStop: 0,
      newTarget: 0,
      trailBy: 0,

      editing: false,
      showHideAdjustments: true,
      showHideAlgoPanel: true,
      showHideTechnicalAnalysisPanel: false,
      showHideOrdersPanel: true,
      showHideInfoPanel: true,

      positionTechnicalAnalysisData: {},
      showHidePortfolioSummaryTabs: true,
      chartInterval: 1,
      chartType: 'spline',

      isExpanded: false,
    }

    state.orderBaskets.push(basket);

    let symbols = payload.orders.map(x => ({
      Exchange: exchange,
      Symbol: x.symbol      
    }));
    dispatch('subscribeToLiveFeed', symbols)

    console.log(`orderBasketExecuted: Sending send AddRemoveExecutedOrderBasketsCommand`)
    dispatch("sendAddRemoveExecutedOrderBasketsCommand", {
      id: basket.id,
      action: 1, //Add
      basket: basket
    })

    // dispatch("sendSaveExecutedOrderBasketsCommand").then (() => {

    // })
  },
  updateBrokerageAndTaxesForBasket({ state }, payload) {

    // TODO: calculated brokerage and taxes on server

    let basket = {}

    if (payload.type === "executed")
      basket = state.orderBaskets.find(x => parseInt(x.id) === parseInt(payload.id))
    else
      basket = state.orderBasketsEditing.find(x => parseInt(x.id) === parseInt(payload.id))

    if (!basket) return

    let taxes = 0
    basket.orders.forEach(order => {
      if (payload.type != 'executed' && !order.includeInPayoff) return

      // let price = 0

      // if (payload.type === 'executed')
      //   price = order.executedPrice
      // else
      //   price = state.instruments[order.symbol].Ltp

      // order.brokerageAndTaxes = SymbolService.calculateTaxesOptions(price, order.quantity * order.lotSize, order.transactionType, order.broker, order.instrumentType)
      // if (order.isSquaredOff)
      order.brokerageAndTaxes = 0

      // Calculate at reverse transaction side
      // Price should be the latest price
      // order.brokerageAndTaxes += SymbolService.calculateTaxesOptions(price, order.quantity * order.lotSize, order.transactionType === 'buy' ? 'sell' : 'buy', order.broker, order.instrumentType)

      taxes = taxes + order.brokerageAndTaxes
    })

    basket.brokerageAndTaxes = taxes

    if (payload.type === 'executed') {
      state.activeExecutedBasket.brokerageAndTaxes = taxes
      //console.log(`updateBrokerageAndTaxesForBasket: Sending send AddRemoveExecutedOrderBasketsCommand`)
      // dispatch("sendAddRemoveExecutedOrderBasketsCommand", {
      //   id: state.activeExecutedBasket.id,
      //   action: 3, //Add
      //   basket: state.activeExecutedBasket
      // })        
    }
    else
      state.activeBasketEditing.brokerageAndTaxes = taxes

  },
  setBasketGroup({ state, dispatch }, parameters) {
    let basket = state.orderBaskets.find(x => x.id === parameters.basketId)
    if (!basket) return

    basket.groupId = parameters.groupId

    // TODO:
    // Send Basket Update Message to the client
    dispatch("sendUpdateBasketProperties", {
      BasketId: basket.id,
      UpdateType: 1,
      UpdateParamater: {
        PropertyName: "groupId",
        PropertyValue: parameters.groupId,
      }
    })

    state.activeExecutedBasket = {}

    dispatch("sendSaveExecutedOrderBasketsCommand")
  },
  // setBasketGroupForEditedBasket({state}, parameters) {
  //   let basket = state.orderBasketsEditing.find(x => x.id === parameters.basketId)
  //   if (!basket) return

  //   basket.groupId = parameters.groupId

  //   // state.activeExecutedBasket = {}
  // },

  removeAllBasketGroups({ state, dispatch }) {
    var basketGroupsCopy = JSON.parse(JSON.stringify(state.basketGroups))
    basketGroupsCopy.forEach(basketGroup => {
      if (basketGroup.id > 100) {
        dispatch("removeBasketGroup", {
          groupId: basketGroup.id,
          removeBaskets: true
        })
      }
    });
  },

  exitBasketGroups({ state, dispatch }, payload) {
    let request = {
      MessageType: 'exit_basket_groups_request',
      ExitType: payload.exitType,
      GroupId: payload.groupId,
      TrackingId: state.trackingId,
    }

    dispatch('sendMessage', JSON.stringify(request))
  },

  removeBasketGroup({ state, dispatch }, payload) {
    let index = state.basketGroups.findIndex(x => parseInt(x.id) == parseInt(payload.groupId))
    if (index === -1) return;

    let basketGroup = state.basketGroups[index]
    let groupId = parseInt(basketGroup.id)

    let basketsToBeRemoved = []

    for (let i = 0; i < state.orderBaskets.length; i++) {
      if (parseInt(state.orderBaskets[i].groupId) == groupId) {
        state.orderBaskets[i].groupId = 1
        basketGroup.showHideBasketGroupHideStopTarget = false

        if (payload.removeBaskets) {
          basketsToBeRemoved.push(state.orderBaskets[i]);
        } else {
          dispatch("sendUpdateBasketGroupProperties", {
            BasketGroupId: basketGroup.id,
            UpdateType: 1,
            UpdateParamater: {
              PropertyName: "showHideBasketGroupHideStopTarget",
              PropertyValue: basketGroup.showHideBasketGroupHideStopTarget,
            }
          })

          dispatch("sendUpdateBasketProperties", {
            BasketId: state.orderBaskets[i].id,
            UpdateType: 1,
            UpdateParamater: {
              PropertyName: "groupId",
              PropertyValue: 1,
            }
          })
        }
      }
    }

    for (let i = 0; i < basketsToBeRemoved.length; i++) {

      let basketId = basketsToBeRemoved[i].id

      let index = state.orderBaskets.findIndex(x => x.id == basketId)

      if (index === -1) return

      let parameters = {
        StrategyId: basketId,
        StrategyName: basketsToBeRemoved[i].strategyName,
        PlacedOn: new Date(),
      };

      let stopStrategy = {
        MessageType: "algo_stop_strategy_request",
        Parameters: parameters,
      };

      dispatch("sendMessage", JSON.stringify(stopStrategy));

      state.orderBaskets.splice(index, 1)

      console.log(`removeBasketGroup: Sending send AddRemoveExecutedOrderBasketsCommand`)
      dispatch("sendAddRemoveExecutedOrderBasketsCommand", {
        id: basketId,
        action: 2 //Remove
      })
      state.activeExecutedBasket = {}
    }


    state.basketGroups.splice(index, 1)
    state.activeBasketGroup = state.basketGroups[0]

    dispatch("sendAddRemoveBasketGroupRequest", {
      id: payload.groupId,
      action: 2, // 2 - Remove
      basketGroup: basketGroup
    })

  },
  getGlobalSettings({ state, dispatch }) {
    let request = {
      MessageType: 'get_global_settings_request',
      TrackingId: state.trackingId,
    }
    dispatch('sendMessage', JSON.stringify(request))
  },
  addNewBasketGroup({ state, dispatch }) {
    let groupId = Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)
    let basketGroup = {
      id: groupId,
      name: 'New Group ' + groupId,
      net: 0.0,  // groupNetPnl
      mtm: 0.0,
      realized: 0.0,
      addedOn: moment(Date.now()).format('DD-MMM-YYYY HH:mm'),
      updatedOn: moment(Date.now()).format('DD-MMM-YYYY HH:mm'),
      editing: true,
      isSentForBasketStopSquareOff: false,
      showHideBasketGroupHideStopTarget: false,

      positionTechnicalAnalysisData: {},
      chartInterval: 1,
      chartType: 'spline',

      isSquaredOff: false,
      isExpanded: false,
      ExecutionMode: 1,
      AlgoScheduledWaitForOrderBasketsExecution: true,
      stopTarget: {
        enabled: false,
        stop: -2000.00,
        target: 3000.00,
        trail: 1000.00,
        isTrailing: false,
        trailStop: 0.0,
        trailTarget: 0.0,
        finalTarget: 0.0,
        protectTheProfit: true,
        protectWhenProfitReaches: 1500.00,
        protectLockMinimumProfitAt: 500.00,
        reducingTrailStopGap: true,
        reducingTrailStopGapReductionPercent: 10.00,
        reducingTrailStopGapMinimumGapPercent: 25.00,
        checkExitTime: true,
        exitTime: '15:20:00',
        multiplier: 1, //1x
      }
    }

    dispatch("sendAddRemoveBasketGroupRequest", {
      id: basketGroup.id,
      action: 1, //Add
      basketGroup: basketGroup
    })

    state.basketGroups.push(basketGroup)
  },

  addNewExecutedBasket({ state, dispatch }) {

    // set basket status as new
    // add default parameters
    // allow slection of broker
    // assign random strategy name
    // allow changing the strategy name
    // allow selection of intrument
    // set default lot size
    // make it active
    // Do not process for pnl calculation till a new position is added to the basket
    // save to the server
    let executedOn = moment(Date.now()).format('DD-MMM-YYYY HH:mm')
    if (state.showHideOptionsSimulator) {
      executedOn = moment(state.optionSimulatorParams.selectedTimeInUnix).format('DD-MMM-YYYY HH:mm')
    }

    let groupId = 1
    if (state.activeBasketGroup)
      groupId = state.activeBasketGroup.id

    let basket = {
      id: Math.floor(Math.random() * 999999),
      groupId: groupId,
      strategyName: 'OpStra-' + Math.floor(Math.random() * 100000) + 1,
      broker: -1,
      squaredOffOrders: [],
      realizedPL: 0.0,
      realizedPLPoints: 0.0,
      totalPnlPoints: 0.0,
      totalPnlPointsActualLots: 0.0,

      atOpenCombinedPremium: 0.0,
      atCloseCombinedPremium: 0.0,
      currentCombinedPremium: 0.0,

      showPremiumAndPointsForOneLot: true,

      atOpenCombinedPremiumActualsLots: 0.0,
      atCloseCombinedPremiumActualsLots: 0.0,
      currentCombinedPremiumActualsLots: 0.0,

      payoffShouldIncludeRealizedPnL: true,
      isSentForBasketStopSquareOff: false,
      isSelectedForAnalysisChart: true,
      isSelectedForAnalysisChartIncludePnL: true,

      orders: [],
      isSquaredOff: false,
      instrument: 'nifty',
      totalPnl: 0,

      isTesting: true,
      stop: 0,
      target: 0,
      trail: 0,
      isPerLegSL: false,
      isPerLegSLHitOpenNew: false,
      isShiftToNextOTM: false,
      oppositeQtyToAdd: 0,
      deltaBalanceStrikeDifference: 0,
      ExecutionMode: 1,
      isPerLegSLReEntry: false,
      isStayOTM: false,
      shiftStrikes: false,
      perLegSLType: 1, // 1-percent, 2-fixed
      stopTargetType: 1, // 1-points, 2-premium
      perLegSLValue: 0,

      isFuturesSL: false,
      futuresSLType: 1,

      slTargetBasedOnCandlePrice: false,
      fixedCandleSL: false,
      underlyingSLCandlePriceType: 1,
      underlyingSLCandlePriceCheckType: 2,
      underlyingSLCandlesLookbackCount: 3,

      persona: 2, // seller
      signal: 1, // buy

      futuresSLUnderlyingType: 1,
      futuresSLComparisionType: '<=', // <= or >=
      futuresTargetComparisionType: '>=', // <= or >=
      futuresSLValue: 1,
      futuresTargetValue: 1,

      checkExitTime: false,
      exitTime: '15:20:00',

      squareOffMode: 'manual',
      lotSize: SymbolService.getMarketLot('nifty'),
      isStopTargetHit: false,
      executedBasketPayOffPrice: 'executed',
      underlyingPriceAtOpen: 0,
      futuresPriceAtOpen: 0,
      futuresPriceAtClose: 0,
      showGreeks: false,
      brokerageAndTaxes: 0,
      vixWhenExecuted: 0,
      executedOn: executedOn,
      marginRequired: 0,
      status: 'new',
      isMultiExpiry: false,
      setupCost: 0,
      isAdjusting: false,
      squaredOffOn: new Date(),
      underlyingPriceAtClose: 0.0,
      VixAtSquarOff: 0.0,
      includeInAccountPnl: true,

      capital: 100000,
      maxLossType: 'percent',
      accountMaxLossSL: 1,
      optionPrice: 300,
      premiumSLType: 'percent',
      positionMaxLossSL: 20,
      lotsToTrade: 0,

      strategyExecutionStatus: '', // scheduled, stopped
      strategyExecutionMessages: [],
      showLogMessages: false,

      isTrailing: false,
      newStop: 0,
      newTarget: 0,
      trailBy: 0,

      editing: false,
      showHideAdjustments: true,
      showHideAlgoPanel: true,
      showHideTechnicalAnalysisPanel: false,
      showHideOrdersPanel: true,
      showHideInfoPanel: true,

      positionTechnicalAnalysisData: {},
      showHidePortfolioSummaryTabs: true,
      isExpanded: false,
      chartInterval: 1,
      chartType: 'spline',

    }

    state.orderBaskets.push(basket)

    dispatch("addPositionToExecutedBasket", basket.id)

  },
  showHideOITopGraphsAction({ state }) {
    state.showHideOITopGraphs = !state.showHideOITopGraphs
  },
  saveBasket({ state, dispatch }, payload) {
    var basket = state.orderBaskets.find(x => x.id === payload.id)
    if (!basket) return

    basket.id = payload.id,
      basket.strategyName = payload.strategyName,
      basket.instrument = payload.instrument,
      basket.brokerId = payload.brokerId,
      basket.isTesting = payload.isTesting
    basket.status = 'old'
    basket.lotSize = SymbolService.getMarketLot(payload.instrument)

    basket.orders.forEach(x => {
      x.brokerId = basket.brokerId
    })

    dispatch("sendSaveExecutedOrderBasketsCommand")

  },
  adjustExecutedBasket({ state, dispatch }, strategyId) {
    // cler active basket and baskets
    // Switch the editor in the adjustment mode - done
    // Add a copy of executed basket to the activeeditedbasket
    // user can not remove this basket, unless he gets out of adjustment mode
    // color code / put labe to specified this is being adjusted
    // Many executed baskets can be added for the adjustments
    // User can not execute orders from the basket whhich has already been executed
    // However, he can add/remove individual order legs from the executed baskets
    // There will be option to calcuate the option prices:
    // 1. Ltp (not the price when basket was executed)
    // if it is ltp, days will be calculated from today, not when basket was executed

    let executedBasket = state.orderBaskets.find(x => parseInt(x.id) === parseInt(strategyId))
    if (!executedBasket) return

    if (!state.isBasketEditorInAdjustmentMode) {
      state.activeBasketEditing = {}
      // state.orderBasketsEditing = []      
    }
    // should not add again
    let findIndex = state.orderBasketsEditing.findIndex(x => parseInt(x.id) === parseInt(strategyId))
    if (findIndex !== -1) return

    state.isBasketEditorInAdjustmentMode = true

    let newObject = { ...executedBasket, isAdjusting: true, id: executedBasket.id }
    let executedBasketCopy = JSON.parse(JSON.stringify(newObject))

    executedBasketCopy.includeInPayoff = true
    executedBasketCopy.orders.forEach(order => {
      order.includeInPayoff = true
    })

    state.orderBasketsEditing.push(executedBasketCopy)
    state.activeBasketEditing = executedBasketCopy

    dispatch('updateBasketEditorChart', executedBasketCopy.instrument)
  },
  exitFromAdjustments({ state }) {
    state.activeBasketEditing = {}
    state.orderBasketsEditing = []
    state.isBasketEditorInAdjustmentMode = false
  },
  sendSaveExecutedOrderBasketsCommand({ state, dispatch }) {
    state.orderBaskets.forEach(basket => {
      console.log(basket.executedOn)
    });

    let saveExecutedOrderBasketCommand = {
      MessageType: 'save_executed_order_baskets_command',
      OrderBaskets: state.orderBaskets,
      MultiChartViewBaskets: state.multiChartViewBaskets,
      BasketGroups: state.basketGroups
    }

    dispatch('sendMessage', JSON.stringify(saveExecutedOrderBasketCommand))
  },
  makeCopyOfAnalysisBasket({ state }, basketId) {
    let basket = state.multiChartViewBaskets.find(x => parseInt(x.id) === parseInt(basketId))

    if (!basket) return

    var basketCopy = JSON.parse(JSON.stringify(basket))
    basketCopy.id = Math.floor(Math.random() * 999999)

    state.multiChartViewBaskets.push(basketCopy)

  },
  sendSaveMultiChartViewBasketsCommand({ state, dispatch }) {
    let saveExecutedOrderBasketCommand = {
      MessageType: 'save_executed_order_baskets_command',
      MultiChartViewBaskets: state.multiChartViewBaskets,
    }

    dispatch('sendMessage', JSON.stringify(saveExecutedOrderBasketCommand))
  },
  sendAddRemoveExecutedOrderBasketsCommand({ state, dispatch }, parameters) {

    console.log("Received request for sendAddRemoveExecutedOrderBasketsCommand in actions");

    let addRemoveExecutedOrderBasketCommand = {
      MessageType: 'add_remove_executed_order_basket_request',
      TrackingId: state.trackingId,
      Id: parameters.id,
      Action: parameters.action, // Remove
      Basket: parameters.basket
    }

    console.log("dispatched  sendAddRemoveExecutedOrderBasketsCommand in actions to sendMessage");
    dispatch('sendMessage', JSON.stringify(addRemoveExecutedOrderBasketCommand))
  },
  sendAddRemoveBasketGroupRequest({ state, dispatch }, parameters) {
    let request = {
      MessageType: 'add_remove_basket_group_request',
      TrackingId: state.trackingId,
      Id: parameters.id,
      Action: parameters.action, // Remove
      BasketGroup: parameters.basketGroup
    }

    dispatch('sendMessage', JSON.stringify(request))
  },
  clearOrdersFromTheBasket({ state, dispatch }, id) {
    var basket = state.orderBasketsEditing.find(x => x.id === id)

    if (!basket) return

    basket.orders = []

    dispatch('updateBasketEditorChart', basket.instrument)
  },
  clearAllBaskets({ state }) {
    state.orderBasketsEditing = []
    state.activeBasketEditing = []
  },
  updateBasketEditorChart({ state, dispatch }, instrument) {

    // Update setup cost and other params
    //await dispatch('updateEditedBasketsSetupCostAndMultiExpiryFlag')
    dispatch('updateEditedBasketsSetupCostAndMultiExpiryFlag')

    let nearestStrike = 0
    let strikeUpDownRange = 0
    let strikeIncrementStep = 0

    // 'Today' should be based on whether running the simulator or live trades
    let today = moment.now()

    if (state.showHideOptionsSimulator)
      today = state.optionSimulatorParams.onDate

    let underlyingAtNow = 0

    if (instrument == 'nifty') {
      if (!state.instruments["NIFTY 50"]) return
      nearestStrike = state.niftyAtmStrike
      strikeUpDownRange = state.strikeUpDownRangeNifty
      strikeIncrementStep = 10
      underlyingAtNow = state.instruments["NIFTY 50"].Ltp
    }
    else if (instrument == 'banknifty') {
      if (!state.instruments["NIFTY BANK"]) return
      nearestStrike = state.bankNiftyAtmStrike
      strikeUpDownRange = state.strikeUpDownRangeBankNifty
      strikeIncrementStep = 50
      underlyingAtNow = state.instruments["NIFTY BANK"].Ltp
    }
    else if (instrument == 'sensex') {
      if (!state.instruments["SENSEX"]) return
      nearestStrike = state.sensexAtmStrike
      strikeUpDownRange = state.strikeUpDownRangeSensex
      strikeIncrementStep = 50
      underlyingAtNow = state.instruments["SENSEX"].Ltp
    }    
    else if (instrument == 'finnifty') {
      if (!state.instruments["FINNIFTY"]) return
      nearestStrike = state.finNiftyAtmStrike
      strikeUpDownRange = state.strikeUpDownRangeFinNifty
      strikeIncrementStep = 10
      underlyingAtNow = state.instruments["FINNIFTY"].Ltp
    }
    else if (instrument == 'midcpnifty') {
      if (!state.instruments["MIDCPNIFTY"]) return
      nearestStrike = state.midcapNiftyAtmStrike
      strikeUpDownRange = state.strikeUpDownRangeMidcapNifty
      strikeIncrementStep = 10
      underlyingAtNow = state.instruments["MIDCPNIFTY"].Ltp
    }

    let xSeries = []
    let start = nearestStrike - strikeUpDownRange
    start = 10 * Math.round(start / 10);
    let end = nearestStrike + strikeUpDownRange
    end = 50 * Math.round(end / 50);


    let isStrikeMatched = false

    let ySeriesData = []
    let maxProfitLossBreakEvens = []
    let maxProfitLossBreakEvensData = {}

    let legPlSum = 0

    // fill xeries
    for (let strike = start; strike <= end; strike += strikeIncrementStep) {
      xSeries.push(strike)
    }

    state.orderBasketsEditing.forEach(x => {

      // let sortedOrders = x.orders.sort((a,b)=> new Date(a.expiry).getTime()- new Date(b.expiry).getTime());

      if (x.orders.length === 0) return
      if (!x.includeInPayoff) return

      let underlyingPrice = 0
      if (x.isAdjusting && x.executedBasketPayOffPrice === 'executed') {
        underlyingPrice = x.underlyingPriceAtOpen
        // console.log('In Adjustment mode. Underprice when eecuted is : ' + underlyingPrice)
      }
      else {
        if (x.instrument === 'nifty')
          underlyingPrice = state.instruments["NIFTY 50"] ? state.instruments["NIFTY 50"].Ltp : 0
        else if (x.instrument === 'banknifty')
          underlyingPrice = state.instruments["NIFTY BANK"] ? state.instruments["NIFTY BANK"].Ltp : 0
        else if (x.instrument === 'sensex')
          underlyingPrice = state.instruments["SENSEX"] ? state.instruments["SENSEX"].Ltp : 0        
        else if (x.instrument === 'finnifty')
          underlyingPrice = state.instruments["FINNIFTY"] ? state.instruments["FINNIFTY"].Ltp : 0
        else if (x.instrument === 'midcpnifty')
          underlyingPrice = state.instruments["MIDCPNIFTY"] ? state.instruments["MIDCPNIFTY"].Ltp : 0
      }

      let yseries = {
        name: x.id,
        data: []
      }

      maxProfitLossBreakEvensData = {
        id: x.id,
        plAtSelectedStrikes: [],
        stikeBreakEvenMap: [],
        maxProfit: 0,
        maxLoss: 0,
        breakEvens: []
      }

      for (let strike = start; strike <= end; strike += strikeIncrementStep) {
        legPlSum = 0

        if (x.orders.length == 0)
          continue

        x.orders.forEach(order => {

          if (!order.includeInPayoff) return
          let legPl = 0

          let sign
          let ltp = 0

          if (x.isAdjusting && x.executedBasketPayOffPrice === 'executed') {
            if (order.executedPrice === 0)
              ltp = state.instruments[order.symbol] ? state.instruments[order.symbol].Ltp : 0
            else
              ltp = order.executedPrice
            today = moment(order.executedOn)
            // console.log('In Adjustment. ltp when executed: ' + ltp + ', today is executed on: ' + today.format())
          }
          else
            ltp = state.instruments[order.symbol] ? state.instruments[order.symbol].Ltp : 0

          if (x && x.isMultiExpiry) {
            order.bsPrice = SymbolService.getBlackScholesPrice(x, order, today, ltp, strike, underlyingPrice, state.riskFreeInterest)
            // console.log(`strike at expoiry: ${strike}, order strike: ${order.strike}, ltp: ${ltp}, today: ${today}, bsPrice: ${order.bsPrice}, callorPut: ${order.optionType}`)
          } else {

            if (order.instrumentType === 'f') {
              legPl = strike - ltp
            }
            else {
              if (order.optionType === 'ce') {
                legPl = (Math.max(strike - order.strike, 0) - ltp)
              } else {
                legPl = (Math.max(order.strike - strike, 0) - ltp)
              }
            }

            sign = order.transactionType === 'buy' ? Math.sign((Math.abs(order.quantity))) : Math.sign((-Math.abs(order.quantity)))
            legPl = legPl * sign * order.lotSize * order.quantity
          }

          legPlSum += legPl

          if (order.strike === strike) {
            isStrikeMatched = true
          }
        })

        if (x && x.isMultiExpiry) {
          legPlSum = SymbolService.getFinalBlackScholesPriceOfABasket(x)
        }

        // add update realized profit
        if (x.realizedPL)
          legPlSum += x.realizedPL

        yseries.data.push(legPlSum)

        if (isStrikeMatched) {
          legPlSum = Math.trunc(legPlSum)
          maxProfitLossBreakEvensData.plAtSelectedStrikes.push(legPlSum)
          maxProfitLossBreakEvensData.stikeBreakEvenMap.push({
            strike: strike,
            pl: legPlSum
          })

          isStrikeMatched = false
        }
      }

      if (yseries.data.length > 0) {
        ySeriesData.push(yseries)
        maxProfitLossBreakEvens.push(maxProfitLossBreakEvensData)
      }
    })

    // at zero strike
    state.orderBasketsEditing.forEach(x => {
      let strike = 0
      let legPlSum = 0

      maxProfitLossBreakEvensData = maxProfitLossBreakEvens.find(maxPL => maxPL.id == x.id)
      if (!maxProfitLossBreakEvensData) return

      x.orders.forEach(order => {
        let legPl = 0
        let sign
        let ltp = 0

        if (x.isAdjusting && x.executedBasketPayOffPrice === 'executed')
          if (order.executedPrice === 0)
            ltp = state.instruments[order.symbol] ? state.instruments[order.symbol].Ltp : 0
          else
            ltp = order.executedPrice
        else
          ltp = state.instruments[order.symbol] ? state.instruments[order.symbol].Ltp : 0

        if (order.instrumentType === 'f') {
          legPl = strike - ltp
        }
        else {
          if (order.optionType === 'ce') {
            legPl = (Math.max(strike - order.strike, 0) - ltp)
          } else {
            legPl = (Math.max(order.strike - strike, 0) - ltp)
          }
        }

        sign = order.transactionType === 'buy' ? Math.sign((Math.abs(order.quantity))) : Math.sign((-Math.abs(order.quantity)))
        legPl = legPl * sign * order.lotSize * order.quantity

        legPlSum += legPl

      })

      if (x && x.isMultiExpiry)
        legPlSum = x.setupCost
      else {
        if (x.realizedPL)
          legPlSum += x.realizedPL

        legPlSum = Math.trunc(legPlSum)
      }

      maxProfitLossBreakEvensData.plAtSelectedStrikes.splice(0, 0, legPlSum)
      maxProfitLossBreakEvensData.stikeBreakEvenMap.splice(0, 0, {
        strike: strike,
        pl: legPlSum
      })

    })

    state.orderBasketsEditing.forEach(x => {
      let strike = 1000000
      let legPlSum = 0

      maxProfitLossBreakEvensData = maxProfitLossBreakEvens.find(maxPL => maxPL.id == x.id)
      if (!maxProfitLossBreakEvensData) return

      x.orders.forEach(order => {
        let legPl = 0
        let sign
        let ltp = 0

        if (x.isAdjusting && x.executedBasketPayOffPrice === 'executed')
          if (order.executedPrice === 0)
            ltp = state.instruments[order.symbol] ? state.instruments[order.symbol].Ltp : 0
          else
            ltp = order.executedPrice
        else
          ltp = state.instruments[order.symbol] ? state.instruments[order.symbol].Ltp : 0

        if (order.instrumentType === 'f') {
          legPl = strike - ltp
        }
        else {
          if (order.optionType === 'ce') {
            legPl = (Math.max(strike - order.strike, 0) - ltp)
          } else {
            legPl = (Math.max(order.strike - strike, 0) - ltp)
          }
        }

        sign = order.transactionType === 'buy' ? Math.sign((Math.abs(order.quantity))) : Math.sign((-Math.abs(order.quantity)))
        legPl = legPl * sign * order.lotSize * order.quantity

        legPlSum += legPl
      })

      if (x && x.isMultiExpiry)
        legPlSum = x.setupCost
      else {
        if (x.realizedPL)
          legPlSum += x.realizedPL

        legPlSum = Math.trunc(legPlSum)
      }

      maxProfitLossBreakEvensData.plAtSelectedStrikes.push(legPlSum)
      maxProfitLossBreakEvensData.stikeBreakEvenMap.push({
        strike: strike,
        pl: legPlSum
      })
    })

    maxProfitLossBreakEvens.forEach(x => {

      let basket = state.orderBasketsEditing.find(b => b.id == x.id)

      let maxProfit = -1
      let maxLoss = 1

      if (basket && basket.isMultiExpiry) {
        maxProfit = Math.max(...x.stikeBreakEvenMap.map(o => o.pl), 0);
        if (maxProfit < 0) {
          maxProfit = 0
        }

        maxLoss = basket.setupCost
      }
      else {
        if (x.plAtSelectedStrikes[x.plAtSelectedStrikes.length - 1] > x.plAtSelectedStrikes[x.plAtSelectedStrikes.length - 2] ||
          x.plAtSelectedStrikes[0] > x.plAtSelectedStrikes[1])
          maxProfit = -1
        else {
          let strikesCloned = [...x.plAtSelectedStrikes]
          strikesCloned.splice(strikesCloned.length - 1)
          maxProfit = Math.max(...strikesCloned)
        }


        if (x.plAtSelectedStrikes[x.plAtSelectedStrikes.length - 1] < x.plAtSelectedStrikes[x.plAtSelectedStrikes.length - 2] ||
          x.plAtSelectedStrikes[0] < x.plAtSelectedStrikes[1])
          maxLoss = 1
        else {
          let strikesCloned = [...x.plAtSelectedStrikes]
          strikesCloned.splice(strikesCloned.length - 1)
          maxLoss = Math.min(...strikesCloned)
        }
      }

      let breakEvens = []

      for (let i = 0; i < x.stikeBreakEvenMap.length; i++) {
        if (i >= 1) {
          let previousPl = x.stikeBreakEvenMap[i - 1].pl
          let currentPl = x.stikeBreakEvenMap[i].pl
          var sign = Math.sign(currentPl) == Math.sign(previousPl) ? 0 : 1
          if (sign == 1) {
            let previousStrike = x.stikeBreakEvenMap[i - 1].strike
            let currentStrike = x.stikeBreakEvenMap[i].strike
            let breakEventPoint = previousStrike + (currentStrike - previousStrike) * (0 - previousPl) / (currentPl - previousPl)
            breakEvens.push(Math.trunc(breakEventPoint))
          }
        }
      }

      x.maxProfit = maxProfit
      x.maxLoss = maxLoss
      x.breakEvens = breakEvens

      basket.breakEvens = x
    })

    let totalPnlYSeries = {
      name: 'Total PnL',
      data: []
    }

    if (ySeriesData.length > 0)
      totalPnlYSeries.data = Array(ySeriesData[0].data.length).fill(0)

    ySeriesData.forEach(x => {
      for (let i = 0; i < x.data.length; i++) {
        totalPnlYSeries.data[i] = totalPnlYSeries.data[i] + x.data[i]
      }
    })

    ySeriesData.push(totalPnlYSeries)

    state.basketEditorMaxProfitLossBreakEvens = maxProfitLossBreakEvens

    state.basketEditorChartOptions.options = ChartOptions.editorPayoff('PayOff', xSeries, underlyingAtNow, strikeIncrementStep)

    state.basketEditorChartOptions.series = ySeriesData
  },

  async getOptionChainExpiries({ state, dispatch }) {
    let forDate = Date.now()
    if (state.executionMode == "SIMULATION") {
      console.log('getOptionChainExpiries 1 : ' + state.optionSimulatorParams.onDate)
      let momentDate = moment(state.optionSimulatorParams.onDate)
      console.log('getOptionChainExpiries: ' +  momentDate.format())
      forDate = momentDate.unix()
    }

    state.optionChainExpiries = await utils.getExpiries(forDate, state.activeOptionChain, "all")
    state.selectedOptionChainExpiry = state.optionChainExpiries[0]

    let instrument = state.activeOptionChain ? state.activeOptionChain : 'nifty'
    dispatch('setActiveOptionChain', { instrument: instrument, expiry: state.selectedOptionChainExpiry })
  },
  getExpiriesForSimulator({ dispatch }, forDate) {
    let request = {
      MessageType: 'get_expiries_for_simulator_client_request',
      ForDate: forDate ? forDate : moment().format('DD-MM-YYYY')
    }

    dispatch('sendMessage', JSON.stringify(request))
  },
  getExpiries({ dispatch }) {
    let request = {
      MessageType: 'get_expiries_client_request',
      ForDate: moment().format('DD-MM-YYYY')
    }

    dispatch('sendMessage', JSON.stringify(request))
  }, 
  // updateTotalPL: _.throttle(function ({ state, commit, dispatch }) { 
  updateTotalPL: _.throttle(function ({ state, commit, dispatch }) {
    if (state.orderBaskets.length === 0) return;
    
    var accountPnL = 0;
    var realizedPnL = 0;

    commit("UpdateAccountPnl", {
      accountPnL: accountPnL,
      realizedPnL: 0,
    });

    state.orderBaskets.forEach((orderBasket) => {
      let totalPnl = 0;
      let totalPnlPoints = 0;
      let totalPnlPointsActualLots = 0;
      let totalRealizedPnL = 0;

      let atOpenCombinedPremium = 0;
      let atCloseCombinedPremium = 0;
      let currentCombinedPremium = 0;

      let atOpenCombinedPremiumActualsLots = 0;
      let atCloseCombinedPremiumActualsLots = 0;
      let currentCombinedPremiumActualsLots = 0;

      if (orderBasket.status == "new") return;
      if (!orderBasket.includeInAccountPnl) return;

      if (orderBasket.isSquaredOff && orderBasket.isPerLegSLReEntry == false) {
        totalPnl = orderBasket.totalPnl;
        totalRealizedPnL = orderBasket.realizedPL;
      } else {
        orderBasket.orders.forEach((order) => {

          if (!order) return;

          if (order.status === "rejected" || order.status === "new") {
            order.pl = 0;
            totalPnl = 0;
            return;
          }

          var ltp = 0.0;

          if (order.isSquaredOff)
            ltp = order.squaredOffPrice;
          else
            ltp = state.instruments[order.symbol]
              ? state.instruments[order.symbol].Ltp
              : 0;

          if (order.transactionType == "buy") {
            order.pl =
              (ltp - order.executedPrice) * order.quantity * order.lotSize;

            atOpenCombinedPremium -= parseFloat(order.executedPrice);
            atOpenCombinedPremiumActualsLots -= parseFloat(order.executedPrice * order.quantity);

            currentCombinedPremium -= parseFloat(ltp);
            currentCombinedPremiumActualsLots -= parseFloat(ltp * order.quantity);

            if (order.isSquaredOff) {
              atCloseCombinedPremium -= parseFloat(order.squaredOffPrice);
              atCloseCombinedPremiumActualsLots -= parseFloat(order.squaredOffPrice * order.quantity);
            }
          } else {
            order.pl =
              (order.executedPrice - ltp) * order.quantity * order.lotSize;

            atOpenCombinedPremium += parseFloat(order.executedPrice);
            atOpenCombinedPremiumActualsLots += parseFloat(order.executedPrice * order.quantity);

            currentCombinedPremium += parseFloat(ltp);
            currentCombinedPremiumActualsLots += parseFloat(ltp * order.quantity);

            if (order.isSquaredOff) {
              atCloseCombinedPremium += parseFloat(order.squaredOffPrice);
              atCloseCombinedPremiumActualsLots += parseFloat(order.squaredOffPrice * order.quantity);
            }
          }

          order.plPoints = order.pl / (order.lotSize * order.quantity);
          totalPnl += order.pl;
          totalPnlPoints += order.plPoints;
          totalPnlPointsActualLots += order.plPoints * order.quantity
        });

        orderBasket.totalPnl = totalPnl;
        orderBasket.totalPnlPoints = totalPnlPoints;
        orderBasket.totalPnlPointsActualLots = totalPnlPointsActualLots;

        orderBasket.atOpenCombinedPremium = parseFloat(atOpenCombinedPremium);
        orderBasket.atCloseCombinedPremium = parseFloat(atCloseCombinedPremium);
        orderBasket.currentCombinedPremium = parseFloat(currentCombinedPremium)

        orderBasket.atOpenCombinedPremiumActualsLots = parseFloat(atOpenCombinedPremiumActualsLots);
        orderBasket.atCloseCombinedPremiumActualsLots = parseFloat(atCloseCombinedPremiumActualsLots);
        orderBasket.currentCombinedPremiumActualsLots = parseFloat(currentCombinedPremiumActualsLots);

        totalRealizedPnL = orderBasket.realizedPL;
      }

      accountPnL += totalPnl;
      realizedPnL += totalRealizedPnL;

      commit("UpdateAccountPnl", {
        accountPnL: accountPnL,
        realizedPnL: realizedPnL,
      });

      if (orderBasket.isStopTargetHit) return;

    });

    // :set="(pnlSum = orderBasket.totalPnl + orderBasket.realizedPL)"

    state.basketGroups.forEach(basketGroup => {
      // get all order baskets for this group
      let orderBaskets = state.orderBaskets.filter(x => parseInt(x.groupId) === parseInt(basketGroup.id));
      if (!orderBaskets || orderBaskets.length === 0) return;

      let mtm = 0.0
      let realized = 0.0
      let isAnyBasketScheduledForAlgoExecution = false;

      orderBaskets.forEach(ob => {
        if (!ob.includeInAccountPnl) return
        mtm += ob.totalPnl
        realized += ob.realizedPL

        if (ob.strategyExecutionStatus === 'scheduled')
          isAnyBasketScheduledForAlgoExecution = true
      });

      basketGroup.isAnyBasketScheduledForAlgoExecution = isAnyBasketScheduledForAlgoExecution
      basketGroup.mtm = mtm
      basketGroup.realized = realized
      basketGroup.net = (mtm + realized)

    })

    dispatch("calculateRealizedPnLAndPoints");

  }, 400),

}

export default actions