import Utils from "@/services/Utils";
import moment from "moment";
import SymbolService from "../services/SymbolService";
import { notify } from "@kyvg/vue3-notification";
//import store from '../store/'

// import app from '@/main'
// import { _ } from 'core-js'

let mutations = {
  SOCKET_ONOPEN(state, event) {
    //app.config.globalProperties.$socket = event.currentTarget
    state.$socket = event.currentTarget;
    state.socket.isConnected = true;

    state.socket.heartBeatTimer = setInterval(() => {
      const message = "Heartbeat message";
      state.socket.isConnected &&
        state.$socket.sendObj({
          code: 200,
          msg: message,
        });
    }, state.socket.heartBeatInterval);

    // Get broker connection status
    this.dispatch("brokerConnectionStatusRequest");
  },
  SOCKET_ONCLOSE(state, event) {
    state.socket.isConnected = false;
    clearInterval(state.socket.heartBeatTimer);
    state.socket.heartBeatTimer = 0;
    console.log("The line is disconnected: " + new Date());
    console.log("SOCKET_ONCLOSE " + event);
  },
  SOCKET_ONERROR(state, event) {
    console.error(state, event);
  },
  // default handler called for all methods
  SOCKET_ONMESSAGE(state, message) {
    // LIVE FEED MESSAGE
    if (message.MessageType == "f") {
      // console.log(message)
      if (!state.instruments[message.s]) {
        let feed = {
          Symbol: message.s,
          Ltp: message.l,
          Close: message.c,
        };
        state.instruments[message.s] = feed;
      } else {
        let feed = state.instruments[message.s];
        feed.Symbol = message.s;
        feed.Ltp = message.l;
        feed.Close = message.c;
      }

      state.feedArrived = message.s;

      this.dispatch("updateTotalPL");
    } else if (message.MessageType == "show_error_message_response") {
      this.dispatch(
        "showMessageAlert",
        `${message.Title} - ${message.Description}`
      );
    } else if (message.MessageType == "test_message_response") {
      // Handle test message
      state.vwapCrossOverTestData = {
        OHLC: message.OHLC,
        Flags: message.Flags,
        Volumes: message.Volumes,
        VWAP: message.VWAP,
      };
    } else if (
      message.MessageType == "refresh_oi_analysis_data_series_response"
    ) {
      state.oiAnalysisData = message.Data;
      state.oiAnalysisDataArrived = new Date();
    } else if (message.MessageType == "global_settings_client_response") {
      state.globalSettings.globalSL = message.Settings.GlobalSL;
      state.globalSettings.globalTarget = message.Settings.GlobalTarget;
      state.globalSettings.checkGlobalExit = message.Settings.CheckGlobalExit;
    } else if (message.MessageType == "multistrike_chart_scanner_response") {
      if (message.Instrument === "banknifty") {
        if (message.Scannercategory === 0) {
          if (message.MultipleData) {
            state.multistrikeChartScannerData_CallsAndPuts_BNF =
              message.MultipleData;
          } else {
            let chartData =
              state.multistrikeChartScannerData_CallsAndPuts_BNF.find(
                (x) => x.Title === message.Data.Title
              );

            if (!chartData)
              state.multistrikeChartScannerData_CallsAndPuts_BNF.push(
                message.Data
              );
            else {
              chartData.Id = message.Data.Id;
              // chartData.IndividualStrikeSeriesInfo = message.Data.IndividualStrikeSeriesInfo
              chartData.CombinedPremiumSeriesInfo =
                message.Data.CombinedPremiumSeriesInfo;
              chartData.VWAPSeries = message.Data.VWAPSeries;
            }
          }
        } else if (message.Scannercategory === 1) {
          if (message.MultipleData) {
            state.multistrikeChartScannerData_Straddles_BNF =
              message.MultipleData;
          } else {
            let chartData =
              state.multistrikeChartScannerData_Straddles_BNF.find(
                (x) => x.Title === message.Data.Title
              );

            if (!chartData)
              state.multistrikeChartScannerData_Straddles_BNF.push(
                message.Data
              );
            else {
              chartData.Id = message.Data.Id;
              // chartData.IndividualStrikeSeriesInfo = message.Data.IndividualStrikeSeriesInfo
              chartData.CombinedPremiumSeriesInfo =
                message.Data.CombinedPremiumSeriesInfo;
              chartData.VWAPSeries = message.Data.VWAPSeries;
            }
          }
        } else if (message.Scannercategory === 2) {
          state.multistrikeChartScannerData_TripleStraddles_BNF = message.Data;
        }
      } else if (message.Instrument === "nifty") {
        if (message.Scannercategory === 0) {
          if (message.MultipleData) {
            state.multistrikeChartScannerData_CallsAndPuts_NF =
              message.MultipleData;
          } else {
            let chartData =
              state.multistrikeChartScannerData_CallsAndPuts_NF.find(
                (x) => x.Title === message.Data.Title
              );

            if (!chartData)
              state.multistrikeChartScannerData_CallsAndPuts_NF.push(
                message.Data
              );
            else {
              chartData.Id = message.Data.Id;
              // chartData.IndividualStrikeSeriesInfo = message.Data.IndividualStrikeSeriesInfo
              chartData.CombinedPremiumSeriesInfo =
                message.Data.CombinedPremiumSeriesInfo;
              chartData.VWAPSeries = message.Data.VWAPSeries;
            }
          }
        } else if (message.Scannercategory === 1) {
          if (message.MultipleData) {
            state.multistrikeChartScannerData_Straddles_NF =
              message.MultipleData;
          } else {
            let chartData = state.multistrikeChartScannerData_Straddles_NF.find(
              (x) => x.Title === message.Data.Title
            );

            if (!chartData)
              state.multistrikeChartScannerData_Straddles_NF.push(message.Data);
            else {
              chartData.Id = message.Data.Id;
              // chartData.IndividualStrikeSeriesInfo = message.Data.IndividualStrikeSeriesInfo
              chartData.CombinedPremiumSeriesInfo =
                message.Data.CombinedPremiumSeriesInfo;
              chartData.VWAPSeries = message.Data.VWAPSeries;
            }
          }
        } else if (message.Scannercategory === 2) {
          state.multistrikeChartScannerData_TripleStraddles_NF = message.Data;
        }
      } else if (message.Instrument === "combo") {
        state.multistrikeChartScannerData_ComboStraddles = message.MultipleData;
      }
    } else if (message.MessageType == "client_notification_message_request") {
      let notifType = "success";
      if (message.NotificationMessage.NotificationType === 1)
        notifType = "success";
      else if (message.NotificationMessage.NotificationType === 2)
        notifType = "warn";
      else if (message.NotificationMessage.NotificationType === 3)
        notifType = "error";

      notify({
        title: message.NotificationMessage.Title,
        text: message.NotificationMessage.Message,
        type: notifType,
        duration: message.NotificationMessage.Duration,
      });
    } else if (message.MessageType == "risk_management_profile_response") {
      state.riskManagementProfiles = message.Profiles;
    } else if (message.MessageType == "market_status_update_message") {
      state.marketStatus = message.Status;
    } else if (message.MessageType == "execution_mode_status_update_message") {
      state.executionMode = message.ExecutionMode;
      if (state.executionMode == "SIMULATION") {
        state.showHideOptionsSimulator = true;
      }
    } else if (message.MessageType == "get_algo_scheduler_settings_response") {
      state.algoSchedulerSettings = message.Settings;
    } else if (
      message.MessageType == "update_algo_scheduler_setting_response"
    ) {
      if (message.Status == 1) {
        if (message.Action === 1) {
          // Add
          state.algoSchedulerSettings.push(message.Setting);
        } else if (message.Action === 2) {
          // Update
          let index = state.algoSchedulerSettings.findIndex(
            (x) => parseInt(message.Setting.Id) == parseInt(x.Id)
          );
          if (index !== -1) {
            // state.riskManagementProfiles[index] = message.Profile
            // state.riskManagementProfiles.splice(index, 1)
            state.algoSchedulerSettings[index].AlgoType =
              message.Setting.AlgoType;
            state.algoSchedulerSettings[index].Instrument =
              message.Setting.Instrument;
            state.algoSchedulerSettings[index].EntryTime =
              message.Setting.EntryTime;
            state.algoSchedulerSettings[index].ExitTime =
              message.Setting.ExitTime;
            state.algoSchedulerSettings[index].ExecutionType =
              message.Setting.ExecutionType;
            state.algoSchedulerSettings[index].BrokerId =
              message.Setting.BrokerId;
            state.algoSchedulerSettings[index].Multiplier =
              message.Setting.Multiplier;
          }
          // state.riskManagementProfiles.push(message.Profile)
        } else if (message.Action === 3) {
          // Delete
          let index = state.algoSchedulerSettings.findIndex(
            (x) => parseInt(message.Setting.Id) == parseInt(x.Id)
          );
          if (index !== -1) {
            state.algoSchedulerSettings.splice(index, 1);
          }
        }
        this.dispatch(
          "showMessageAlert",
          `Algo Scheduler Setting updated successfully - ${message.Setting.Id}`
        );
      } else {
        this.dispatch(
          "showMessageAlert",
          `Algo Scheduler Setting Update - ${message.Message}`
        );
      }
    } else if (
      message.MessageType == "update_risk_management_profile_response"
    ) {
      if (message.Status == 1) {
        if (message.Action === 1) {
          // Add
          state.riskManagementProfiles.push(message.Profile);
        } else if (message.Action === 2) {
          // Update
          let index = state.riskManagementProfiles.findIndex(
            (x) => parseInt(message.Profile.Id) == parseInt(x.Id)
          );
          if (index !== -1) {
            // state.riskManagementProfiles[index] = message.Profile
            // state.riskManagementProfiles.splice(index, 1)
            state.riskManagementProfiles[index].Name = message.Profile.Name;
            state.riskManagementProfiles[index].Instrument =
              message.Profile.Instrument;
            state.riskManagementProfiles[index].StopTargetType =
              message.Profile.StopTargetType;
            state.riskManagementProfiles[index].Stop = message.Profile.Stop;
            state.riskManagementProfiles[index].Target = message.Profile.Target;
            state.riskManagementProfiles[index].Trail = message.Profile.Trail;
            state.riskManagementProfiles[index].IsPerLegSL =
              message.Profile.IsPerLegSL;
            state.riskManagementProfiles[index].PerLegSLType =
              message.Profile.PerLegSLType;
            state.riskManagementProfiles[index].PerLegSLValue =
              message.Profile.PerLegSLValue;
            state.riskManagementProfiles[index].UseDeltaBalanceAdjustment =
              message.Profile.UseDeltaBalanceAdjustment;
            state.riskManagementProfiles[index].UseReEntryAdjustment =
              message.Profile.UseReEntryAdjustment;
            state.riskManagementProfiles[index].FuturesBasedSLTarget =
              message.Profile.FuturesBasedSLTarget;
            state.riskManagementProfiles[index].FuturesSLCondition =
              message.Profile.FuturesSLCondition;
            state.riskManagementProfiles[index].FuturesSLValue =
              message.Profile.FuturesSLValue;
            state.riskManagementProfiles[index].FuturesTargetCondition =
              message.Profile.FuturesTargetCondition;

            state.riskManagementProfiles[index].FuturesTargetValue =
              message.Profile.FuturesTargetValue;
            state.riskManagementProfiles[index].Editing =
              message.Profile.Editing;
            state.riskManagementProfiles[index].IsDefault =
              message.Profile.IsDefault;
            state.riskManagementProfiles[index].ScheduleOnExecution =
              message.Profile.ScheduleOnExecution;
          }
          // state.riskManagementProfiles.push(message.Profile)
        } else if (message.Action === 3) {
          // Delete
          let index = state.riskManagementProfiles.findIndex(
            (x) => parseInt(message.Profile.Id) == parseInt(x.Id)
          );
          if (index !== -1) {
            state.riskManagementProfiles.splice(index, 1);
          }
        }
        this.dispatch(
          "showMessageAlert",
          `Risk Management Profile updated successfully - ${message.Profile.Name}`
        );
      } else {
        this.dispatch(
          "showMessageAlert",
          `Risk Management Profile Update - ${message.Message}`
        );
      }
    } else if (
      message.MessageType == "positions_technical_analysis_chart_data_response"
    ) {
      //state.positionTechnicalAnalysisData = message.Data
      if (message.ForMultiChart) {
        let basket = state.multiChartViewBaskets.find(
          (x) => parseInt(x.id) === parseInt(message.StrategyId)
        );
        if (basket) {
          basket.ohlc = message.Data.OHLC;
          basket.volume = message.Data.Volume;
          basket.min = message.Data.Min;
          basket.max = message.Data.Max;
          basket.chartTitle = message.Data.Title;
          basket.isLoading = false;
          // if (message.TrackingId === state.trackingId)
          //   this.dispatch("sendSaveMultiChartViewBasketsCommand");
        }
      } else {
        if (message.StrategyType == 1) {
          // for basket
          let basket = state.orderBaskets.find(
            (x) => parseInt(x.id) === parseInt(message.StrategyId)
          );
          if (basket) {
            basket.positionTechnicalAnalysisData = message.Data;
          }
        } else if (message.StrategyType == 2) {
          // for basket group
          let basketGroup = state.basketGroups.find(
            (x) => parseInt(x.id) === parseInt(message.StrategyId)
          );
          if (basketGroup) {
            basketGroup.positionTechnicalAnalysisData = message.Data;
          }
        }
      }
      //state.positionTechnicalAnalysisDataChartTitle = message.Data.Title
    } else if (
      message.MessageType ==
      "refresh_oi_analysis_strike_wise_oi_change_response"
    ) {
      state.oiAnalysisStrikeWiseData = message;
      state.oiAnalysisStrikeWiseDataArrived = new Date();
    } else if (message.MessageType == "add_remove_basket_group_request") {
      if (message.TrackingId == state.trackingId) return;

      let index = state.basketGroups.findIndex(
        (x) => parseInt(x.id) === parseInt(message.BasketGroup.id)
      );

      if (message.Action === 1) {
        // Add
        if (index === -1) {
          state.basketGroups.push(message.BasketGroup);
        }
      } else if (message.Action == 2) {
        // Remove
        if (index !== -1) state.basketGroups.splice(index, 1);
      }
    } else if (
      message.MessageType == "update_basket_properties_client_response"
    ) {
      if (message.TrackingId === state.trackingId) return;

      // let PropertyInfo = message.PropertyInfo;

      let basket = state.orderBaskets.find(
        (x) => parseInt(x.id) === parseInt(message.PropertyInfo.Id)
      );
      if (basket != null) {
        if (message.PropertyInfo.UpdateType === 1) {
          if (message.PropertyInfo.UpdateParamater.PropertyType != null) {
            let propValue = "";
            if (
              message.PropertyInfo.UpdateParamater.PropertyType === "Boolean"
            ) {
              propValue =
                message.PropertyInfo.UpdateParamater.PropertyValue.toLowerCase() ===
                "true"
                  ? true
                  : false;
            } else if (
              message.PropertyInfo.UpdateParamater.PropertyType === "Decimal"
            ) {
              propValue = parseFloat(
                message.PropertyInfo.UpdateParamater.PropertyValue
              );
            } else if (
              message.PropertyInfo.UpdateParamater.PropertyType === "Integer" ||
              message.PropertyInfo.UpdateParamater.PropertyType === "Long" ||
              message.PropertyInfo.UpdateParamater.PropertyType === "Short"
            ) {
              propValue = parseInt(
                message.PropertyInfo.UpdateParamater.PropertyValue
              );
            } else {
              propValue = parseInt(
                message.PropertyInfo.UpdateParamater.PropertyValue
              );
            }

            basket[message.PropertyInfo.UpdateParamater.PropertyName] =
              propValue;
            // if (!_.isEmpty(state.activeExecutedBasket))
            // state.activeExecutedBasket[message.PropertyInfo.UpdateParamater.PropertyName] = propValue;
          } else {
            basket[message.PropertyInfo.UpdateParamater.PropertyName] =
              message.PropertyInfo.UpdateParamater.PropertyValue;
            // if (!_.isEmpty(state.activeExecutedBasket))
            // state.activeExecutedBasket[message.PropertyInfo.UpdateParamater.PropertyName] = message.PropertyInfo.UpdateParamater.PropertyValue;
          }
        } else {
          message.PropertyInfo.UpdateParamaters.forEach((parameter) => {
            if (parameter.PropertyType != null) {
              let propValue = "";
              if (parameter.PropertyType === "Boolean") {
                propValue =
                  parameter.PropertyValue.toLowerCase() === "true"
                    ? true
                    : false;
              } else if (parameter.PropertyType === "Decimal") {
                propValue = parseFloat(parameter.PropertyValue);
              } else if (
                parameter.PropertyType === "Integer" ||
                parameter.PropertyType === "Long" ||
                parameter.PropertyType === "Short"
              ) {
                propValue = parseInt(parameter.PropertyValue);
              } else {
                propValue = parseInt(parameter.PropertyValue);
              }

              basket[parameter.PropertyName] = propValue;
              // if (!_.isEmpty(state.activeExecutedBasket))
              // state.activeExecutedBasket[parameter.PropertyName] = propValue;
            } else {
              // if (!_.isEmpty(state.activeExecutedBasket))
              // state.activeExecutedBasket[parameter.PropertyName] = parameter.PropertyValue;
              basket[parameter.PropertyName] = parameter.PropertyValue;
            }
          });
        }
      }
    } else if (
      message.MessageType == "update_basket_group_properties_client_response"
    ) {
      if (message.TrackingId === state.trackingId) return;

      // let PropertyInfo = message.PropertyInfo;

      let basketGroup = state.basketGroups.find(
        (x) => parseInt(x.id) === parseInt(message.PropertyInfo.Id)
      );
      if (basketGroup != null) {
        if (message.PropertyInfo.UpdateType === 1) {
          if (message.PropertyInfo.UpdateParamater.PropertyType != null) {
            let propValue = "";
            if (
              message.PropertyInfo.UpdateParamater.PropertyType === "Boolean"
            ) {
              propValue =
                message.PropertyInfo.UpdateParamater.PropertyValue.toLowerCase() ===
                "true"
                  ? true
                  : false;
            } else if (
              message.PropertyInfo.UpdateParamater.PropertyType === "Decimal"
            ) {
              propValue = parseFloat(
                message.PropertyInfo.UpdateParamater.PropertyValue
              );
            } else if (
              message.PropertyInfo.UpdateParamater.PropertyType === "Integer" ||
              message.PropertyInfo.UpdateParamater.PropertyType === "Long" ||
              message.PropertyInfo.UpdateParamater.PropertyType === "Short"
            ) {
              propValue = parseInt(
                message.PropertyInfo.UpdateParamater.PropertyValue
              );
            } else {
              propValue = parseInt(
                message.PropertyInfo.UpdateParamater.PropertyValue
              );
            }

            basketGroup[message.PropertyInfo.UpdateParamater.PropertyName] =
              propValue;
            // if (!_.isEmpty(state.activeBasketGroup))
            // state.activeBasketGroup[message.PropertyInfo.UpdateParamater.PropertyName] = propValue;
          } else {
            basketGroup[message.PropertyInfo.UpdateParamater.PropertyName] =
              message.PropertyInfo.UpdateParamater.PropertyValue;
            // if (!_.isEmpty(state.activeBasketGroup))
            // state.activeBasketGroup[message.PropertyInfo.UpdateParamater.PropertyName] = message.PropertyInfo.UpdateParamater.PropertyValue;
          }
        } else {
          message.PropertyInfo.UpdateParamaters.forEach((parameter) => {
            if (parameter.PropertyType != null) {
              let propValue = "";
              if (parameter.PropertyType === "Boolean") {
                propValue =
                  parameter.PropertyValue.toLowerCase() === "true"
                    ? true
                    : false;
              } else if (parameter.PropertyType === "Decimal") {
                propValue = parseFloat(parameter.PropertyValue);
              } else if (
                parameter.PropertyType === "Integer" ||
                parameter.PropertyType === "Long" ||
                parameter.PropertyType === "Short"
              ) {
                propValue = parseInt(parameter.PropertyValue);
              } else {
                propValue = parseInt(parameter.PropertyValue);
              }

              basketGroup[parameter.PropertyName] = propValue;
              // if (!_.isEmpty(state.activeBasketGroup))
              // state.activeBasketGroup[parameter.PropertyName] = propValue;
            } else {
              basketGroup[parameter.PropertyName] = parameter.PropertyValue;
              // if (!_.isEmpty(state.activeBasketGroup))
              // state.activeBasketGroup[parameter.PropertyName] = parameter.PropertyValue;
            }
          });
        }
      }
    } else if (
      message.MessageType == "update_basket_group_sl_property_client_response"
    ) {
      if (message.TrackingId === state.trackingId) return;

      // let PropertyInfo = message.PropertyInfo;

      let basketGroup = state.basketGroups.find(
        (x) => parseInt(x.id) === parseInt(message.PropertyInfo.Id)
      );
      if (basketGroup != null) {
        if (message.PropertyInfo.UpdateType === 1) {
          if (message.PropertyInfo.UpdateParamater.PropertyType != null) {
            let propValue = "";
            if (
              message.PropertyInfo.UpdateParamater.PropertyType === "Boolean"
            ) {
              propValue =
                message.PropertyInfo.UpdateParamater.PropertyValue.toLowerCase() ===
                "true"
                  ? true
                  : false;
            } else if (
              message.PropertyInfo.UpdateParamater.PropertyType === "Decimal"
            ) {
              propValue = parseFloat(
                message.PropertyInfo.UpdateParamater.PropertyValue
              );
            } else if (
              message.PropertyInfo.UpdateParamater.PropertyType === "Integer" ||
              message.PropertyInfo.UpdateParamater.PropertyType === "Long" ||
              message.PropertyInfo.UpdateParamater.PropertyType === "Short"
            ) {
              propValue = parseInt(
                message.PropertyInfo.UpdateParamater.PropertyValue
              );
            } else {
              propValue = parseInt(
                message.PropertyInfo.UpdateParamater.PropertyValue
              );
            }

            basketGroup.stopTarget[
              message.PropertyInfo.UpdateParamater.PropertyName
            ] = propValue;
            // if (!_.isEmpty(state.activeBasketGroup))
            // state.activeBasketGroup.stopTarget[message.PropertyInfo.UpdateParamater.PropertyName] = propValue;
          } else {
            basketGroup.stopTarget[
              message.PropertyInfo.UpdateParamater.PropertyName
            ] = JSON.parse(message.PropertyInfo.UpdateParamater.PropertyValue);
            // if (!_.isEmpty(state.activeBasketGroup))
            // state.activeBasketGroup.stopTarget[message.PropertyInfo.UpdateParamater.PropertyName] = JSON.parse(message.PropertyInfo.UpdateParamater.PropertyValue);
          }
        } else {
          message.PropertyInfo.UpdateParamaters.forEach((parameter) => {
            if (parameter.PropertyType != null) {
              let propValue = "";
              if (parameter.PropertyType === "Boolean") {
                propValue =
                  parameter.PropertyValue.toLowerCase() === "true"
                    ? true
                    : false;
              } else if (parameter.PropertyType === "Decimal") {
                propValue = parseFloat(parameter.PropertyValue);
              } else if (
                parameter.PropertyType === "Integer" ||
                parameter.PropertyType === "Long" ||
                parameter.PropertyType === "Short"
              ) {
                propValue = parseInt(parameter.PropertyValue);
              } else {
                propValue = parseInt(parameter.PropertyValue);
              }

              basketGroup.stopTarget[parameter.PropertyName] = propValue;
              // if (!_.isEmpty(state.activeBasketGroup))
              // state.activeBasketGroup.stopTarget[parameter.PropertyName] = propValue;
            } else {
              basketGroup.stopTarget[parameter.PropertyName] = JSON.parse(
                parameter.PropertyValue
              );
              // if (!_.isEmpty(state.activeBasketGroup))
              // state.activeBasketGroup.stopTarget[parameter.PropertyName] = JSON.parse(parameter.PropertyValue);
            }
          });
        }
      }
    } else if (
      message.MessageType ==
      "refresh_oi_analysis_strike_wise_oi_change_profile_response"
    ) {
      state.oiAnalysisStrikeWiseProfileData = message;
      state.oiAnalysisStrikeWiseProfileDataArrived = new Date();
    } else if (
      message.MessageType == "add_remove_executed_order_basket_response"
    ) {
      console.log("add_remove_executed_order_basket_response " + message);

      if (state.trackingId === message.TrackingId) return;

      let index = state.orderBaskets.findIndex(
        (x) => parseInt(x.id) == parseInt(message.Id)
      );

      if (message.Action == 2) {
        // Remove Basket
        if (index !== -1) {
          state.orderBaskets.splice(index, 1);
        }
      } else if (message.Action == 1) {
        // Add Basket
        if (index === -1) {
          state.orderBaskets.push(message.Basket);
        }
      } else if (message.Action == 3) {
        // Update Basket
        // let index = state.orderBaskets.findIndex(x => parseInt(x.id) == parseInt(message.Id))
        if (index !== -1) {
          let basket = state.orderBaskets.find(
            (x) => parseInt(x.id) == parseInt(message.Id)
          );

          // Update parameters manually
          basket.orders = JSON.parse(JSON.stringify(message.Basket.orders));
          basket.squaredOffOrders = JSON.parse(
            JSON.stringify(message.Basket.squaredOffOrders)
          );

          basket.accountMaxLossSL = message.Basket.accountMaxLossSL;
          basket.atCloseCombinedPremium = message.Basket.atCloseCombinedPremium;
          basket.atCloseCombinedPremiumActualsLots =
            message.Basket.atCloseCombinedPremiumActualsLots;
          basket.atOpenCombinedPremium = message.Basket.atOpenCombinedPremium;
          basket.atOpenCombinedPremiumActualsLots =
            message.Basket.atOpenCombinedPremiumActualsLots;
          basket.breakEvens = message.Basket.breakEvens;
          basket.brokerId = message.Basket.brokerId;
          basket.capital = message.Basket.capital;
          basket.currentCombinedPremium = message.Basket.currentCombinedPremium;
          basket.currentCombinedPremiumActualsLots =
            message.Basket.currentCombinedPremiumActualsLots;
          basket.editing = message.Basket.editing;
          basket.executedBasketPayOffPrice =
            message.Basket.executedBasketPayOffPrice;
          basket.executedOn = message.Basket.executedOn;
          // basket.id = request.Basket.id;
          basket.includeInAccountPnl = message.Basket.includeInAccountPnl;
          basket.instrument = message.Basket.instrument;
          basket.isAdjusting = message.Basket.isAdjusting;
          basket.isMultiExpiry = message.Basket.isMultiExpiry;
          basket.isPerLegSL = message.Basket.isPerLegSL;
          basket.isPerLegSLHitOpenNew = message.Basket.isPerLegSLHitOpenNew;
          basket.oppositeQtyToAdd = message.Basket.oppositeQtyToAdd;
          (basket.isShiftToNextOTM = message.Basket.isShiftToNextOTM),
            (basket.deltaBalanceStrikeDifference =
              message.Basket.deltaBalanceStrikeDifference);
          basket.ExecutionMode = message.Basket.ExecutionMode;
          (basket.isPerLegSLReEntry = message.Basket.isPerLegSLReEntry),
            (basket.isStayOTM = message.Basket.isStayOTM),
            (basket.shiftStrikes = message.Basket.shiftStrikes),
            (basket.isFuturesSL = message.Basket.isFuturesSL),
            (basket.futuresSLType = message.Basket.futuresSLType),
            (basket.slTargetBasedOnCandlePrice =
              message.Basket.slTargetBasedOnCandlePrice),
            (basket.fixedCandleSL = message.Basket.fixedCandleSL),
            (basket.underlyingSLCandlePriceType =
              message.Basket.underlyingSLCandlePriceType),
            (basket.underlyingSLCandlePriceCheckType =
              message.Basket.underlyingSLCandlePriceCheckType),
            (basket.underlyingSLCandlesLookbackCount =
              message.Basket.underlyingSLCandlesLookbackCount),
            (basket.persona = message.Basket.persona),
            (basket.signal = message.Basket.signal),
            (basket.futuresSLUnderlyingType =
              message.Basket.futuresSLUnderlyingType),
            (basket.futuresSLComparisionType =
              message.Basket.futuresSLComparisionType), // <= or >=
            (basket.futuresTargetComparisionType =
              message.Basket.futuresTargetComparisionType), // <= or >=
            (basket.futuresSLValue = message.Basket.futuresSLValue),
            (basket.futuresTargetValue = message.Basket.futuresTargetValue),
            (basket.checkExitTime = message.Basket.checkExitTime),
            (basket.exitTime = message.Basket.exitTime),
            (basket.isSelectedForAnalysisChart =
              message.Basket.isSelectedForAnalysisChart);
          basket.isSelectedForAnalysisChartIncludePnL =
            message.Basket.isSelectedForAnalysisChartIncludePnL;
          basket.isSquaredOff = message.Basket.isSquaredOff;
          basket.isStopTargetHit = message.Basket.isStopTargetHit;
          basket.isTesting = message.Basket.isTesting;
          basket.isTrailing = message.Basket.isTrailing;
          basket.lotSize = message.Basket.lotSize;
          basket.lotsToTrade = message.Basket.lotsToTrade;
          basket.marginRequired = message.Basket.marginRequired;
          basket.maxLossType = message.Basket.maxLossType;
          basket.newStop = message.Basket.newStop;
          basket.newTarget = message.Basket.newTarget;
          basket.optionPrice = message.Basket.optionPrice;
          // basket.orders = message.Basket.orders;
          basket.payoffShouldIncludeRealizedPnL =
            message.Basket.payoffShouldIncludeRealizedPnL;
          basket.isSentForBasketStopSquareOff =
            message.Basket.isSentForBasketStopSquareOff;
          basket.perLegSLType = message.Basket.perLegSLType;
          basket.perLegSLValue = message.Basket.perLegSLValue;
          basket.positionMaxLossSL = message.Basket.positionMaxLossSL;
          basket.positionTechnicalAnalysisData =
            message.Basket.positionTechnicalAnalysisData;
          basket.showHidePortfolioSummaryTabs =
            message.Basket.showHidePortfolioSummaryTabs;
          basket.isExpanded = message.Basket.isExpanded;
          basket.premiumSLType = message.Basket.premiumSLType;
          basket.realizedPL = message.Basket.realizedPL;
          basket.realizedPLPoints = message.Basket.realizedPLPoints;
          basket.setupCost = message.Basket.setupCost;
          basket.showGreeks = message.Basket.showGreeks;
          basket.showHideAdjustments = message.Basket.showHideAdjustments;
          basket.showHideAlgoPanel = message.Basket.showHideAlgoPanel;
          basket.showHideInfoPanel = message.Basket.showHideInfoPanel;
          basket.showHideOrdersPanel = message.Basket.showHideOrdersPanel;
          basket.showHideTechnicalAnalysisPanel =
            message.Basket.showHideTechnicalAnalysisPanel;
          basket.showLogMessages = message.Basket.showLogMessages;
          basket.showPremiumAndPointsForOneLot =
            message.Basket.showPremiumAndPointsForOneLot;
          basket.squaredOffOn = message.Basket.squaredOffOn;
          // basket.squaredOffOrders = message.Basket.squaredOffOrders;
          basket.squareOffMode = message.Basket.squareOffMode;
          basket.status = message.Basket.status;
          basket.stop = message.Basket.stop;
          basket.stopTargetType = message.Basket.stopTargetType;
          basket.strategyExecutionMessages =
            message.Basket.strategyExecutionMessages;
          basket.strategyExecutionStatus =
            message.Basket.strategyExecutionStatus;
          basket.strategyName = message.Basket.strategyName;
          basket.target = message.Basket.target;
          basket.totalPnl = message.Basket.totalPnl;
          basket.totalPnlPoints = message.Basket.totalPnlPoints;
          basket.totalPnlPointsActualLots =
            message.Basket.totalPnlPointsActualLots;
          basket.trail = message.Basket.trail;
          basket.trailBy = message.Basket.trailBy;
          basket.underlyingPriceAtClose = message.Basket.underlyingPriceAtClose;
          basket.underlyingPriceAtOpen = message.Basket.underlyingPriceAtOpen;
          (basket.futuresPriceAtOpen = message.Basket.futuresPriceAtOpen),
            (basket.futuresPriceAtClose = message.Basket.futuresPriceAtClose),
            (basket.VixAtSquarOff = message.Basket.VixAtSquarOff);
          basket.vixWhenExecuted = message.Basket.vixWhenExecuted;
          basket.brokerageAndTaxes = message.Basket.brokerageAndTaxes;
          basket.groupId = message.Basket.groupId;

          if (
            state.activeExecutedBasket &&
            parseInt(state.activeExecutedBasket.id) == parseInt(message.Id)
          ) {
            state.activeExecutedBasket = basket;
          }
        }
      }
    } else if (
      message.MessageType == "refresh_oi_analysis_history_dates_response"
    ) {
      state.oiChangeHistoryDates = message.Dates;
      state.oiChangeHistoryDates.unshift("Select");
      state.oiChangeHistoryTimesForADate = [];
    } else if (message.MessageType == "algo_strategy_cllient_response") {
      let basket = state.orderBaskets.find((x) => {
        if (x && message.StrategyId)
          return x.id.toString() === message.StrategyId.toString();
        else return false;
      });
      if (basket) {
        if (message.ActionType === "schedule" && message.IsSuccess) {
          basket.strategyExecutionStatus = "scheduled";
        } else if (message.ActionType === "stop" && message.IsSuccess) {
          basket.strategyExecutionStatus = "stopped";
        } else if (!message.IsSuccess)
          basket.strategyExecutionStatus =
            "Strategy " +
            message.ActionType +
            " errors. Please refer strategy execution message log for more details.";
      }
    } else if (message.MessageType == "algo_strategy_log_cllient_response") {
      let basket = state.orderBaskets.find((x) => {
        if (x && message.StrategyId)
          return x.id.toString() === message.StrategyId.toString();
        else return false;
      });
      if (basket && basket.strategyExecutionMessages) {
        // keep only 50 message in the array
        basket.strategyExecutionMessages.unshift(message.LogMessage);

        if (basket.strategyExecutionMessages.length > 50)
          basket.strategyExecutionMessages.pop();
      }
    } else if (
      message.MessageType == "algo_strategy_trailing_update_cllient_response"
    ) {
      let basket = state.orderBaskets.find((x) => {
        if (x && message.StrategyId)
          return x.id.toString() === message.StrategyId.toString();
        else return false;
      });
      if (basket) {
        basket.isTrailing = message.IsTrailing;
        basket.newStop = message.NewStop;
        basket.newTarget = message.NewTarget;
        basket.trailBy = message.TrailBy;

        // Update the existing stop & target as well
        basket.stop = message.NewStop;
        basket.target = message.NewTarget;

        /*
        if (state.activeExecutedBasket) {
          state.activeExecutedBasket.isTrailing = message.IsTrailing
          state.activeExecutedBasket.newStop = message.NewStop
          state.activeExecutedBasket.newTarget = message.NewTarget
          state.activeExecutedBasket.trailBy = message.TrailBy
        }
        */
      }
    } else if (
      message.MessageType == "refresh_oi_analysis_history_times_response"
    ) {
      state.oiChangeHistoryTimesForADate = message.Times;
      state.oiChangeHistoryTimesForADate.unshift("Select");
    } else if (message.MessageType == "option_chain_response") {
      state.optionChain = message;
      state.isLoadingOptionChain = false;
    } else if (message.MessageType == "ba") {
      // BID ASK SPREAD
      console.log("ba " + message);
      state.bidAskSpread.symbol = message.s;
      state.bidAskSpread.bidPrice = message.b;
      state.bidAskSpread.askPrice = message.a;
      state.bidAskSpread.spread = message.sp;
      state.bidAskSpread.loading = false;
    } else if (message.MessageType == "bot_square_off_request") {
      state.botMessages.squareOffStrategy =
        message.Strategy + ";" + Utils.getRandomNumber(1, 999999);
    } else if (message.MessageType == "bot_square_off_confirmed") {
      state.botMessages.squareOffStrategyConfirmed =
        message.Strategy + ";" + Utils.getRandomNumber(1, 999999);
    } else if (message.MessageType == "bot_strategy_square_off_list_request") {
      state.botMessages.squareOffStrategyList = Utils.getRandomNumber(
        1,
        999999
      );
    } else if (message.MessageType == "atm_strike_response") {
      state.niftyAtmStrike = message.NiftyStrike;
      state.bankNiftyAtmStrike = message.BankNiftyStrike;
      state.sensexAtmStrike = message.SensexStrike;
      state.finNiftyAtmStrike = message.FinNiftyStrike;
      state.midcapNiftyAtmStrike = message.MidcapNiftyStrike;
    } else if (message.MessageType == "get_expiries_client_response") {
      state.expiries = message.Expiries;
      state.monthlies = message.Monthlies;
      state.finNiftyExpiries = message.FinNiftyExpiries;
      state.finNiftyMonthlies = message.FinNiftyMonthlies;
      state.midcapNiftyExpiries = message.MidcapNiftyExpiries;
      state.midcapNiftyMonthlies = message.MidcapNiftyMonthlies;
      state.bankNiftyExpiries = message.BankNiftyExpiries;
      state.bankNiftyMonthlies = message.BankNiftyMonthlies;
      state.sensexExpiries = message.SensexExpiries;
      state.sensexMonthlies = message.SensexMonthlies;
      state.holidays = message.Holidays;

      state.optionSimulatorParams.datePickerDisabledDates.dates = [];
      if (state.holidays) {
        state.holidays.forEach((date) => {
          let dt = moment(date);
          state.optionSimulatorParams.datePickerDisabledDates.dates.push(
            dt.toDate()
          );
        });
      }
    } else if (
      message.MessageType == "broker_socket_connection_status_response"
    ) {
      state.isBrokerConnected = message.Status == 1 ? true : false;
    } else if (message.MessageType == "get_available_margin_response") {
      state.marginBalance[message.Broker] = message.Balance;
    } else if (message.MessageType == "executed_order_baskets_response") {
      if (state.trackingId !== message.TrackingId) return;

      if (message.OrderBaskets) state.orderBaskets = message.OrderBaskets;

      if (message.BasketGroups) state.basketGroups = message.BasketGroups;

      if (message.MultiChartViewBaskets)
        state.multiChartViewBaskets = message.MultiChartViewBaskets;

      state.isLoadingExecutedOrderBasketsFromServer = false;
      state.isExecutedOrderBasketsRefreshedFromServer = true;
    } else if (message.MessageType == "get_required_margin_response") {
      let basket = state.orderBasketsEditing.find((x) => x.id == message.Tag);
      if (!basket) return;

      basket.marginRequired = message.Result.Total;
      if (state.activeBasketEditing)
        state.activeBasketEditing.marginRequired = message.Result.Total;
    } else if (message.MessageType == "order_update_response") {
      // No duplicate order update handling on clinet
      // Based on the order update from server side,
      // send orderbasket update message to all connected client and let client update its baskets property

      // Server baskets object would act as single source of truth

      // No saving of basket entire json command to server
      // Only update specific properties of json

      // for shceduling also

      console.log(`Order Update: ${JSON.stringify(message)}`);

      state.orderBaskets.forEach((orderBasket) => {
        // TODO: Temporarily disabling moving to square off
        let squaredOffOrders = [];

        let underlyingPrice = 0;

        if (orderBasket.instrument === "nifty")
          underlyingPrice = state.instruments["NIFTY 50"]
            ? state.instruments["NIFTY 50"].Ltp
            : 0;
        else if (orderBasket.instrument === "banknifty")
          underlyingPrice = state.instruments["NIFTY BANK"]
            ? state.instruments["NIFTY BANK"].Ltp
            : 0;
        else if (orderBasket.instrument === "senex")
          underlyingPrice = state.instruments["SENSEX"]
            ? state.instruments["SENSEX"].Ltp
            : 0;
        else if (orderBasket.instrument === "finnifty")
          underlyingPrice = state.instruments["FINNIFTY"]
            ? state.instruments["FINNIFTY"].Ltp
            : 0;
        else if (orderBasket.instrument === "midcpnifty")
          underlyingPrice = state.instruments["MIDCPNIFTY"]
            ? state.instruments["MIDCPNIFTY"].Ltp
            : 0;

        var futuresPriceObj =
          state.instruments[
            SymbolService.getFuturesSymbolCurrent(
              orderBasket.instrument,
              state.monthlies[0]
            )
          ];
        let futuresPrice = futuresPriceObj ? futuresPriceObj.Ltp : 0.0;

        orderBasket.orders.forEach((order) => {
          var isOrderFound = false;

          if (parseInt(message.Tag) !== parseInt(order.strategyId)) return;

          // console.log(`First comparison: [${message.Tag}]-[${order.strategyName}], [${message.Order.symbol}]-[${order.symbol}], [${message.Order.ProductShortCode}]-[${order.product}], [${message.Broker}]-[${order.broker}]`)

          if (
            message.Order.Symbol == order.symbol &&
            message.Order.ProductShortCode == order.product &&
            message.BrokerId == order.brokerId
          ) {
            isOrderFound = true;
            // When added first to basket, its status will be pending
            if (message.Order.Status === "rejected") {
              order.status = message.Order.Status;
              order.reason = message.Order.Message;
            } else {
              if (order.status === "pending") {
                order.executedPrice = message.Order.AveragePrice;
                order.status = message.Order.Status;
                order.reason = message.Order.Message;
              }
              // if status already compeletd, then second order update is from square off
              else if (order.status === "complete") {
                // && (order.squareOffInitiated || message.Source === 'algo')) {

                if (message.Order.IsAddToExistingPosition) {
                  console.log(
                    `IsAddToExistingPosition: ${
                      message.Order.IsAddToExistingPosition
                    }, order message ${JSON.stringify(message)}`
                  );
                  if (
                    message.Source == "algo" ||
                    message.Source == "algo-add"
                  ) {
                    console.log(
                      `in IsAddToExistingPosition of order.status=complete. message. Source: ${message.Source}`
                    );
                    // message.Order.IsAddToExistingPosition = false
                    // let existingOrder = orderBasket.orders.find(
                    //   (x) => x.symbol == message.Order.Symbol
                    // );
                    // if (existingOrder) {
                    //   // Increase qty + average price
                    //   // through volume weighted average
                    //   let totalValue = 0;
                    //   let totalVolume = 0;
                    //   totalValue +=
                    //     existingOrder.executedPrice * existingOrder.quantity;
                    //   totalVolume += existingOrder.quantity;

                    //   totalValue +=
                    //     message.Order.AveragePrice * message.Order.Qty;
                    //   totalVolume += message.Order.Qty;

                    //   let vwapPrice = totalValue / totalVolume;

                    //   existingOrder.executedPrice = vwapPrice;
                    //   console.log(
                    //     `Existing.qty ${existingOrder.quantity}, order qty: ${message.Order.Qty}`
                    //   );
                    //   existingOrder.quantity += message.Order.Qty;
                    // }
                  }
                } else {
                  order.squaredOffPrice = message.Order.AveragePrice;
                  order.isSquaredOff = true;
                  order.squaredOffOn = new Date();
                  order.isSelectedForAnalysisChart = false;
                  order.isSelectedForAnalysisChartIncludePnL = true;
                  order.underlyingPriceAtClose = underlyingPrice;
                  order.futuresPriceAtClose = futuresPrice;
                  squaredOffOrders.push(order);
                }
                // TODO: Temporarily disabling moving to square off
              }
            }
          }

          // console.log(`IsOrderFound - ${isOrderFound}, Strike: ${message.Order.Strike}, [${message.Tag}]-[${orderBasket.strategyName}], Source: ${message.Source} `)

          if (
            isOrderFound == false &&
            message.Order.Strike !== 0 &&
            parseInt(message.Tag) == parseInt(orderBasket.id) &&
            message.Source == "algo"
          ) {
            // Added by strategy manager
            // add to the executed order book
            // Get a copy of existing order
            // Update the required values

            console.log(`Is Order Found. message source : ${message.Source}`);
            // if same symbol already added to the basket do not add
            if (
              !orderBasket.orders.find((x) => x.symbol == message.Order.Symbol)
            ) {
              let orderCopy = JSON.parse(JSON.stringify(orderBasket.orders[0]));
              orderCopy.id = Math.floor(Math.random() * 999999);
              orderCopy.optionType = message.Order.OptionType;
              orderCopy.symbol = message.Order.Symbol;
              orderCopy.strike = message.Order.Strike;
              orderCopy.transactionType =
                message.Order.TransactionType.toLowerCase();
              orderCopy.quantity = message.Order.Qty;
              // orderCopy.lotSize = SymbolService.getMarketLot('nifty')   // TODO - Change Lot Size
              orderCopy.priceWhenAddedToBasket = 0;
              orderCopy.executedOn = new Date();
              orderCopy.editing = false;
              // orderCopy.expiry: payload.expiry,
              orderCopy.executedPrice = message.Order.AveragePrice;
              orderCopy.bsPrice = 0;
              orderCopy.includeInPayoff = true;
              // orderCopy.greeksAtExecution: greeks,
              // orderCopy.greeksAtNow: greeks,
              // orderCopy.expiryEdited: expiry,
              orderCopy.status = message.Order.Status;
              // orderCopy.brokerageAndTaxes: 0,
              // orderCopy.instrumentType: payload.instrumentType,
              orderCopy.isSquaredOff = false;
              orderCopy.squaredOffPrice = 0;
              // orderCopy.underlyingPriceAtOpen: underlyingPrice,
              orderCopy.underlyingPriceAtClose = 0.0;
              orderCopy.futuresPriceAtClose = 0.0;

              orderCopy.isSelectedForAnalysisChart = true;
              orderCopy.isSelectedForAnalysisChartIncludePnL = true;

              // Update pnlPoints & PL
              let ltp = state.instruments[orderCopy.symbol]
                ? state.instruments[orderCopy.symbol].Ltp
                : 0;
              if (orderCopy.transactionType == "buy") {
                orderCopy.pl =
                  (ltp - orderCopy.executedPrice) *
                  orderCopy.quantity *
                  orderCopy.lotSize;
                // totalPnl +=  order.pl
              } else {
                orderCopy.pl =
                  (orderCopy.executedPrice - ltp) *
                  orderCopy.quantity *
                  orderCopy.lotSize;
              }

              orderCopy.plPoints =
                orderCopy.pl / (orderCopy.lotSize * orderCopy.quantity);

              orderBasket.orders.push(orderCopy);
            }
          }
        });

        //if source == algo-add
        // tag matches with the strategy, just add to the orders
        // console.log(`Algo-add: Source: ${message.Source}, Tag [${message.Tag}]-[${orderBasket.strategyName}]`)

        if (
          message.Source == "algo-add" &&
          parseInt(message.Tag) == parseInt(orderBasket.id)
        ) {
          if (message.Order.IsAddToExistingPosition == true) {
            console.log(
              `in IsAddToExistingPosition order not found in previus condition but tag matched. message. Source: ${message.Source}`
            );

            let existingOrder = orderBasket.orders.find(
              (x) => x.symbol == message.Order.Symbol
            );
            if (existingOrder) {
              console.log(`found matching ordeer to update vwap price`);

              // Increase qty + average price
              // through volume weighted average
              let totalValue = 0;
              let totalVolume = 0;
              totalValue +=
                existingOrder.executedPrice * (existingOrder.quantity * existingOrder.lotSize);
              totalVolume += (existingOrder.quantity * existingOrder.lotSize);

              totalValue += message.Order.AveragePrice * (message.Order.Qty * existingOrder.lotSize);
              totalVolume += message.Order.Qty * existingOrder.lotSize;

              let vwapPrice = totalValue / totalVolume;

              existingOrder.executedPrice = vwapPrice;
              console.log(
                `Existing.qty ${existingOrder.quantity}, order qty: ${message.Order.Qty}`
              );
              existingOrder.quantity += message.Order.Qty;
            }
            console.log("Algo add IsAddToExising Postion");
          } else {
            let orderCopy = {};
            if (orderBasket.orders.length !== 0) {
              orderCopy = JSON.parse(JSON.stringify(orderBasket.orders[0]));
            } else {
              orderCopy = JSON.parse(
                JSON.stringify(orderBasket.squaredOffOrders[0])
              );
            }

            orderCopy.id = Math.floor(Math.random() * 999999);
            orderCopy.optionType = message.Order.OptionType;
            orderCopy.symbol = message.Order.Symbol;
            orderCopy.strike = message.Order.Strike;
            orderCopy.transactionType =
              message.Order.TransactionType.toLowerCase();
            orderCopy.quantity = message.Order.Qty;
            // orderCopy.lotSize = SymbolService.getMarketLot('nifty')   // TODO - Change Lot Size
            orderCopy.priceWhenAddedToBasket = 0;
            orderCopy.executedOn = new Date();
            orderCopy.editing = false;
            // orderCopy.expiry: payload.expiry,
            orderCopy.executedPrice = message.Order.AveragePrice;
            orderCopy.bsPrice = 0;
            orderCopy.includeInPayoff = true;
            // orderCopy.greeksAtExecution: greeks,
            // orderCopy.greeksAtNow: greeks,
            // orderCopy.expiryEdited: expiry,
            orderCopy.status = message.Order.Status;
            // orderCopy.brokerageAndTaxes: 0,
            // orderCopy.instrumentType: payload.instrumentType,
            orderCopy.isSquaredOff = false;
            orderCopy.squaredOffPrice = 0;
            // orderCopy.underlyingPriceAtOpen: underlyingPrice,
            orderCopy.underlyingPriceAtClose = 0.0;
            orderCopy.futuresPriceAtClose = 0.0;

            orderCopy.isSelectedForAnalysisChart = true;
            orderCopy.isSelectedForAnalysisChartIncludePnL = true;

            // Update pnlPoints & PL
            let ltp = state.instruments[orderCopy.symbol]
              ? state.instruments[orderCopy.symbol].Ltp
              : 0;
            if (orderCopy.transactionType == "buy") {
              orderCopy.pl =
                (ltp - orderCopy.executedPrice) *
                orderCopy.quantity *
                orderCopy.lotSize;
              // totalPnl +=  order.pl
            } else {
              orderCopy.pl =
                (orderCopy.executedPrice - ltp) *
                orderCopy.quantity *
                orderCopy.lotSize;
            }

            orderCopy.plPoints =
              orderCopy.pl / (orderCopy.lotSize * orderCopy.quantity);

            orderBasket.orders.push(orderCopy);
          }
        }
        // TODO: Temporarily disabling moving to square off

        if (squaredOffOrders.length > 0) {
          // orderBasket.squaredOffOrders = JSON.parse(JSON.stringify(squaredOffOrders))

          // remove all these squared off orders from the basket
          squaredOffOrders.forEach((o) => {
            orderBasket.squaredOffOrders.push(JSON.parse(JSON.stringify(o)));

            let index = orderBasket.orders.findIndex(
              (x) => parseInt(x.id) === parseInt(o.id)
            );
            if (index !== -1) {
              orderBasket.orders.splice(index, 1);
            }
          });
        }

        if (orderBasket.squaredOffOrders) {
          let realizedPL = 0;
          let realizedPLPoints = 0;
          orderBasket.squaredOffOrders.forEach((o) => {
            realizedPL += o.pl;
            realizedPLPoints += o.plPoints;
          });
          orderBasket.realizedPL = realizedPL;
          orderBasket.realizedPLPoints = isNaN(parseFloat(realizedPLPoints))
            ? 0
            : realizedPLPoints;
          // isBasketPropsUpated = true
        }

        // If all line items are squared off, square off the strategy
        // TODO: Temprary disable this. Since threr would be a
        // moment where no order will be there in the book during adjutment

        var orderNotSuaredOff = orderBasket.orders.some(
          (order) => order.isSquaredOff == false
        );
        if (!orderNotSuaredOff && orderBasket.sc) {
          orderBasket.isSquaredOff = true;
          orderBasket.squaredOffOn = new Date();

          orderBasket.underlyingPriceAtClose = underlyingPrice;

          orderBasket.VixAtSquarOff = state.instruments["INDIA VIX"].Ltp;

          // this.dispatch("calculateRealizedPnLAndPoints")
        }

        // Handling on server side,
        // All clients will upate its on order baskets locally...

        // console.log('isBasketPropsUpated ' + isBasketPropsUpated);

        // if (isBasketPropsUpated) {
        //   this.dispatch("sendAddRemoveExecutedOrderBasketsCommand", {
        //     id: orderBasket.id,
        //     action: 3, //Update
        //     basket: orderBasket
        //   })
        // }

        this.dispatch("updateBrokerageAndTaxesForBasket", {
          type: "executed",
          id: orderBasket.id,
        });
      });

      this.dispatch("updateActiveExecutedBasketSetupCostAndMultiExpiryFlag");

      /// WARNING !!!! NOT A BETTER WAY TO CALL ACTIONS FROM MUTATIONS
      /// REFACTOR IT LATER ON
      console.log("Saving execute baskets.. from Socket_OnMessage");
      // this.dispatch("sendSaveExecutedOrderBasketsCommand")
    }

    state.socket.message = message;
  },
  // mutations for reconnect methods
  SOCKET_RECONNECT(state, count) {
    console.info("Reconnecting...", state, count);
  },
  SOCKET_RECONNECT_ERROR(state) {
    state.socket.reconnectError = true;
  },
  OptionChainRequested(state) {
    state.isLoadingOptionChain = true;
  },
  UpdateAccountPnl(state, pnl) {
    state.accountPnL = pnl.accountPnL;
    state.realizedPnL = pnl.realizedPnL;

    // Updates happens on next tick hence there might be bit of delay
    // looks like below is slowing down the updates
    // document.title = accountPnl.toFixed(2)
  },
  updateSelectedExpiry(state, value) {
    console.log(`updateSelectedExpiry ${value}`);
    state.selectedOptionChainExpiry = value;
  },
  updateSelectedExpiryNifty(state, value) {
    state.selectedExpiryNifty = value;
  },
  updateSelectedExpiryBankNifty(state, value) {
    state.selectedExpiryBankNifty = value;
  },
  updateSelectedExpiryFinNifty(state, value) {
    state.selectedExpiryFinNifty = value;
  },
  updateSelectedExpiryMidcapNifty(state, value) {
    state.selectedExpiryMidcapNifty = value;
  },
  updateActiveOptionChain(state, value) {
    state.activeOptionChain = value;
  },
  updateDefaultBrokerId(state, value) {
    console.log("updating default broker id ", value);
    state.defaultBrokerId = value;
  },
};

export default mutations;
