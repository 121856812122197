<template>
  <div style="padding:0px">
    <div class="row">
      <OptionChainIndexSpotPanelNew v-if="showSpotPanel"></OptionChainIndexSpotPanelNew>
      <!-- eslint-disable-next-line vue/no-v-for-template-key -->
      <template v-for="(basket, index) in multiChartViewBaskets" :key="index  + parseInt(basket.id)"> 
        
        <div class="col" style="position: relative;">
          <!-- <div class="text-center float-right"> -->
            <a
              type="button"
              href="javascript:void(0)"
              style="margin-bottom:5px; margin-right:10px;position:absolute;left:15px; bottom:75px;z-index:1"
              v-on:click.exact.prevent="requestMultiChartAnalysisData(basket)"
              v-on:click.ctrl.prevent="requestMultiChartAnalysisData()"
              class="btn btn-outline-secondary btn-sm "
              title="Click => Refresh Chart, Ctrl+Click => Refresh All"
            >
              <span class="material-icons" >
              refresh
              </span>
            </a> 

            <a
              type="button"
              href="javascript:void(0)"
              style="margin-bottom:5px; margin-right:10px;position:absolute;left:70px; bottom:75px;z-index:1"
              v-on:click.exact.prevent="removeFromMultiChartAnalysisDataInternal(basket.id)"
              v-on:click.ctrl.prevent="removeFromMultiChartAnalysisDataInternal()"
              class=" btn btn-outline-secondary btn-sm"
              title="Click => Remove This Chart, Ctrl+Click => Remove All Charts"
            >
              <span class="material-icons">
              remove_from_queue
              </span>
            </a> 

            <select
              v-model="basket.chartInterval"
              class="btn"
              style="margin-bottom:5px; margin-right:10px;position:absolute;left:123px; bottom:75px;z-index:1;height:43px;width:75px;padding:5px;"
              title="Interval"
              @change="requestMultiChartAnalysisData(basket)"
            >
              <option
                value="1"
                :selected="basket.chartInterval === 1"
              >
                1M
              </option>
              <option
                value="3"
                :selected="basket.chartInterval === 3"
              >
                3M
              </option>              
              <option
                value="5"
                :selected="basket.chartInterval === 5"
              >
                5M
              </option>
              <option
                value="10"
                :selected="basket.chartInterval === 10"
              >
                10M
              </option>
              <option
                value="15"
                :selected="basket.chartInterval === 15"
              >
                15M
              </option>
              <option
                value="30"
                :selected="basket.chartInterval === 30"
              >
                30M
              </option>
              <option
                value="60"
                :selected="basket.chartInterval === 60"
              >
                1H
              </option>                                                        
            </select>

            <select
              v-model="basket.chartType"
              class="btn"
              style="margin-bottom:5px; margin-right:10px;position:absolute;left:210px; bottom:75px;z-index:1;height:43px;width:100px;padding:5px;"
              title="Interval"
              @change="requestMultiChartAnalysisData(basket)"
            >
              <option
                value="spline"
                :selected="basket.chartType === 'spline'"
              >
                Spline
              </option>
              <option
                value="candlestick"
                :selected="basket.chartType === 'candlestick'"
              >
                Candle
              </option>              
            </select>            

            <a
              type="button"
              href="javascript:void(0)"
              style="margin-bottom:5px; margin-right:10px;position:absolute;left:320px; bottom:75px;z-index:1"
              v-on:click.exact.prevent="makeCopyOfAnalysisBasket(basket.id)"
              class=" btn btn-outline-secondary btn-sm"
              title="Click => Make a Copy"
            >
              <span class="material-icons">
              table_view
              </span>
            </a>             


          <!-- </div>          -->
          <TechnicalAnalysis
            style="height:300px; width:100%;"
            :ohlc="basket.ohlc"
            :min="basket.min"
            :max="basket.max"
            :volume="basket.volume"
            :chartTitle="basket.chartTitle"
            :showHideStopTarget="basket.showHideStopTarget"
            :stop="basket.stop"
            :target="basket.target"
            :isLoading="basket.isLoading"
            :executed="basket.atOpenCombinedPremium"
            :chartType="basket.chartType"
            :showVolume="basket.chartTitle.includes('FUT,')  ?  true : false"
            :showNavigator=false            
          ></TechnicalAnalysis>
        </div>        
        <div v-if="((parseInt(index)+1) % maxGridColumns) === 0" class="w-100" :key="index + parseInt(basket.id) + Math.random() * 99999"></div>
      </template>
    </div>

    <!-- <OptionsSimulatorEx v-show="showHideOptionsSimulator"></OptionsSimulatorEx> -->
  </div>
</template>

<script>
// import OIChangeAnalysis from '@/components/OIChangeAnalysis'
import moment from "moment";
import { mapState, mapActions } from "vuex";
import TechnicalAnalysis from "@/components/TechnicalAnalysis";
import OptionChainIndexSpotPanelNew from "@/components/OptionChainIndexSpotPanelNew";
// import OptionsSimulatorEx from '@/components/OptionsSimulatorEx'

export default {
  name: 'AdvanceMultiChart',
  components: {
    // OIChangeAnalysis
    TechnicalAnalysis,
    OptionChainIndexSpotPanelNew,
    // OptionsSimulatorEx
  },
  data () { 
    return {
      maxGridColumns: 3,
  }},  
  computed: {
    ...mapState({
      multiChartViewBaskets: (state) => state.multiChartViewBaskets,
      trackingId: (state) => state.trackingId,
      showHideOptionsSimulator: state => state.showHideOptionsSimulator,
      optionSimulatorParams: state => state.optionSimulatorParams,
      selectedTimeInUnix: state => state.optionSimulatorParams.selectedTimeInUnix
    }),
  },
 watch: {
    // feedArrived: function() {
    //   if (this.orderBaskets && this.orderBaskets.length > 0)
    //     this.updateTotalPL();
    // },
    selectedTimeInUnix: function () {
      if (this.showHideOptionsSimulator)
        this.requestMultiChartAnalysisData()
    }
  },
  props: {
    showSpotPanel: {
      default: true,
      type: Boolean
    }
  },
  methods: {
    ...mapActions([
      "sendMessage",
      "removeFromMultiChartAnalysisData",
      "makeCopyOfAnalysisBasket"
    ]),
    requestMultiChartAnalysisData (basket) {
      if (basket) {
        basket.isLoading = true;
        
        var requestChartData = {
          MessageType: "positions_technical_analysis_chart_data_request",
          TrackingId: this.trackingId,
          Orders: basket.orders,
          Interval: basket.chartInterval,
          ShouldConsiderStopTargetForYAxisRange: false,
          Stop: basket.stop,
          Target: basket.target,
          StrategyId: basket.id,
          StrategyType: 1, // 1 - basket, 2 - basket group
          ForMultiChart: true,
        };

        if (this.showHideOptionsSimulator) {
          requestChartData.IsSimulationHistoryRequest = true
          requestChartData.HistoryForDate = moment(this.optionSimulatorParams.onDate).format('DD-MMM-YYYY HH:mm')
          requestChartData.HistoryFrom = moment(this.optionSimulatorParams.onDate).set({hour: 9, minute: 15}).format('DD-MMM-YYYY HH:mm')
          requestChartData.HistoryTo = moment(this.optionSimulatorParams.selectedTimeInUnix).format('DD-MMM-YYYY HH:mm')
        }        

        this.sendMessage(JSON.stringify(requestChartData));
      } else {
        this.multiChartViewBaskets.forEach(b => {
          b.isLoading = true;
          
          var requestChartData = {
            MessageType: "positions_technical_analysis_chart_data_request",
            TrackingId: this.trackingId,
            Orders: b.orders,
            Interval: b.chartInterval,
            ShouldConsiderStopTargetForYAxisRange: false,
            Stop: b.stop,
            Target: b.target,
            StrategyId: b.id,
            StrategyType: 1, // 1 - basket, 2 - basket group
            ForMultiChart: true
          };

          if (this.showHideOptionsSimulator) {
            requestChartData.IsSimulationHistoryRequest = true
            requestChartData.HistoryForDate = moment(this.optionSimulatorParams.onDate).format('DD-MMM-YYYY HH:mm')
            requestChartData.HistoryFrom = moment(this.optionSimulatorParams.onDate).set({hour: 9, minute: 15}).format('DD-MMM-YYYY HH:mm')
            requestChartData.HistoryTo = moment(this.optionSimulatorParams.selectedTimeInUnix).format('DD-MMM-YYYY HH:mm')
          }             

          this.sendMessage(JSON.stringify(requestChartData));          
        });
      }
    },
    removeFromMultiChartAnalysisDataInternal(basketId) {
      let message = "";
      let messageTitle = "";

      if (basketId) {
        message = "Are you sure you want to remove this chart from view?";
        messageTitle = "Remove Chart";
      }
      else {
        message = "Are you sure you want to clear all charts?";
        messageTitle = "Clear All Charts";
      }

      this.$simpleAlert.confirm(message,  messageTitle, 'question')
          .then(() => {
            this.removeFromMultiChartAnalysisData(basketId)
          })
          .catch(() => {
          
          })           
    }
  },
}
</script>

<style scoped>
.col {
  padding-right: 0px;
  padding-left: 0px;  
}
</style>
