import moment from 'moment'
import Api from '@/services/Api'

// Heroku app hosted on izappworld@gmail email
// refer the Container Registry use heroku deployment guide
// Commands 


// First build & tag the docker image
// docker login
// login: theskhan  Sy@23!
// docker build -t theskhan/mzcalc1:latest
// heroku container:login .
// docker run -p 8080:8080 heroku container:login


// IMPORANT - For Heroku deployment, below commands should be engough
//            This will call docker internall to build/tag and push to heroku registry
// heroku login
// login: izappworld@gmail.com Sy@23!
// heroku container:login
// heroku container:push web --app mzcalc1
// heroku container:release web --app mzcalc1

export default {
    getRequiredMargin (params) {

      let query = `${params.instrument.toUpperCase()}$`

      for(let i=0; i< params.orders.length; i++) {
        let order = params.orders[i]
        if (order.status == 'new') continue

        query += `${order.transactionType === 'buy' ? '+' : '-'}${order.quantity * order.lotSize}`

        if (order.instrumentType === 'f') {
          query += `xFUTURES`
        } else {
          query += `x${order.strike}${order.optionType.toUpperCase()}`
        }

        query += `x${moment(order.expiry).format('DDMMMYYYY').toUpperCase()}`

        let price = 0
        if (params.isExecuted)
          price = order.executedPrice
        else
          price = order.priceWhenAddedToBasket

        query += `x${price}`
        query += `x0x0`

        if (i < params.orders.length -1)
          query += '&'
      }
      
      query += '$'

      let url = `https://mzcalc1.herokuapp.com/${query}`
      return Api().get(url)
    },
    getGreeks (params) {

      let query = `${params.instrument.toUpperCase()}$`

      for(let i=0; i< params.orders.length; i++) {
        let order = params.orders[i]
        if (order.status == 'new') continue

        query += `${order.transactionType === 'buy' ? '+' : '-'}${order.quantity * order.lotSize}`

        if (order.instrumentType === 'f') {
          query += `xFUTURES`
        } else {
          query += `x${order.strike}${order.optionType.toUpperCase()}`
        }

        query += `x${moment(order.expiry).format('DDMMMYYYY').toUpperCase()}`

        let price = 0
        if (params.isExecuted)
          price = order.executedPrice
        else
          price = order.priceWhenAddedToBasket

        query += `x${price}`
        query += `x0x0`

        if (i < params.orders.length -1)
          query += '&'
      }

      query += '$'
      query += `${moment(params.expiry).format('YYYY-MM-DD')}$0$0$0`

      let url = `https://mzcalc1.herokuapp.com/greeks/${query}`

      // New url with Greeks
      // http://mzcalc1.herokuapp.com/greeks/NIFTY$+75x13500CEx28JAN2021x481.8x0x0$2021-01-28$0$0$0


      return Api().get(url)      
    },
    getOptionSimulatorTimeStamps (params) {
      // format
      // timestamps/2021-05-17&2021-05-28
      let query = `${params.startDate}&${params.payoffDate}`

      let url = `https://mzcalc1.herokuapp.com/optionssimulator/timestamps/${query}`
      return Api().get(url)
    },
    getOptionSimulatorExpiries () {
      let url = `https://mzcalc1.herokuapp.com/optionssimulator/simulatorexpiries`
      return Api().get(url)
    },
}