<template>
  <div style="width:100%; margin-left:100px" @click="refreshGlobalIndex()" title="Click to refresh Global Indexes">
      <table class="table table-sm text-center bold" style="margin: 0">
        <tr>
          <td>VIX: 
            {{ indiaVIXSpot ? indiaVIXSpot.Ltp.toFixed(2) : -1}} 
            (<span v-bind:class="{red: indiaVIXSpotChange<0, green: indiaVIXSpotChange > 0 }">{{indiaVIXSpotChange.toFixed(2)}}</span> 
            <span class="grey"> | </span> 
            <span v-bind:class="{red: indiaVIXSpotChange<0, green: indiaVIXSpotChange > 0 }">{{indiaVIXSpotChangePercent.toFixed(2)}}%</span>)                      
          </td>
          <td>Nifty: 
            <a href="https://in.tradingview.com/symbols/NSE-NIFTY/" target="_blank">{{ niftySpot ? niftySpot.Ltp.toFixed(2) : 0}}</a> 
            (<span v-bind:class="{red: niftySpotChange<0, green: niftySpotChange > 0 }">{{niftySpotChange.toFixed(2)}}</span> 
            <span class="grey"> | </span> 
            <span v-bind:class="{red: niftySpotChange<0, green: niftySpotChange > 0 }">{{niftySpotChangePercent.toFixed(2)}}%</span>)              
          </td>
          <td>Bank Nifty: 
            <a href="https://in.tradingview.com/symbols/NSE-BANKNIFTY/" target="_blank">{{ bankNiftySpot ? bankNiftySpot.Ltp.toFixed(2) : 0}}</a> 
            (<span v-bind:class="{red: bankNiftySpotChange<0, green: bankNiftySpotChange > 0 }">{{bankNiftySpotChange.toFixed(2)}}</span> 
            <span class="grey"> | </span> 
            <span v-bind:class="{red: bankNiftySpotChange<0, green: bankNiftySpotChange > 0 }">{{bankNiftySpotChangePercent.toFixed(2)}}%</span>)               
          </td>                    
          <td>Fin Nifty:
            <a href="https://in.tradingview.com/symbols/NSE-FINNIFTY/" target="_blank">{{ finNiftySpot ? finNiftySpot.Ltp.toFixed(2) : 0}}</a> 
            (<span v-bind:class="{red: finNiftySpotChange<0, green: finNiftySpotChange > 0 }">{{finNiftySpotChange.toFixed(2)}}</span> 
            <span class="grey"> | </span> 
            <span v-bind:class="{red: finNiftySpotChange<0, green: finNiftySpotChange > 0 }">{{finNiftySpotChangePercent.toFixed(2)}}%</span>)               
          </td>                              
          <td>Midcap Nifty:
            <a href="https://in.tradingview.com/symbols/NSE-NIFTYMIDCAP50/" target="_blank">{{ midcapNiftySpot ? midcapNiftySpot.Ltp.toFixed(2) : 0}}</a> 
            (<span v-bind:class="{red: midcapNiftySpotChange<0, green: midcapNiftySpotChange > 0 }">{{midcapNiftySpotChange.toFixed(2)}}</span> 
            <span class="grey"> | </span> 
            <span v-bind:class="{red: midcapNiftySpotChange<0, green: midcapNiftySpotChange > 0 }">{{midcapNiftySpotChangePercent.toFixed(2)}}%</span>)               
          </td>    
          <td>Sensex:
            <a href="https://in.tradingview.com/symbols/BSE-SENSEX/" target="_blank">{{ sensexSpot ? sensexSpot.Ltp.toFixed(2) : 0}}</a> 
            (<span v-bind:class="{red: sensexSpotChange<0, green: sensexSpotChange > 0 }">{{sensexSpotChange.toFixed(2)}}</span> 
            <span class="grey"> | </span> 
            <span v-bind:class="{red: sensexSpotChange<0, green: sensexSpotChange > 0 }">{{sensexSpotChangePercent.toFixed(2)}}%</span>)               
          </td>                                                 

          <!-- <td>SGX: 
            {{ sgxnifty ? sgxnifty.Ltp.toFixed(2) : 0}} 
            (<span v-bind:class="{red: sgxNiftyChange<0, green: sgxNiftyChange > 0 }">{{sgxNiftyChange.toFixed(2)}}</span> 
            <span class="grey"> | </span> 
            <span v-bind:class="{red: sgxNiftyChange<0, green: sgxNiftyChange > 0 }">{{sgxNiftyChangePercent.toFixed(2)}}%</span>)                      
          </td>

          <td>DOW: 
            {{ dow ? dow.Ltp.toFixed(2) : 0}} 
            (<span v-bind:class="{red: dowChange<0, green: dowChange > 0 }">{{dowChange.toFixed(2)}}</span> 
            <span class="grey"> | </span> 
            <span v-bind:class="{red: dowChange<0, green: dowChange > 0 }">{{dowChangePercent.toFixed(2)}}%</span>)                      
          </td>

          <td>NASDAQ:
            {{ nasdaq ? nasdaq.Ltp.toFixed(2) : 0}}
            (<span v-bind:class="{red: nasdaqChange<0, green: nasdaqChange > 0 }">{{nasdaqChange.toFixed(2)}}</span> 
            <span class="grey"> | </span> 
            <span v-bind:class="{red: nasdaqChange<0, green: nasdaqChange > 0 }">{{nasdaqChangePercent.toFixed(2)}}%</span>)                      
          </td>

          <td>FTSE:
            {{ ftse ? ftse.Ltp.toFixed(2) : 0}}
            (<span v-bind:class="{red: ftseChange<0, green: ftseChange > 0 }">{{ftseChange.toFixed(2)}}</span> 
            <span class="grey"> | </span> 
            <span v-bind:class="{red: ftseChange<0, green: ftseChange > 0 }">{{ftseChangePercent.toFixed(2)}}%</span>)                      
          </td>

          <td>CAC:
            {{ cac ? cac.Ltp.toFixed(2) : 0}} 
            (<span v-bind:class="{red: cacChange<0, green: cacChange > 0 }">{{cacChange.toFixed(2)}}</span> 
            <span class="grey"> | </span> 
            <span v-bind:class="{red: cacChange<0, green: cacChange > 0 }">{{cacChangePercent.toFixed(2)}}%</span>)                      
          </td>          

          <td>DAX:
            {{ dax ? dax.Ltp.toFixed(2) : 0}} 
            (<span v-bind:class="{red: daxChange<0, green: daxChange > 0 }">{{daxChange.toFixed(2)}}</span> 
            <span class="grey"> | </span> 
            <span v-bind:class="{red: daxChange<0, green: daxChange > 0 }">{{cacChangePercent.toFixed(2)}}%</span>)                      
          </td>                     -->
        </tr>
      </table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'OptionChainIndexSpotPanel',
  components: {
    
  },
  data () { 
    return {
    }
  },
  computed: {
    ...mapState({
      accountPnL: state => state.accountPnL,
      activeOptionChain: state => state.activeOptionChain,      
      orderBasketsEditing: state => state.orderBasketsEditing,
      orderBaskets: state => state.orderBaskets,
      niftySpot: state => state.instruments['NIFTY 50'],
      bankNiftySpot: state => state.instruments['NIFTY BANK'],
      sensexSpot: state => state.instruments['SENSEX'],
      finNiftySpot: state => state.instruments['FINNIFTY'],
      midcapNiftySpot: state => state.instruments['MIDCPNIFTY'],      
      indiaVIXSpot: state => state.instruments['INDIA VIX'],

      nasdaq: state => state.instruments['NASDAQ'],
      ftse: state => state.instruments['FTSE'],
      cac: state => state.instruments['CAC'],
      dax: state => state.instruments['DAX'],
      sgxnifty: state => state.instruments['SGXNIFTY'],
      dow: state => state.instruments['DOWFUTURES'],

      botMessages: state => state.botMessages,
      selectedExpiryBankNifty: state => state.selectedExpiryBankNifty,
      niftySpotChange: state => {
        var spot = state.instruments['NIFTY 50']
        var change = spot ? (spot.Ltp - spot.Close) : 0
        return change
      },
      niftySpotChangePercent: state => {
        var spot = state.instruments['NIFTY 50']
        var percent = spot ? (((spot.Ltp - spot.Close)/spot.Ltp) * 100) : 0
        return percent
      },
      bankNiftySpotChange: state => {
        var spot = state.instruments['NIFTY BANK']
        var change = spot ? (spot.Ltp - spot.Close) : 0
        return change
      },
      sensexSpotChange: state => {
        var spot = state.instruments['SENSEX']
        var change = spot ? (spot.Ltp - spot.Close) : 0
        return change
      },      
      bankNiftySpotChangePercent: state => {
        var spot = state.instruments['NIFTY BANK']
        var percent = spot ? (((spot.Ltp - spot.Close)/spot.Ltp) * 100) : 0
        return percent
      },
      finNiftySpotChange: state => {
        var spot = state.instruments['FINNIFTY']
        var change = spot ? (spot.Ltp - spot.Close) : 0
        return change
      },
      finNiftySpotChangePercent: state => {
        var spot = state.instruments['FINNIFTY']
        var percent = spot ? (((spot.Ltp - spot.Close)/spot.Ltp) * 100) : 0
        return percent
      },      
      midcapNiftySpotChange: state => {
        var spot = state.instruments['MIDCPNIFTY']
        var change = spot ? (spot.Ltp - spot.Close) : 0
        return change
      },
      midcapNiftySpotChangePercent: state => {
        var spot = state.instruments['MIDCPNIFTY']
        var percent = spot ? (((spot.Ltp - spot.Close)/spot.Ltp) * 100) : 0
        return percent
      },            
      indiaVIXSpotChange: state => {
        var spot = state.instruments['INDIA VIX']
        var change = spot ? (spot.Ltp -spot.Close) : 0
        return change
      },
      indiaVIXSpotChangePercent: state => {
        var spot = state.instruments['INDIA VIX']
        var percent = spot ? (((spot.Ltp - spot.Close)/spot.Ltp) * 100) : 0
        return percent
      },

      nasdaqChange: state => {
        var spot = state.instruments['NASDAQ']
        var change = spot ? (spot.Ltp - spot.Close) : 0
        return change
      },
      nasdaqChangePercent: state => {
        var spot = state.instruments['NASDAQ']
        var percent = spot ? (((spot.Ltp - spot.Close)/spot.Ltp) * 100) : 0
        return percent
      },

      ftseChange: state => {
        var spot = state.instruments['FTSE']
        var change = spot ? (spot.Ltp - spot.Close) : 0
        return change
      },
      ftseChangePercent: state => {
        var spot = state.instruments['FTSE']
        var percent = spot ? (((spot.Ltp - spot.Close)/spot.Ltp) * 100) : 0
        return percent
      },    
      
      cacChange: state => {
        var spot = state.instruments['CAC']
        var change = spot ? (spot.Ltp - spot.Close) : 0
        return change
      },
      cacChangePercent: state => {
        var spot = state.instruments['CAC']
        var percent = spot ? (((spot.Ltp - spot.Close)/spot.Ltp) * 100) : 0
        return percent
      },
      
      daxChange: state => {
        var spot = state.instruments['DAX']
        var change = spot ? (spot.Ltp - spot.Close) : 0
        return change
      },
      daxChangePercent: state => {
        var spot = state.instruments['DAX']
        var percent = spot ? (((spot.Ltp - spot.Close)/spot.Ltp) * 100) : 0
        return percent
      },

      sgxNiftyChange: state => {
        var spot = state.instruments['SGXNIFTY']
        var change = spot ? (spot.Ltp - spot.Close) : 0
        return change
      },
      sgxNiftyChangePercent: state => {
        var spot = state.instruments['SGXNIFTY']
        var percent = spot ? (((spot.Ltp - spot.Close)/spot.Ltp) * 100) : 0
        return percent
      },
      
      dowChange: state => {
        var spot = state.instruments['DOWFUTURES']
        var change = spot ? (spot.Ltp - spot.Close) : 0
        return change
      },
      dowChangePercent: state => {
        var spot = state.instruments['DOWFUTURES']
        var percent = spot ? (((spot.Ltp - spot.Close)/spot.Ltp) * 100) : 0
        return percent
      },        

      expiries: state => state.expiries,
      finNiftyExpiries: state => state.finNiftyExpiries,
      midcapNiftyExpiries: state => state.midcapNiftyExpiries,
      bankNiftyExpiries: state => state.bankNiftyExpiries,
      sensexExpiries: state => state.sensexExpiries       
    }),
    selectedExpiryNifty: {
      get () {
        return this.$store.state.selectedExpiryNifty
      },
      set (value) {
        this.$store.commit('updateSelectedExpiryNifty', value)
      }
    },
    selectedExpiryBankNifty: {
      get () {
        return this.$store.state.selectedExpiryBankNifty
      },
      set (value) {
        this.$store.commit('updateSelectedExpiryBankNifty', value)
      }
    },
    selectedExpiryFinNifty: {
      get () {
        return this.$store.state.selectedExpiryFinNifty
      },
      set (value) {
        this.$store.commit('updateSelectedExpiryFinNifty', value)
      }
    },
    selectedExpiryMidcapNifty: {
      get () {
        return this.$store.state.selectedExpiryMidcapNifty
      },
      set (value) {
        this.$store.commit('updateSelectedExpiryMidcapNifty', value)
      }
    }        
  },
  methods: {
    ...mapActions([
      'sendMessage',
    ]),
    refreshGlobalIndex () {
      let request = {
        MessageType: 'global_index_refresh_request',
      }

      this.sendMessage(JSON.stringify(request))        
    },
  },
  mounted () {
    this.refreshGlobalIndex()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-row {
  display: block;
  margin-top:5px;
}

/* .table th, .table td { 
  vertical-align: top !important;
} */

</style>
